import React,{Fragment} from 'react'
import { Modal, Input, Card, Button, Avatar} from 'antd'

import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import Scroller from 'components/elements/Scroller'

class Show extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      createPatientVisible: false
    }
    this.handlePatientCreateShow = this.handlePatientCreateShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.editPatient = this.editPatient.bind(this)
  }
  
  get current(){
    return this.props.current || {}
  }

  get administratorOptions(){
    return this.current.administratorOptions || {}
  }

  get serviceRegions(){
      return this.props.serviceRegions || {}
  }

  handlePatientCreateShow(){
    this.setState({
      createPatientVisible: true,
      patientID: null
    })
  }

  handleClose(){
    this.setState({
      createPatientVisible: false
    })
  }

  editPatient(patient, e){
    e.preventDefault()

    this.setState({
      createPatientVisible: true,
      patientID: patient.id
    })
  }

  render() {
    return (
      <Scroller>
        <Card bordered={false}>
          <div className='edit-switch'>
            <Link to={`/admin/administrators/${this.current.id}/edit`}>Edit</Link>
            <Link style={{"float":"right"}} to={`/admin/administrators/${this.current.id}/edit-shifts`}>Edit Shifts</Link>
            
            <h2 className='text-center'>
              {this.current.title} {this.current.firstName} {this.current.middleInitial} {this.current.lastName}
            </h2>
            {this.current.email &&
              <h4 className='text-center'>
                {this.current.email}
              </h4>
            }
            <h4 className='text-center'>
              {this.current.mobilePhone && `mobile:${this.current.mobilePhone}`} 
              {this.current.homePhone && `home:${this.current.homePhone}`}
              {this.current.workPhone && `work:${this.current.workPhone}`}
            </h4>
            
            {this.current.image && 
              <div>
                <Avatar src={this.current.image} shape="square" size={120} />
                <br />
              </div>
            }

            <br />
            PIMS ID: {Object.values(Object(this.current.integrationIDs))[0]}
            <br />
            <label>Appointment Provider:</label> {this.current.provider?'On':'Off'}
            <br />
            <br />
            <label>Notifications:</label> {this.current.receiveNotifications?'On':'Off'}
            <br />
            <br />
            <label>Date of Birth:</label> {this.current.birthday && moment(this.current.birthday).format('M/DD/YY')}
            <br />
            <br />
            <label>Address:</label> {this.current.address}
            <br />
            {this.current.unit &&
              <div>
                <label>Unit:</label> {this.current.unit}
                <br />
              </div>
            }
            <label>City:</label> {this.current.city}
            <br />
            <label>County:</label> {this.current.county}
            <br />
            <label>State:</label> {this.current.state}
            <br />
            <label>Country:</label> {this.current.country}
            <br />
            <label>Zip:</label> {this.current.zip}
            <br />
            <br />
            {this.current.description &&
              <div>
                <label>Description:</label> {this.current.description}
                <br />
                <br />
              </div>
            }
            {this.administratorOptions.maxPatientsPerVisit &&
              <div>
                <label>Max Patients Per Visit:</label> {this.administratorOptions.maxPatientsPerVisit}
              </div>
            }
            {this.administratorOptions.maxPatientsPerTimeSlot &&
              <div>
                <label>Max Patients Per Time Slot:</label> {this.administratorOptions.maxPatientsPerTimeSlot}
              </div>
            }
            {this.administratorOptions.maxVisitsPerTimeSlot &&
              <div>
                <label>Max Visits Per Time Slot:</label> {this.administratorOptions.maxVisitsPerTimeSlot}
              </div>
            }
            <br />
            <br />
            {this.current.serviceRegionID &&
                <Fragment>
                    <div>
                        <label>Default Service Region:</label> {(this.serviceRegions[this.current.serviceRegionID] || {}).name}
                    </div>
                    <br />
                    <br />
                </Fragment>
            }

            <label>ID:</label> {this.current.id} 
            <br />
            <br />
          </div>
        </Card>
      </Scroller>
    )
  }
}

const mapStateToProps = (state) => {
  const administrators = state.administrators
  const serviceRegions = state.serviceRegions

  const {patientCurrent} = state
  
  return {
    administrators,
    serviceRegions
  }
}


export default withRouter(connect(mapStateToProps)(Show))