import './index.less'
import React,{Fragment} from 'react'
import {Button, Row, Col, Input} from 'antd'
import {Spinner} from 'components/elements'
export default (props)=>{
    const className = []
    props.className && className.push(props.className);
    const isSaveFn = typeof props.onSave == "function"
    const isNextFn = typeof props.onNext == "function"
    className.push('general-editor');
    return (
        <div className={className}>
            <div className='container'>
                {props.children}
            </div>
            <div className='footer'>
                <Row>
                    <Col span={12}>
                        {typeof props.onCancel == 'function' && (
                            <Button onClick={props.onCancel} type='danger'>Cancel</Button>
                        )}
                        {typeof props.onBack == 'function' && (
                            <Button onClick={props.onBack} type='danger'>Back</Button>
                        )}
                    </Col>
                    <Col span={12} className='text-right'>
                        {props.spinner ?(
                            <Button type='secondary'>
                                <Spinner />
                            </Button>
                        ) : (
                            <Fragment>
                                {props.onSave && (
                                    isSaveFn ? (
                                        <Button htmlType='submit' onClick={isSaveFn && props.onSave} type='primary'>Save</Button>
                                    ):(
                                        <Button htmlType='submit' type='primary'>Save</Button>
                                    )
                                )}
                                {props.onNext && (
                                    <Button htmlType='submit' onClick={isNextFn && props.onNext} type='primary'>Next</Button>
                                )}
                            </Fragment>
                        )}

                    </Col>
                </Row>
            </div>
        </div>
    )
}