import React from 'react'
import { withRouter } from 'react-router-dom'
import { Card, Layout, Col, Row, List } from 'antd'
import {utils} from 'lib';

import './index.less'
import SectionContent from 'components/elements/SectionContent'
import {CreateOrEditShift} from 'components/partials'
import moment from 'moment'
import {shiftActions,openTimeActions,serviceRegionActions} from 'actions'
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import processRepeating from '../../../lib/processRepeating'

const localizer = momentLocalizer(moment)

const getRepeatingShifts = (responseData, startingAt, endingAt)=>{
    if(!startingAt){
        startingAt = moment()
        endingAt = startingAt.clone().endOf('week')
    }

    if(startingAt.isBefore(moment())){
        startingAt = moment()
        endingAt = startingAt.clone().endOf('week')
    }

    let data = processRepeating({
      data: responseData,
      startingAt,
      endingAt,
      getPastItems: true
    })
  
    data = {
      ...data
    }
  
    return data
}
  
class ShiftCalendar extends React.Component {
    constructor(){
        super()

        this.state = {
            shifts: {},
            currentDate: moment(),
            view: "week"
        };
        this.handleCreate = this.handleCreate.bind(this)
        this.hideCreate = this.hideCreate.bind(this)
        this.handleChangeView = this.handleChangeView.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleNavigate = this.handleNavigate.bind(this)
    }
    async componentDidMount(){
        let shifts = await shiftActions.fetch({administratorID: this.props.match.params.id})
        let $shifts = getRepeatingShifts(Object.values(shifts), this.state.currentDate.clone().startOf("week"), this.state.currentDate.clone().endOf("week"))
        
        this.setState({
            shifts: $shifts
        })
        await openTimeActions.fetch({skipRepeating: true})
        await serviceRegionActions.fetch()
    }

    async componentWillUnmount() { 
         this.setState({
             shifts: {}
         })
        await shiftActions.clear()
    }

    handleSelect(event){
        this.setState({
            event: {
                ...event,
                startsAt: moment(this.props.shifts[event.id].startsAt),
                endsAt: moment(this.props.shifts[event.id].endsAt)
            }
        }, ()=>{
            this.setState({
                createVisible: true,
            })
        })
    }
    handleCreate($event){
        const event = {
            startsAt: moment($event.start),
            endsAt: moment($event.end)
        }
        this.setState({
            event,
        }, ()=>{
            this.setState({
                createVisible: true
            })
        })
    }
    hideCreate(shiftModified){
        let $shifts = this.state.shifts
        if(shiftModified){
            $shifts = getRepeatingShifts(this.shifts, this.state.currentDate.clone().startOf(this.state.view), this.state.currentDate.clone().endOf(this.state.view))
        }
        this.setState({
            createVisible: false,
            event: undefined,
            shifts: $shifts
        })
    }
    handleChangeView(type){
        this.setState({
            view: type
        })
    }
    handleNavigate(date){
        let $currentDate = moment(date)
        let $shifts = getRepeatingShifts(this.shifts, $currentDate.clone().startOf(this.state.view), $currentDate.clone().endOf(this.state.view))

        this.setState({
            currentDate: moment(date),
            shifts: $shifts
        })
    }
    get shifts(){
        return Object.values(this.props.shifts || {})
    }
    get events(){
        return Object.values(this.state.shifts).reduce((acc,$)=>{
            let start = moment($.startsAt).toDate()
            let end = moment($.endsAt).toDate()
            if(moment($.startsAt).format('dd') != moment($.endsAt).format('dd')){
                acc.push({
                    id: $.id,
                    rule: $.rule,
                    repeating: $.repeating || false,
                    start: moment($.startsAt).toDate(),
                    end: moment($.startsAt).endOf('day').toDate(),
                    startsAt: moment($.startsAt).toDate(),
                    endsAt: moment($.startsAt).endOf('day').toDate(),
                    dayOfWeek: $.dayOfWeek,
                    dayOfMonth: $.dayOfMonth,
                    sequence: $.sequence,
                    skipValue: $.skipValue,
                    skipType: $.skipType,
                    repeatsUntil:  $.repeatsUntil ? moment($.repeatsUntil).toDate() : '',
                    serviceRegionID: $.serviceRegionID
                })
                start = moment($.endsAt).startOf('day').toDate()
                end = moment($.endsAt).toDate()
            }
            acc.push({
                rule: $.rule, 
                id: $.id,
                repeating: $.repeating || false,
                start: start,
                end: end,
                startsAt: start,
                endsAt: end,
                dayOfWeek: $.dayOfWeek,
                dayOfMonth: $.dayOfMonth,
                sequence: $.sequence,
                skipValue: $.skipValue,
                skipType: $.skipType,
                repeatsUntil:  $.repeatsUntil ? moment($.repeatsUntil).toDate() : '',
                serviceRegionID: $.serviceRegionID
            })
            return acc
        },[])
    }
    render() {
        
        return (
            <span >
                <Calendar
                    selectable
                    localizer={localizer}
                    defaultDate={new Date()}
                    view={this.state.view}
                    onView={this.handleChangeView}
                    events={this.events}
                    onSelectEvent={this.handleSelect}
                    onSelectSlot={this.handleCreate}
                    onNavigate={this.handleNavigate}
                    views={['week', 'day']}
                    step={15}
                    timeslots={4}
                />
                <CreateOrEditShift 
                    visible={this.state.createVisible} 
                    onClose={this.hideCreate} 
                    shift={this.state.event} />
            </ span>
        )
    }
}

export default withRouter(connect((state)=> state)(ShiftCalendar))