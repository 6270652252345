import {
    CREATE_APPOINTMENT_STATUS, 
    FETCH_APPOINTMENT_STATUSES, 
    UPDATE_APPOINTMENT_STATUS, 
    UPDATE_APPOINTMENT_STATUSES, 
    DESTROY_APPOINTMENT_STATUS,
    SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
    switch (action.type) {
        case CREATE_APPOINTMENT_STATUS:
            return {
                ...state, 
                [`${action.payload.id}`]: action.payload
            }
        case UPDATE_APPOINTMENT_STATUS:
            return {
                ...state, 
                [`${action.payload.id}`]: action.payload
            }
        case UPDATE_APPOINTMENT_STATUSES:
            let payload = action.payload.reduce((acc, item)=>{
                acc[item.repeatingID || item.id] = item
                return acc;
            }, {})

            return {
                ...state,
                ...payload
            }
        case DESTROY_APPOINTMENT_STATUS:
            state = {
                ...state
            }
            delete state[action.payload] 
            return state
        case FETCH_APPOINTMENT_STATUSES:
            return action.payload || state;
        case SET_ORGANIZATION_CURRENT:
                return {}
        default:
            return state
    }
}
  