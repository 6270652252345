import {
  FETCH_SERVICE_AREAS,
  APPEND_SERVICE_AREA,
  DESTROY_SERVICE_AREA,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SERVICE_AREAS:
      return {
        ...state,
        ...action.payload
      }
    case APPEND_SERVICE_AREA:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case DESTROY_SERVICE_AREA:
      state = Object.values(state).filter((s)=>s.id != action.payload).reduce((acc, item)=>{
        acc[item.repeatingID || item.id] = item
        return acc;
      }, {})
      
      return state
    case SET_ORGANIZATION_CURRENT:
        return {}
    default:
      return state
  }
}
