import React, {Component} from "react"

class Pie extends Component {
    constructor(props){
        super(props)

        this.chart = React.createRef('chart')
    }
    componentDidMount(){
        if(this.chart.current){
            this.chart = new Chart(this.chart.current, {
                type: this.props.type,
                data: this.props.data,
                options: Object(this.props.options)
            })
        }
    }
    componentDidUpdate(previousProps){
        if(JSON.stringify(previousProps) != JSON.stringify(this.props) && this.chart.current){
            this.chart.data = this.props.data
            this.chart.update()
        }
    }

    render(){
        return <React.Fragment>
            <canvas ref={this.chart} style={Object(this.props.style)}></canvas>
        </React.Fragment>
    }
}
export default Pie