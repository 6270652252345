import * as ActionTypes from '../constants/actionTypes'
import * as organizationActions from './organizations'
import {apiClient} from '../lib'
import store from 'store'

export const logout = ()=>{
  localStorage.removeItem('store')

  store.dispatch({ type: ActionTypes.LOGOUT_ACCOUNT })
}

export const changeFormFields = formFieldsChange => ({
  type: ActionTypes.LOGIN_EDITFORM_CHANGE,
  field: formFieldsChange,
})

export const login = async(values)=>{

  store.dispatch({ type: ActionTypes.LOGIN_LOGIN_LOAD })

  try{
    const response = await apiClient.accounts.authenticate({
      value: values.value,
      password: values.password,
    })

    store.dispatch({ type: ActionTypes.LOGIN_LOGIN_SUCCEED })
    
    store.dispatch({ 
        type: ActionTypes.LOGIN_ACCOUNT,
        accessToken: response.data.accessToken,
        patients: response.data.patients,
        account: response.data.account,
        organizations: response.data.administration.organizations,
        accountID: values.accountID
    })

    const currentOrganizationID = Object.keys(store.getState().organizations)[0]
    organizationActions.changeCurrent(currentOrganizationID)
    
    organizationActions.fetchTypes()
  }catch(error){
    console.error(error)
    if(String(Object(error.response.data).error).includes("is currently locked, come back later and try again.")){
      store.dispatch({ type: ActionTypes.LOGIN_LOGIN_FAIL, loginError: 'Too many failed login attempts, this account is locked for 30 minutes.' })
    } else {
      store.dispatch({ type: ActionTypes.LOGIN_LOGIN_FAIL, loginError: 'This is a login error.' })
    }
  }
}

export const fetch = async()=>{

  store.dispatch({ type: ActionTypes.LOGIN_LOGIN_LOAD })

  try{
    const response = await apiClient.accounts.authenticate()

    store.dispatch({ type: ActionTypes.LOGIN_LOGIN_SUCCEED })
    
    store.dispatch({ 
        type: ActionTypes.LOGIN_ACCOUNT,
        accessToken: response.data.accessToken,
        patients: response.data.patients,
        account: response.data.account,
        organizations: response.data.administration.organizations,
        accountID: response.data.account.id
    })
  }catch(error){
    console.log(error)
    store.dispatch({ type: ActionTypes.LOGIN_LOGIN_FAIL, loginError: 'This is a login error.' })
  }
}

export const signup = async(values)=>{
    store.dispatch({ type: ActionTypes.LOGIN_LOGIN_LOAD })
    
    try{
        if(store.getState().session.accessToken){
            values.proxy = true
        }
        const response = await apiClient.accounts.create(values)
        
        store.dispatch({ type: ActionTypes.LOGIN_LOGIN_SUCCEED })
        
        store.dispatch({
            type: ActionTypes.LOGIN_ACCOUNT, 
            accessToken: response.data.accessToken,
            accountID: response.data.accountID,
            patients: response.data.patients,
            account: response.data.account,
            organizations: response.data.organizations
        })
    }catch(error){
        console.log(error)
        store.dispatch({ type: ActionTypes.LOGIN_LOGIN_FAIL, loginError: error.response.data.errors })
    }
}

export const loadOrganizations = values => async (dispatch) => {
  try{

    const organizations = await apiClient.organizations.myOrganizations()
    
    dispatch({ type: ActionTypes.SET_ORGANIZATIONS, value: organizations })
  }catch(error){
    dispatch({ type: ActionTypes.ORGANIZATIONS_SET_FAIL, loginError: 'This is a login error.' })
  }
}

export const updateAccount = async(params)=>{
  const response = await apiClient.accounts.update(params)

  store.dispatch({
    type: ActionTypes.FETCH_CURRENT_ACCOUNT,
    payload: response.data
  })
}