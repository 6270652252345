import React from 'react'
import { Form, Card, Select } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import GeneralEditor from 'components/elements/GeneralEditor'
import Scroller from 'components/elements/Scroller'
import {AppointmentStatusesForm} from 'components/partials'
import {
  appointmentStatusActions
} from 'actions'

class Edit extends React.Component {
  constructor(){
    super()
    this.formRef = React.createRef()
    this.state = {
      officeHours:[],
      visible: false,
      createAppointmentStatusVisible: false,
      appointmentStatusSearch: ''
    }
    this.appointmentStatusSearchTimeout = ''
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAppointmentStatusSearch = this.handleAppointmentStatusSearch.bind(this)
    this.handleAppointmentStatusChange = this.handleAppointmentStatusChange.bind(this)
    this.handleNewAppointmentStatus = this.handleNewAppointmentStatus.bind(this)
  }
  handleAppointmentStatusChange(value){
    if(value === 'new' || value == 'speciesSearchPrompt'){
      if(value === 'new'){
        this.setState({
          createAppointmentStatusVisible: true
        })
      }

      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({appointmentStatusID: ''});
      }, 1)
    }
  }
  handleAppointmentStatusSearch(value){
    if(this.appointmentStatusSearchTimeout){
      clearTimeout(this.appointmentStatusSearchTimeout)
    }
    this.appointmentStatusSearchTimeout = setTimeout(()=>{this.appointmentStatusSearch(value)},600)
  }
  handleSubmit(values){
    Object.keys(values).forEach((key)=>{
      if(!values[key]){
        values[key] = null
      }
    })

    if(this.props.type === 'new'){
      appointmentStatusActions.create(values)
    }
    
    if(this.props.type === 'edit'){
      appointmentStatusActions.update({
        ...this.current,
        ...values
      })
    }
    this.props.history.push(`/admin/appointment-statuses/${this.current.id}`)
  }
  get current(){
    return this.props.current || {}
  }
  handleCancel(){
    if(this.current.id){
        this.props.history.push(`/admin/appointment-statuses/${this.current.id}`)
    } else {
        this.props.history.push(`/admin/appointment-statuses`)
    }
  }
  componentDidUpdate(previousProps){
    if(JSON.stringify(previousProps) !== JSON.stringify(this.props)){
      this.form && this.form.resetFields()
      this.setState(this.state)
    }
  }
  handleNewAppointmentStatus(value){
    if(value === 'new'){
      this.setState({
        createAppointmentStatusVisible: true
      })
      setTimeout(()=>{
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({appointmentStatusID: ''});
      }, 1)
    }
  }
  get form(){
    return this.formRef.current
  }
  render() {
    const initialValues = {
      name: this.current.name,
      description: this.current.description,
      userType: this.current.userType || '',
      classificationID: this.current.classificationID,
      isConfirmed: this.current.isConfirmed,
      isNoShow: this.current.isNoShow,
    }
    return (
      <div className='edit-switch'>
        <Form ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
          <GeneralEditor onCancel={this.handleCancel} onSave={true}>
            <Scroller>
              <Card bordered={false}>
                <AppointmentStatusesForm formRef={this.formRef} appointmentStatus={this.current} />
              </Card>
            </Scroller>
          </GeneralEditor>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    appointmentStatuses: state.appointmentStatuses
  }
}

export default withRouter(
  (
    connect(mapStateToProps)(Edit)
  )
)