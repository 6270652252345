import React, { Component, Fragment } from 'react'
import { Switch, Button, Form, Input, Select } from 'antd';
import {
    appointmentTypeActions,
    partitionActions
} from 'actions'
import {
    CreateOrEditAppointmentTypeDurations,
    CreateOrEditPartition
} from 'components/partials'
import {connect} from 'react-redux'

class CreateAppointmentType extends Component {
    constructor(){
        super();
        this.state = { 
            createAppointmentTypeDurationsVisible: false,
            createOrEditPartitionVisible: false,
            durations: ''
        };
        appointmentTypeActions.fetch();
        partitionActions.fetch();
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDestroyAppointmentType = this.handleDestroyAppointmentType.bind(this)
        this.handleCreateOrEditAppointmentTypeDurationsClose = this.handleCreateOrEditAppointmentTypeDurationsClose.bind(this)
        this.handleCreateOrEditAppointmentTypeDurationsOpen = this.handleCreateOrEditAppointmentTypeDurationsOpen.bind(this)
        this.handleNewTypeDurations = this.handleNewTypeDurations.bind(this)
        this.handlePartitionClose = this.handlePartitionClose.bind(this)
        this.handlePartitionChange = this.handlePartitionChange.bind(this)
        this.handleEditPartition = this.handleEditPartition.bind(this)
        this.onSetCurrentPartition = this.onSetCurrentPartition.bind(this)
    }
    async handleSubmit(values){
        if(this.appointmentType.id){
            await appointmentTypeActions.update({
                id: this.appointmentType.id,
                ...values
            })
            this.props.onClose()
        }else{
            const appointmentType = await appointmentTypeActions.create({
                durations: this.state.durations || '',
                ...values
            })
            this.props.onSetCurrentAppointmentType(appointmentType.id)
            this.props.onClose()
        }
    }
    handleNewTypeDurations(durations){
        this.setState({durations})
    }
    handleCreateOrEditAppointmentTypeDurationsClose(){
        this.setState({
            createAppointmentTypeDurationsVisible: false
        })
    }
    handleCreateOrEditAppointmentTypeDurationsOpen(){
        this.setState({
            createAppointmentTypeDurationsVisible: true
        })
      }
    async handleDestroyAppointmentType(e){
        e.preventDefault()
        
        await appointmentTypeActions.destroy(this.appointmentType.id)
        this.props.onSetCurrentAppointmentType('')
        this.props.onClose()
    }
    handlePartitionClose(){
        this.setState({
            createOrEditPartitionVisible: false
        })
    }
    handleEditPartition(){
        this.setState({
          createOrEditPartitionVisible: true
        })
    }
    handlePartitionChange(value){
        if(value === 'new'){
            this.setState({
                createOrEditPartitionVisible: true
            })
        setTimeout(()=>{ 
            // Huge Hack
            // Huge Hack
            // Huge Hack
            // Huge Hack
            this.form && this.form.setFieldsValue({partitionID: ''});
            }, 1)
        }
    }
    onSetCurrentPartition(value){
        this.form && this.form.setFieldsValue({partitionID: value});
    }
    get appointmentType(){
        return {
            ...(this.props.appointmentType || {})
        }
    }
    get durations(){
        if(!this.appointmentType.durations && !this.state.durations){
            return {}
        }

        let durationTimes = ((this.appointmentType.durations || this.state.durations || '').split(",") || []).map((duration)=> parseInt(duration))
        
        let maxDuration = 0
        durationTimes.forEach((duration)=>{
            if(duration > maxDuration){
                maxDuration = duration
            }
        })

        let maxPatients = durationTimes.length
        
        let durations = {}
        durationTimes.forEach((durationTime,i)=>{
            if(durationTime == 0){
                return
            }
            if(!durations[durationTime]){
                durations[durationTime] = {}
                durations[durationTime].patientCount = []
            }
            if(i == 0){
                durations[durationTime].patientCount.push('1 Patient')
            } else {
                durations[durationTime].patientCount.push(i+1)
            }
            durations[durationTime].style = {
                width: `${(durationTime / maxDuration) * 100}%`,
                zIndex: maxPatients - i
            }
        })

        return durations
    }

    get partitions(){
        return Object.values(this.props.partitions || {})
    }

    get partition(){
        if(this.form && this.form.getFieldValue('partitionID')){
            return (this.props.partitions || {})[this.form.getFieldValue('partitionID')]
        } else {
            return undefined
        }
    }

    get form() {
        return Object(this.props.formRef).current
    }

    render(){
        let durations = this.durations
        let editPartition = false;
        if(this.form && this.form.getFieldValue('partitionID') && this.form.getFieldValue('partitionID') !== "new"){
            editPartition = true
        }
        return (
            <div>

                <CreateOrEditAppointmentTypeDurations
                    visible={this.state.createAppointmentTypeDurationsVisible}
                    onClose={this.handleCreateOrEditAppointmentTypeDurationsClose}
                    handleNewTypeDurations={this.handleNewTypeDurations}
                    appointmentType={this.appointmentType}
                />
                <CreateOrEditPartition
                    visible={this.state.createOrEditPartitionVisible}
                    onSetCurrentPartition={this.onSetCurrentPartition}
                    onClose={this.handlePartitionClose}
                    partition={this.partition}
                />
                <Form.Item 
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input Appointment Type name!' }]}
                >
                    <Input placeholder="Name of Appointment Type" />
                </Form.Item>
                <Form.Item 
                    label="Description"
                    name="description"
                >
                    <Input.TextArea placeholder="Description" />
                </Form.Item>
                <label>Appointment Duration (Minutes):</label>
                {Object.keys(durations).length?(
                    <div className='appointment-length-container'>
                        <div className='appointment-length-indicator' style={{width: "0%"}}>
                            <div className='appointment-length-number'>
                                0
                            </div>
                        </div>
                        {Object.keys(durations).map((time,i)=>{
                            let elementStyle=['appointment-length-contents']
                            if(i % 2 == 1){
                                elementStyle.push('off-color')
                            }
                            return (
                                <span key={`duration-display-${i}`}>
                                    <div className='appointment-length-indicator' style={durations[time].style}>
                                        <div className='appointment-length-number'>
                                            {time}
                                        </div>
                                    </div>
                                    <div onClick={this.handleCreateOrEditAppointmentTypeDurationsOpen} className={elementStyle.join(' ')} style={durations[time].style}>
                                        <div className='appointment-length-contents-label'>
                                            {durations[time].patientCount.join(", ")}
                                        </div>
                                    </div>
                                </span>
                            )
                        })}
                    </div>
                ):( 
                    <Button onClick={this.handleCreateOrEditAppointmentTypeDurationsOpen} className="new-durations-btn">Set Appointment Durations</Button>
                )}
                <Form.Item 
                    label="Generate available appointments starting: "
                    name="spacer"
                >
                    <Select>
                        <Select.Option key='spacer-nothing' value={0}>After the previous appointment</Select.Option>
                        <Select.Option key='spacer-60' value={60}>On the hour</Select.Option>
                        <Select.Option key='spacer-30' value={30}>On the half hour</Select.Option>
                        <Select.Option key='spacer-15' value={15}>On the quarter hour</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    label="Limit who can access this appointment type"
                    name="userType"
                >
                    <Select>
                        <Select.Option key='user-type-any' value=''>Any User</Select.Option>
                        <Select.Option key='user-type-new' value='new'>New Users</Select.Option>
                        <Select.Option key='user-type-returning' value='returning'>Returning Users</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Add to appointment type partition: '>
                    <Input.Group style={{ width: '100%' }} compact >
                        <Form.Item 
                            noStyle
                            label="partitionID"
                            name="partitionID"
                        >
                            <Select
                                style={{width: editPartition ? 'calc(100% - 58px)' : '100%'}}
                                onChange={this.handlePartitionChange}
                            >
                                {this.partitions.map((partition, i)=>{
                                    return (
                                        <Select.Option key={`partition-${i}`} value={partition.id}>
                                            {partition.name}
                                        </Select.Option>
                                    )
                                })}
                                <Select.Option key='divider' className='divider' disabled={true}>&nbsp;</Select.Option>
                                <Select.Option value=''>None</Select.Option>
                                <Select.Option key='new'>New Partiton</Select.Option>
                            </Select>
                        </Form.Item>
                        {editPartition && (
                            <Button onClick={this.handleEditPartition}>Edit</Button>
                        )}
                    </Input.Group>
                </Form.Item>
                <Form.Item>
                    <Form.Item
                        name="sendReminders"
                        valuePropName="checked"
                        noStyle
                        >
                        <Switch />
                    </Form.Item>
                    Send Reminders
                </Form.Item>
            </div>
        )
    }
}

export default connect((state)=> state )(
    (CreateAppointmentType)
)