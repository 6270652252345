import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'
import { apiClient } from 'lib';

import { Row, Col, Input, message } from 'antd'
import AdminLayout from 'components/elements/AdminLayout'
import SectionHeader from 'components/elements/SectionHeader'
import SectionHeaderTemplate from 'components/elements/SectionHeaderTemplate'
import SectionContent from 'components/elements/SectionContent'
import { CreateButton } from 'components/elements/AppButton'
import ShiftCalendar from './ShiftCalendar'
import AdministratorCards from './AdministratorCards'
import Item from './Item'

import {
  administratorActions,
  appointmentTypeActions,
  serviceRegionActions,
  serviceAreaActions
} from 'actions'

const { Search } = Input
const pageTitle = 'Users'
const storeKey = 'administrators'
const createPath = '/admin/patients/create'
const editPath = '/admin/patients/edit'

class Users extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      accounts: ''
    }
    this.handleCreateButtonOnClick = this.handleCreateButtonOnClick.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
  }

  async componentDidMount() {
    await administratorActions.fetch()
    await serviceRegionActions.fetch()
    await serviceAreaActions.fetch()
    appointmentTypeActions.fetch();

    if(this.props.location.search){
      let params = this.props.location.search
      params = params.replace(/\?/, '')
      params = params.split("&")
      let _params = {}
      for(let param of params){
        let splitParam = param.split("=")
        _params[splitParam[0]] = splitParam[1]
      }

      let data = {}
      for(let admin of Object.values(this.props.accounts)){
        if((admin.firstName || '').toLowerCase().includes(_params.search) || (admin.lastName || '').toLowerCase().includes(_params.search) || (admin.email || '').toLowerCase().includes(_params.search)){
          data[admin.id] = {...admin}
        }
      }
      
      this.setState({
        search: _params.search,
        accounts: data
      })
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.isFetchItemsLoading && this.props.fetchItemsError) {
      message.error(this.props.fetchItemsError)
    }
  }

  handleCreateButtonOnClick(e) {
    e.preventDefault()
    this.props.history.push(createPath)
  }

  handleOnSelect(account = {}) {
    if(account.id){
      this.props.history.push(`/admin/administrators/${account.id}${this.state.search?`?search=${this.state.search}`:''}`)
    }
  }

  handleCreate() {
    this.props.history.push(`/admin/administrators/newType`)
  }

  handleSearch(e){
    let search = e.target.value.toLowerCase()

    if(this.searchTimeout){
      clearTimeout(this.searchTimeout)
    }

    if(!search){
      this.setState({
        accounts: ''
      })
    } else {
      
      this.searchTimeout = setTimeout(async()=>{
        let data = {}
        for(let admin of Object.values(this.props.accounts)){
          if((admin.firstName || '').toLowerCase().includes(search) || (admin.lastName || '').toLowerCase().includes(search) || (admin.email || '').toLowerCase().includes(search)){
            data[admin.id] = {...admin}
          }
        }

        this.setState({
          accounts: data,
          search
        })
      },400)
    }
  }

  render() {
    
    return (
      <div>
        <AdminLayout>
          <SectionContent noPadding noMargin title='Administrators'>
          <Switch>
            <Route exact path='/admin/administrators/:id/edit-shifts' render={()=>
              <ShiftCalendar />
            }/>
            <Route path='/admin/administrators' render={()=>
              <Row style={{height: '100%'}}>
                <Col xs={24} lg={8}>
                  <AdministratorCards
                    dataSource={this.state.accounts || this.props.accounts || []}
                    onSelect={this.handleOnSelect}
                    search={this.state.search || ''}
                    onCreate={this.handleCreate}
                    onSearch={this.handleSearch}
                    editPath={editPath}
                    />
                </Col>
                <Col xs={24} lg={16}>
                  <Route path='/admin/administrators/:id' component={Item} />
                </Col>
              </Row>
            }/>
          </Switch>
          </SectionContent>
        </AdminLayout>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const accounts = (state[storeKey] || {})

  return {
    accounts
  }
}

export default withRouter(connect(mapStateToProps)(Users))
