import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import store from '../store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.shifts.fetch(params)

  store.dispatch({
    type: ActionTypes.FETCH_SHIFTS,
    payload: response.data.reduce((acc, item)=>{
      acc[item.id] = item
      return acc
    },{})
  })

  return response.data
}

export const update = async (params = {}) => {
  const response = await apiClient.organizations.shifts.update(params)

  store.dispatch({
    type: ActionTypes.APPEND_SHIFT,
    payload: {[`${response.data.id}`]: response.data}
  })

  return response.data
}

export const create = async (params = {}) => {
  const response = await apiClient.organizations.shifts.create(params)

  store.dispatch({
    type: ActionTypes.APPEND_SHIFT,
    payload: {[`${response.data.id}`]: response.data}
  })
  
  return response.data
}

export const destroy = async (params = {}) => {
  await apiClient.organizations.shifts.destroy(params)

  store.dispatch({
    type: ActionTypes.DESTROY_SHIFT,
    payload: params.id
  })
}

export const clear = async () => {

  store.dispatch({
    type: ActionTypes.CLEAR_SHIFTS,
  })
}