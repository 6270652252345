import React from 'react'
import { Input, List, Card, Table, Modal, Row, Col } from 'antd'
import {withRouter} from 'react-router-dom'
import './index.less'

class SnapList extends React.Component {
  constructor(props){
    super(props)

    let currentID;
    
    if(props.initial){
        currentID = props.initial
    }else{
        currentID = (
            Object.entries(props.data)[0] || {}
        ).id
    }
    this.state = {
        currentID,
        lastID: null
    }

    props.onSelect && props.onSelect()
    
    this.handleSelect = this.handleSelect.bind(this)
  }
  handleSelect(item){
    this.setState({
        currentID: item.id,
        lastID: this.state.currentID
    }, ()=>{
        this.onSelect();
    })
  }
  get current(){
      return this.props.data[this.state.currentID] || {}
  }
  get data(){
        let data = Object.values(this.props.data)
        let sortedData
        if(this.props.sortBy){
            let sortBy = this.props.sortBy
            let currentID = this.props.match.params.id
            sortedData = data.sort((a,b)=>{
                if(currentID && a.id == currentID) return -1
                if(currentID && b.id == currentID) return 1
                if(a[sortBy] > b[sortBy]) return 1
                if(a[sortBy] < b[sortBy]) return -1
                return 0
            })
        }
        sortedData.push({id:"searchHolder", firstName: "Search to see more results..."})
        return sortedData
  }
  onSelect(){
    if(this.current && this.state.currentID !== this.state.lastID){
        this.props.onSelect && this.props.onSelect(this.current)
    }
  }
  componentDidUpdate(){
    if(!this.state.current && !this.state.currentID){
        const firstItem = Object.entries(this.props.data || {})[0]
        const currentID = firstItem && firstItem.id
        if(currentID){
            this.setState({currentID}, ()=>{
                this.onSelect();
            });
        }
    }
  }
  
  render() {
    const RenderRow = this.props.row;
    const classNames = ['border-center-only'];
    this.props.className && classNames.push(this.props.className);
    return (
        <Card
            className={'no-padding full-height snap-list'}
            title={
                <Input.Search 
                    placeholder={`Search ${this.props.listType}`} 
                    defaultValue={this.props.search || ''}
                    onChange={this.props.onSearch}
                    onBlur={this.props.onSearch}
                    enterButton
                />
            }
            >
            
            <List
                bordered
                className={classNames.join(' ')}
                dataSource={this.data}
                renderItem={row => (
                    <List.Item onClick={this.handleSelect.bind(null, row)} className={this.current.id == row.id ? 'selected' : ''}>
                        <RenderRow  {...row} />
                    </List.Item>
                )}
            />
            {this.props.onCreate && (
                <div onClick={this.props.onCreate} className={'create-new'}>
                    {this.props.createPrompt || "Create New"}
                </div>
            )}
        </Card>
            
    )
  }
}

export default withRouter(SnapList)