import {
  FETCH_ADMINISTRATOR_TYPES,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ADMINISTRATOR_TYPES:
      return {
        ...state,
        ...action.payload
      }
    
    case SET_ORGANIZATION_CURRENT:
        return {}
    default:
      return state
  }
}
