import {
    FETCH_WAIT_LISTS,
    DESTROY_WAIT_LIST,
    APPEND_WAIT_LIST,
    SET_ORGANIZATION_CURRENT
  } from '../constants/actionTypes'
  
  export default (state = '', action) => {
        switch (action.type) {
            case APPEND_WAIT_LIST:
                return [
                    action.payload,
                    ...state
                ]
            case FETCH_WAIT_LISTS:
                return [
                    ...action.payload
                ]
            case DESTROY_WAIT_LIST:
                return state.filter((s)=>s.id != action.payload)
            case SET_ORGANIZATION_CURRENT:
                return ''
            default:
                return state
        }
  }
  