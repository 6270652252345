import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, InputNumber, Switch } from 'antd';
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons'
import {serviceRegionActions} from 'actions'
import {CreateOrEditServiceArea} from 'components/partials'
import {connect} from 'react-redux'
const TextArea = Input.TextArea;

class CreateOrEditServiceRegion extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createOrEditServiceAreaVisible: false,
      editServiceAreaID: '',
      selectedServiceAreas: []
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDestroyServiceRegion = this.handleDestroyServiceRegion.bind(this)
    this.handleCreateServiceArea = this.handleCreateServiceArea.bind(this)
    this.handleCreateOrEditServiceAreaClose = this.handleCreateOrEditServiceAreaClose.bind(this)
  }
  
  async handleSubmit (values) {
    const _serviceRegion = {
        name: values.name,
        description: values.description,
        maxTravelDistance: values.maxTravelDistance,
        serviceAreaIDs: this.state.selectedServiceAreas
    }
    if(this.serviceRegion.id){
      serviceRegionActions.update({
          id: this.serviceRegion.id,
          ..._serviceRegion
      })
    }else{
      let serviceRegion = await serviceRegionActions.create(_serviceRegion)
      this.props.setServiceRegion && this.props.setServiceRegion(serviceRegion.id)
    }
    this.props.onClose()
  }

  get serviceRegion(){
    const _serviceRegion = this.props.serviceRegion || {}
    
    return {
      id: _serviceRegion.id,
      name: _serviceRegion.name,
      description: _serviceRegion.description,
      maxTravelDistance: _serviceRegion.maxTravelDistance,
      serviceAreaIDs: (_serviceRegion.areas || []).map((area)=>area.id)
    }
  }

    componentDidUpdate(previousProps){
        if(previousProps.visible && !this.props.visible){
            this.form && this.form.resetFields();
        }
        if(JSON.stringify(previousProps.serviceRegion) != JSON.stringify(this.props.serviceRegion)){
            this.setState({
                selectedServiceAreas: (Object(this.props.serviceRegion).areas || []).map((area)=>area.id)
            })
        }
    }

  async handleDestroyServiceRegion(e){
    e.preventDefault()

    await serviceRegionActions.destroy(this.serviceRegion.id)
    this.props.onClose()
  }

  get serviceAreas(){
    return (Object.values(this.props.serviceAreas || {})
    .map((serviceArea = {})=>{
      let _serviceArea = {...serviceArea}
      _serviceArea.key = serviceArea.id
      _serviceArea.title = `${serviceArea.name}`
      return _serviceArea
    }) || []).sort((a,b)=>{
      if(a.name < b.name) return -1
      if(a.name > b.name) return 1
      return 0
    })
  }

  get form() {
    return this.formRef.current
  }

  get serviceArea(){
    return this.props.serviceAreas[this.state.editServiceAreaID] || {}
  }

  get currentServiceAreaIDs(){
    return this.state.selectedServiceAreas || []
  } 
  
  get initialValues(){
      return this.serviceRegion
  }

  handleCreateServiceArea(){
    this.setState({
      createOrEditServiceAreaVisible: true,
      editServiceAreaID: ''
    })
  }

  handleEditServiceArea(id){
    this.setState({
      createOrEditServiceAreaVisible: true,
      editServiceAreaID: id
    })
  }

  handleServiceAreaToggle(id){
    let areas = this.state.selectedServiceAreas
    var index = areas.indexOf(id);
    if (index > -1) {
      areas.splice(index, 1);
    } else {
      areas.push(id)
    }
    this.setState({
      selectedServiceAreas: areas
    })
  }

  handleCreateOrEditServiceAreaClose(){
    this.setState({
      createOrEditServiceAreaVisible: false
    })
  }

  render() {
    let edit = false;

    if(this.serviceRegion.id){
      edit = true
    }
    return (
      <Drawer
        title={this.serviceRegion.id ? 'Edit Service Region' : 'Create Service Region'}
        width={520}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <CreateOrEditServiceArea
          visible={this.state.createOrEditServiceAreaVisible}
          onClose={this.handleCreateOrEditServiceAreaClose}
          serviceArea={this.serviceArea}
        />
        <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit} initialValues={this.initialValues}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Name"
                name="name"
                rules={[{ message: 'Please select a name' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Description"
                name="description"
                rules={[{ message: 'Please select a description' }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Maximum Travel Distance Between Appointments"
                name="maxTravelDistance"
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={24}>
                <PlusCircleOutlined 
                    onClick={this.handleCreateServiceArea} 
                    style={{position: "absolute", right: "10px", cursor: "pointer", zIndex: 0}}
                />
                <label style={{color: 'black'}}>Service Areas</label>
                <div style={{paddingTop: "10px"}}>
                    {this.serviceAreas.map((serviceArea, serviceAreaI)=>(
                        <div key={`serviceArea-${serviceAreaI}`} style={{marginBottom: "5px"}}>
                            <Switch 
                                checked={this.state.selectedServiceAreas.includes(serviceArea.id)}
                                onChange={this.handleServiceAreaToggle.bind(this,serviceArea.id)} 
                                style={{marginRight: "10px"}} 
                                size='small'
                            />
                            <EditOutlined 
                                onClick={this.handleEditServiceArea.bind(this,serviceArea.id)} 
                                style={{position: "absolute", right: "10px", cursor: "pointer", zIndex: 0}}
                            />
                            {serviceArea.name}
                        </div>
                    ))}
                </div>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyServiceRegion} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Service Region</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.serviceRegion.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  (CreateOrEditServiceRegion)
)