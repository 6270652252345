import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import Widget from './Widget'
import Accounts from './Accounts'
import Scroller from 'components/elements/Scroller'

class Booking extends Component {
  constructor(props, context) {
    super(props, context)
    this.state ={
      
    }
    this.handleAccountSelect = this.handleAccountSelect.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }
  handleAccountSelect(account){
    this.setState({
      account
    })
  }
  handleLogout(){
    this.setState({
      account: null
    })
  }
  render() {
    return (
      <div>
        <AdminLayout>
          <SectionContent noPadding noMargin title='Booking'>
            <Scroller>
              {this.state.account?(
                <Widget account={this.state.account} onLogout={this.handleLogout} />
              ):(
                <Accounts onAccountSelect={this.handleAccountSelect}/>
              )}
            </Scroller>
          </SectionContent>
        </AdminLayout>
      </div>
    )
  }
}

export default withRouter(connect((state)=>(state))(Booking))
