import Collection from 'lib/client/collections/collection'
import IntegrationEntities from './IntegrationEntities'
import CurrentSyncStatus from './CurrentSyncStatus'
import FollowUps from './FollowUps'
import AppointmentRequests from './AppointmentRequests'
import WidgetRequests from './WidgetRequests'
import Notes from './Notes'
import CovetrusAppointmentStatuses from './CovetrusAppointmentStatuses'
import RiskAssessment from './RiskAssessment'

class Operations extends Collection {
    get integrationEntities(){
        return new IntegrationEntities(this.options)
    }
    get currentSyncStatus(){
        return new CurrentSyncStatus(this.options)
    }
    get followUps(){
        return new FollowUps(this.options)
    }
    get appointmentRequests(){
        return new AppointmentRequests(this.options)
    }
    get widgetRequests(){
        return new WidgetRequests(this.options)
    }
    get notes(){
        return new Notes(this.options)
    }
    get covetrusAppointmentStatuses(){
        return new CovetrusAppointmentStatuses(this.options)
    }
    get riskAssessment(){
        return new RiskAssessment(this.options)
    }
}

export default Operations