import { useState, useEffect } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { axiosClient } from '.';


const useAxios = function <T> (options: AxiosRequestConfig<any>) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | unknown | AxiosError>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient(options);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [JSON.stringify(options)]);

  return { data, loading, error };
};

export default useAxios;
