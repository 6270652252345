import React, {Fragment} from 'react'
import moment from 'moment'
import { Layout, Menu, Modal, Badge } from 'antd'
import { BellOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { sessionActions, notificationActions, accountActions } from 'actions'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

const { SubMenu } = Menu
const { Header } = Layout

class CustomisedHeader extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleMenuItemOnClick = this.handleMenuItemOnClick.bind(this)
    this.handleNotification = this.handleNotification.bind(this)
  }

  componentDidMount(){
  // notificationActions.fetch({
  //   accountID: this.props.currentAccount.id
  // })

    // if(!this.notificationsFetch){
    //   // this.notificationsFetch = setInterval(()=>{
    //   //   notificationActions.fetch({
    //   //     accountID: this.props.currentAccount.id
    //   //   })
    //   // }, 30000)
    // }
  }

  componentWillUnmount(){
    // if(this.notificationsFetch) clearInterval(this.notificationsFetch)
  }

  handleMenuItemOnClick(item) {
    switch (item.key) {
      case 'logout': {
        const handleAppLogOut = () => { this.props.handleAppLogOut() }
        Modal.confirm({
          title: 'Logout',
          content: 'Are you sure you want to log out?',
          onOk() {
            handleAppLogOut()
          },
          onCancel() {
            // console.log('Cancel')
          },
        })
        break
      }
      default:
    }
  }

  get notifications(){
    return Object.values(this.props.notifications || {}).sort((a, b)=>{
      return a.createdAt < b.createdAt ? 1 : -1
    }).slice(0, 20)
  }
  
  get unReadNotifications(){
    return Object.values(
      this.props.notifications || {}
    ).filter((n)=>
      !n.viewedAt
    ) 
  }

  pastTense(type, tense){
    switch(type){
      case 'Message':
        return ({
          create: 'received from'
        })[tense]
      default: 
        return ({
          create: 'created by',
          update: 'updated by',
          destroy: 'deleted by'
        })[tense]
    }
  }

  happenedAt(notification){

    const now = moment()
    const createdAt = moment(notification.createdAt)
    var duration = moment.duration(now.diff(createdAt));


    
    if(duration.asDays() < 1){
      if(duration.asHours() < 1){
        if(duration.asMinutes() < 1){
          return `${Math.round(duration.asSeconds())} seconds ago`
        }else{
          return `${Math.round(duration.asMinutes())} minutes ago`
        }
      }else{
        return `${Math.round(duration.asHours())} hours ago`
      }
    }else{
      return `${Math.round(duration.asDays())} days ago`;
    }
  }

  async handleNotification(notification){
    //await notificationActions.markAsViewed(notification.id)
    this.props.history.push(`/admin/messages/${notification.creator.id}`)
  }

  get showOrganizationID(){
    return accountActions.isSuperAdministrator() || accountActions.isPartnerAdministrator()
  }
  render() {
    return (
      <Header key='admin-header' className='admin-header' style={{ background: '#fff', padding: 0 }}>
        <Menu
          mode='horizontal'
          style={{ lineHeight: '64px', minWidth: '85.5px', textAlign: 'center' }}
          
        >
            {this.showOrganizationID && (
                <Menu.Item key={'organizationID'}
                onClick={()=>{
                    navigator.clipboard.writeText(_.get(this, 'props.currentOrganization.id'))
                }}
                >
                {_.get(this, 'props.currentOrganization.id') || ""}
                </Menu.Item>
            )}
            <Menu.Item key='logout' onClick={this.handleMenuItemOnClick}>Logout</Menu.Item>
          </Menu>
      </Header>
    )
  }
}

const mapStateToProps = (s) => ({
  ...s,
  currentAccount: Object(s.session).account,
})

const mapDispatchToProps = dispatch => ({
  handleAppLogOut: sessionActions.logout,
})

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomisedHeader))