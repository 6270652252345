import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';

import store from '../store'

export const fetch = async(params = {})=>{
  let response

  if(Object.keys(params).includes('accountID') && !params.accountID){
    throw Error(`Patient error accountID can't be undefined`)
  }
  if(params.accountID){
    response = await apiClient.organizations.accounts.patients.fetch({
      organizationID: store.getState().currentOrganization.id,
      accountID: params.accountID
    })
  }else{
    let data = {organizationID: store.getState().currentOrganization.id}
    Object.keys(params).forEach((key)=>{
      data[key] = params[key]
    })
    response = await apiClient.organizations.patients.fetch(data)
  } 

  let data = {}
  if(response.data.id){
    data[response.data.id] = response.data
  }else{
    data = response.data.reduce((acc, item)=>{
      acc[item.id] = item
      return acc;
    }, {})
  }
  store.dispatch({ 
    type: ActionTypes.FETCH_PATIENTS,
    payload: data
  })
}

const fetching = {}
export const safeFetchByID = async(params = {})=>{
  if(!fetching[params.id] && !store.getState().patients[params.id]){
    fetching[params.id] = true
    await fetch({id: params.id})
    fetching[params.id] = false
  }
}

export const fetchActive = async(params = {})=>{
  let response

  if(Object.keys(params).includes('accountID') && !params.accountID){
    throw Error(`Patient error accountID can't be undefined`)
  }
  
  response = await apiClient.organizations.patients.fetchActive({
    organizationID: store.getState().currentOrganization.id
  })

  const data = response.data.reduce((acc, item)=>{
    acc[item.id] = item
    return acc;
  }, {})

  store.dispatch({ 
    type: ActionTypes.MERGE_PATIENTS,
    payload: data
  })
}

export const update = async(params = {}) => {
  const response = await apiClient.organizations.accounts.patients.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_PATIENT,
    payload: response.data
  })

  return response
}

export const create = async(params = {}) => {
  const response = await apiClient.organizations.accounts.patients.create(params)

  store.dispatch({
    type: ActionTypes.CREATE_PATIENT,
    payload: response.data
  })

  return response.data
}


export const destroy = async(options) => {
  console.log("TRYYING TO DESTROY")
  await apiClient.organizations.accounts.patients.destroy(options)

  store.dispatch({
    type: ActionTypes.DESTROY_PATIENT,
    payload: options.id
  })
}

export const findOrFetch = async(patientId) => {
  let patient = store.getState().patients[patientId]
  if(!patient){
    await fetch({id: patientId})
    patient = store.getState().patients[patientId]
  }
  return patient
}