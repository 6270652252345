import {
    FETCH_SHIFTS,
    APPEND_SHIFT,
    DESTROY_SHIFT,
    SET_ORGANIZATION_CURRENT,
    CLEAR_SHIFTS
  } from '../constants/actionTypes'
  
  export default (state = {}, action) => {
    switch (action.type) {
      case FETCH_SHIFTS:
        return {
          ...state,
          ...action.payload
        }
      case APPEND_SHIFT:
        let baseID = Object.values(action.payload)[0].id
        state = Object.values(state).filter((s)=>s.id != baseID).reduce((acc, item)=>{
          acc[item.repeatingID || item.id] = item
          return acc;
        }, {})
        return {
          ...state,
          ...action.payload
        }
      case DESTROY_SHIFT:
        state = Object.values(state).filter((s)=>s.id != action.payload).reduce((acc, item)=>{
          acc[item.repeatingID || item.id] = item
          return acc;
        }, {})
        
        return state
      case SET_ORGANIZATION_CURRENT:
      case CLEAR_SHIFTS:
        return {}
      default:
        return state
    }
  }
  