import React from 'react'
import { Form, Input, Button, Col, Row } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import MaskedInput from 'components/elements/MaskedInput';
import {Fa} from 'components/elements';

const FormItem = Form.Item

class LoginForm extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.formRef = React.createRef()
    this.state = {
      hasNumber: false,
      hasLowerCase: false,
      hasUpperCase: false,
      hasEightCharacters: false
    }
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handlePassword = this.handlePassword.bind(this)
    this.handlePasswordConfirmation = this.handlePasswordConfirmation.bind(this)
  }

  async handleOnSubmit(values) {
    if(values.emailOrPhone.includes('@')){
      values.email = values.emailOrPhone
    }else{
      values.phone = values.emailOrPhone
    }

    delete values.emailOrPhone

    this.props.onFinish(values)
  }

  get form() {
    return this.formRef.current
  }

  handlePassword(e){
    const {value} = e.target
    this.setState({
      passwordValue: value,
      passwordConfirmationMatches: value == this.state.passwordConfirmationValue,
      hasNumber: value.match(/(?=.*\d)/),
      hasLowerCase: value.match(/(?=.*[a-z])/),
      hasUpperCase: value.match(/(?=.*[A-Z])/),
      hasEightCharacters:  value.match(/(?=.{8,8})/)
    })
  }

  handlePasswordConfirmation(e){
    const {value} = e.target
    this.setState({
      passwordConfirmationValue: value,
      passwordConfirmationMatches: value == this.state.passwordValue
    })
  }

  render() {

    const open = {
      icon: ['far', 'circle']
    }
    const checked = {
      icon: 'check-circle',
      color:'rgb(82, 196, 26)'
    }
    return (
      <Form ref={this.formRef} onFinish={this.handleOnSubmit}>
        <Row>
          <Col span={12}>
            <h4>Need an Account?</h4>
            <br />
            <FormItem
            name="emailOrPhone"
            rules={[{ required: true, message: 'Please input a valid email address or phone number.' }]}
            >
              <MaskedInput types={['phone', 'email']} size="large" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Email or Phone Number' />
            </FormItem>
            <FormItem
              name="password"
              rules={[{ 
                required: true,
                message: 'Please input a valid password.' 
              }]}
            >
              <Input size="large" onChange={this.handlePassword} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Password' type='password' />
            </FormItem>
            <div className='text-left'>
              <div>
                {this.state.hasNumber ?
                  <Fa {...checked} /> :
                  <Fa  {...open}  />
                } Has at least 1 number
              </div>
              <div>
              {this.state.hasUpperCase ?
                  <Fa {...checked} /> :
                  <Fa {...open} />
                } Has at least 1 uppercase letter
              </div>
              <div>
                {this.state.hasLowerCase ?
                  <Fa {...checked} /> :
                  <Fa {...open} />
                } Has at least 1 lowercase letter
              </div>
              <div>
                {this.state.hasEightCharacters ?
                  <Fa {...checked} /> :
                  <Fa {...open} />
                } Has at least 8 characters
              </div>
              <div>
                {this.state.passwordConfirmationMatches ?
                  <Fa {...checked} /> :
                  <Fa {...open} />
                } Password Confirmation Matches
              </div>
            </div>
            <br />
            <FormItem
              name="passwordConfirmation"
              rules={[{ required: this.validatePassword, message: 'Please input a valid confirmation password.' }]}
            >
              <Input
                onChange={this.handlePasswordConfirmation}
                size="large"
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='Password Confirmation' 
                type='password' 
              />
            </FormItem>
            <FormItem>
              <Button
                loading={this.props.isLoading}
                type='primary'
                htmlType='submit'
                style={{ width: '100%' }}
                size='large'
              >
                Signup
              </Button>
            </FormItem>
          </Col>
          <Col span={12}>
            <br />
            <br />
            Have an Account? <a href='/login'>Click here to login</a>
            <br />
          </Col>
        </Row>
      </Form>
    )
  }
}

export default LoginForm
