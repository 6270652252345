import React, {Fragment, Component} from 'react';
import { Form, Col, Row, Input, Select, Button, Divider, InputNumber } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons'
import {accountActions} from 'actions'
import {connect} from 'react-redux'
import _ from 'lodash'

const gtLtEq = [
  {
    name: 'is greater than',
    value: '>'
  },
  {
    name: 'is less than',
    value: '<'
  },
  {
    name: 'is equal to',
    value: '=='
  },
  {
    name: 'is equal to',
    value: '!='
  }
]
const isIsnot = [
  {
    name: 'is',
    value: '=='
  },
  {
    name: 'is not',
    value: '!='
  }
]

class RestrictionStepForm extends Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      rules: []
    };

    this.addRule = this.addRule.bind(this)
    this.operators = this.operators.bind(this)
    this.operatorsWithKey = this.operatorsWithKey.bind(this)
    this.reducedOperatorsWithKey = this.reducedOperatorsWithKey.bind(this)
    this.handleOperatorChange = this.handleOperatorChange.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.handleKeyChange = this.handleKeyChange.bind(this)
    this.handleDestroyRule = this.handleDestroyRule.bind(this)
  }

  get account(){
    return this.props.account || {}
  }

  handleWorkPhoneChange(e){
    let value = e.target.value
    value = this.maskPhone(value)

    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({workPhone: value});
    }, 1)
  }

  handleHomePhoneChange(e){
    let value = e.target.value
    value = this.maskPhone(value)

    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({homePhone: value});
    }, 1)
  }

  handleMobilePhoneChange(e){
    let value = e.target.value
    value = this.maskPhone(value)

    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({mobilePhone: value});
    }, 1)
  }

  componentDidMount(){
    this.reloadRules(this.props)
  }

  componentDidUpdate(previousProps){
    if(previousProps.visible != this.props.visible){
      this.setState({
        rules:[]
      }, ()=>{
        this.reloadRules(this.props)
      })
    }else{
      if(JSON.stringify(previousProps.rules) != JSON.stringify(this.props.rules)){
        this.reloadRules(this.props)
      }
    }
  }

  reloadRules(props){
    const rules = props.rules || []
    for(let rule of rules){
      const key = (this.reducedKeys[rule.key] || {}).value
      this.setState({
        [`key--${rule.id}`]: key
      })
      const operator = (this.reducedOperatorsWithKey(key)[rule.operator] || {}).name
      this.setState({
        [`operator--${rule.id}`]: operator
      })
      const value = rule.value
      this.setState({
        [`value--${rule.id}`]: value
      })
    }

    const keyedRules = [...this.state.rules, ...rules].reduce((acc, rule)=>({
      ...acc,
      [`${rule.id}`]: rule
    }), {})

    this.setState({
      rules: Object.values(keyedRules)
    })
  }

  async handleDestroyAccount(e){
    e.preventDefault()
    
    // await accountActions.destroy({
    //   id: this.account.id
    // })
    this.props.onClose()
  }

  get restriction(){
    return this.props.restriction || {}
  }
  get appointmentTypes(){
    return Object.values(this.props.appointmentTypes) 
  }
  get reducedKeys(){
    return this.keys.reduce((acc,key)=>{
      acc[key.value] = key
      return acc
    },{})
  }
  get keys(){
    return [
      {name: 'Age', value: 'age', static: true},
      {name: 'Sex', value: 'genderID', static: true},
      {name: 'Weight', value: 'weight', static: true},
      {name: 'Species', value: 'classificationID', static: false}
    ]
  }
  reducedOperatorsWithKey(key){
    return this.operatorsWithKey(key).reduce((acc,operator)=>{
      acc[operator.value] = operator
      return acc
    },{})
  }
  operatorsWithKey(key){
    switch(key || ''){
      case 'age':
        return gtLtEq
      case 'weight':
        return gtLtEq
      case 'genderID':
        return isIsnot
      case 'classificationID':
        return isIsnot
      default:
        return [{
          name: 'Select Key First', 
        }]
    }
  }
  operators(ruleID){
    return this.operatorsWithKey(this.state[`key--${ruleID}`] || '')
  }
  get rules(){
    let rules = this.state.rules
    rules = rules.filter((rule)=>(
      !(this.state.toDelete || []).includes(rule.id)
    ))
    let reducedKeys = this.reducedKeys
    rules = rules.map((rule)=>{
      return {
        ...rule,
        key:(reducedKeys[rule.key] || {}).name
      }
    })
    return rules
  }
  addRule(){
    this.setState({
      rules: [...this.state.rules, {id: `new-${(this.state.rules || []).length}`}]
    })
  }
  async handleDestroyRule(ruleI){
    let toDelete = this.state.toDelete || []
    for(let rule of this.props.rules){
      if(rule.id == ruleI){
        toDelete.push(rule.id)
      }
    }
    this.setState({
      rules: this.state.rules.filter((rule)=>(rule.id != ruleI)),
      toDelete
    })
  }
  handleKeyChange(ruleID, keyName){
    this.setState({
      [`key--${ruleID}`]: keyName
    })
  }
  handleOperatorChange(ruleID, operatorName){
    this.setState({
      [`operator--${ruleID}`]: operatorName
    })
  }
  handleValueChange(ruleID, valueName){
    this.setState({
      [`value--${ruleID}`]: valueName
    })
  }
  inputType(ruleID){
    const rule = this.state[`key--${ruleID}`]
    return rule
  }

  get genders(){
    return Object.values(this.props.genders).filter((gender)=>(!gender.siblingID && gender.active))
  }

  get classifications(){
    return Object.values(this.props.classifications).filter((classification)=>(!classification.siblingID && classification.active))
  }

  get form() {
    return this.formRef.current
  }

//   initialValue: rule.key || ''
//   initialValue: rule.operator || ''
//   initialValue: rule.value || rule.restrictionRuleableID || ''

  render() {
    let editAppointmentType = false
    let edit = false;
    
    if(this.account.id){
      edit = true
    }

    return (
      <div>
        {this.rules.length != 0 && (
          <h3 style={{marginTop: 2}}>Where</h3>
        )}
        <br />
        <br />
        {this.rules.map((rule, ruleI)=>{
          return (
            <Row gutter={10} key={`rule-${ruleI}`}>
              <Col span={24}>
                {ruleI !== 0 && (
                  <Fragment>
                    <Divider>And</Divider>
                    <br />
                  </Fragment>
                )}
                <Form.Item>
                  <Input.Group>
                    <CloseCircleOutlined onClick={this.handleDestroyRule.bind(null,rule.id)} style={{position: "absolute", top: "-40px", right: "0", cursor: "pointer"}}/>
                    <Input.Group style={{ width: '100%' }} compact >
                      <Form.Item
                        noStyle
                        name={`key--${rule.id}`}
                      >
                        <Select style={{width: '33.3333333%'}} onChange={this.handleKeyChange.bind(null, rule.id)}>
                          {this.keys.map((key, keyI)=>(
                            <Select.Option key={`key-${ruleI}-${keyI}`} value={key.value}>{key.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={`operator--${rule.id}`}
                      >
                        <Select style={{width: '33.3333333%'}} onChange={this.handleOperatorChange.bind(null, rule.id)}>
                          {this.operators(rule.id).map((op, operatorI)=>(
                            <Select.Option value={op.value} key={`operator-${ruleI}-${operatorI}`}>{op.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={`value--${rule.id}`}
                      >
                        {this.inputType(rule.id) == 'genderID' ? (
                          <Select style={{width: '33.3333333%'}} onChange={this.handleValueChange.bind(null, rule.id)}>
                            {this.genders.map((op, valueI)=>(
                              <Select.Option value={op.id} key={`value-${ruleI}-${valueI}`}>{_.startCase(_.lowerCase(_.startCase(op.name)))}</Select.Option>
                            ))}
                          </Select>
                        ):(this.inputType(rule.id) == 'classificationID' ? (
                          <Select style={{width: '33.3333333%'}} onChange={this.handleValueChange.bind(null, rule.id)}>
                            {this.classifications.map((op, valueI)=>(
                              <Select.Option value={op.id} key={`value-${ruleI}-${valueI}`}>{_.startCase(_.lowerCase(_.startCase(op.name)))}</Select.Option>
                            ))}
                          </Select>
                        ):(
                          <InputNumber style={{width: '33.3333333%'}} onChange={this.handleValueChange.bind(null, rule.id)}/>
                        ))}
                      </Form.Item>
                    </Input.Group>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
          )
        })}
        <Button onClick={this.addRule} style={{marginBottom: '4em'}}>Add Rule</Button>
      </div>
    );
  }
}
export default connect((state)=> state )(RestrictionStepForm)