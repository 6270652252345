import React, {Component, Fragment} from 'react'
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import moment from 'moment'
import {accountActions, patientActions, appointmentActions, waitingRoomActions} from 'actions'
import {connect} from 'react-redux'
import {apiClient} from 'lib'
import {Spinner} from '../../elements'
import CreateOrEditAccount from 'components/partials/CreateOrEditAccount'
import CreateOrEditPatient from '../CreateOrEditPatient';
const TextArea = Input.TextArea;


const {Option, OptGroup} = Select;

class CreateOrEditWaitingRoom extends React.Component {
    constructor(){
        super();
        this.formRef = React.createRef()
        this.state = { 
            visible: false,
            createAppointmentTypeVisible: false,
            createOrEditAccountVisible: false,
            createOrEditPatientVisible: false,
            patientsUsers: [],
            showSpinner: false
        };
        this.patientSearchTimeout = ''
        this.search = this.search.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        // this.handleAccountChange = this.handleAccountChange.bind(this)
        this.handlePatientChange = this.handlePatientChange.bind(this)
        // this.handleEditAccount = this.handleEditAccount.bind(this)
        // this.onSetCurrentAccount = this.onSetCurrentAccount.bind(this)
        // this.handleAccountSearch = this.handleAccountSearch.bind(this)
        // this.handleCreateOrEditAccountClose = this.handleCreateOrEditAccountClose.bind(this)
        // this.selectAccountByID = this.selectAccountByID.bind(this)
        this.selectPatientByID = this.selectPatientByID.bind(this)
        // this.onCreateAccount = this.onCreateAccount.bind(this)
        this.handleCreateOrEditPatientClose = this.handleCreateOrEditPatientClose.bind(this)
        this.onCreatePatient = this.onCreatePatient.bind(this)
        this.patientSearch = this.patientSearch.bind(this)
        this.handlePatientSearch = this.handlePatientSearch.bind(this)
        this.handlePatientUser = this.handlePatientUser.bind(this)
        this.handleSetCurrentPatient = this.handleSetCurrentPatient.bind(this)
    }

    componentDidUpdate(previousProps){
        if(previousProps.visible != this.props.visible){
            this.form && this.form.resetFields();
            this.setState(this.state)
        }
    }

    async handleSubmit (values) {
        const patient = this.props.patients[values.patientID]
        
        const _appointment = {
            accountID:  patient.primaryAccountID,
            appointmentTypeID: values.appointmentTypeID,
            patientID: values.patientID,
            administratorID: this.administrator.id,
            startsAt: moment(),
            reason: values.reason,
            endsAt: moment().add(15, 'm')
        }

        this.setState({
            showSpinner: true
        })

        try {
            await appointmentActions.create(_appointment)
            await waitingRoomActions.fetch({fullAppointments: true})
            this.props.onClose()
        } 
        catch(error) {
            console.log(error)
        }

        this.setState({
            showSpinner: false
        })

        // do an if block, if appointment contains an appointment you can close (has length of 1)
        
    }

    handlePatientChange(value){
        if(value === 'new'){
            this.setState({
                createOrEditPatientVisible: true
            })
            setTimeout(()=>{
                // Huge Hack
                // Huge Hack
                // Huge Hack
                // Huge Hack
                this.form && this.form.setFieldsValue({patientID: ''});
            }, 1)
        }
    }

    async search(search = ''){
        const {data} = await apiClient.organizations.accounts.fetch({
            limit:5, search
        })
    
        this.setState({
            accounts: data
        })
    }

    async selectPatientByID(patientID){
        this.form && this.form.setFieldsValue({patientID: patientID})
    }

    handleAccountSearch(value){
        if(this.accountSearchTimeout){
            clearTimeout(this.accountSearchTimeout)
        }
        this.accountSearchTimeout = setTimeout(()=>{this.search(value)},1000)
    }

    handleEditAccount(){
        this.setState({
            createOrEditAccountVisible: true
        })
    }

    handleEditPatient(){
        this.setState({
            createOrEditPatientVisible: true
        })
    }

    onCreatePatient(patientID){
        this.selectPatientByID(patientID)
        patientActions.safeFetchByID({id: patientID})
    }

    handleCreateOrEditAccountClose(){
        this.setState({
          createOrEditAccountVisible: false
        })
    }

    handleCreateOrEditPatientClose(){
        this.setState({
            createPatientVisible: false
        })
    }

    get form() {
        return this.formRef.current
    }

    get administrator(){
        for(let administrator of Object.values(this.props.administrators) || []){
            if(administrator.isWaitingRoom){
                return administrator
            }
        }
        return {}
    }

    get account(){
        if(!this.patient.primaryAccountID) return {}
        const account = this.accounts[this.patient.primaryAccountID]
        if(account) return account 
        return {}
    }

    get waitingRoom(){
        return this.props.waitingRoom || {}
    }

    get appointmentTypes(){
        return Object.values(this.props.appointmentTypes || {})
    }

    
    get patients(){
        return this.state.patients || Object.entries(this.props.patients || {}).map(([_, a])=>a)
    }

    get patient(){
        return this.props.patients[this.form && this.form.getFieldValue('patientID')] || {}
    }


    async patientSearch(search = ''){
        const {data} = await apiClient.organizations.patients.fetch({limit:5, search})

        this.setState({
            patients: data
        })
    }

    handlePatientSearch(value){
        if(this.patientSearchTimeout){
            clearTimeout(this.patientSearchTimeout)
        }
        this.patientSearchTimeout = setTimeout(()=>{this.patientSearch(value)},600)
    }

    handlePatientUser(value){
        if(value === 'new' || value == 'patientSearchPrompt'){
            if(value === 'new'){
                this.setState({
                    createPatientVisible: true
                })
            }
        
            setTimeout(()=>{ 
                // Huge Hack
                // Huge Hack
                // Huge Hack
                // Huge Hack
                this.form && this.form.setFieldsValue({patientID: ''});
            }, 1)
        }else{
            let patientID = value
            patientActions.safeFetchByID({id: patientID})
        }
    }
     
    handleSetCurrentPatient(patientID){
        this.form && this.form.setFieldsValue({patientID});
    }
    

    get isIntegrated(){
        let organization = Object(this.props.organizations[this.props.currentOrganizationID])
        for(let integration of Object.values(Object(organization.integrations))){
            if(integration.active){
                return true
            }
        }
        return false
    }

    get accounts(){
        let accounts = this.state.accounts || Object.entries(this.props.accounts || {}).map(([_, a])=> a)
        if(this.waitingRoom.account &&! _.find(accounts, (a)=>(a.id == this.waitingRoom.account.id))){
            accounts.push(this.waitingRoom.account)
        }
        return accounts
    }

    render() {
        const initialValues = {
            accountID: (this.waitingRoom.account || {}).id || '',
            patientID: (this.waitingRoom.patient || {}).id || '',
            appointmentTypeID: this.waitingRoom.appointmentTypeID || ''
        }

        let edit = false
        if(this.waitingRoom.id){
            edit = true
        }

        let editAccount = false
        if(this.form && this.form.getFieldValue('accountID') && this.form.getFieldValue('accountID') !== 'new'){
            editAccount = true
        }
        let editPatient = false
        if(this.form && this.form.getFieldValue('patientID') && this.form.getFieldValue('patientID') !== 'new'){
            editPatient = true
        }
        return (
            <Drawer
                title={edit ? 'Edit Wait Room Appointment' : 'Create Waiting Room Appointment'}
                width={520}
                placement="right"
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
                <CreateOrEditPatient
                    visible={this.state.createPatientVisible}
                    onClose={this.handleCreateOrEditPatientClose}
                    onSetCurrentPatient={this.handleSetCurrentPatient}
                    patient={this.patient}
                />
                <Form layout="vertical" ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
                    <Row gutter={16}>
                        
                        <Col span={24}>
                            <Form.Item 
                                label="Patient"
                            >
                                <Input.Group style={{ width: '100%' }} compact >
                                    <Form.Item
                                        name="patientID"
                                        noStyle
                                    >
                                        <Select
                                        style={{width: editPatient ? 'calc(100% - 57px)' : '100%'}}
                                        onChange={this.handlePatientUser}
                                        onSearch={this.handlePatientSearch}
                                        showSearch
                                        filterOption={false}
                                        showArrow={false}
                                        >
                                        {this.patients.map((patient, i)=>{
                                            return (
                                            <Option key={`patient-user-${i}`} value={patient.id}>
                                                {patient.accountFirstName} {patient.accountLastName} / {patient.firstName} {patient.lastName}
                                            </Option>
                                            )
                                        })}
                                        <Option key={`patientSearchPrompt`} value={`patientSearchPrompt`}>
                                            Search to see more patients...
                                        </Option>
                                        <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                                        <Option value=''>None</Option>
                                        <Option key='new'>New Patient</Option>
                                        </Select>
                                    </Form.Item>
                                    {editPatient && (
                                        <Button onClick={this.handleEditPatient}>Edit</Button>
                                    )}
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label="Appointment Type"
                                name="appointmentTypeID"
                                rules={[{required: true, message: 'Please select an appointment type' }]}
                            >
                                <Select
                                    style={{ width: '100%'}}
                                    placeholder="Select Appointment Type"
                                    key={`select-appointment-type`}
                                >
                                    {this.appointmentTypes.filter((appointmentType)=> (
                                        appointmentType.active  
                                    )).map((appointmentType)=>{
                                        return (
                                            <Select.Option value={`${appointmentType.id}`} key={`appointment-type-select_${appointmentType.id}`}>
                                                {appointmentType.name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label="Reason"
                                name="reason"
                                rules={[{required: true, message: 'Please enter a reason for visit' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={this.props.onClose}
                        >
                            Cancel
                        </Button>
                        <Button 
                            type="primary" 
                            htmlType="submit">
                                {edit ? 'Update' : 'Create'}
                                {this.state.showSpinner && 
                                <Spinner 
                                    iconStyle={{color: 'white'}} 
                                    style={{display: "inline-block"}} 
                                />}
                        </Button>
                    </div>
                </Form>
            </Drawer>
        );
    }
}
export default connect((state)=> state)(
  (CreateOrEditWaitingRoom)
)