import {
  FETCH_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DESTROY_NOTIFICATION,
  APPEND_NOTIFICATION,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload
      }
    case APPEND_NOTIFICATION:
      return {
        ...state,
        ...action.payload
      }
    case CREATE_NOTIFICATION:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    
    case DESTROY_NOTIFICATION:
      state = {
        ...state
      }
      delete state[action.payload]
      return state

    case SET_ORGANIZATION_CURRENT:
        return {}
        
    default:
      return state
  }
}
