import React, {Component} from 'react'
import { Card, Layout, Col, Row, List, Select } from 'antd'
import {utils} from 'lib';

import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
//import './index.less'
import InternalCalendar from './InternalCalendar'
import moment from 'moment'
import _ from 'lodash'

import {
    restrictionActions,
    appointmentActions
} from 'actions'

import { connect } from 'react-redux';

const { Content } = Layout

class Restrictions extends Component {
    constructor(){
        super()

        this.state = {
            events: [],
            adminFilter: ''
        };
    }
    
    componentDidMount(){
        restrictionActions.fetch()
    }

    get events(){
        let events = Object.values(this.props.restrictions || {}).map((restriction)=>({
            ...restriction,
            title: restriction.name,
            start: moment(restriction.startsAt).toDate(),
            end: moment(restriction.endsAt).toDate()
        }))
        .reduce((acc, e)=>{
            const format = "YYYY-MM-DD--HH-mm"
            const key = `${moment(e.start).format(format)}-${moment(e.end).format(format)}`
            const p = _.get(acc, `[${key}].events`,[])
            let title = e.title;

            if(p.length > 0){
                title = `${p.length + 1} restrictions`
            }
            acc[key] = {
                events:[
                    ...p,
                    {
                        ...e,
                        key
                    },
                ],
                ...e,
                key,
                title,
                start: e.start,
                end: e.end
            }
            return acc
        }, {})

        return Object.values(events)
    }

    get minTime(){
        let minTime = ''
        Object.values(this.props.openTimes || {}).forEach((openTime)=>{
            let openMin =  moment(openTime.startsAt).hour()
            if(!minTime || minTime > openMin){
                minTime = openMin
            }
        })
        if(minTime < 1){
            minTime = 1
        }
        return new Date(moment().startOf('day').hour((minTime - 1) || '0').format())
    }
    get maxTime(){
        let maxTime = ''
        Object.values(this.props.openTimes || {}).forEach((openTime)=>{
            let openMax =  moment(openTime.endsAt).hour()
            if(!maxTime || openMax > maxTime){
                maxTime = openMax
            }
        })
        if(maxTime > 22){
            maxTime = 22
        }
        return new Date(moment().endOf('day').hour((maxTime + 1)||'23').format())
    }
    get administrators(){
        return Object.values(this.props.administrators || {}).sort((a,b)=>{
            if(a.firstName < b.firstName) return -1
            if(a.firstName > b.firstName) return 1
            return 0
        })
    }
    render() {
        return (
            <AdminLayout fullHeight={true}>
                <SectionContent noPadding noMargin noHeader>
                    <InternalCalendar 
                        events={this.events}
                    />
                </SectionContent>
            </AdminLayout>
        )
    }
}

export default connect((state)=> state)(Restrictions)