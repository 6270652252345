import React from 'react'
import { Input, List, Card, Table, Modal, Row, Col } from 'antd'
import SnapList from './SnapList'

class ListEditor extends React.Component {
    render(){
        return <div>
            <SnapList 
                data={this.props.data || []}
                listType={this.props.listType}
                onSelect={this.props.onSelect}
                onCreate={this.props.onCreate}
                initial={this.props.initial}
                row={this.props.row}
                className={'full-height'}
            />
            {this.props.children}
        </div>
    }
};

export {
    SnapList
};
export default ListEditor;