import React from 'react';
import {connect} from 'react-redux'
import { Row, Col, Input, message, Tabs, Form, Card, Transfer, Select, Button, Switch} from 'antd'
import { withRouter } from 'react-router-dom'
import {
    appointmentTypeActions
} from 'actions'
import _ from 'lodash'

class WidgetAppointmentTypes extends React.Component {
    constructor(){
        super();
        this.state = {

        }
        this.handleAdministratorChange = this.handleAdministratorChange.bind(this)
        this.handleAppointmentTypeChange = this.handleAppointmentTypeChange.bind(this)
    }   


    get administrators(){
      let nameArray = ["title","firstName","middleInitial","lastName"]
      return Object.values(this.props.administrators || {})
      .map((administrator = {})=>{
        let _administrator = {...administrator}
        _administrator.key = administrator.id
        _administrator.title = ''
        for(let name of nameArray){
          if(administrator[name]){
            _administrator.title += `${administrator[name]} `
          }
        }
        return _administrator
      }) || []
    }
    
    get providers(){
      return this.administrators.filter((admin)=>admin.provider)
    }
  
    get activeAdministratorIDs(){
      return this.administrators.filter((administrator)=>administrator.active).map((administrator)=>administrator.id)
    }
  
    get appointmentTypes(){
      return Object.values(this.props.appointmentTypes || {})
      .map((appointmentType = {})=>{
        let _appointmentType = {...appointmentType}
        _appointmentType.key = appointmentType.id
        _appointmentType.title = `${appointmentType.name}`
        return _appointmentType
      }) || []
    }
  
    get activeAppointmentTypeIDs(){
      return this.appointmentTypes.filter((appointmentType)=>appointmentType.active).map((appointmentType)=>appointmentType.id)
    }
  
    async handleAppointmentTypeChange(targetAppointmentTypes){
      let currentActive = this.activeAppointmentTypeIDs
      let appointmentTypeIDs = []
  
      this.appointmentTypes.forEach((appointmentType)=>{
        if((targetAppointmentTypes.includes(appointmentType.id) || currentActive.includes(appointmentType.id)) &&
          !(targetAppointmentTypes.includes(appointmentType.id) && currentActive.includes(appointmentType.id))){
            appointmentTypeIDs.push(appointmentType.id)
        }
      })
      await appointmentTypeActions.activate(appointmentTypeIDs)
    }

    async handleAdministratorChange(targetAdministrators){
      let currentActive = this.activeAdministratorIDs
      let administratorIDs = []
  
      this.administrators.forEach((administrator)=>{
        if((targetAdministrators.includes(administrator.id) || currentActive.includes(administrator.id)) &&
          !(targetAdministrators.includes(administrator.id) && currentActive.includes(administrator.id))){
            administratorIDs.push(administrator.id)
        }
      })
      await administratorActions.activate(administratorIDs)
    }

    render() {
        return (
            <Row>
                <Col span={12}>
                <Transfer 
                    titles={['Inactive', 'Active']}
                    listStyle={{width:'calc(50% - 25px)'}}
                    dataSource={this.appointmentTypes}
                    targetKeys={this.activeAppointmentTypeIDs}
                    render={item => item.title}
                    onChange={this.handleAppointmentTypeChange}
                />
                </Col>
            </Row>
        );
    }
}
export default withRouter(connect((state)=>state)(WidgetAppointmentTypes))
