import React from 'react'
import { withRouter } from 'react-router-dom'
import { Card, Layout, Col, Row, List } from 'antd'
import {utils} from 'lib';

import './index.less'
import SectionContent from 'components/elements/SectionContent'
import {CreateOrEditServiceTime} from 'components/partials'
import moment from 'moment'
import {serviceTimeActions,openTimeActions} from 'actions'
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import processRepeating from '../../../lib/processRepeating'

const localizer = momentLocalizer(moment)
 
const getRepeatingServiceTimes = (responseData, startingAt, endingAt)=>{
    if(!startingAt){
        startingAt = moment()
        endingAt = startingAt.clone().endOf('week')
    }

    if(startingAt.isBefore(moment())){
        startingAt = moment()
        endingAt = startingAt.clone().endOf('week')
    }

    let data = processRepeating({
      data: responseData,
      startingAt,
      endingAt,
      getPastItems: true
    })
  
    data = {
      ...data
    }
  
    return data
}
  
class ServiceTimeCalendar extends React.Component {
    constructor(){
        super()

        this.state = {
            events: [],
            serviceTimes: {},
            currentDate: moment(),
            view: "week"
        };
        this.handleCreate = this.handleCreate.bind(this)
        this.hideCreate = this.hideCreate.bind(this)
        this.handleChangeView = this.handleChangeView.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleNavigate = this.handleNavigate.bind(this)
    }
    async componentDidMount(){
        let serviceTimes = await serviceTimeActions.fetch({appointmentTypeID: this.props.match.params.id})
        let _serviceTimes = getRepeatingServiceTimes(Object.values(serviceTimes), this.state.currentDate.clone().startOf("week"), this.state.currentDate.clone().endOf("week"))
        
        this.setState({
            serviceTimes: _serviceTimes
        })
        openTimeActions.fetch({skipRepeating: true})
    }
    handleSelect(event){
        
        this.setState({
            event
        }, ()=>{
            this.setState({
                createVisible: true,
            })
        })
    }
    handleCreate(event){
        this.setState({
            createVisible: true,
            event,
            events: [event]
        })
    }
    hideCreate(serviceTimeModified){
        let _serviceTimes = this.state.serviceTimes
        if(serviceTimeModified){
            _serviceTimes = getRepeatingServiceTimes(this.serviceTimes, this.state.currentDate.clone().startOf(this.state.view), this.state.currentDate.clone().endOf(this.state.view))
        }
        this.setState({
            createVisible: false,
            event: '',
            events: [],
            serviceTimes: _serviceTimes
        })
    }
    handleChangeView(type){
        this.setState({
            view: type
        })
    }
    handleNavigate(date){
        let _currentDate = moment(date)
        let _serviceTimes = getRepeatingServiceTimes(this.serviceTimes, _currentDate.clone().startOf(this.state.view), _currentDate.clone().endOf(this.state.view))

        this.setState({
            currentDate: moment(date),
            serviceTimes: _serviceTimes
        })
    }
    get serviceTimes(){
        return Object.values(this.props.serviceTimes || {})
    }
    get serviceTime(){
        const serviceTime = this.state.event || {}
        
        return {
            ...serviceTime,
            startsAt: serviceTime.start,
            endsAt: serviceTime.end
        }
    }
    get events(){
        return Object.values(this.state.serviceTimes).map((_)=>({
            id: _.id,
            repeating: _.repeating || false,
            repeatingType: _.repeatingType,
            start: moment(_.startsAt).toDate(),
            end: moment(_.endsAt).toDate(),
            dayOfWeek: _.dayOfWeek,
            dayOfMonth: _.dayOfMonth,
            sequence: _.sequence,
            skipValue: _.skipValue,
            skipType: _.skipType,
            repeatsUntil:  _.repeatsUntil ? moment(_.repeatsUntil).toDate() : ''
        })).concat(this.state.events)
    }
    get minTime(){
        let minTime = ''
        Object.values(this.props.openTimes || {}).forEach((openTime)=>{
            let openMin =  moment(openTime.startsAt).hour()
            if(!minTime || minTime > openMin){
                minTime = openMin
            }
        })
        if(minTime < 1){
            minTime = 1
        }
        return new Date(moment().startOf('day').hour((minTime - 1) || '0').format())
    }
    get maxTime(){
        let maxTime = ''
        Object.values(this.props.openTimes || {}).forEach((openTime)=>{
            let openMax =  moment(openTime.endsAt).hour()
            if(!maxTime || openMax > maxTime){
                maxTime = openMax
            }
        })
        if(maxTime > 22){
            maxTime = 22
        }
        return new Date(moment().endOf('day').hour((maxTime + 1)||'23').format())
    }
    render() {
        return (
            <span >
                <Calendar
                    selectable
                    localizer={localizer}
                    defaultDate={new Date()}
                    view={this.state.view}
                    onView={this.handleChangeView}
                    events={this.events}
                    onSelectEvent={this.handleSelect}
                    onSelectSlot={this.handleCreate}
                    onNavigate={this.handleNavigate}
                    views={['week', 'day']}
                    min={this.minTime}
                    max={this.maxTime}
                    step={15}
                    timeslots={4}
                />
                <CreateOrEditServiceTime 
                    visible={this.state.createVisible} 
                    onClose={this.hideCreate} 
                    serviceTime={this.serviceTime} />
            </ span>
        )
    }
}

export default withRouter(connect((state)=> state)(ServiceTimeCalendar))