import React from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Input, message, Tabs, Form, Card, Transfer, Select, Button, Switch} from 'antd'
import { Fa } from 'components/elements'
import {
    availabilityActions,
    organizationActions
} from 'actions'
import _ from 'lodash'

class WidgetAppointments extends React.Component {
    constructor(){
        super();
        this.state = {
            condenseAdminsToggled: false,
            widgetAvailabilityToggled: false,
        }
        this.handleCondenseToggle = this.handleCondenseToggle.bind(this)
        this.handleWidgetType = this.handleWidgetType.bind(this)
        this.handleWidgetValue = this.handleWidgetValue.bind(this)
        this.handleWidgetPeriod = this.handleWidgetPeriod.bind(this)
        this.handleLimitToggle = this.handleLimitToggle.bind(this)
        this.handleBasicSubmit = this.handleBasicSubmit.bind(this)
    }   

    componentDidMount() {    
        let widgetAvailability = this.widgetAvailability
        this.setState({
          widgetAvailabilityToggled: !!this.props.currentOrganization.widgetAvailability,
          condenseAdminsToggled: !!(this.props.currentOrganization.widgetSettings || {}).condenseAdministrators,
          widgetPeriod: widgetAvailability.period,
          widgetValue: widgetAvailability.value,
          widgetType: widgetAvailability.type,
        })
      }
      
      handleCondenseToggle(value){
        this.setState({
          condenseAdminsToggled: value
        })
      }

      handleWidgetValue(e){
        this.setState({
          widgetValue: e.target.value
        })
      }

      handleLimitToggle(value){
        this.setState({
          widgetAvailabilityToggled: value
        })
      }

      handleWidgetType(value){
        this.setState({
          widgetType: value
        })
      }

      async handleBasicSubmit(values){
        let data = {}
        if(!this.state.widgetAvailabilityToggled){
          data.widgetAvailability = ''
        } else {
          data.widgetAvailability = `${this.state.widgetPeriod}_${this.state.widgetValue}_${this.state.widgetType}`
        }

        data.condenseAdministrators = this.state.condenseAdminsToggled

        organizationActions.update(data)
      }

      get widgetAvailability(){
        let values = (this.props.currentOrganization.widgetAvailability || '').split('_')
        let data = {
          period: this.state.widgetPeriod || values[0],
          value: this.state.widgetValue || values[1],
          type: this.state.widgetType || values[2]
        }
    
        return data
      }
    
      get appointmentTypes(){
        return Object.values(this.props.appointmentTypes || {})
        .map((appointmentType = {})=>{
          let _appointmentType = {...appointmentType}
          _appointmentType.key = appointmentType.id
          _appointmentType.title = `${appointmentType.name}`
          return _appointmentType
        }) || []
      }

      handleWidgetPeriod(value){
        this.setState({
          widgetPeriod: value
        })
      }

    render() {
        return (
            <Form layout="vertical" onFinish={this.handleBasicSubmit}>
            <label style={{paddingRight: "20px"}}>Limit when users can book appointments:</label>
            <Switch 
              checked={this.state.widgetAvailabilityToggled} 
              onChange={this.handleLimitToggle}
            />
            <br />
            {this.state.widgetAvailabilityToggled &&
              <div>
                <br />
                Prevent clients from booking appointments 
                <Select onChange={this.handleWidgetPeriod} defaultValue={this.widgetAvailability.period} placeholder="Within" style={{width: "100px",  marginLeft: "20px"}}>
                  <Select.Option value="within" key="widget-within">Within</Select.Option>
                  <Select.Option value="after" key="widget-after">After</Select.Option>
                </Select> 
                <Input onBlur={this.handleWidgetValue} defaultValue={this.widgetAvailability.value} placeholder="24" style={{width: "100px", marginLeft: "20px"}}/> 
                <Select onChange={this.handleWidgetType} defaultValue={this.widgetAvailability.type} placeholder="Hours" style={{width: "100px", marginLeft: "20px"}}>
                  <Select.Option value="hours" key="widget-hours">Hours</Select.Option>
                  <Select.Option value="days" key="widget-days">Days</Select.Option>
                  <Select.Option value="weeks" key="widget-weeks">Weeks</Select.Option>
                  <Select.Option value="months" key="widget-months">Months</Select.Option>
                </Select>
              </div>
            }
            <br />
            <br />
            <label style={{paddingRight: "20px"}}>Condense all Administrators in the widget:</label>
            <Switch 
              checked={this.state.condenseAdminsToggled} 
              onChange={this.handleCondenseToggle}
            />
            <br />
            <br />
            <br />
            <Button type="primary" htmlType="submit">Update</Button>
          </Form>
        );
    }
}
export default withRouter(connect((state)=>state)(WidgetAppointments))
