import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';

import store from '../store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.appointmentStatuses.fetch()
  
  const data = response.data.reduce((acc, item)=>{
    acc[item.id] = item
    return acc;
  }, {})

  store.dispatch({ 
    type: ActionTypes.FETCH_APPOINTMENT_STATUSES,
    payload: data
  })
}

export const update = async(params = {}) => {
  console.log(params, 'update')
  const response = await apiClient.organizations.appointmentStatuses.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_APPOINTMENT_STATUS,
    payload: response.data
  })
}

export const activate = async(ids = []) => {
  const response = await apiClient.organizations.appointmentStatuses.activate({ids})

  store.dispatch({
    type: ActionTypes.UPDATE_APPOINTMENT_STATUSES,
    payload: response.data
  })

  return response.data
}

export const destroy = async(id) => {
  const response = await apiClient.organizations.appointmentStatuses.destroy(id)

  store.dispatch({
    type: ActionTypes.DESTROY_APPOINTMENT_STATUS,
    payload: id
  })
}

export const create = async(params = {})=>{
  const response = await apiClient.organizations.appointmentStatuses.create(params)

  store.dispatch({
    type: ActionTypes.CREATE_APPOINTMENT_STATUS,
    payload: response.data
  })
  return response.data
}