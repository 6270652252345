import React, {Component, Fragment} from 'react'
import {Drawer} from 'antd'
import moment from 'moment'
import { CreateOrEditRestriction, SelectRestriction } from 'components/partials'
import {connect} from 'react-redux'
import { Calendar, momentLocalizer } from 'react-big-calendar'

const localizer = momentLocalizer(moment)

let i = 0;

class InternalCalendar extends Component {
    constructor(){
        super()
        this.state = {
            view: 'week',
            events: []
        }
        this.handleChangeView = this.handleChangeView.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
        this.setRestriction = this.setRestriction.bind(this)
        this.hideCreate = this.hideCreate.bind(this)
        this.hideSelect = this.hideSelect.bind(this)
    }

    handleChangeView(view){
        this.setState({
            view
        })
    }

    get events() {
        if(this.state.eventKey){
            return (
                _.get(this, 'props.events', []).find((event)=>
                    event.key == this.state.eventKey
                ) || {events: []}
            ).events.map((event)=>{
                let _event = {...event}
                
                _event.startsAt = moment(event.start)
                delete _event.start
                _event.endsAt = moment(event.end)
                delete _event.end
                return _event;
            })
        }else{
            return [this.state.event || {}]
        }
    }
    
    handleSelect(event){
        if(event.events.length == 1){
            this.setState({
                createEditVisible: true,
                eventKey: event.key
            })
        }else{
            this.setState({
                eventKey: event.key
            }, ()=>{
                this.setState({
                    selectVisible: true,
                })
            })
        }
    }

    handleCreate(event){
        this.setState({
            createEditVisible: true,
            event: {
                startsAt: moment(event.start),
                endsAt: moment(event.end)
            },
            eventKey: null
        })
    }

    setRestriction(event){
        i++
        this.setState({
            events: [
                ...this.state.events,
                {
                    ...event,
                    title: `There ${i}`
                }
            ]
        })
    }

    hideCreate(){
        this.setState({
            eventKey: undefined,
            events: [],
            event: undefined,
            createEditVisible: false
        })
    }

    hideSelect(){
        this.setState({
            selectVisible: false
        })
    }
    
    render(){
        return (
            <Fragment>
                <Calendar
                    selectable
                    localizer={localizer}
                    defaultDate={new Date()}
                    view={this.state.view}
                    onView={this.handleChangeView}
                    events={this.props.events}
                    onSelectEvent={this.handleSelect}
                    onSelectSlot={this.handleCreate}
                    onNavigate={this.handleNavigate}
                    views={['week', 'day']}
                    min={this.minTime}
                    max={this.maxTime}
                    step={15}
                    timeslots={4}
                />
                <CreateOrEditRestriction visible={this.state.createEditVisible} onClose={this.hideCreate} restriction={this.events[0]} />
                <SelectRestriction visible={this.state.selectVisible} onClose={this.hideSelect} restrictions={this.events} />
            </Fragment>
        )
    }
}

export default connect((state)=> ({
    store: state
}))(InternalCalendar)