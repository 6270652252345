import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, List, Row, Col, Input } from 'antd'
import {AdminLayout, Scroller, SectionContent, Spinner} from 'components/elements'
import { waitListMessageActions, administratorActions, appointmentActions, appointmentTypeActions } from 'actions'
import moment from 'moment'
import { CreateOrEditWaitListMessage } from 'components/partials'
const _ = require('lodash')

class WaitListMessages extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            resetVisible: false,
            appointmentAdministrators: {},
            saveAppointmentSpinner: {},
            removeAppointmentSpinner: {},
            waitingRoomSpinner: false,
            search: '',
            createOrEditWaitListMessageVisible: false
        }

        this.handleCancel = this.handleCancel.bind(this)
        this.handleClearPrompt = this.handleClearPrompt.bind(this)
        this.handleChangeAdministrator = this.handleChangeAdministrator.bind(this)
        this.handleAddWaitListMessage = this.handleAddWaitListMessage.bind(this)
        this.handleWaitListMessageClose = this.handleWaitListMessageClose.bind(this)
        this.timeToSend = this.timeToSend.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidMount() {
        waitListMessageActions.fetch()
        administratorActions.fetch()
        appointmentTypeActions.fetch()
        if(!this.timeToSendInterval){
            this.timeToSendInterval = setInterval(()=>{
                waitListMessageActions.fetch()
            },30000)
        }
    }

    componentWillUnmount(){
        if(this.timeToSendInterval) clearInterval(this.timeToSendInterval)
    }

    handleCancel(){
        this.setState({resetVisible: false})
    }

    handleClearPrompt(){
        this.setState({
            resetVisible: true
        })
    }

    handleAddWaitListMessage(){
        this.setState({
            createOrEditWaitListMessageVisible: true
        })
    }

    handleWaitListMessageClose(){
        this.setState({
            createOrEditWaitListMessageVisible: false,
            currentWaitListMessage: ''
        })
    }

    handleChangeAdministrator(value){
        if(value){
            let [appointmentID, administratorID] = value.split('--')
            this.setState({
                appointmentAdministrators:{
                    ...this.state.appointmentAdministrators,
                    [`${appointmentID}`]: administratorID
                }
            })
        }
    }
    
    handleSearch(value){
        this.setState({
            search: value
        })
    }

    handleEditWaitListMessage(waitListMessage){
        this.setState({
            createOrEditWaitListMessageVisible: true,
            currentWaitListMessage: waitListMessage
        })
    }

    handleRemoveWaitListMessage(waitListMessage){
        waitListMessageActions.destroy(waitListMessage.id)
    }

    timeToSend(waitListMessage){
        return this.waitListMessageDelay - moment().diff(moment(waitListMessage.createdAt),"minutes")
    }

    get organization(){
        return this.props.organizations[this.props.currentOrganizationID] || {}
    }

    get waitListMessages(){
        return _.sortBy(this.props.waitListMessages || [], ['createdAt'])
    }

    get appointmentTypes(){
        return this.props.appointmentTypes || {}
    }

    get administrators(){
        return this.props.administrators || {}
    }

    get waitListMessageDelay(){
        return (this.organization.settings || {}).waitListMessageDelay
    }

    render() {
        return (
            <AdminLayout>
                <CreateOrEditWaitListMessage 
                    visible={this.state.createOrEditWaitListMessageVisible}
                    onClose={this.handleWaitListMessageClose}
                    waitListMessage={this.state.currentWaitListMessage}
                />
                <SectionContent noPadding={true} noMargin={true}>
                    <Scroller>
                        <Row style={{ margin: '20px'}}>
                            <Col md={12}></Col>
                            <Col md={12}>
                                <div style={{float: 'right'}}>
                                    <Button 
                                        type='primary'
                                        onClick={this.handleAddWaitListMessage}
                                    >
                                        Send New Message
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Row style={{margin: '0px 40px 10px'}}>
                                <Col md={5} lg={5}>
                                    Administrator
                                </Col>
                                <Col md={5} lg={5}>
                                    Appointment Type
                                </Col>
                                <Col md={5} lg={5}>
                                    Message Time
                                </Col>
                                <Col md={5} lg={5}>
                                    Sends In
                                </Col>
                            </Row>
                            <List 
                                style={{margin: '0px 20px'}}
                                bordered
                                dataSource={this.waitListMessages}
                                renderItem={waitListMessage => (
                                    <List.Item>
                                        <Row style={{width: '100%'}}>
                                            <Col md={5} lg={5}>
                                                {(this.administrators[waitListMessage.administratorID] || {}).firstName} {(this.administrators[waitListMessage.administratorID] || {}).lastName}
                                            </Col>
                                            <Col md={5} lg={5}>
                                                {(this.appointmentTypes[waitListMessage.appointmentTypeID] || {}).name}
                                            </Col>
                                            <Col md={5} lg={5}>
                                                {moment(waitListMessage.startsAt).format("MM/DD/YYYY hh:mm a")} {moment(waitListMessage.endsAt).format("MM/DD/YYYY hh:mm a")}
                                            </Col>
                                            <Col md={5} lg={5}>
                                                {this.timeToSend(waitListMessage) > 0 ? (
                                                    <div>
                                                        {this.timeToSend(waitListMessage)} Minutes
                                                    </div>
                                                ) : (
                                                    <div>
                                                        Sending Now
                                                    </div>
                                                )}
                                            </Col>
                                            <Col md={2} lg={2} align='center'>
                                                <Button 
                                                    type='danger'
                                                    onClick={this.handleRemoveWaitListMessage.bind(this,waitListMessage)}
                                                >
                                                    Remove
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Scroller>
                </SectionContent>
            </AdminLayout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        waitListMessages: state.waitListMessages || [],
        appointmentTypes: state.appointmentTypes || [],
        administrators: state.administrators || [],
        organizations: state.organizations || [],
        currentOrganizationID: state.currentOrganizationID || ''
    }
}

export default withRouter(connect(mapStateToProps)(WaitListMessages))
