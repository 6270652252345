const Money = (props)=>{
    let value = '0.00';
    if(typeof props.children == 'number'){
        value = props.children.toLocaleString("en-US")
    }
    if(!value.includes('.')){
        value = `${value}.00`
    }
    return `$${value}`
}

export default Money