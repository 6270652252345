import React from 'react';
import { Card, Drawer, Form, Button, Col, Row } from 'antd';
import moment from 'moment'
import { patientActions } from 'actions'
import {connect} from 'react-redux'
import {CreateOrEditRestriction} from 'components/partials'
import _ from 'lodash'

class SelectRestriction extends React.Component {
  constructor(){
    super();
    this.state = { 
      visible: false,
      createRestrictionTypeVisible: false,
      providerSearch: '',
      restriction: {}
    };

    this.handleRestrictionEditClose = this.handleRestrictionEditClose.bind(this)
    this.handleRestrictionCreate = this.handleRestrictionCreate.bind(this)
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  handleRestrictionEdit(restriction){
    this.setState({
      restrictionID: restriction.id
    }, ()=>{
      this.setState({
        createOrEditRestrictionVisible: true
      })
    })
  }

  handleRestrictionCreate(){
    this.setState({
      restriction: {
        startsAt: this.props.restrictions[0].startsAt,
        endsAt: this.props.restrictions[0].endsAt,
        rules: []
      },
      restrictionID: null
    }, ()=>{
      this.setState({
        createOrEditRestrictionVisible: true
      })
    })
  }
  
  handleRestrictionEditClose(){
    this.setState({
      restriction: {}
    }, ()=>{
      this.setState({
        createOrEditRestrictionVisible: false
      })
    })
  }

  get restriction(){
    let _event;
    if(this.state.restrictionID){
      _event = _.get(this, "props.restrictions", []).find((restriction)=>(
        restriction.id == this.state.restrictionID
      ))
    }else if(this.state.restriction){
      _event = this.state.restriction
    }

    return _event
  }

  get restrictions(){
    return _.sortBy(
      _.get(this, 'props.restrictions', []), [(restriction)=>
        restriction.id
      ]
    )
  }
  get startsAt(){
    return _.get(this, 'props.restrictions[0].startsAt', moment())
  }
  get endsAt(){
    return _.get(this, 'props.restrictions[0].endsAt', moment())
  }

  render() {
    const title = (<div
          style={{
            position: 'absolute',
            top: 0,
            zIndex:1,
            width: '100%',
            borderBottom: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'left',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}
          >Restrictions on <b>
                {this.startsAt.format('M/DD/YYYY')}
          </b> from <b>
            {this.startsAt.format('h:mma')}
          </b> to <b>
            {this.endsAt.format('h:mma')}
          </b>
    </div>)

    return (
      <Drawer
        title={title}
        width={720}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <CreateOrEditRestriction
          visible={this.state.createOrEditRestrictionVisible} 
          onClose={this.handleRestrictionEditClose}
          onSetCurrentRestriction={this.handleSetCurrentRestriction}
          restriction={this.restriction}
        />
        <Form layout="vertical" onFinish={this.handleSubmit}>
          <Row>
            <Col span={24}>
              {this.restrictions.map((restriction, i)=>(
                <Card key={`${i}`} style={{marginBottom: 14}} onClick={this.handleRestrictionEdit.bind(this, restriction)} className='clickable-card'>
                  {restriction.name}
                </Card>
              ))}
              <br />
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
          >
            
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Done
            </Button>
            <Button type="primary" onClick={this.handleRestrictionCreate}>Create New</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> ({
  store:{
    restrictions: state.restrictions
  }
}))(
  SelectRestriction
)