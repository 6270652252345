import {
    FETCH_MANUAL_WORKER_EXECUTIONS,
    UPDATE_MANUAL_WORKER_EXECUTIONS,
  } from '../constants/actionTypes'
  
  export default (state = [], action) => {
    switch (action.type) {
    //   case UPDATE_MANUAL_WORKER_EXECUTIONS:
    //     return [
    //       ...state,
    //       action.payload
    //     ]
      case FETCH_MANUAL_WORKER_EXECUTIONS:
        return action.payload
      default:
        return state
    }
  }
  