import Collection from 'lib/client/collections/collection'

class Patients extends Collection{
    create(options){
        const {accountID} = options
        delete options.accountID
        return this.axios.post(`/organizations/${this.props.currentOrganization.id}/accounts/${accountID}/patients`, {
            ...options
        });
    }
    update(options){
        const {id, accountID} = options
        delete options.accountID
        return this.axios.put(`/organizations/${this.props.currentOrganization.id}/accounts/${accountID}/patients/${id}`, {
            ...options
        });
    }
    fetch(options = {}){
        return this.axios.get(`/organizations/${this.props.currentOrganization.id}/accounts/${options.accountID}/patients`, {
            ...options
        });
    }
    destroy(options){
        const {id, accountID} = options
        return this.axios.delete(`/organizations/${this.props.currentOrganization.id}/accounts/${accountID}/patients/${id}`, {});
    }
}

export default Patients;