import React from 'react'
import Fetch from "./Fetch"
import AppointmentRequests from "./AppointmentRequests"
import Notes from "./Notes"
import RiskAssessment from './RiskAssessment'

import IntegrationEntities from "./IntegrationEntities"
import {
    Tabs
} from 'antd';
import WidgetRequests from './WidgetRequests'
const { TabPane } = Tabs;
export default (props)=>(
    <div>
        <Tabs defaultActiveKey="1" tabBarStyle={{marginBottom: '0px'}}>
            <TabPane tab="Events" key="1">
                <Fetch />
            </TabPane>
            <TabPane tab="Integration Entities" key="2">
               <IntegrationEntities />
            </TabPane>
            <TabPane tab="Appointment Requests" key="3">
                <AppointmentRequests />
            </TabPane>
            <TabPane tab="Notes" key="4">
                <Notes />
            </TabPane>
            <TabPane tab="Risk Assessment" key="5">
                <RiskAssessment />
            </TabPane>
            <TabPane tab="Sessions" key="6">
                <WidgetRequests />
            </TabPane>
        </Tabs>
    </div>
)