import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'
import { Row, Col, message } from 'antd'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import StatusCards from './StatusCards'
import Item from './Item'

import {
  appointmentStatusActions, classificationActions
} from 'actions'

class AppointmentStatuses extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      search: ''
    }
    this.handleCreateButtonOnClick = this.handleCreateButtonOnClick.bind(this)
    this.handleTableOnChange = this.handleTableOnChange.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.onSearch = this.onSearch.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(previousProps) {
    if (previousProps.isFetchItemsLoading && this.props.fetchItemsError) {
      message.error(this.props.fetchItemsError)
    }
  }

  fetch() {
    appointmentStatusActions.fetch()
  }

  handleCreateButtonOnClick(e) {
    e.preventDefault()
    this.props.history.push(createPath)
  }
  onSearch(e){
    this.setState({search: e.target.value})
  }

  async handleTableOnChange(pagination, filters, sorter) {
    const { columnKey, field, order } = sorter
    await this.props.changeTable({
      pagination,
      filters,
      sorter: {
        columnKey,
        field,
        order,
      },
    })
    this.fetch()
  }

  handleOnSelect(appointmentStatus) {
    if(appointmentStatus){
      this.props.history.push(`/admin/appointment-statuses/${appointmentStatus.id}`)
    }
  }

  handleCreate() {
    this.props.history.push(`/admin/appointment-statuses/new`)
  }

  get appointmentStatuses(){
    if(!this.state.search){
      return this.props.appointmentStatuses || []
    } else {
      let filterAppointmentStatuses = Object.values(this.props.appointmentStatuses || {})
      return filterAppointmentStatuses
    }
  }

  async handleSearchOnClick() {
    await this.props.searchTable({ isSearching: true })
    await this.props.changeTable({})
    this.fetch()
  }

  async handleSearchOnCancel(e) {
    e.preventDefault()
    await this.props.editSearch({ search: null })
    await this.props.searchTable({ isSearching: false })
    await this.props.changeTable({})
    this.fetch()
  }

  render() {
    
    return (
      <div>
        <AdminLayout>
          <SectionContent noPadding noMargin title='Appointment Statuses'>
          <Switch>
            <Route path='/admin/appointment-statuses' render={()=>
              <Row style={{height: '100%'}}>
                <Col xs={24} lg={8}>
                  <StatusCards
                    dataSource={this.appointmentStatuses}
                    onSelect={this.handleOnSelect}
                    onCreate={this.handleCreate}
                    onSearch={this.onSearch}
                    />
                </Col>
                <Col xs={24} lg={16}>
                  <Route path='/admin/appointment-statuses/:id' component={Item} />
                </Col>
              </Row>
            }/>
          </Switch>
          </SectionContent>
        </AdminLayout>
      </div>
    )
  }
}

export default withRouter(connect((state)=>(state))(AppointmentStatuses))
