import React from 'react'
import {
  Modal, Input, Card, Button, Row, Col
} from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import Scroller from 'components/elements/Scroller'
import SectionContent from 'components/elements/SectionContent'
import {CreateOrEditMessages} from 'components/partials'
import {messageActions, notificationActions} from 'actions'
import style from './style.less'
import parse from 'html-react-parser';

const TextArea = Input.TextArea

class Show extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      createPatientVisible: false,
      messageLimit: 20
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.lastMessage = React.createRef()
  }

  componentDidMount(){
    if(this.account.id && this.account.id != 'new'){
      messageActions.fetch({
        accountID: this.account.id
      })
    }
  }

  componentDidUpdate(previousProps){
    if(this.lastMessage.current && Object(this.lastMessageData).id !== Object(this.messages.reverse()[0]).id){
      this.lastMessageData = this.messages.reverse()[0];
                
      this.lastMessage.current.scrollIntoView({behavior: "smooth", block: "end"})
    }
    if(previousProps.notifications != this.props.notifications){
      const messageNotifications = Object.values(this.props.notifications).filter((notification)=>
        notification.notificationableType = 'Message'
      )
      this.lastProps = previousProps
      for(let messageNotification of messageNotifications){
        if(!previousProps.notifications[messageNotification.id]){
          if(messageNotification.notificationableID != 'new'){
            messageActions.safeFetchByID({
              id: messageNotification.notificationableID
            })
          }
          // notificationActions.markAsViewed(messageNotification.id, {
          //   local: true
          // })
        }
      }
    }
  }

  get account(){
    return {
      id: this.props.match.params.id
    }
  }

  get messages(){
    let contentIDs = {}
    return Object.values(this.props.messages || {}).filter((v)=>
      v.accountID == this.account.id
    ).reduce((acc, message)=>{
      message = {...message}
      if(!contentIDs[message.contentID]){
        acc.push(message)
      }else{
        contentIDs[message.contentID].services = `${contentIDs[message.contentID].service} and ${message.service}`
      }
      if(message.contentID) contentIDs[message.contentID] = message
      
      return acc
    }, [])
    .sort((a, b)=>
      a.createdAt > b.createdAt ? 1 : -1
    )
  }

  handleChange(e){
    this.setState({
      newMessage: e.target.value
    })
  }

  handleKeyDown(e){
    if(e.key == 'Enter'){
      e.preventDefault()
      this.setState({
        newMessage: ''
      })

      this.handleSubmit({
        preventDefault: ()=>{}
      })
    }
  }

  handleSubmit(e){
    e.preventDefault();
    
    this.setState({
      newMessage: ''
    })

    messageActions.create({
      accountID: this.account.id,
      body: this.state.newMessage
    })
  }

  render() {
    let lastAdminMessenger = {};
    let currentAdminMessenger = {};
    return (
      <div className='messages'>
        <div className='container'>
          <Scroller anchorBottom={true}>
            <Card bordered={false}>
              {this.messages.slice(
                this.messages.length - this.state.messageLimit,
                this.messages.length
              ).map((message, i)=>{
                lastAdminMessenger = currentAdminMessenger || {};
                currentAdminMessenger = message.sender || {};
                let direction = message.direction == 'outbound' ? 'to' : 'from'
                return (
                  <div className={direction} key={`message-${i}`}>
                    {message.direction == 'outbound' && currentAdminMessenger.id != lastAdminMessenger.id && (
                      <div className='name'>{Object(message.sender).firstName} {Object(message.sender).lastName}</div>
                    )}
                    <div className={'message'} ref={this.lastMessage}>
                      {parse(`${message.body}`)}
                      {message.attachments && (
                        <div>
                          {message.attachments.map((image)=>
                            <img src={image} />
                          )}
                        </div>
                      )}
                    <div className={'details'}>{direction == 'to' ? 'Sent' : 'Recieved'} via {message.services || message.service} at {moment(message.createdAt).format('h:mma MM/DD/YYYY')}</div>
                    </div>
                  </div>
                )
              })}
            </Card>
          </Scroller>
        </div>
        <div className='footer'>
          <form onSubmit={this.handleSubmit}>
            <TextArea
              placeholder='Type here to create a message'
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              value={this.state.newMessage}
            />
            <div style={{float: 'right'}}>
              <Button type='primary' onClick={this.handleSubmit}>
                Send <ArrowRightOutlined />
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {

  const {patientCurrent} = state

  return {
    accounts: state.accounts,
    currentAccount: Object(state.session).account,
    patientCurrent,
    patients: state.patients,
    accountsPatients: state.accountsPatients,
    breeds: state.breeds,
    genders: state.genders,
    classifications: state.classifications,
    messages: state.messages,
    notifications: state.notifications
  }
}

const mapDispatchToProps = dispatch => ({
  fetchItems: params => dispatch(fetchItems(params)),
  changeTable: params => dispatch(changeTable(params)),
  editSearch: search => dispatch(editSearch(search)),
  searchTable: search => dispatch(searchTable(search)),
  setCurrent: search => dispatch(setCurrent(search)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Show))

// <Table
//   rowKey={record => `item-row-${record.id}`}
//   columns={columns}
//   {...this.props}
// />