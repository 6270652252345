import Collection from 'lib/client/collections/collection'

class MessengerTemplates extends Collection{
    fetch(options){
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/messenger-templates`, options);
    }

    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/messenger-templates`, options);
    }

    update(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/messenger-templates/${options.messengerTemplateID}`, options);
    }
    
    destroy(options){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/messenger-templates/${options.messengerTemplateID}`);
    }
}

export default MessengerTemplates;