import { combineReducers } from 'redux'
import session from './session'
import home from './home'
import login from './login'
import admin from './admin'
import accounts from './accounts'
import patients from './patients'
import groups from './groups'
import companies from './companies'
import patientCurrent from './patientCurrent'
import currentOrganizationID from './currentOrganizationID'
import organizations from './organizations'
import organizationTypes from './organizationTypes'
import patientForm from './patientForm'
import appointments from './appointments'
import appointmentTypes from './appointmentTypes'
import appointmentStatuses from './appointmentStatuses'
import classifications from './classifications'
import breeds from './breeds'
import genders from './genders'
import administrators from './administrators'
import administratorTypes from './administratorTypes'
import shifts from './shifts'
import openTimes from './openTimes'
import serviceTimes from './serviceTimes'
import serviceRegions from './serviceRegions'
import serviceAreas from './serviceAreas'
import integrationConfigs from './integrationConfigs'
import insights from './insights'
import restrictions from './restrictions'
import partners from './partners'
import workers from './workers'
import messages from './messages'
import notifications from './notifications'
import messengerTemplates from './messengerTemplates'
import sync from './sync'
import syncStatus from './syncStatus'
import waitingRoom from './waitingRoom'
import customFields from './customFields'
import waitLists from './waitLists'
import waitListMessages from './waitListMessages'
import partitions from './partitions'
import manualWorkerExecutions from './manualWorkerExecutions'

export default combineReducers({
  session,
  home,
  login,
  admin,
  accounts,
  patients,
  groups,
  companies,
  patientCurrent,
  currentOrganizationID,
  organizations,
  organizationTypes,
  patientForm,
  appointments,
  appointmentTypes,
  appointmentStatuses,
  genders,
  classifications,
  breeds,
  administrators,
  administratorTypes,
  shifts,
  openTimes,
  manualWorkerExecutions,
  serviceTimes,
  serviceRegions,
  serviceAreas,
  integrationConfigs,
  insights,
  partners,
  restrictions,
  workers,
  messages,
  notifications,
  messengerTemplates,
  sync,
  syncStatus,
  waitingRoom,
  customFields,
  waitLists,
  waitListMessages,
  partitions
})
