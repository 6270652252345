import Collection from './collection'

class Companies extends Collection{
    create(options){
        return this.axios.post(`/companies`, {
            ...options
        });
    }
    update(options){
        return this.axios.put(`/companies/${options.id}`, {
            ...options
        });
    }
    activate(options){
        return this.axios.put(`/companies/activate`, {
            ...options
        });
    }
    destroy(id){
        return this.axios.delete(`/companies/${id}`);
    }
    fetch(options = {}){
        let params = ''
        
        return this.axios.get(`/companies${params}`);
    }
}

export default Companies;