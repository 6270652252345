import React from 'react'
import { Modal } from 'antd'
import { TableRowEditButton } from 'components/elements/AppButton'
import {SnapList} from 'components/elements/ListEditor'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'

class MessengerTemplateCards extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleDeleteButtonOnClick = this.handleDeleteButtonOnClick.bind(this)
  }

  handleDeleteButtonOnClick() {
    const handleTableOnDelete = () => { this.props.onDelete() }
    Modal.confirm({
      title: 'Delete Item',
      content: 'Are you sure to delete this item?',
      onOk() {
        handleTableOnDelete()
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  
  get dataSource() {
    return Object.entries(this.props.dataSource || {}).reduce((acc, [id, messengerTemplate])=>({
      ...acc,
      [`${id}`]: {
        ...messengerTemplate,
        key: `${messengerTemplate.type}-${messengerTemplate.service}`
      }
    }), {})
  }

  render() {
    return (
      <SnapList
        data={this.dataSource}
        listType={'MessengerTemplates'}
        search={this.props.search || ''}
        sortBy={'key'}
        initial={this.props.match.params.messengerTemplateID}
        onSelect={this.props.onSelect}
        onSearch={this.props.onSearch}
        row={(props)=>(
          props.service ? (
            <div>
              {_.startCase(props.type)} ({_.upperCase(props.service)})
            </div>
          ):(
            <div></div>
          )
        )}
      />
    )
  }
}

export default withRouter(MessengerTemplateCards)