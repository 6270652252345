import React from "react";
// import { Loader } from "@googlemaps/js-api-loader"


class ClientCounts extends React.Component {

    constructor(props) {
        super(props);
        this.rerender = this.rerender.bind(this)
    }
    componentDidMount(){
       this.rerender()
    }
    componentDidUpdate(previousProps, previousState){
        if(JSON.stringify(Object(previousProps).clientCountsByZip) !== JSON.stringify(Object(this.props).clientCountsByZip)){
            this.rerender()
        }
    }

    buildContent(property) {
        const content = document.createElement("div");
      
        content.classList.add("property");
        content.innerHTML = `
          <div class="icon" style="background-color: rgba(100, 255, 122, .2); width: 20px; height: 20px;">
              <i aria-hidden="true" class="heatmap"></i>
              <span class="fa-sr-only">${property.count}</span>
          </div>`
        //   `<div class="details">
        //       <div class="address">${property.address}</div>
        //       <div class="features">
        //       <div>
        //           <i aria-hidden="true" class="fa fa-bed fa-lg bed" title="bedroom"></i>
        //           <span class="fa-sr-only">bedroom</span>
        //           <span>${property.bed}</span>
        //       </div>
        //       <div>
        //           <i aria-hidden="true" class="fa fa-bath fa-lg bath" title="bathroom"></i>
        //           <span class="fa-sr-only">bathroom</span>
        //           <span>${property.bath}</span>
        //       </div>
        //       <div>
        //           <i aria-hidden="true" class="fa fa-ruler fa-lg size" title="size"></i>
        //           <span class="fa-sr-only">size</span>
        //           <span>${property.size} ft<sup>2</sup></span>
        //       </div>
        //       </div>
        //   </div>
        //   `;
        return content;
    }

    async rerender(){
        const clientCountsByZip = this.props.clientCountsByZip || []
        const heatmap = document.getElementById('heatmap')
        if(!heatmap) return
      

        // https://developers.google.com/maps/documentation/javascript/advanced-markers/html-markers
        // const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        // const loader = new Loader({
        //     apiKey: "AIzaSyAJJeN368u6Uj0g5Hx_wgj1gq5FzeRdVAc",
        //     version: "weekly",
        //   });
          
        //   loader.load().then(async () => {
        //     const { Map } = await google.maps.importLibrary("maps");
        //     const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        //     var map = new Map(heatmap, {
        //         center: { lat: 40.244468, lng: -98.724476 },
        //         zoom: 4,
        //         mapId: "4504f8b37365c3d0",
        //       });

        //     for(let row of clientCountsByZip){
        //         console.log(row)

        //         if(row.lat && row.lng){
        //             const priceTag = document.createElement("div");
        //             priceTag.style.backgroundColor = 'rgba(255, 60, 0, .5)'
        //             priceTag.style.borderRadius = '50%'
        //             priceTag.style.width = '30px'
        //             priceTag.style.height = '30px'
        //             // priceTag.innerHTML = `${row.state} ${row.zip}`
        //             const advancedMarkerElement = new AdvancedMarkerElement({
        //                 map,
        //                 content: priceTag,
        //                 position: {lat: row.lat, lng: row.lng}
        //             });
        //         }
        //     }
        // });


    }
    render(){

        return (
            <div id="heatmap" style={{height: '600px', width: '100%'}}></div>
        )
    }

}

export default ClientCounts;