import React, {Component, Fragment} from 'react'
import { Button } from 'antd';
import JSONTree from 'react-json-tree'
import Scroller from 'components/elements/Scroller'
import { Form, Card } from 'antd'

export default class EntityData extends Component {
    constructor(){
        super()
        this.state = {}
        this.toggleDetails = this.toggleDetails.bind(this)
    }
    toggleDetails(e){
        e.preventDefault()
        this.setState({
            showDetails: !this.state.showDetails
        })
    }
    render(){
        return <div>
                {this.state.showDetails ? (
                    <Fragment>
                        <JSONTree hideRoot={true} data={this.props.item} />
                        <a onClick={this.toggleDetails}>Hide Details</a>
                    </Fragment>
                ):(
                    <a onClick={this.toggleDetails}>Details ...</a>
                )}
            </div>
    }
}