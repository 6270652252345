import React from 'react'
import { Form, DatePicker, Input, Button, Select, Col, Row, Card } from 'antd'

import GeneralEditor from 'components/elements/GeneralEditor'
import MaskedInput from 'components/elements/MaskedInput'
import Scroller from 'components/elements/Scroller'
import {createOrganization} from 'actions/organizations'
import {connect} from 'react-redux'
import * as moment from 'moment-timezone'
import { withRouter } from 'react-router-dom'
import {organizationActions} from 'actions'

const FormItem = Form.Item

class OrganizationSetupForm extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.formRef = React.createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  async handleSubmit(values) {
    await organizationActions.createOrganization(values)
    setTimeout(() => {
      this.props.history.push('/')
    }, 1000)
  }
  handleCancel(values) {
    sessionStorage.clear()
    localStorage.clear()
    try{
      sessionActions.logout()
      this.props.history.push('/login')
    }catch(error){
      console.log('error')
    }
  }
  
  get form() {
    return this.formRef.current
  }

  render() {

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
    };

    return (
      <Form ref={this.formRef} onFinish={this.handleSubmit}>
        <GeneralEditor onNext={this.handleSubmit} onCancel={this.handleCancel}>
          <Scroller>
              <FormItem
                  {...formItemLayout}
                  label="Name"
                  name="name"
                  required={true}
                  rules={[{
                      type: 'string', message: 'The input is not valid Title!',
                    }, {
                        required: false, message: 'Please input your Title!',
                    }]}
                >
                
                      
                  <Input placeholder={'Practice Vet'} />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label="Description"
                  required={true}
                  name="description"
                  rules={[{
                    type: 'string', message: 'The input is not valid Description!',
                  }, {
                    required: true, message: 'Please input is not valid Description!',
                  }]}
                >
                  <Input placeholder={'The best veterinary service ever, totes magotes.'} />
                </FormItem>
                <FormItem 
                  {...formItemLayout}
                  label="Country"
                  name="country"
                  required={true}
                  rules={[{
                    type: 'string', message: 'Please select a country',
                  }, {
                    required: true, message: 'Please select a country',
                  }]}
                >
                  <Select
                    showSearch
                    // style={{ display: "inline", marginTop: "10px" }}
                    placeholder="Select Country"
                    filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                  >
                      <Select.Option key='US' value='US'>United States of America</Select.Option>
                      <Select.Option key='CA' value='CA'>Canada</Select.Option>
                  </Select>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label="Timezone"
                  name="timezone"
                  required={true}
                  rules={[{
                    type: 'string', message: 'Please select a timezone',
                  }, {
                    required: true, message: 'Please select a timezone',
                  }]}
                >
                  <Select
                    showSearch
                    // style={{ display: "inline", marginTop: "10px" }}
                    placeholder="Select Timezone"
                    filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {moment.tz.names().map((timezone, i)=>
                      <Select.Option key={timezone}>
                        {timezone}
                      </Select.Option>
                    )}
                  </Select>
                </FormItem>
            </Scroller>
          </GeneralEditor>
      </Form>
    )
  }
}

export default withRouter(
  OrganizationSetupForm
)
