import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Ready from './ReadyRoute'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isLoggedIn = !!rest.isLoggedIn
        return (
          isLoggedIn
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login' }} />
        )
      }}
    />
  )
}

const mapStateToProps = (state) => {
  const isLoggedIn = !!state.session.accessToken
  return { isLoggedIn }
}

export default connect(mapStateToProps)(PrivateRoute)

export const ReadyRoute = connect(mapStateToProps)(
  (props)=>(
    <PrivateRoute {...props} component={()=>(
      <Ready {...props} />
    )}/>
  )
)
