import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Card, Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, Tabs, DatePicker, Table, Switch } from 'antd';
import Scroller from 'components/elements/Scroller'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import {workersActions, organizationActions} from 'actions'
import { NotesWidget } from 'components/partials';
import { List, Typography, Divider } from 'antd';
import Companies from './Companies';
import Groups from './Groups';
const TabPane = Tabs.TabPane

class Organization extends Component {
    constructor(props){
        super(props)
        this.state = {
            workers: {
                evet: {}
            },
            filters: {}
        }
        this.handleChangeOrganization = this.handleChangeOrganization.bind(this)
        this.handleActivity = this.handleActivity.bind(this)
        this.handleNameFilter = this.handleNameFilter.bind(this)
        this.handleIntegrationFilter = this.handleIntegrationFilter.bind(this)
    }
    
    componentDidMount(){
        organizationActions.fetch()
    }
    handleChangeOrganization(organization){
        organizationActions.changeCurrent(organization.id)
        this.props.history.push('/admin')
    }
    handleActivity(organization){
        this.handleChangeOrganization(organization)
        this.props.history.push('/admin/integrations')
    }
    handleActiveOrganization(organization, checked){
        organizationActions.update({
            organizationID: organization.id,
            active: checked
        })
    }
    get data(){
        return Object.values(Object(this.props.organizations)).filter((organization)=>{
            let val = true;
            if(this.state.filters.name){
                val = `${organization.name}`.toLowerCase().includes(String(this.state.filters.name).toLowerCase())
            } 
            if(this.state.filters.integration){
                const integrations = Object(Object(organization.integrations)[this.state.filters.integration])
                val = val && (integrations.active || integrations.fetchActive || integrations.transcribeActive)
            }
            return val
        })
    }
    handleNameFilter(e){
        this.setState({
            filters: {
                ...this.state.filters,
                name: e.target.value
            }
        })
    }
    handleIntegrationFilter(value){
        this.setState({
            filters: {
                ...this.state.filters,
                integration: value
            }
        })
    }
    render(){
        return (
            <AdminLayout fullHeight={true}>
                <SectionContent noPadding noMargin noHeader>

                    <Tabs defaultActiveKey="organizations" onChange={this.handleTabChange}>
                        <TabPane tab={<span>Organizations</span>} key="organizations">

                            <Row>
                                <Col xs={12} style={{padding: 10}} >
                                    <Input onChange={this.handleNameFilter} />
                                </Col>
                                <Col xs={12} style={{padding: 10}} >
                                    <Select defaultValue='all'  onChange={this.handleIntegrationFilter} style={{ width: 120 }}>
                                        <Option value={null}>All</Option>
                                        <Option value="covetrus">Covetrus</Option>
                                        <Option value="datapoint">Datapoint</Option>
                                        <Option value="vetter">Vetter</Option>
                                        <Option value="evet">eVet</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Scroller>
                                    <List
                                        size="large"
                                        bordered
                                        dataSource={this.data}
                                        renderItem={(organization)=> (
                                            <List.Item>
                                                <Row style={{width: '100%'}}>
                                                    <Col xs={8}>
                                                        <Link onClick={this.handleChangeOrganization.bind(null, organization)}>{organization.name || 'No Name Provided'} </Link>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Switch 
                                                            checked={organization.active}
                                                            defaultChecked
                                                            onChange={this.handleActiveOrganization.bind(this,organization)}
                                                        />
                                                    </Col>
                                                    <Col xs={8}>
                                                        {Object.entries(Object(organization.integrations)).map(([integrationName, integration])=>(
                                                            <Row>
                                                                <Col xs={8}>   
                                                                    <label>{integrationName}</label>
                                                                </Col>
                                                                <Col xs={8}>   
                                                                    <Link onClick={this.handleActivity.bind(null, organization)}>
                                                                        {integration.active ? 'active' : 'inactive'}
                                                                    </Link>
                                                                </Col>
                                                                <Col xs={8}>   
                                                                    {integration.pim ? 'IS PIM' : 'NOT PIM'}
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </Col>
                                                    <Col xs={4}>   
                                                        Minnow:
                                                        &nbsp;<a href={`https://minnow.nextinline.io?organizationID=${organization.id}`}>Production</a> |
                                                        &nbsp;<a href={`https://minnow-staging.nextinline.io?organizationID=${organization.id}`}>Staging</a>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                            </Scroller>


                        </TabPane>
                        <TabPane tab={<span>Notes</span>} key="notes">
                            <NotesWidget />
                        </TabPane>
                        <TabPane tab={<span>Groups</span>} key="groups">
                            <Groups />
                        </TabPane>
                        <TabPane tab={<span>Companies</span>} key="companies">
                            <Companies />
                        </TabPane>
                    </Tabs>
                </SectionContent>
            </AdminLayout>
        )
    }
}

export default withRouter(
    connect((state)=>(
        state
    ))(Organization)
)