import React, {Component} from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import OpenTimes from './OpenTimes'

export default class Organization extends Component {
    componentDidMount(){
        
    }
    render(){
        return <Route exact path='/admin/organization' component={OpenTimes} />
    }
}