import {
    FETCH_PARTITIONS,
    CREATE_PARTITION,
    UPDATE_PARTITION,
    DESTROY_PARTITION,
    SET_ORGANIZATION_CURRENT
  } from '../constants/actionTypes'
  
  export default (state = {}, action) => {
    switch (action.type) {
      case FETCH_PARTITIONS:
        return {
          ...state,
          ...action.payload
        }
      case CREATE_PARTITION:
        return {
          ...state,
          [`${action.payload.id}`]: action.payload
        }
      case DESTROY_PARTITION:
          state = {
            ...state
          }
          delete state[action.payload]
          return state
      case UPDATE_PARTITION:
        return {
          ...state,
          [`${action.payload.id}`]: action.payload
        }
      // case UPDATE_PARTITIONS:
      //   let payload = action.payload.reduce((acc, item)=>{
      //       acc[item.repeatingID || item.id] = item
      //       return acc;
      //   }, {})
  
      //   return {
      //       ...state,
      //       ...payload
      //   }
      case SET_ORGANIZATION_CURRENT:
          return {}
      default:
        return state
    }
  }
  