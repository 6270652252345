import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Input, Select, Button } from 'antd';
import CreateOrEditSpecies from 'components/partials/CreateOrEditSpecies'
import {
    breedActions
} from 'actions'

class BreedForm extends Component {
    constructor(){
        super();
        this.state = {
          officeHours:[],
          visible: false,
          createBreedVisible: false,
          breedSearch: '',
          createOrEditSpeciesVisible: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDestroyBreed = this.handleDestroyBreed.bind(this)
        this.breedSearchTimeout = ''
        this.handleCancel = this.handleCancel.bind(this)
        this.handleBreedSearch = this.handleBreedSearch.bind(this)
        this.handleSpeciesChange = this.handleSpeciesChange.bind(this)
        this.handleNewBreed = this.handleNewBreed.bind(this)
        this.handleEditSpecies = this.handleEditSpecies.bind(this)
        this.handleSpeciesClose = this.handleSpeciesClose.bind(this)
        this.setClassification = this.setClassification.bind(this)
    }
    async handleSubmit(values){
        if(this.breed.id){
            await breedActions.update({
                id: this.breed.id,
                ...values
            })
            this.props.onClose()
        }else{
            const breed = await breedActions.create({
                ...values
            })
            this.props.onSetCurrentBreed(breed.id)
            this.props.onClose()
        }
    }
    handleBreedSearch(value){
        if(this.breedSearchTimeout){
            clearTimeout(this.breedSearchTimeout)
        }
        this.breedSearchTimeout = setTimeout(()=>{this.breedSearch(value)},600)
    }
    async handleDestroyBreed(e){
        e.preventDefault()
        
        await breedActions.destroy(this.breed.id)
        this.props.onSetCurrentBreed('')
        this.props.onClose()
    }
    get breed(){
        return {
            ...(this.props.breed || {})
        }
    }
    get form() {
        return Object(this.props.formRef).current
    }
    get classifications() {
        return Object.values(this.props.classifications || {})
    }
    handleNewBreed(value){
        if(value === 'new'){
            this.setState({
                createBreedVisible: true
            })
            setTimeout(()=>{
                // Huge Hack
                // Huge Hack
                // Huge Hack
                // Huge Hack
                this.form && this.form.setFieldsValue({breedID: ''});
            }, 1)
        }
    }
    handleSpeciesClose(){
        this.setState({
          createOrEditSpeciesVisible: false
        })
    }
    handleEditSpecies(value){
        this.setState({
          createOrEditSpeciesVisible: true
        })
    }
    handleSpeciesChange(value){
        if(value === 'new'){
            this.setState({
                createOrEditSpeciesVisible: true
            })
        setTimeout(()=>{ 
            // Huge Hack
            // Huge Hack
            // Huge Hack
            // Huge Hack
            this.form && this.form.setFieldsValue({classificationID: ''});
            }, 1)
        }
    }
    get classification(){
        const id = this.form && this.form.getFieldValue('classificationID')
        return this.props.classifications[id]
    }
    setClassification(classificationID){
        setTimeout(()=>{ 
          // Huge Hack
          // Huge Hack
          // Huge Hack
          // Huge Hack
          this.form && this.form.setFieldsValue({classificationID});
        }, 1)
    }
    handleCancel(){
        if(this.current.id){
            this.props.history.push(`/admin/breeds/${this.current.id}`)
        } else {
            this.props.history.push(`/admin/breeds`)
        }
    }
    render(){
        let editSpecies = false;
        if(this.form && this.form.getFieldValue('classificationID') && this.form.getFieldValue('classificationID') !== "new"){
            editSpecies = true
        }
        return (
            <div> 
                <CreateOrEditSpecies 
                    visible={this.state.createOrEditSpeciesVisible}
                    setClassification={this.setClassification}
                    onClose={this.handleSpeciesClose}
                    classification={this.classification}
                />
                <Form.Item label='Species'>
                    <Input.Group style={{ width: '100%' }} compact >
                        <Form.Item 
                            noStyle
                            label="ClassificationID"
                            name="classificationID"
                            rules={[{ required: true, message: 'Please choose a species!' }]}
                        >
                            <Select
                                style={{width: editSpecies ? 'calc(100% - 57px)' : '100%'}}
                                onChange={this.handleSpeciesChange}
                                onSearch={this.handleBreedSearch}
                                showSearch
                                filterOption={false}
                                showArrow={false}
                            >
                                {this.classifications.map((classification, i)=>{
                                    return (
                                        <Option key={`classification-user-${i}`} value={classification.id}>
                                            {classification.name}
                                        </Option>
                                    )
                                })}
                                <Option key={`speciesSearchPrompt`} value={`speciesSearchPrompt`}>
                                    Search to see more species...
                                </Option>
                                <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                                <Option value=''>None</Option>
                                <Option key='new'>New Species</Option>
                            </Select>
                        </Form.Item>
                            {editSpecies && (
                                <Button onClick={this.handleEditSpecies}>Edit</Button>
                            )}
                    </Input.Group>
                </Form.Item>
                <Form.Item 
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input Breed name!' }]}
                >
                    <Input placeholder="Name of Breed" />
                </Form.Item>
                <Form.Item 
                    label="Description"
                    name="description"
                >
                    <Input.TextArea placeholder="Description" />
                </Form.Item>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        classifications: state.classifications
    }
}

export default withRouter(
    (
        connect(mapStateToProps)(BreedForm)
    )
)
