import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Home from '../../../components/templates/Home'
import Login from '../../../components/templates/sessions/Login'
import Signup from '../../../components/templates/sessions/Signup'
import NotFound from '../../../components/templates/NotFound'
import Dashboard from '../../../components/templates/Dashboard'
import Appointments from '../../../components/templates/Appointments'
import Accounts from '../../../components/templates/Accounts'
import Administrators from '../../../components/templates/Administrators'
import General from '../../../components/templates/General'
import ManualWorkerExecutions from '../../../components/templates/ManualWorkerExecutions'
import Patients from '../../../components/templates/Patients'
import Widgets from '../../../components/templates/Widgets'
import Integrations from '../../../components/templates/Integrations'
import Setup from '../../../components/templates/Setup'
import Organization from '../../../components/templates/Organization';
import Operations from '../../../components/templates/Operations';
import Global from '../../../components/templates/Global';
import AppointmentTypes from '../../../components/templates/AppointmentTypes';
import AppointmentStatuses from '../../../components/templates/AppointmentStatuses';
import Restrictions from '../../../components/templates/Restrictions';
import Booking from '../../../components/templates/Booking';
import Messages from '../../../components/templates/Messages';
import MessengerTemplates from '../../../components/templates/MessengerTemplates';
import WaitingRoom from '../../../components/templates/WaitingRoom';
import WaitListMembers from '../../../components/templates/WaitListMembers';
import WaitListMessages from '../../../components/templates/WaitListMessages';
import PrivateRoute from '../../../components/elements/PrivateRoute';
import ReadyRoute from '../../../components/elements/ReadyRoute';
import Sexes from '../../../components/templates/Sexes';
import Breeds from '../../../components/templates/Breeds';
import Species from '../../../components/templates/Species';

import './index.less'

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <ReadyRoute exact path='/' component={Dashboard} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/signup' component={Signup} />

          <PrivateRoute exact path='/setup' component={Setup} />
          <ReadyRoute exact path='/admin' component={()=> <Redirect to='/admin/dashboard' /> } />
          
          <ReadyRoute exact path='/admin/dashboard' component={Dashboard} />
          <ReadyRoute exact path='/admin/schedules' component={Dashboard} />
          
          <ReadyRoute exact path='/admin/appointments/:type' component={Appointments} />

          <ReadyRoute exact path='/admin/general' component={General} />
          <ReadyRoute exact path='/admin/accounts' component={Accounts} />
          <ReadyRoute exact path='/admin/accounts/:id' component={Accounts} />
          <ReadyRoute exact path='/admin/accounts/:id/edit' component={Accounts} />
          <ReadyRoute exact path='/admin/accounts/create' component={Accounts} />

          <ReadyRoute exact path='/admin/patients' component={Patients} />
          <ReadyRoute exact path='/admin/patients/:id' component={Patients} />
          <ReadyRoute exact path='/admin/patients/:id/edit' component={Patients} />
          <ReadyRoute exact path='/admin/patients/new' component={Patients} />

          <ReadyRoute exact path='/admin/messages' component={Messages} />
          <ReadyRoute exact path='/admin/messages/:id' component={Messages} />
          <ReadyRoute exact path='/admin/messages/:id/new' component={Messages} />

          <ReadyRoute exact path='/admin/inventory' component={Accounts} />
          <ReadyRoute exact path='/admin/billing' component={Accounts} />
          
          <ReadyRoute exact path='/admin/administrators' component={Administrators} />
          <ReadyRoute exact path='/admin/administrators/:id' component={Administrators} />
          <ReadyRoute exact path='/admin/administrators/:id/edit' component={Administrators} />
          <ReadyRoute exact path='/admin/administrators/:id/edit-shifts' component={Administrators} />
          
          <ReadyRoute exact path='/admin/messenger-templates' component={MessengerTemplates} />
          <ReadyRoute exact path='/admin/messenger-templates/:messengerTemplateID' component={MessengerTemplates} />
          <ReadyRoute exact path='/admin/messenger-templates/:messengerTemplateID/edit' component={MessengerTemplates} />
          <ReadyRoute exact path='/admin/messenger-templates/:messengerTemplateID/new' component={MessengerTemplates} />
          
          <ReadyRoute exact path='/admin/appointment-types' component={AppointmentTypes} />
          <ReadyRoute exact path='/admin/appointment-types/:id' component={AppointmentTypes} />
          <ReadyRoute exact path='/admin/appointment-types/:id/edit' component={AppointmentTypes} />
          <ReadyRoute exact path='/admin/appointment-types/:id/edit-service-times' component={AppointmentTypes} />
          
          <ReadyRoute exact path='/admin/appointment-statuses' component={AppointmentStatuses} />
          <ReadyRoute exact path='/admin/appointment-statuses/:id' component={AppointmentStatuses} />
          <ReadyRoute exact path='/admin/appointment-statuses/:id/edit' component={AppointmentStatuses} />

          <ReadyRoute exact path='/admin/sexes' component={Sexes} />
          <ReadyRoute exact path='/admin/sexes/:id' component={Sexes} />
          <ReadyRoute exact path='/admin/sexes/:id/edit' component={Sexes} />

          <ReadyRoute exact path='/admin/breeds' component={Breeds} />
          <ReadyRoute exact path='/admin/breeds/:id' component={Breeds} />
          <ReadyRoute exact path='/admin/breeds/:id/edit' component={Breeds} />

          <ReadyRoute exact path='/admin/species' component={Species} />
          <ReadyRoute exact path='/admin/species/:id' component={Species} />
          <ReadyRoute exact path='/admin/species/:id/edit' component={Species} />

          <ReadyRoute exact path='/admin/restrictions' component={Restrictions} />
          
          <ReadyRoute exact path='/admin/organization' component={Organization} />

          <ReadyRoute exact path='/admin/organization-details' component={Dashboard} />
          <ReadyRoute exact path='/admin/widgets' component={Widgets} />
          
          <ReadyRoute exact path='/admin/integrations' component={Integrations} />
          <ReadyRoute exact path='/admin/manual-worker-executions' component={ManualWorkerExecutions} />
          <ReadyRoute exact path='/admin/integrations/connect/google/callback' component={Integrations} />
          
          <ReadyRoute exact path='/admin/booking' component={Booking} />
          <ReadyRoute exact path='/admin/operations' component={Operations} />
          <ReadyRoute exact path='/admin/global' component={Global} />

          <ReadyRoute exact path='/admin/waitingRoom' component={WaitingRoom} />

          <ReadyRoute exact path='/admin/waitListMessages' component={WaitListMessages} />
          <ReadyRoute exact path='/admin/waitListMembers' component={WaitListMembers} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    )
  }
}