import {
  FETCH_COMPANIES,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  UPDATE_COMPANIES,
  DESTROY_COMPANY,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return {
        ...state,
        ...action.payload,
      }
    case CREATE_COMPANY:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case DESTROY_COMPANY:
      state = {
        ...state
      }
      delete state[action.payload]
      return state
    case UPDATE_COMPANY:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    // case UPDATE_COMPANIES:
    //   let payload = action.payload.reduce((acc, item)=>{
    //     acc[item.repeatingID || item.id] = item
    //     return acc;
    //   }, {})

    //   return {
    //     ...state,
    //     ...payload
    //   }
    default:
      return state
  }
}
