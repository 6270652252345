import Axios from 'axios';
import store from 'store'

class Collection {
    constructor(options){
        this.options = options
        this.axios = Axios.create(options);
    }
    get props(){
        return store.getState()
    }
}

export default Collection;
