import React from 'react';
import { Card, Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import moment from 'moment'
import {appointmentActions, patientActions} from 'actions'
import {connect} from 'react-redux'
import {CreateOrEditAppointment} from 'components/partials'
import {apiClient} from 'lib'
import admin from 'reducers/admin';
import './index.less'

const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;
const InputGroup = Input.Group;
const ButtonGroup = Button.Group;

class SelectAppointment extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createAppointmentTypeVisible: false,
      providerSearch: ''
    };

    this.handleAppointmentEditClose = this.handleAppointmentEditClose.bind(this)
    this.handleAppointmentCreate = this.handleAppointmentCreate.bind(this)
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  async handleSubmit (values) {
        if(this.appointment.id){
          appointmentActions.update({
            id: this.appointment.id,
            patientID: values.patientID || null,
            administratorID: values.administratorID || null,
            appointmentTypeID: values.appointmentTypeID || null,
            reason: values.reason,
            startsAt: values.startTime,
            endsAt: values.endTime
          })
        }else{
          appointmentActions.create({
            appointmentTypeID: values.appointmentTypeID || null,
            patientID: values.patientID || null,
            administratorID: values.administratorID || null,
            reason: values.reason,
            startsAt: values.startTime,
            endsAt: values.endTime
          })
        }
        this.props.onClose()
  }

  componentDidUpdate(previousProps){
    if(previousProps.visible != this.props.visible){
      this.form && this.form.resetFields();
    }
  }

  handleAppointmentTypeSearch(value){
    this.setState({
      appointmentTypes: appointmentTypes.filter((_)=>(
        _.title.toLocaleLowerCase().includes(value.toLowerCase())
      ))
    });
  }

  componentDidMount(){
    if(this.props.patientID){
      patientActions.findOrFetch(this.appointment.patientID)
    }
  }

  handleAppointmentEdit(appointment){
    this.setState({
      appointment
    }, ()=>{
      this.setState({
        createOrEditAppointmentVisible: true
      })
    })
  }

  handleAppointmentCreate(){
    this.setState({
      appointment: {
        start: this.props.selectedAppointments.startsAt,
        end: this.props.selectedAppointments.endsAt
      }
    }, ()=>{
      this.setState({
        createOrEditAppointmentVisible: true
      })
    })
  }
  
  handleAppointmentEditClose(){
    this.setState({
      appointment: {}
    }, ()=>{
      this.setState({
        createOrEditAppointmentVisible: false
      })
    })
  }

  get form() {
    return this.formRef.current
  }
  
  get appointment(){
    const _event = this.state.appointment || {}

    return {
      id: _event.id,
      title: _event.reason,
      appointmentTypeID: _event.appointmentTypeID,
      administratorID: _event.administratorID,
      patientID: _event.patientID,
      start: moment(_event.start),
      end: moment(_event.end),
      createdAt: _event.createdAt,
      confirmedAt: _event.confirmedAt,
      canceledAt: _event.canceledAt
    }
  }

  render() {
    let initialValues = this.appointment
    let edit = false;
    let editAppointmentType = false;
    let editPatient = false;
    let editProvider = false;
    
    if(this.appointment.id){
      edit = true
    }
    if(this.form && this.form.getFieldValue('appointmentTypeID')){
      editAppointmentType = true
    }

    if(this.form && this.form.getFieldValue('patientID')){
      editPatient = true
    }

    if(this.form && this.form.getFieldValue('administratorID')){
      editProvider = true
    }

    const title = (<div
          style={{
            position: 'absolute',
            top: 0,
            zIndex:1,
            width: '100%',
            borderBottom: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'left',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}
          >Appointments on <b>
                {moment(_.get(this, 'props.selectedAppointments.startsAt')).format('M/DD/YYYY')}
          </b> from <b>
            {moment(_.get(this, 'props.selectedAppointments.startsAt')).format('h:mma')}
          </b> to <b>
            {moment(_.get(this, 'props.selectedAppointments.endsAt')).format('h:mma')}
          </b>
    </div>)
    return (
      <Drawer
        title={title}
        width={720}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <CreateOrEditAppointment 
          visible={this.state.createOrEditAppointmentVisible} 
          onClose={this.handleAppointmentEditClose}
          onSetCurrentAppointment={this.handleSetCurrentAppointment}
          appointment={this.appointment}
        />
        <Form 
          
          layout="vertical" 
          ref={this.formRef} 
          initialValues={initialValues} 
          onFinish={this.handleSubmit}
        >
          <Row>
            <Col span={24}>
              {_.get(this, 'props.selectedAppointments.events', []).map((appointment, i)=>(
                <Card key={`${i}`} style={{marginBottom: 14}} onClick={this.handleAppointmentEdit.bind(this, appointment)} className='clickable-card'>
                  {appointment.reason}
                  <br />
                  {_.get(this, `props.patients[${appointment.patientID}].firstName`)}
                  {_.get(this, `props.patients[${appointment.patientID}].lastName`)}
                  <br />
                </Card>
              ))}
              <br />
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Done
            </Button>
            <Button type="primary" onClick={this.handleAppointmentCreate}>Create New</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  (SelectAppointment)
)