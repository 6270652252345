import React from 'react'
import { Modal, Input, Card, Button } from 'antd'

import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import Scroller from 'components/elements/Scroller'
import {CreateOrEditPatient} from 'components/partials'
import {patientActions} from 'actions'
import _ from "lodash"

class Show extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      createPatientVisible: false
    }
    this.handlePatientCreateShow = this.handlePatientCreateShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.editPatient = this.editPatient.bind(this)

    if(props.account.id){
      patientActions.fetch({
        accountID: props.account.id
      })
    }
  }
 
  componentDidMount(){
    patientActions.fetch({
      accountID: this.account.id
    })
  } 

  get account(){
    return this.props.account || {}
  }

  handlePatientCreateShow(){
    this.setState({
      createPatientVisible: true,
      patientID: null
    })
  }
  handleClose(patient){
    // if(patient && patient.primaryAccountID){
    //   this.props.history.push(`/admin/accounts/${patient.primaryAccountID}`)
    // }
    this.setState({
      createPatientVisible: false
    })
  }

  get patients(){
    const patientIDs = this.props.accountsPatients[this.props.account.id] || []
    return patientIDs.map((id)=>(
      this.props.patients[id]
    ))
  }

  get genders(){
    return Object.entries(
      this.props.genders || {}
    ).map(([_, gender])=> gender)
  }

  get breeds(){
    return Object.entries(
      this.props.breeds || {}
    ).map(([_, breed])=> breed)
  }

  get classifications(){
    return Object.entries(
      this.props.patients || {}
    ).map(([_, classification])=> classification)
  }

  classification(patient){
    return this.props.classifications[
      Object(this.breed(patient)).classificationID
    ]
  }

  breed(patient){
    return Object(this.props.breeds[patient.breedID]).classificationID 
  }

  get patient(){
    return this.props.patients[this.state.patientID]
  }
  editPatient(patient, e){
    e.preventDefault()

    this.setState({
      createPatientVisible: true,
      patientID: patient.id
    })
  }
  render() {
    return (
      <Scroller>
        <Card bordered={false}>
          <div className='edit-switch'>
            <Link to={`/admin/accounts/${this.account.id}/edit`}>Edit</Link>
            
            <h2 className='text-center'>
              {this.account.title} {this.account.firstName} {this.account.middleInitial} {this.account.lastName}
            </h2>
            {this.account.email &&
              <h4 className='text-center'>
                {this.account.email}
              </h4>
            }
            <h4 className='text-center'>
              {this.account.mobilePhone && `mobile:${this.account.mobilePhone}`} &nbsp;
              {this.account.homePhone && `home:${this.account.homePhone}`} &nbsp;
              {this.account.workPhone && `work:${this.account.workPhone}`}
            </h4>
            <br />
            
            {this.account.birthday && 
              <div>
                <label>Date of Birth:</label> {moment(this.account.birthday).format('M/DD/YY')}
                <br />
                <br />
              </div>
            }
            {this.account.birthday && 
              <div>
                <label>Date of Birth:</label> {moment(this.account.birthday).format('M/DD/YY')}
                <br />
                <br />
              </div>
            }
            <label>Address:</label> {this.account.address}
            <br />
            <label>Unit:</label> {this.account.unit}
            <br />
            <label>City:</label> {this.account.city}
            <br />
            <label>County:</label> {this.account.county}
            <br />
            <label>State:</label> {this.account.state}
            <br />
            <label>Country:</label> {this.account.country}
            <br />
            <label>Zip:</label> {this.account.zip}
            <br />
            <br />
            <label>id:</label> {this.account.id} 
            <br />
            <br />
            <label>Patients:</label>
            <br />
            {this.patients.map((patient, i)=>{
                const integration = Object.keys(Object(patient.integrationIDs))[0]
                let integrationID 
                if(integration) integrationID = patient.integrationIDs[integration]
                return (
                    <div key={`patients-${i}`}>
                        <a href='' onClick={this.editPatient.bind(null, patient)}>
                        {patient.firstName || 'none'} {patient.lastName}
                        </a>
                        {this.props.genders[patient.genderID] && (
                        <React.Fragment>
                            {(this.classification(patient) || {}).name} {(this.breed(patient) || {}).name} ({(this.props.genders[patient.genderID] || {}).name}) 
                        </React.Fragment>
                        )}
                        <br />
                        {patient.integrationIDs && (
                        <React.Fragment>
                            {_.capitalize(integration)} {integrationID}
                        </React.Fragment>
                        )}
                        <br />
                        <Link to={`/admin/patients/${patient.id}`}>
                        details
                        </Link>
                        <br />
                        {moment(patient.birthday).format('MM/DD/YY')}
                    </div>
                )
            })} 
            {this.patients.length == 0 && (
              '(None)'
            )}

            <br />
            <br />
            <br />

            <label>PIMS Client Details:</label>
            <br />
            {_.get(this, 'account.users', []).map((user, i)=>(
                <div key={i}>
                    <label>Integration:</label> {Object.keys(Object(user.integrationIDs))[0]}
                    <br />
                    <label>ID:</label> {Object.values(Object(user.integrationIDs))[0]}
                    <br />
                    <label>First Name:</label> {user.proposedFirstName}
                    <br />
                    <label>Last Name:</label> {user.proposedLastName}
                    <br />
                    <label>Email:</label> {user.proposedEmail}
                    <br />
                    <label>Mobile Phone:</label> {user.proposedMobilePhone}
                    <br />
                    <label>Home Phone:</label> {user.proposedHomePhone}
                </div>
            ))}

            <br />
            <br />
            
            <CreateOrEditPatient
              visible={this.state.createPatientVisible}
              onClose={this.handleClose}
              account={this.account}
              patient={this.patient}
            />
            
            <Button onClick={this.handlePatientCreateShow}>
              Create Patient
            </Button>
          </div>
        </Card>
      </Scroller>
    )
  }
}

const mapStateToProps = (state) => {

  const {patientCurrent} = state
  
  return {
    accounts: state.accounts,
    patientCurrent,
    patients: state.patients,
    accountsPatients: state.accountsPatients,
    breeds: state.breeds,
    genders: state.genders,
    classifications: state.classifications
  }
}

const mapDispatchToProps = dispatch => ({
  fetchItems: params => dispatch(fetchItems(params)),
  changeTable: params => dispatch(changeTable(params)),
  editSearch: search => dispatch(editSearch(search)),
  searchTable: search => dispatch(searchTable(search)),
  setCurrent: search => dispatch(setCurrent(search)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Show))

// <Table
//   rowKey={record => `item-row-${record.id}`}
//   columns={columns}
//   {...this.props}
// />