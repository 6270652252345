import React from 'react'
import { connect } from 'react-redux'
import { apiClient } from 'lib';
import {patientActions} from 'actions'

import { Row, Col, Input, message } from 'antd'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import PatientCards from './PatientCards'

import Item from './Item'

import {withRouter,Route} from 'react-router-dom'

const createPath = '/admin/patients/create'
const editPath = '/admin/patients/edit'

class PatientList extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      createVisible: false,
      patients: ''
    };

    this.searchTimeout = ''

    this.handleCreateButtonOnClick = this.handleCreateButtonOnClick.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
  }

  componentDidMount() {
    if(this.props.location.search){
      let params = this.props.location.search
      params = params.replace(/\?/, '')
      params = params.split("&")
      let _params = {}
      for(let param of params){
        let splitParam = param.split("=")
        _params[splitParam[0]] = splitParam[1]
      }
      this.setState({
        search: _params.search
      })

      patientActions.fetch({search: _params.search, limit:20})
    } else {
      patientActions.fetch({limit:20})
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.isFetchItemsLoading && this.props.fetchItemsError) {
      message.error(this.props.fetchItemsError)
    }
  }

  handleCreateButtonOnClick(e) {
    e.preventDefault()
    this.props.history.push(createPath)
  }
  
  handleSelect(current = {}) {
    if(current.id && current.id != "searchHolder"){
      this.props.history.push(`/admin/patients/${current.id}${this.state.search?`?search=${this.state.search}`:''}`)
    }
  }

  handleCreate() {
    this.props.history.push(`/admin/patients/new`)
  }

  handleSearch(e) {
    let data = e.target.value

    if(this.searchTimeout){
      clearTimeout(this.searchTimeout)
    }

    this.searchTimeout = setTimeout(async()=>{
      if(!data){
        patientActions.fetch({limit: 20})
      }else {
        patientActions.fetch({search: data, limit:5})
      }
      this.setState({
        search: data
      })
    },400)
  }

  get patients(){
    let patients = {}
    if(!this.state.search){
      patients = {...this.props.patients}
    } else {
      for(let patient of Object.values(this.props.patients)){
        if(patient.firstName.toLowerCase().includes(this.state.search)){
          patients[patient.id] = {...patient}
        } else {
          let account = {
            firstName: patient.accountFirstName || '',
            lastName: patient.accountLastName || '',
            email: patient.accountEmail || '',
            fullName: `${patient.accountFirstName} ${patient.accountLastName}`
          }
          if((account.firstName || '').toLowerCase().includes(this.state.search.toLowerCase()) || 
            (account.lastName || '').toLowerCase().includes(this.state.search.toLowerCase()) || 
            (account.email || '').toLowerCase().includes(this.state.search.toLowerCase()) || 
            (account.fullName || '').toLowerCase().includes(this.state.search.toLowerCase())
          ){
            patients[patient.id] = {...patient}
          }
        }
      }
    }
    return patients || {}
  }
  get current(){
    const current = this.patients[this.props.match.params.id] || {};

    return {
      ...current,
      details: {
        ...(current.details || {})
      }
    }
  }
  render() {
    return (
      <div>
        <AdminLayout>
            <SectionContent
              noPadding={true}
              noMargin={true}
              title='Patients'
            >
              <Row style={{height: "100%"}}>
                <Col span={8}>
                  <PatientCards
                    search={this.state.search || ''}
                    dataSource={this.patients}
                    onSelect={this.handleSelect}
                    onCreate={this.handleCreate}
                    onSearch={this.handleSearch}
                    editPath={editPath}
                  />
                </Col>
                <Col span={16}>
                  <Route path='/admin/patients/:id'>
                    <Item />
                  </Route>
                </Col>
              </Row>
            </SectionContent>
        </AdminLayout>
      </div>
    )
  }
}

export default withRouter(connect((state)=> state)(PatientList))