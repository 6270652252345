import React from 'react';
import { Popconfirm, Form, Col, Row, Button, Input, DatePicker, Select } from 'antd';
import moment from 'moment'
import {accountActions} from 'actions'
import {connect} from 'react-redux'
import {countries} from 'lib'
import {customFieldActions} from 'actions'
import _ from 'lodash'


class CustomFieldForm extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      submitVisible: false,
      type: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitVisibility = this.handleSubmitVisibility.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDestroyCustomForm = this.handleDestroyCustomForm.bind(this)
    this.handleDropdownOptionsAdd = this.handleDropdownOptionsAdd.bind(this)
  }

  componentDidUpdate() {
    if(this.state.submitVisible != true && (!this.props.customField || !this.props.customField.id)) {
      this.setState({
        submitVisible: true
      })
    }
    if(this.props.customField) {
      let previousType = this.form.getFieldsValue()[this.props.customField.id].type
      if(previousType && previousType == "String"){
        let customField = this.props.customField || {}
        let nextType = customField.type
        if(nextType == 'String' && Object.values(customField.dropdownOptions || {}).length > 0){
          nextType = 'Dropdown'
        }
        if(previousType != nextType) {
          this.form.setFieldsValue({
            [this.customField.id || 'new']: {
              type: nextType
            }
          })
          this.setState()
        }
      }
    }
  }

  componentDidMount() {
    customFieldActions.fetch();
  }

  handleSubmit(values) {
    values = values[this.customField.id || 'new']
    const required = values.required;

    let dropdownOptions
    if(values.type == 'Dropdown' && values.dropdownOptions) {
      let _dropdownOptions = values.dropdownOptions.split(',')
      dropdownOptions = _dropdownOptions.map((dropdownOption) => ({ name: _.trim(dropdownOption), value: _.trim(dropdownOption)}))
    } else {
      dropdownOptions = []
    }

    if(values.type == 'Dropdown') {
      values.type = 'String'
    }

    let key = _.camelCase(values.label)

    if(this.customField && this.customField.id){
      customFieldActions.update({...this.customField, ...values, dropdownOptions, required, key})   
    } else {
      customFieldActions.create({...values, dropdownOptions, required, key}) 
    }
    
    this.setState({
      submitVisible: !this.state.submitVisible
    })

    this.props.onFinish && this.props.onFinish(values)
  }

  handleCancel(e) {
    e.preventDefault()
    {this.form && this.form.resetFields ? 
      this.setState({
        submitVisible: false
      }) : this.setState({
        submitVisible: true
      })
    }
    this.form && this.form.resetFields()
    this.props.onCancel && this.props.onCancel(e)
  }

  handleDropdownOptionsAdd(e) {
    e.preventDefault()
    dropdownOptions.create()
  }

  handleDestroyCustomForm(e) {
    e.preventDefault()
    customFieldActions.destroy(this.customField.id)
  }

  get customField(){
    return this.props.customField || {}
  }

  get dropdownOptions() {
    return (this.customField.dropdownOptions || []).map((dropdownOption) => {
      return dropdownOption.name
    }).join(',')
  }

  handleSubmitVisibility(e) {
    this.setState({
      submitVisible: true
    })
  }

  get form() {
    return this.formRef.current
  }

  render() {
    
    let initialValues = {
      [this.customField.id || 'new']: {...(this.customField), dropdownOptions: this.dropdownOptions}
    }
    
    return (
        <Form onChange={this.handleSubmitVisibility} ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                // {...Form.ItemLayout}
                label="Label Name"
                name={[this.customField.id || 'new', "label"]}
              >
                <Input onChange={this.handleSubmitVisibility} placeholder={'Custom Fields'} />
              </Form.Item>
            </Col> 
            <Col span={8}>
              <Form.Item
                // {...Form.ItemLayout}
                label="Type"
                name={[this.customField.id || 'new', "type"]}
              >
                <Select onChange={this.handleSubmitVisibility} id={`dataType--${this.customField.id || 'new'}`}>
                  <Select.Option value="">Select a data type</Select.Option>
                  <Select.Option value="String">Text</Select.Option>
                  <Select.Option value="Float">Decimal</Select.Option>
                  <Select.Option value="Boolean">True / False</Select.Option>
                  <Select.Option value="Integer">Whole Numbers</Select.Option>
                  <Select.Option value="Dropdown">Dropdown</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                // {...Form.ItemLayout}
                label="Data Target"
                name={[this.customField.id || 'new', "model"]}
              >
                <Select onChange={this.handleSubmitVisibility} id={`dataTarget--${this.customField.id || 'new'}`}>
                  <Select.Option value="">Select a data target</Select.Option>
                  <Select.Option value="Patient">Patient</Select.Option>
                  <Select.Option value="Account">Account</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                // {...Form.ItemLayout}
                label="Is this form required?"
                name={[this.customField.id || 'new', "required"]}
              >
                <Select onChange={this.handleSubmitVisibility} id={`requiredChange--${this.customField.id || 'new'}`}>
                    <Select.Option value={true}>Required</Select.Option>
                    <Select.Option value={false}>Not Required</Select.Option>
                </Select> 
              </Form.Item>
            </Col>
            <Col span={12}>
              {this.form && this.form.getFieldValue([this.customField.id || 'new', 'type']) == 'Dropdown' && (
                <Form.Item
                  // {...Form.ItemLayout}
                  label="Enter dropdown options (separate with commas)"
                  name={[this.customField.id || 'new', "dropdownOptions"]}
                >
                  <Input.TextArea onChange={this.handleSubmitVisibility} rows={1} placeholder={'Custom Dropdown Field'} />
                </Form.Item>
              )}
            </Col>
          </Row>
          {this.state.submitVisible ? (
            <div>
              <Button id="submitButton" type='primary' htmlType='submit'>Submit</Button>
              <Button id="cancelButton" style={{marginLeft: '20px'}} onClick={this.handleCancel}>Cancel</Button>
            </div>
          ) : (
            <div style={{height: '32px'}}></div>
          )}
          {this.customField.id && 
          <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyCustomForm} cancelText="No">
            <Button style={{right: '0', position: 'absolute', marginTop: '-32px'}} type='danger'>Delete Custom Form</Button>
          </Popconfirm>
          }
        </Form>  
    );
  }
}
export default CustomFieldForm