import Collection from 'lib/client/collections/collection'
import _ from 'lodash'

class RiskAssessments extends Collection {
    fetch(params){
        params = _.pickBy(params, _.identity);
        let query = Object.entries(params).map(([key, value])=>
            `${key}=${value}`
        )
        if(query.length !== 0){
            query = `?${query.join('&')}`
        }else{
            query = ''
        }

        return this.axios.get(`/operations/risk-assessment${query}`)
    }
}

export default RiskAssessments