import React, {Fragment} from 'react'
import { Layout, Table, Tooltip, Select} from 'antd'
import { connect } from 'react-redux'
import { AdminLayout, SectionContent } from 'components/elements';
import { manualWorkerExecutionActions } from 'actions';
import moment from 'moment'
import YAML from 'json-to-pretty-yaml';
import AddManualWorkerExecution from './AddManualWorkerExecution'

class ManualWorkerExecutions extends React.Component {
    constructor(props){
        super(props)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleRetry = this.handleRetry.bind(this)
        this.refetch = this.refetch.bind(this)
        this.handleChangePolling = this.handleChangePolling.bind(this)
        this.state ={
            pollTime: 15
        }
    }
    componentDidMount(){
        manualWorkerExecutionActions.fetch()
        this.refetch()
    }
    refetch(){
        if(this.state.pollTime != 0){
            const pollTime = this.state.pollTime * 1000
            console.log("pollTime", pollTime)
            setTimeout(()=>{
                manualWorkerExecutionActions.fetch()
                this.refetch()
            }, pollTime)
        }
    }
    handleCancel(id){
        manualWorkerExecutionActions.cancel(id)
    }
    handleRetry(id){
        manualWorkerExecutionActions.retry(id)
    }
    handleChangePolling (value){
        this.setState({
            pollTime: value
        })
    }
    render() {
        let date = null
        const columns = [
            {
                title: 'Worker Name',
                dataIndex: 'workerName',
                key: 'workerName',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Started At',
                dataIndex: 'startedAt',
                key: 'startedAt',
                render:(startedAt)=> (
                    <Tooltip placement="topLeft" title={moment(startedAt).format('M/DD/YY')}>
                        {moment(startedAt).format('h:mm:ss a')}
                    </Tooltip>
                )
            },
            {
                title: 'Completed At',
                dataIndex: 'completedAt',
                key: 'completed',
                render:(completedAt)=> completedAt ? (
                    <Tooltip placement="topLeft" title={moment(completedAt).format('M/DD/YY')}>
                        {moment(completedAt).format('h:mm:ss a')}
                    </Tooltip>
                ): (
                    'Not Completed'
                )
            },
            {
                title: 'Time Taken',
                dataIndex: 'completedAt',
                key: 'completed',
                
                render:(item, row)=> {
                    const diff = moment.duration(moment(row.completedAt).diff(row.startedAt))
                    if(diff.asHours() > 1){
                        return diff.asHours() + ' Hrs'
                    }
                    if(diff.asMinutes() > 1){
                        return diff.asMinutes() + ' Min'
                    }
                    if(diff.asSeconds() > 1){
                        return diff.asSeconds() + ' Sec'
                    }
                    if(diff.asMilliseconds() > 1){
                        return diff.asMilliseconds() + ' MS '
                    }
                    if(diff == 0){
                        return 0 + ' MS '
                    }
                }
            },
            {
                title: 'Summary',
                dataIndex: 'summary',
                key: 'summary',
                render: (item)=>{
                    return item ? YAML.stringify(item) : 'none'
                }
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                  <Fragment>
                    <a onClick={this.handleRetry.bind(null, record.id)}>Retry</a> 
                    {/* <a onClick={this.handleCancel.bind(null, record.id)}>Cancel</a> */}
                  </Fragment>
                ),
            }
        ];

        return (
            <AdminLayout>
                <SectionContent noPadding noMargin noHeader>
                    <Table columns={columns} dataSource={this.props.manualWorkerExecutions } rowKey='id' />
                    <div>
                        &nbsp; <Select defaultValue={15} onChange={this.handleChangePolling} options={[
                            {
                                value: 0,
                                label: 'No Polling',
                            },
                            {
                                value: 5,
                                label: '5 Second',
                            },
                            {
                                value: 15,
                                label: '15 Seconds',
                            },
                            {
                                value: 30,
                                label: '30 Seconds',
                            }
                        ]}  /> &nbsp; <AddManualWorkerExecution /> 
                        <br />
                        <br />
                    </div>
                </SectionContent>
            </AdminLayout>
        )
    }
}

export default  connect((state)=>({
    manualWorkerExecutions: state.manualWorkerExecutions
}))(ManualWorkerExecutions)
