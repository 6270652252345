import {
  FETCH_MESSENGER_TEMPLATES,
  CREATE_MESSENGER_TEMPLATES,
  UPDATE_MESSENGER_TEMPLATES,
  DESTROY_MESSENGER_TEMPLATES,
  APPEND_MESSENGER_TEMPLATES,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_MESSENGER_TEMPLATES:
        return {
            ...state, 
            [`${action.payload.id}`]: action.payload
        }
    case UPDATE_MESSENGER_TEMPLATES:
        return {
            ...state, 
            [`${action.payload.id}`]: action.payload
        }
    case UPDATE_MESSENGER_TEMPLATES:
        let payload = action.payload.reduce((acc, item)=>{
            acc[item.repeatingID || item.id] = item
            return acc;
        }, {})

        return {
            ...state,
            ...payload
        }
    case DESTROY_MESSENGER_TEMPLATES:
        state = {
            ...state
        }
        delete state[action.payload] 
        return state
    case FETCH_MESSENGER_TEMPLATES:
        return action.payload || state;
    case SET_ORGANIZATION_CURRENT:
            return {}
    default:
        return state
}
}
