import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';

import store from '../store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.appointmentTypes.fetch()
  
  const data = response.data.reduce((acc, item)=>{
    acc[item.id] = item
    return acc;
  }, {})

  store.dispatch({ 
    type: ActionTypes.FETCH_APPOINTMENT_TYPES,
    payload: data
  })
}

export const update = async(params = {}) => {
  // throw new Error("here")
  params.spacer = params.spacer == "" ? null : params.spacer
  const response = await apiClient.organizations.appointmentTypes.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_APPOINTMENT_TYPE,
    payload: response.data
  })
}

export const activate = async(ids = []) => {
  const response = await apiClient.organizations.appointmentTypes.activate({ids})

  store.dispatch({
    type: ActionTypes.UPDATE_APPOINTMENT_TYPES,
    payload: response.data
  })

  return response.data
}

export const destroy = async(id) => {
  const response = await apiClient.organizations.appointmentTypes.destroy(id)

  store.dispatch({
    type: ActionTypes.DESTROY_APPOINTMENT_TYPE,
    payload: id
  })
}

export const create = async(params = {})=>{
  params.spacer = params.spacer == "" ? null : params.spacer
  const response = await apiClient.organizations.appointmentTypes.create(params)

  store.dispatch({
    type: ActionTypes.CREATE_APPOINTMENT_TYPE,
    payload: response.data
  })
  return response.data
}