import React from 'react';
import { withRouter } from 'react-router-dom'
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker, Switch } from 'antd';
import {CreateOrEditServiceRegion} from 'components/partials'
import moment from 'moment'
import {shiftActions, accountActions} from 'actions'
import {connect} from 'react-redux'
import _ from 'lodash'

const {Option} = Select;

class CreateOrEditShift extends React.Component {
  constructor(props){
      super(props);
      this.formRef = React.createRef()
      this.state = { 
          visible: false,
          createOrEditServiceRegionVisible: false
      };
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleDestroyShift = this.handleDestroyShift.bind(this)
      this.setStartDate = this.setStartDate.bind(this)
      this.setEndDate = this.setEndDate.bind(this)
      this.setServiceRegion = this.setServiceRegion.bind(this)
      this.handleServiceRegion = this.handleServiceRegion.bind(this)
      this.handleEditServiceRegion = this.handleEditServiceRegion.bind(this)
      this.handleCreateOrEditServiceRegionClose = this.handleCreateOrEditServiceRegionClose.bind(this)
      this.handleChanges = this.handleChanges.bind(this)
  }

    componentDidUpdate(prevProps){
        if(!this.props.visible && prevProps.visible){
            const shift = Object.keys(this.shift).reduce((acc, key)=>({
                ...acc,
                [`${key}`]: undefined
            }), {})
            this.form.setFieldsValue(shift)
        }

        if(JSON.stringify(prevProps.shift) != JSON.stringify(this.props.shift)){
            this.setState(this.state, ()=>{
                this.form && this.form.resetFields();
            })
        }
    }

  get shift(){
    const _event = this.props.shift || {}
    
    let sequence = this.form && this.form.getFieldValue('sequence')
    if(_.isNil(sequence) && _event.startsAt) sequence = _event.sequence
   
    let dayOfMonth = this.form && this.form.getFieldValue('dayOfMonth')
    if(_.isNil(dayOfMonth) && _event.startsAt) dayOfMonth = _event.dayOfMonth
    
    let dayOfWeek = this.form && this.form.getFieldValue('dayOfWeek')
    if(_.isNil(dayOfWeek) && _event.startsAt) dayOfWeek = _event.dayOfWeek
    
    let repeatingType = this.form && this.form.getFieldValue('repeatingType')
    if(_.isNil(repeatingType) && _event.startsAt) repeatingType = this.repeatingType
    
    let serviceRegionID = this.form && this.form.getFieldValue('serviceRegionID')
    if(_.isNil(serviceRegionID) && _event.startsAt) serviceRegionID = _event.serviceRegionID
    
    let startsAt = this.form && this.form.getFieldValue('startsAt')
    if(_.isNil(startsAt) && _event.startsAt) startsAt = _event.startsAt
    
    let endsAt = this.form && this.form.getFieldValue('endsAt')
    if(_.isNil(endsAt) && _event.startsAt) endsAt = _event.endsAt
    
    let startsAtDate = this.form && this.form.getFieldValue('startsAtDate')
    if(_.isNil(startsAtDate) && _event.startsAt) startsAtDate = _event.startsAt
    
    let endsAtDate = this.form && this.form.getFieldValue('endsAtDate')
    if(_.isNil(endsAtDate) && _event.startsAt) endsAtDate = _event.endsAt
    
    let repeatsUntil = this.form && this.form.getFieldValue('repeatsUntil')
    if(_.isNil(repeatsUntil) && _event.startsAt && _event.repeatsUntil) repeatsUntil = moment(_event.repeatsUntil)
   
    let repeatsUntilType = this.form && this.form.getFieldValue('repeatsUntilType')
    if(_.isNil(repeatsUntilType) && _event.startsAt) repeatsUntilType = _event.repeatsUntil  ? 'end' : '';
    
    let skipType = this.form && this.form.getFieldValue('skipType')
    if(_.isNil(skipType) && _event.startsAt) skipType = _event.skipType
    
    let skipValue = this.form && this.form.getFieldValue('skipValue')
    if(_.isNil(skipValue) && _event.startsAt) skipValue = _event.skipValue
    
    return {
      id: _event.id,
      sequence,
      dayOfMonth,
      dayOfWeek,
      repeatingType,
      serviceRegionID,
      startsAtDate,
      endsAtDate,
      startsAt,
      endsAt,
      repeatsUntil,
      repeatsUntilType,
      skipType,
      skipValue
    }
  }

  get serviceRegions(){
    return Object.values(this.props.serviceRegions).sort((a,b)=>{
      if(a.name < b.name) return -1
      if(a.name > b.name) return 1
      return 0
    })
  }

  get serviceRegion(){
    const id = this.form && this.form.getFieldValue('serviceRegionID')
    return this.props.serviceRegions[id]
  }

  showDrawer () {
      this.setState({
          visible: true,
      });
  };

  setStartDate(time){
    let date = this.form && this.form.getFieldValue('startsAtDate')
    let day = date.date()
    let month = date.month()
    let year = date.year()

    return time.clone().date(day).month(month).year(year)
  }

  setEndDate(time){
    let date = this.form && this.form.getFieldValue('endsAtDate')
    let day = date.date()
    let month = date.month()
    let year = date.year()

    return time.clone().date(day).month(month).year(year)
  }

  async handleSubmit (values) {
    let _shift = {
      sequence: null,
      dayOfMonth: null,
      dayOfWeek: null,
      skipType: null,
      skipValue: null,
      startsAt: this.setStartDate(values.startsAt),
      endsAt: this.setEndDate(values.endsAt),
      administratorID: this.props.match.params.id,
      serviceRegionID: values.serviceRegionID || null,
      repeating: true
    }

    switch(values.repeatingType){
      case 'daily':
        _shift.dayOfWeek = 'Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday'
        break;
      case 'weekday':
        _shift.dayOfWeek = 'Monday,Tuesday,Wednesday,Thursday,Friday'
        break;
      case 'weekly':
        _shift.dayOfWeek = this.dayOfWeek
        break;
      case 'nthOfMonth':
        _shift.dayOfMonth = moment(this.shift.startsAt).date()
        break;
      case 'nthDayOfMonth':
        _shift.dayOfWeek = this.dayOfWeek
        _shift.sequence = Math.ceil(moment(this.shift.startsAt).date() / 7)
        break;
      default:
        _shift.repeating = false
    }

    switch(values.skipType){
      case 'week':
        _shift.skipValue = 1
        _shift.skipType = 'week'
        break;
      case 'month':
        _shift.skipValue = 1
        _shift.skipType = 'month'
        break;
      case 'xWeeks':
        _shift.skipValue = values.skipValue
        _shift.skipType = 'week'
        break;
      case 'xMonths':
        _shift.skipValue = values.skipValue
        _shift.skipType = 'month'
        break;
    }

    if(values.repeatsUntilType && values.repeatsUntil){
      _shift.repeatsUntil = values.repeatsUntil.format()
    } else {
      _shift.repeatsUntil = null
    }

    if(this.shift.id){
      await shiftActions.update({
          id: this.shift.id,
          ..._shift
      })
    }else{
      await shiftActions.create(_shift)
    }
    this.props.onClose(true)
  }


  async handleDestroyShift(e){
    e.preventDefault()

    await shiftActions.destroy({
        id: this.shift.id,
        administratorID: this.props.match.params.id
    })
    this.props.onClose(true)
  }

  get dayOfWeek(){
    return this.shift.startsAtDate && moment(this.shift.startsAtDate).format('dddd')
  }

  get dateOfMonth(){
    return moment(this.shift.startsAt).format('Do')
  }

  get nthDay(){
    let nth = Math.ceil(moment(this.shift.startsAt).date() / 7)
    switch(nth){
      case 1:
        return `${nth}st`
      case 2:
        return `${nth}nd`
      case 3:
        return `${nth}rd`
      default:
        return `${nth}th`
    }
  }

  get repeatingType(){
    if(this.form && this.form.getFieldValue('repeatingType')){
      return this.form && this.form.getFieldValue('repeatingType')
    }
    const shift = Object(this.props.shift)
    if(!shift.repeating){
      return '' 
    }
    if(shift.sequence){
      return 'nthDayOfMonth'
    }
    if(shift.dayOfWeek){
      let days = shift.dayOfWeek.split(',')
      if(days.length == 7){
        return 'daily'
      }
      if(days.length == 5){
        return 'weekday'
      }
      if(days.length == 1){
        return 'weekly'
      }
    }
    if(shift.dayOfMonth){
      return 'nthOfMonth'
    }
    
    return '' 
  }

  get skipOptions(){
    switch(this.repeatingType){
      case 'weekly':
      case 'daily':
      case 'weekday':
        return [
          {value:'week',text:"Skip Every Other Week"},
          {value:'xWeeks',text:"Skip Every X Week"}
        ]
      case 'nthDayOfMonth':
      case 'nthOfMonth':
        return [
          {value:'month',text:'Skip Every Other Month'},
          {value:'xMonths',text:'Skip Every X Month'}
        ]
      default:
        return []
    }
  }

  get skipType(){
    if(this.form && this.form.getFieldValue('skipType')){
      return this.form && this.form.getFieldValue('skipType')
    }

    const shift = Object(this.props.shift)

    if(!shift.skipValue){
      return ''
    }
    if(shift.skipValue > 1 && shift.skipType == 'week'){
      return 'xWeeks'
    }
    if(shift.skipValue > 1 && shift.skipType == 'month'){
      return 'xMonths'
    }
    if(shift.skipType == 'week'){
      return 'week'
    }
    if(shift.skipType == 'month'){
      return 'month'
    }
    
    return ''
  }

  get form() {
    return this.formRef.current
  }

  get skipX(){
    if(this.form && this.form.getFieldValue('skip') && this.form.getFieldValue('skip').includes('x')){
      return true
    }
    return false
  }

  get specificDate(){
    if(this.form && this.form.getFieldValue('repeatsUntil') == 'end'){
      return true
    }
    return false

  }

  setServiceRegion(value){
    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({serviceRegionID: value});
    }, 1)
  }

  handleEditServiceRegion(){
    this.setState({
      createOrEditServiceRegionVisible: true
    })
  }

  handleServiceRegion(value){
    if(value === 'new'){
      this.setState({
        createOrEditServiceRegionVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({serviceRegionID: ''});
      }, 1)
    }
  }

  handleCreateOrEditServiceRegionClose(){
    this.setState({
      createOrEditServiceRegionVisible: false
    })
  }
  handleChanges(){
    this.setState(this.state)
  }

  get showShiftID(){
    return accountActions.isSuperAdministrator() || accountActions.isPartnerAdministrator()
  }
  render() {
    const initialValues = {
      startsAtDate: this.shift.startsAt,
      endsAtDate: this.shift.endsAt,
      startsAt: this.shift.startsAt, 
      endsAt: this.shift.endsAt, 
      repeatingType: this.shift.repeatingType,
      skipType: this.skipType,
      skipValue: this.shift.skipValue,
      repeatsUntil: this.shift.repeatsUntil,
      repeatsUntilType: this.shift.repeatsUntilType,
      endDate: this.shift.repeatsUntil ? this.shift.repeatsUntil : moment(),
      serviceRegionID: this.shift.serviceRegionID
    }
    let edit = false;
    
    if(this.shift.id){
      edit = true
    }    
    
    let editServiceRegion = false

    if(this.form && this.form.getFieldValue('serviceRegionID')){
      editServiceRegion = true
    }

    
    return (
      <Drawer
        title={edit ? 'Edit Shift' : 'Create Shift'}
        width={520}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
      <CreateOrEditServiceRegion
        visible={this.state.createOrEditServiceRegionVisible}
        onClose={this.handleCreateOrEditServiceRegionClose}
        setServiceRegion={this.setServiceRegion}
        serviceRegion={this.serviceRegion}
      />
      <Form
        ref={this.formRef}
        initialValues={initialValues}
        layout="vertical"
        onValuesChange={this.handleChanges}
        onFinish={this.handleSubmit}
      >
        {this.showShiftID && (
          <div>
            {this.shift.id}
          </div>
        )}
        <Row gutter={16}>
            <Col span={8}>
              <Form.Item 
                label="Start Date"
                name="startsAtDate"
                rules={[{ required: true, message: 'Please select a start date' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="Start Time"
                name="startsAt"
                rules={[{ required: true, message: 'Please choose the start time' }]}
              >
                <TimePicker use12Hours format="h:mm A" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item 
                label="End Date"
                name="endsAtDate"
                rules={[{ required: true, message: 'Please select an end date' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="End Time"
                name="endsAt"
                rules={[{ required: true, message: 'Please choose the end time' }]}
              >
                <TimePicker use12Hours format="h:mm A" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item 
                label="Repeating"
                name="repeatingType"
                rules={[{ message: 'Please select a classification' }]}
              >
                <Select>
                  <Option value=''>Does Not Repeat</Option>
                  <Option value='daily'>Repeat Daily</Option>
                  <Option value='weekday'>Repeat Every Weekday (Monday - Friday)</Option>
                  <Option value='weekly'>Repeat Every {this.dayOfWeek}</Option>
                  <Option value='nthOfMonth'>Repeat on the {this.dateOfMonth} of every month</Option>
                  <Option value='nthDayOfMonth'>Repeat Monthly on the {this.nthDay} {this.dayOfWeek}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {this.shift.repeatingType &&
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item 
                  label="Skip"
                  name="skipType"
                >
                  <Select>
                    <Option value=''>Does Not Skip</Option>
                    {this.skipOptions.map((skip,i)=>{
                      return (
                        <Option key={`skip-${i}`}value={skip.value}>{skip.text}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {this.skipType.includes('x') &&
                <Col span={4}>
                  <Form.Item 
                    label=" "
                    name="skipValue"
                    placeholde="x"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              }
            </Row>
          }
          {this.shift.repeatingType && (
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item 
                  label="Repeats Until"
                  name="repeatsUntilType"
                >
                  <Select>
                    <Option value=''>Does Not End</Option>
                    <Option value='end'>Repeat Until a Specific Date</Option>
                  </Select>
                </Form.Item>
              </Col>
              {!!this.shift.repeatsUntilType &&
                <Col span={8}>
                  <Form.Item 
                    label="End Date"
                    name="repeatsUntil"
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
              }
            </Row>
          )}
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item 
                label="Service Region"
                name="serviceRegionID"
              >
                <Select
                  style={{width: editServiceRegion ? 'calc(100% - 57px)' : '100%'}}
                  onChange={this.handleServiceRegion}
                  filterOption={false}
                  showArrow={false}
                  placeholder="Select Region"
                >
                  {this.serviceRegions.map((region, i)=>
                    <Option key={`region-${i}`} value={region.id}>
                      {region.name}
                    </Option>
                  )}
                  <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                  <Option value=''>None</Option>
                  <Option key='new'>New Service Region</Option>
                </Select>
              </Form.Item>
              {editServiceRegion && (
                <Button onClick={this.handleEditServiceRegion}>Edit</Button>
              )}
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyShift} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Shift</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.shift.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default withRouter(connect((state)=> state)(
  CreateOrEditShift
))