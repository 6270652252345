import React from 'react'
import { Input, Form, InputNumber, DatePicker, Select, Card, Row, Col, Switch} from 'antd'
import { MaskedInput } from 'components/elements'
const FormItem = Form.Item
const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import GeneralEditor from 'components/elements/GeneralEditor'
import Scroller from 'components/elements/Scroller'
import FormGroup from 'components/elements/FormGroup'
import {
  administratorActions,
} from 'actions'

const dateFormat = 'DD/MM/YYYY';

class Invite extends React.Component {
  constructor(){
    super()
    this.formRef = React.createRef()
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      officeHours:[],
      inviteError: false
    }
  }
  async handleSubmit(values){
    Object.keys(values).forEach((key)=>{
      if(!values[key]){
        values[key] = null
      }
    })
    
    let administrator = await administratorActions.invite(values)
    
    if(administrator){
      this.props.history.push(`/admin/administrators/${administrator.id}`)
    } else {
      this.setState({
        inviteError: true
      })
    }
  }

  get form() {
    return this.formRef.current
  }

  get current(){
    return this.props.current || {
      administratorOptions: {}
    }
  }
  
  handleCancel(){
    if(this.current.id){
        this.props.history.push(`/admin/administrators/${this.current.id}`)
    } else {
        this.props.history.push(`/admin/administrators`)
    }
  }
  
  handleChange(){
    if(this.state.inviteError){
      this.setState({
        inviteError: false
      })
    }
  }
  
  render() {

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
    };

    return (
      <div className='edit-switch'>
        <Form ref={this.formRef} onFinish={this.handleSubmit}>
          <GeneralEditor onCancel={this.handleCancel} onSave={this.handleSubmit}>
            <Scroller>
              <br />
                <br />
                <FormItem
                    {...formItemLayout}
                    label="E-mail"
                    name="email"
                    rules={[{
                        type: 'email', message: 'The input is not valid E-mail!',
                    }, {
                        required: false, message: 'Please input your E-mail!',
                    }]}
                >
                  <Input onChange={this.handleChange} placeholder={'drdolittle@example.com'} />
                </FormItem>
                <br />
                <FormItem
                    {...formItemLayout}
                    label="Mobile Phone"
                    name="mobilePhone"
                    rules={[{
                        type: 'string', message: 'The input is not valid Phone!',
                    }, {
                        required: false, message: 'Please input your Phone!',
                    }]}
                >
                  <MaskedInput onChange={this.handleChange} placeholder={'822-555-0405'} type='phone' />
                </FormItem>
                {this.state.inviteError && 
                  <div className="invite-error">
                    This account does not exist    
                  </div>
                }
            </Scroller>
          </GeneralEditor>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.patients
  }
}

export default withRouter(
  (
    connect(mapStateToProps)(Invite)
  )
)