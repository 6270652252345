import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';

import store from 'store'

export const fetch = async(params = {})=>{
  if(_.get(store.getState(), 'currentOrganization.id')){
    const response = await apiClient.organizations.insights.fetch({})
  
    store.dispatch({ 
      type: ActionTypes.FETCH_INSIGHTS,
      payload: response.data
    })
  }
}

export const update = async(params = {}) => {
  const response = await apiClient.organizations.insights.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_WORKERS,
    payload: response.data
  })
  
  return response.data
}