import React, {Component, Fragment} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import { 
    Badge,
    Input,
    List,
    Avatar,
    Button,
    Skeleton,
    Row,
    Col,
    Select,
    Tabs,
    DatePicker,
    Space,
    Card
} from 'antd';
import JSONTree from 'react-json-tree'
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
import moment from 'moment'
import Scroller from 'components/elements/Scroller'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import {
    syncActions,
    syncStatusActions
} from 'actions'
import { apiClient } from 'lib';
import Display from './Display'
import './style.less'

const TextArea = Input.TextArea;

class Fetch extends Component {
    constructor(props){
        super(props)
        this.state = {
            initLoading: true,
            loading: false,
            data: [],
            list: [],
            type: 'between'
        };
        this.between = this.between.bind(this)
        this.weekOf = this.weekOf.bind(this)
        this.type = this.type.bind(this)
        this.fetchData = this.fetchData.bind(this)
    }
    
    async fetchData(){
        this.setState({
            initLoading: true
        }, async()=>{

            let options = {};
            if(this.state.type == 'between'){
                options = {
                    startsAt: this.state.startsAt,
                    endsAt: this.state.endsAt
                }
                
            }else{
                options = {
                    weekOf: this.state.weekOf
                }
            }
            
            const response = await apiClient.organizations.operations.appointmentRequests.fetch(options)
            if(response){
                this.setState({
                    data: response.data
                })
            }
            this.setState({
                initLoading: false
            });
        });
    }
    get data(){
        return this.state.data.map((data)=>({
            ...data,
            states: data.states.map(({createdAt, status})=>({
                createdAt: moment(createdAt).format('YY/MM/DD h:mm:ss a'),
                status
            }))  
        }))
    }
    type(type){
        this.setState({
            type
        })
    }
    weekOf(weekOf){
        this.setState({
            weekOf: weekOf.format()
        }, ()=>{
            this.fetchData()
        })
    }
    between([startsAt, endsAt]){
        this.setState({
            startsAt: startsAt.format(),
            endsAt: endsAt.format()
        }, ()=>{
            this.fetchData()
        })
    }
    
    render(){
        const { initLoading, loading } = this.state;
        const list = this.data

        const loadMore =
        !initLoading && !loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: 12,
              height: 32,
              lineHeight: '32px',
            }}
          >
            <Button onClick={this.onLoadMore}>loading more</Button>
          </div>
        ) : null;

        
        return (
            <div>
                <br />
                <Input.Group compact>
                    <Select defaultValue="between" onChange={this.type} style={{width: 200}}>
                        <Select.Option value="between">
                            Between
                        </Select.Option>
                        <Select.Option value="weekOf">
                            Week
                        </Select.Option>
                    </Select>
                    {this.state.type == 'between' ? (
                        <Fragment>
                            <Space direction="vertical" size={12}>
                                <RangePicker style={{ width: '80.333%' }} onChange={this.between} />
                            </Space>
                        </Fragment>
                    ):(
                        <Fragment>
                            <DatePicker style={{ width: '80.333%' }} placeholder={'Start Date'} onChange={this.weekOf}/>
                        </Fragment>
                    )}
                </Input.Group>
                <Card bordered={false}>
                    <Scroller>
                        <Card bordered={false}>
                            <List
                                className="demo-loadmore-list"
                                loading={initLoading}
                                itemLayout="horizontal"
                                loadMore={loadMore}
                                dataSource={list}
                                renderItem={(item)=>{
                                    return (

                                        <List.Item
                                            actions={(
                                                <Fragment>
                                                    <a key="list-loadmore-edit">Transcribe</a>
                                                    <a key="list-loadmore-more">Options</a>
                                                </Fragment>
                                            )}
                                        >
                                            <Skeleton avatar title={false} loading={item.loading} active>
                                            <List.Item.Meta
                                                title={
                                                    <Row>
                                                        <Col sm={8}>
                                                            <span className='operations-sync-type'>
                                                                {Object(item.patient).firstName || 'No patient found'}
                                                            </span>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <span className='operations-sync-name'>
                                                                {Object(item.appointment).id && <span>Created Appointment</span>} {Object(item.account).firstName} {Object(item.account).lastName}
                                                            </span>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <span className='operations-sync-status'>
                                                                {moment(item.startsAt).format('YY/MM/DD h:mm a')}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                }

                                                description={
                                                    <div><Display item={item} /></div>
                                                }
                                            />
                                            </Skeleton>
                                        </List.Item>
                                    )
                                }}
                            />
                            <br />
                            <br />
                        </Card>
                    </Scroller>
                </Card>
            </div>
        )
    }
}

export default connect((props)=>({
    data: props.sync,
    syncStatus: props.syncStatus
}))(Fetch)