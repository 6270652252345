import React, {Component, Fragment} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import { Table } from 'antd';

const { Column, ColumnGroup } = Table;
import { 
    Input,
    Button,
    Form,
    Tabs,
    DatePicker,
    Card,
    List,
    Row,
    Col
} from 'antd';
import JSONTree from 'react-json-tree'
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
import moment from 'moment'
import Scroller from 'components/elements/Scroller'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import {
    syncActions,
    syncStatusActions,
    administratorActions
} from 'actions'
import { apiClient } from 'lib';
import Display from './Display'
import './style.less'
import Item from 'antd/lib/list/Item';

const TextArea = Input.TextArea;

class RiskAssessment extends Component {
    constructor(props){
        super(props)
        this.state = {
            notes: []
        };

        this.fetchData = this.fetchData.bind(this)
        this.formRef = React.createRef()
    }
    
    async fetchData(){    
        const response = await apiClient.organizations.operations.riskAssessment.fetch()
        if(response){
            this.setState({
                riskAssessment: response.data
            })
        }
    }
    componentDidMount(){
        this.fetchData()
    }
    
    
    get riskAssessment(){
        return Object.values(this.state.riskAssessment || {})
    }
    
    render(){
        return (
            <div>
                <Card bordered={false}>
                    <Scroller>
                        <Card bordered={false}>
                            <Table dataSource={this.riskAssessment}>
                                <Column
                                    title="Organization Name"
                                    key="organization"
                                    render={(text, record) => (
                                        _.get(record, 'organization.name')
                                    )}
                                />
                                <Column
                                    title="Last Appointment"
                                    key="appointment"
                                    render={(text, record) => (
                                        moment(_.get(record, 'appointment.createdAt', 'None')).fromNow()
                                    )}
                                />
                            </Table>
                        </Card>
                    </Scroller>
                </Card>
            </div>
        )
    }
}

export default connect(({session, administrators})=>({session, administrators}))(RiskAssessment)