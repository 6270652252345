import {
  FETCH_PATIENTS,
  CREATE_PATIENT,
  UPDATE_PATIENT,
  DESTROY_PATIENT,
  MERGE_PATIENTS,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {

    case FETCH_PATIENTS:
      return {
        ...state,
        ...action.payload
      }
    case MERGE_PATIENTS:
      return {
        ...state,
        ...action.payload
      }
    case CREATE_PATIENT:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    
    case UPDATE_PATIENT:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    
    case DESTROY_PATIENT:
      state = {
        ...state
      }
      delete state[action.payload]
      return state

    case SET_ORGANIZATION_CURRENT:
        return {}

    default:
      return state
  }
}
