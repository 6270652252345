import {
  FETCH_GROUPS,
  CREATE_GROUP,
  UPDATE_GROUP,
  UPDATE_GROUPS,
  DESTROY_GROUP,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_GROUPS:
      return {
        ...state,
        ...action.payload,
      }
    case CREATE_GROUP:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case DESTROY_GROUP:
      state = {
        ...state
      }
      delete state[action.payload]
      return state
    case UPDATE_GROUP:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case UPDATE_GROUPS:
      let payload = action.payload.reduce((acc, item)=>{
        acc[item.repeatingID || item.id] = item
        return acc;
      }, {})

      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
