export default (state)=>{
    return {
        ...state,
        accountsPatients: Object.values(state.patients).reduce((acc, patient)=>{
            for(let accountID of patient.accountIDs){
                acc[accountID] = [patient.id, ...(acc[accountID] || [])]
            }
            return acc;
        }, {})
    }
}