import {
  FETCH_ACCOUNTS,
  APPEND_ACCOUNT,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCOUNTS:
      
      return {
        ...state,
        ...action.payload
      }
    case APPEND_ACCOUNT:
      var accounts = Object.assign({},state)
      accounts[action.payload.id] = action.payload
      return {
        ...accounts
      }
    case SET_ORGANIZATION_CURRENT:
      return {}
    default:
      return state
  }
}
