import {RRule, rrulestr} from 'rrule'
import moment from 'moment-timezone'
import _ from 'lodash'

const RRuleDays = {
    SUNDAY: RRule.SU,
    MONDAY: RRule.MO,
    TUESDAY: RRule.TU,
    WEDNESDAY: RRule.WE,
    THURSDAY: RRule.TH,
    FRIDAY: RRule.FR,
    SATURDAY: RRule.SA
}
const getRRuleDays = (days)=>{
    const $days = []
    for(let day of days){

        $days.push(
            RRuleDays[day.toUpperCase()]
        )

    }
    return $days
}
const toRRule = ($data, timezone)=>{
    const rule = {}

    if($data.dayOfWeek){
        let days = $data.dayOfWeek.split(',')
        if(days.length == 7){
            rule.freq = RRule.WEEKLY
            rule.interval = 1
            rule.byweekday = getRRuleDays(days)
        }
        if(days.length == 5){
            rule.freq = RRule.WEEKLY
            rule.interval = 1
            rule.byweekday = getRRuleDays(days)
        }
        if(days.length == 1){
            rule.freq = RRule.WEEKLY
            rule.interval = 1
            rule.byweekday = getRRuleDays(days)
        }
    }

    if($data.dayOfMonth){ // nthOfMonth
        rule.freq = RRule.MONTHLY
        rule.interval = 1;
        rule.bymonthday	= $data.dayOfMonth
    } else if($data.sequence) {
        rule.freq = RRule.MONTHLY
        rule.bysetpos = $data.sequence
    }

    if($data.skipValue){
        rule.interval = rule.interval + $data.skipValue
    }
    
    let year = moment($data.startsAt).year();
    let month = moment($data.startsAt).month();
    let day = moment($data.startsAt).date();
    
    if(timezone){
        rule.dtstart = moment().tz(timezone).startOf('day').year(year).month(month).date(day).toDate()
    } else {
        rule.dtstart = moment().startOf('day').utc().year(year).month(month).date(day).toDate()
    }

    rule.until = $data.repeatsUntil || moment().add(6, 'months')
    return rule;
}

const generateRRule = (options)=>{
    let data = []
    for(let $data of options.data){
        let duration = moment($data.endsAt).diff(moment($data.startsAt),'minutes')
        let rruleData = []

        if($data.repeating){
            rruleData = new RRule(
                toRRule($data, options.timezone)
            ).between(moment(options.startingAt).utc().subtract(1,'day').toDate(), moment(options.endingAt).utc().toDate())
            
            rruleData = rruleData.map((_date)=> {
                let startsAt, endsAt
                let year = moment(_date).year();
                let month = moment(_date).month();
                let day = moment(_date).date();

                if(options.timezone){ 
                    startsAt = moment($data.startsAt).year(year).month(month).date(day).tz(options.timezone).startOf('minute')
                    endsAt = moment(startsAt).add(duration,'minutes')
                } else {
                    startsAt = moment($data.startsAt).year(year).month(month).date(day).startOf('minute')
                    endsAt = moment(startsAt).add(duration,'minutes')
                }
                
                return {
                    ...$data,
                    startsAt,
                    endsAt,
                    id: $data.id,
                    repeatingID: `${$data.id}-${startsAt.clone().format()}-${endsAt.clone().format()}`
                }
            })
        } else {
            rruleData = [{
                ...$data,
                startsAt: moment($data.startsAt),
                endsAt: moment($data.endsAt),
                id: $data.id
            }]
        }

        data = _.concat(data,rruleData)
    }
    return data
}

const processRepeating = (options)=>{
    return generateRRule(options)
}

export default processRepeating