import React from 'react'
import { withRouter } from 'react-router-dom'

import './index.less'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import {CreateOrEditOpenTime} from 'components/partials'
import moment from 'moment'
import {openTimeActions} from 'actions'
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar'

const localizer = momentLocalizer(moment)
 
class OpenTimeCalendar extends React.Component {
    constructor(){
        super()

        this.state = {
            events: []
        };
        this.handleCreate = this.handleCreate.bind(this)
        this.hideCreate = this.hideCreate.bind(this)
        this.handleChangeView = this.handleChangeView.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
    }
    componentDidMount(){
        openTimeActions.fetch()
    }
    handleSelect(event){
        let eventID = `${event.id}-${moment(event.start).format("YYYY-MM-DDTHH:mm:ssZ")}-${moment(event.end).format("YYYY-MM-DDTHH:mm:ssZ")}`
        let openTime = this.props.openTimes[eventID]
        if(!openTime){
            openTime = this.props.openTimes[event.id]
        }
        
        this.setState({
            event: {
                ...event,
                startsAtDate: moment(openTime.startsAt),
                endsAtDate: moment(openTime.endsAt),
                startsAt: moment(openTime.startsAt),
                endsAt: moment(openTime.endsAt)
            }
        }, ()=>{
            this.setState({
                createVisible: true,
            })
        })
    }
    handleCreate(event){
        this.setState({
            event: {
                startsAtDate: moment(event.start), 
                endsAtDate: moment(event.end), 
                startsAt: moment(event.start), 
                endsAt: moment(event.end)
            }
        }, ()=>{
            this.setState({createVisible: true})
        })
    }
    hideCreate(){
        this.setState({
            createVisible: false,
            event: undefined,
            events: []
        })
    }
    handleChangeView(type){
        // this.props.history.push(`/admin/appointments/${type}`)
    }
    get events(){
        return Object.values(this.props.openTimes).reduce((acc,_)=>{
            let start = moment(_.startsAt).toDate()
            let end = moment(_.endsAt).toDate()
            if(moment(_.startsAt).format('dd') != moment(_.endsAt).format('dd')){
                acc.push({
                    id: _.id,
                    repeating: _.repeating || false,
                    start: moment(_.startsAt).toDate(),
                    end: moment(_.startsAt).endOf('day').toDate(),
                    dayOfWeek: _.dayOfWeek,
                    dayOfMonth: _.dayOfMonth,
                    sequence: _.sequence,
                    skipValue: _.skipValue,
                    skipType: _.skipType,
                    repeatsUntil:  _.repeatsUntil ? moment(_.repeatsUntil).toDate() : ''
                })
                start = moment(_.endsAt).startOf('day').toDate()
                end = moment(_.endsAt).toDate()
            }
            acc.push({
                id: _.id,
                repeating: _.repeating || false,
                start: start,
                end: end,
                dayOfWeek: _.dayOfWeek,
                dayOfMonth: _.dayOfMonth,
                sequence: _.sequence,
                skipValue: _.skipValue,
                skipType: _.skipType,
                repeatsUntil:  _.repeatsUntil ? moment(_.repeatsUntil).toDate() : ''
            })
            return acc
        },[]).concat(this.state.events)
    }
    render() {
        return (
            <AdminLayout fullHeight={true}>
                <SectionContent noPadding noMargin noHeader>
                    <Calendar
                        selectable
                        localizer={localizer}
                        defaultDate={new Date()}
                        view={"week"}
                        onView={this.handleChangeView}
                        events={this.events}
                        onSelectEvent={this.handleSelect}
                        onSelectSlot={this.handleCreate}
                        views={['week', 'day']}
                        step={15}
                        timeslots={4}
                    />
                    <CreateOrEditOpenTime 
                        visible={this.state.createVisible} 
                        onClose={this.hideCreate} 
                        openTime={this.state.event} />
                </SectionContent>
            </AdminLayout>
        )
    }
}

export default withRouter(connect((state)=> state)(OpenTimeCalendar))