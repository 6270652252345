import Collection from 'lib/client/collections/collection'

class WaitLists extends Collection{
    fetch(options){
        let params = ''
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/wait-lists${params}`);
    }

    create(params){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/wait-lists`,params);
    }

    update(params){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/wait-lists/${params.id}`,params);
    }

    destroy(id = ''){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/wait-lists/${id}`);
    }
}

export default WaitLists;