import React, { Component } from 'react'
import { Form, Input } from 'antd';
import {genderActions} from 'actions'
class SexesForm extends Component {
    constructor(){
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        genderActions.fetch();
        this.handleDestroyGender = this.handleDestroyGender.bind(this)
    }
    async handleSubmit(values){
        if(this.gender.id){
            await genderActions.update({
                id: this.gender.id,
                ...values
            })
            this.props.onClose()
        }else{
            const gender = await genderActions.create({
                ...values
            })
            this.props.onSetCurrentGender(gender.id)
            this.props.onClose()
        }
    }

    async handleDestroyGender(e){
        e.preventDefault()
        
        await genderActions.destroy(this.gender.id)
        this.props.onSetCurrentGender('')
        this.props.onClose()
    }
    get gender(){
        return {
            ...(this.props.gender || {})
        }
    }

    get form() {
        return Object(this.props.formRef).current
    }

    render(){
        return (
            <div> 
                <Form.Item 
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input Sex name!' }]}
                >
                    <Input placeholder="Name of Sex" />
                </Form.Item>
                <Form.Item 
                    label="Description"
                    name="description"
                >
                    <Input.TextArea placeholder="Description" />
                </Form.Item>
            </div>
        )
    }
}

export default SexesForm