import Collection from '../collection'

class Breeds extends Collection{
    create(options){
        return this.axios.post(`/organizations/${this.props.currentOrganization.id}/breeds`, {
            ...options
        });
    }
    update(options){
        return this.axios.put(`/organizations/${this.props.currentOrganization.id}/breeds/${options.id}`, {
            ...options
        });
    }
    activate(options){
        return this.axios.put(`/organizations/${this.props.currentOrganization.id}/breeds/activate`, {
            ...options
        });
    }
    destroy(id){
        return this.axios.delete(`/organizations/${this.props.currentOrganization.id}/breeds/${id}`);
    }
    fetch(options = {}){
        let params = ''
        if(options.classificationID) params = `?classificationID=${options.classificationID}`

        return this.axios.get(`/organizations/${this.props.currentOrganization.id}/breeds${params}`);
    }
}

export default Breeds;