import React, {Component, Fragment} from 'react'
import { Card, Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import CreateOrEditAccount from 'components/partials/CreateOrEditAccount';
import { apiClient } from 'lib';


class Account extends Component {
  constructor(props, context) {
    super(props, context)
    this.formRef = React.createRef()
    this.state = { 
        visible: false,
        createGendersVisible: false,
        genders: []
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAccountChange = this.handleAccountChange.bind(this)
    this.handleEditAccount = this.handleEditAccount.bind(this)
    this.onSetCurrentAccount = this.onSetCurrentAccount.bind(this)
    this.handleAccountSearch = this.handleAccountSearch.bind(this)
    this.handleCreateOrEditAccountClose = this.handleCreateOrEditAccountClose.bind(this)
    this.selectAccountByID = this.selectAccountByID.bind(this)
    this.onCreateAccount = this.onCreateAccount.bind(this)
  }

  handleAccountChange(value){
    if(value === 'new'){
      this.setState({
        createOrEditAccountVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({accountID: ''});
      }, 1)
    } 
  }

  handleEditAccount(){
    this.setState({
      createOrEditAccountVisible: true
    })
  }

  onSetCurrentAccount(accountID){
    this.form && this.form.setFieldsValue({accountID});
  }

  handleAccountSearch(value){
    if(this.accountSearchTimeout){
      clearTimeout(this.accountSearchTimeout)
    }
    this.accountSearchTimeout = setTimeout(()=>{this.search(value)},1000)
  }

  async search(search = ''){
    const {data} = await apiClient.organizations.accounts.fetch({limit:5, search})

    this.setState({
      accounts: data
    })
  }  

  get form() {
    return this.formRef.current
  }

  get accounts(){
    return this.state.accounts || []
  }

  get account(){
    return (
      this.props.account || (this.props.accounts && this.props.accounts[(this.form && this.form.getFieldValue('accountID')) || '']) || {}
    )
  }

  handleCreateOrEditAccountClose(){
    this.setState({
      createOrEditAccountVisible: false
    })
  }

  onCreateAccount(accountID){
    this.selectAccountByID(accountID)
  }

  async selectAccountByID(accountID){
    const {data} = await apiClient.organizations.accounts.requestToken({id: accountID});
    try{this.props.onAccountSelect(data)}
    catch(error){
      console.log(error)
    }
  }

  handleSubmit({id} = {}){
    this.selectAccountByID(id)
  }

// check later

  

  render() {
    let editAccount = false;
    let initialValues = {id: this.account.id}
    return (
      <Fragment>
        <CreateOrEditAccount
            visible={this.state.createOrEditAccountVisible}
            onClose={this.handleCreateOrEditAccountClose}
            onSetCurrentAccount={this.onCreateAccount}
            account={this.account}
        />
        <Form ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
          <Card style={{ maxWidth: 500, margin: '200px auto' }} bordered={false}>
            <h3>Select or Create an Account</h3>
            <br /> 
            <Input.Group style={{ width: '100%' }} compact>
              <Form.Item
                noStyle
                name="id"
                rules={[{required: true, message: 'Please select an Account'}]}
              >
                <Select
                    style={{width: editAccount ? 'calc(100% - 57px)' : '100%'}}
                    showSearch
                    placeholder={"Search by First Name, Last Name, Email, or Mobile Phone"}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    notFoundContent={null}
                    onChange={this.handleAccountChange}
                    onSearch={this.handleAccountSearch}
                >
                    {this.accounts.map((account, i)=>(
                      <Select.Option key={`accounts-${i}`} value={account.id}>{account.firstName} {account.lastName}</Select.Option>
                    ))}
                    <Select.Option key='divider' className='divider' disabled={true}>&nbsp;</Select.Option>
                    <Select.Option value=''>None</Select.Option>
                    <Select.Option key='new'>New Account</Select.Option>
                </Select>
              </Form.Item>
              {editAccount && 
                  <Button onClick={this.handleEditAccount}>Edit</Button>
              }
            </Input.Group>
            <br />
            <br />
            <Button type="primary" htmlType="submit">Next</Button>
          </Card>
        </Form>
      </Fragment>
    )
  }
}

export default (
    withRouter(
        (Account)
    )
)
