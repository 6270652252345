import React from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Input, message, Tabs, Form, Card, Transfer, Select, Button, Switch} from 'antd'
import {
    genderActions
} from 'actions'
import _ from 'lodash'

class WidgetGenderChange extends React.Component {
    constructor(){
        super();
        this.state = {

        }
        this.handleGenderChange = this.handleGenderChange.bind(this)
    }   

    componentDidMount() {
      genderActions.fetch()
    }

    get activeGenderIDs(){
      return this.genders.filter((gender)=>gender.active).map((gender)=>gender.id)
    }
  
    get genders(){
      return Object.values(this.props.genders || []).map((gender = {})=>{
        let _gender = {...gender}
        _gender.key = gender.id
        _gender.title = `${gender.name}`
        return _gender
      })
    }

    async handleGenderChange(targetGenders){
      let currentActive = this.activeGenderIDs
      let genderIDs = []
  
      this.genders.forEach((gender)=>{
        if((targetGenders.includes(gender.id) || currentActive.includes(gender.id)) &&
          !(targetGenders.includes(gender.id) && currentActive.includes(gender.id))){
            genderIDs.push(gender.id)
        }
      })
      await genderActions.activate(genderIDs)
    }

    render() {
        return (
            <Row>
                <Col span={12}>
                    <br />
                    <Transfer 
                        titles={['Inactive', 'Active']}
                        listStyle={{width:'calc(50% - 25px)'}}
                        dataSource={this.genders}
                        targetKeys={this.activeGenderIDs}
                        render={item => item.title}
                        onChange={this.handleGenderChange}
                    />
                </Col>
            </Row>
        );
    }
}
export default withRouter(connect((state)=>state)(WidgetGenderChange))
