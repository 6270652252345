import React from 'react'
import { Form, Card} from 'antd'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import GeneralEditor from 'components/elements/GeneralEditor'
import Scroller from 'components/elements/Scroller'
import {AppointmentTypeForm} from 'components/partials'
import {
  appointmentTypeActions,
} from 'actions'

class Edit extends React.Component {
  constructor(){
    super()
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formRef = React.createRef()
    this.state = {
      officeHours:[]
    }
  }
  handleSubmit(values){
    Object.keys(values).forEach((key)=>{
      if(!values[key]){
        values[key] = null
      }
    })

    if(this.props.type === 'new'){
      appointmentTypeActions.create(values)
    }
    
    if(this.props.type === 'edit'){
      appointmentTypeActions.update({
        ...this.current,
        ...values
      })
    }
    this.props.history.push(`/admin/appointment-types/${this.current.id}`)
  }
  get current(){
    return this.props.current || {}
  }

  handleCancel(){
    if(this.current.id){
        this.props.history.push(`/admin/appointment-types/${this.current.id}`)
    } else {
        this.props.history.push(`/admin/appointment-types`)
    }
  }
  componentDidUpdate(previousProps){
    if(JSON.stringify(previousProps.current) !== JSON.stringify(this.props.current)){
      this.form && this.form.resetFields()
      this.setState(this.state)
    }
  }
  get form(){
    return this.formRef.current
  }
  render() {
    const initialValues = {
      name: this.current.name,
      description: this.current.description,
      partitionID: this.current.partitionID || '',
      userType: this.current.userType || '',
      spacer: this.current.spacer || 0,
      sendReminders: !!this.current.sendReminders,
    }
    return (
      <div className='edit-switch'>
        <Form ref={this.formRef} initialValues={initialValues}  onFinish={this.handleSubmit}>
          <GeneralEditor onCancel={this.handleCancel} onSave={true}>
            <Scroller>
              <Card bordered={false}>
                <AppointmentTypeForm 
                    formRef={this.formRef} 
                    appointmentType={this.current} 
                />
              </Card>
            </Scroller>
          </GeneralEditor>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.patients
  }
}

export default withRouter(
  (
    connect(mapStateToProps)(Edit)
  )
)