import React from 'react'
import { Card } from 'antd'
import './index.less'

class SectionHeader extends React.Component {
  render() {
    const classes = ['section-content']
    this.props.noPadding && classes.push('no-padding')
    this.props.noMargin && classes.push('no-margin')
    this.props.noMarginRight && classes.push('no-margin-right')
    !this.props.noHeader && classes.push('header')
    if(this.props.className) classes.push(this.props.className)

    return (
      <div className={classes.join(' ')}>
        <div className='orange-top' />
        {!this.props.noHeader && (
          <div className='section-content-header'>{this.props.title}</div>
        )}
        <div className='section-content-body'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default SectionHeader
