import React, {Component, Fragment} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { 
    Badge,
    Input,
    List,
    Avatar,
    Button,
    Skeleton,
    Row,
    Col,
    Select,
    Card,
    Tabs
} from 'antd';
const { TabPane } = Tabs;
import moment from 'moment'
import Scroller from 'components/elements/Scroller'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import AppointmentRequests from './AppointmentRequests'
import RiskAssessment from './RiskAssessment'
import Notes from './Notes'
import {
    syncActions,
    syncStatusActions,
    integrationConfigActions
} from 'actions'
import { apiClient } from 'lib';
import Display from './Display'
import './style.less'

const TextArea = Input.TextArea;

class IntegrationEntities extends Component {
    constructor(props){
        super(props)
        this.state = {
            initLoading: true,
            loading: false,
            data: [],
            list: [],
        };
        this.search = this.search.bind(this)
        this.type = this.type.bind(this)
        this.retranscribe = this.retranscribe.bind(this)
    }
    async componentDidMount(){
     //   await syncActions.fetch()
        this.syncInteval = setInterval(()=>{
            syncStatusActions.fetch()
        }, 10000)
        integrationConfigActions.fetch()
        
        this.setState({
            initLoading: false
        });
    }

    prepareOptions(){
        let options = {}
        if(this.state.query){
            options.contains = this.state.query
        }
        
        if(this.state.type && this.state.type !== 'all'){
            options.type = this.state.type
        }

        if(this.state.page){
            options.page = this.state.page
        }

        return options
    }
    
    async fetchData(){
        const response = await apiClient.organizations.operations.integrationEntities.fetch(this.prepareOptions())
        if(response){
            this.setState({
                data: response.data
            })
        }
    }
    get data(){
        return this.state.data.map((data)=>({
            ...data,
            entityData: JSON.parse(data.entityData)
        }))
    }
    search(e){
        if(e.keyCode == 13){
            this.fetchData()
        }else{
            this.setState({
                query: e.target.value 
            })
        }
    }
    type(type){
        this.setState({
            type
        })
    }
    processName(item){
        return (
            _.get(item, 'entityData.Name')
            || _.get(item, 'entityData.CalendarResource.Name') ? [
                moment(_.get(item, 'entityData.Date')).format('M/DD/YYYY h:mm a'),
                _.get(item, 'entityData.CalendarResource.Name')
            ].join(' ') : null
            || _.get(item, 'entityData.Description')
            || (
                [
                    _.get(item, 'entityData.User.FirstName'),
                    _.get(item, 'entityData.User.LastName')
                ].join(' ')
            )
        )
    }
    processSynced(item){
        let lastTranscribedAt;
        // if(item.lastTranscribedAt) {
        //     lastTranscribedAt = moment(item.lastTranscribedAt)
        // }else{
        //     return 'warning'
        // }

        // let lastFetchedAt;
        // if(item.lastFetchedAt) lastFetchedAt = moment(item.lastFetchedAt)

        // if(lastFetchedAt && lastFetchedAt.isSameOrAfter(lastTranscribedAt)) {
        //     return 'processing'
        // }

        if(item.state =='transcribing'){
            return 'warning'
        }
        
        if(item.state =='fetched'){
            return 'processing'
        }

        return 'success'
    }
    componentWillUnmount(){
        clearInterval(this.syncInteval)
    }
    get evetWorkers(){
        return [
            'Appointment Statuses',
            'Classifications',
            'Breeds',
            'Providers',
            'Genders',
            'Appointment Types',
            'Clients',
            'Patients',
            'Appointments',
            'Schedules',
            'PatientReminders'
        ]
    }
    get rhapsodyWorkers(){
        return [
            'Administrator Types',
            'Appointment Statuses',
            'Appointment Types',
            'Classifications',
            'Breeds',
            'Genders',
            'Providers',
            'Clients',
            'Patients',
            'Appointments'
        ]
    }
    get vetterNormalWorkers(){
        return [
            'Clients',
            'Patients',
            'Appointments'
        ]
    }
    get vetterDailyWorkers(){
        return [
            'Classifications',
            'Breeds',
            'Genders',
            'Providers',
            'Appointment Types'
        ]
    }
    get covetrusWorkers(){
        return [
            'Classifications',
            'Breeds',
            'Genders',
            'Appointment Types',
            'Providers',
            'Clients',
            'Patients',
            'Appointments',
            'Blockoff Resources'
        ]
    }
    get covetrusRepeatingWorkers(){
        return [
            'Generate',
            'Retranscribe'
        ]
    }
    get datapointWorkers(){
        return [
            'Appointment Types',
            'Classifications',
            'Breeds',
            'Genders',
            'Providers',
            'Clients',
            'Patients',
            'Appointments',
        ]
    }
    get datapointArchiverWorkers(){
        return [
            'Appointments'
        ]
    }
    workerName(name){
        if('AdministratorTypesWorker' == name) return 'Administrator Types'
        if('AppointmentStatusesWorker' == name) return 'Appointment Statuses'
        if('AppointmentTypesWorker' == name) return 'Appointment Types'
        if('AppointmentsFetchWorker' == name) return 'Appointments'
        if('RecurringAppointmentsWorker' == name) return 'Recurring Appointments'
        if('ResourceBlockoffsWorker' == name) return 'Resource Blockoffs'
        if('PatientRemindersWorker' == name) return 'Patient Reminders'
        if('RepeatingBlocksGenerateWorker' == name) return 'Generate'
        if('RepeatingBlocksRetranscribeWorker' == name) return 'Retranscribe'
            
        return `${name}`.replace('Worker', '')
    }
    get evetServerData(){
        return _.get(this, 'props.syncStatus.evetServer') || {}
    }
    get evetFetcherData(){
        return _.get(this, 'props.syncStatus.fetcher') || {}
    }
    get evetTranscriberData(){
        return _.get(this, 'props.syncStatus.transcriber') || {}
    }
    get rhapsodyFetcherData(){
        return _.get(this, 'props.syncStatus.rhapsodyFetcher') || {}
    }
    get rhapsodyTranscriberData(){
        return _.get(this, 'props.syncStatus.rhapsodyTranscriber') || {}
    }
    get vetterData(){
        return _.get(this, 'props.syncStatus.vetter') || {}
    }
    get covetrusFetcherData(){
        return _.get(this, 'props.syncStatus.covetrusFetchers') || {}
    }
    get covetrusTranscriberData(){
        return _.get(this, 'props.syncStatus.covetrusTranscribers') || {}
    }
    get covetrusRepeatingData(){
        return _.get(this, 'props.syncStatus.covetrusRepeating') || {}
    }
    get datapointFetcherData(){
        return _.get(this, 'props.syncStatus.datapointFetcher') || {}
    }
    get datapointTranscriberData(){
        return _.get(this, 'props.syncStatus.datapointTranscriber') || {}
    }
    get datapointArchiverData(){
        return _.get(this, 'props.syncStatus.datapointArchiver') || {}
    }
    get availabilitiesData(){
        return _.get(this, 'props.syncStatus.availabilities') || {}
    }
    get remindersData(){
        return _.get(this, 'props.syncStatus.reminders') || {}
    }
    get organizationRemindersData(){
        return _.get(this, 'props.syncStatus.organizationReminders') || {}
    }
    isCurrentFetcherWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.fetcher.current')
        ) == name
    }
    isCurrentSyncWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.sync.current')
        ) == name
    }
    isCurrentTranscriberWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.transcriber.current')
        ) == name
    }
    isCurrentVetterWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.vetter.current')
        ) == name
    }
    isCurrentCovetrusFetcherWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.covetrusFetchers.current')
        ) == name
    }
    isCurrentCovetrusTranscriberWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.covetrusTranscribers.current')
        ) == name
    }
    isCurrentRhapsodyFetcherWorker(name){
        console.log(name)
        return this.workerName(
            _.get(this, 'props.syncStatus.rhapsodyFetcher.current')
        ) == name
    }
    isCurrentRhapsodyTranscriberWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.rhapsodyTranscriber.current')
        ) == name
    }
    isCurrentCovetrusRepeatingWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.covetrusRepeating.current')
        ) == name
    }
    isCurrentDatapointFetchersWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.datapointFetcher.current')
        ) == name
    }
    isCurrentDatapointTranscribersWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.datapointTranscriber.current')
        ) == name
    }
    isCurrentDatapointArchiverWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.datapointArchiver.current')
        ) == name
    }
    isActive(type){
        return _.get(this, `props.integrationConfigs.${type}.active`) || _.get(this, `props.integrationConfigs.${type}.fetchActive`) || _.get(this, `props.integrationConfigs.${type}.transcribeActive`)
    }
    activeFor(item){
        if(item){
            const diff = moment.duration(moment().diff(item))
            const days = Math.round(diff.asDays())
            const hours = Math.round(diff.asHours())
            const minutes = Math.round(diff.minutes())
            const seconds = Math.round(diff.seconds())
            if(days > 1){
                return `${days} days`
            }
            if(hours > 1){
                return `${hours} hours`
            }
            if(minutes > 1){
                return `${minutes} minutes`
            }
            return `${seconds} seconds`
        }else{
            return false
        }
    }
    async retranscribe(){
        const response = await apiClient.organizations.operations.integrationEntities.retranscribe(this.prepareOptions())

    }
    render(){
        const { initLoading, loading } = this.state;
        const list = this.data

        const loadMore =
        !initLoading && !loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: 12,
              height: 32,
              lineHeight: '32px',
            }}
          >
            <Button onClick={this.onLoadMore}>loading more</Button>
          </div>
        ) : null;

        
        return (
            <Scroller>
                <Card bordered={false}>
                    <br />
                    <Input.Group compact>
                        <Select defaultValue="all" onChange={this.type} style={{width: 200}}>
                            <Select.Option value="all">
                                All
                            </Select.Option>
                            <Select.Option value="Clients">
                                Clients
                            </Select.Option>
                            <Select.Option value="ConfirmStatuses">
                                ConfirmStatuses
                            </Select.Option>
                            <Select.Option value="Appointments">
                                Appointments
                            </Select.Option>
                            <Select.Option value="AppointmentTypes">
                                AppointmentTypes
                            </Select.Option>
                            <Select.Option value="Breeds">
                                Breeds
                            </Select.Option>
                            <Select.Option value="Species">
                                Species
                            </Select.Option>
                            <Select.Option value="Sexes">
                                Sexes
                            </Select.Option>
                            <Select.Option value="Patients">
                                Patients
                            </Select.Option>
                            <Select.Option value="Providers">
                                Providers
                            </Select.Option>
                            <Select.Option value="CalendarResourceSchedules">
                                CalendarResourceSchedules
                            </Select.Option>
                            <Select.Option value="PatientReminders">
                                Patient Reminders
                            </Select.Option>
                        </Select>
                        <Input style={{ width: 'calc(100% - 200px)' }} placeholder={'Search data'} onKeyUp={this.search}/>
                        <Button onClick={this.retranscribe}>
                            Retranscribe
                        </Button>
                    </Input.Group>
                    <List
                        className="demo-loadmore-list"
                        loading={initLoading}
                        itemLayout="horizontal"
                        loadMore={loadMore}
                        dataSource={list}
                        renderItem={(item)=>{
                            return (
                                <List.Item
                                    actions={(
                                        <Fragment>
                                            <a key="list-loadmore-edit">Transcribe</a>
                                            <a key="list-loadmore-more">Options</a>
                                        </Fragment>
                                    )}
                                >
                                    <Skeleton avatar title={false} loading={item.loading} active>
                                    <List.Item.Meta
                                        title={
                                            <Row>
                                                <Col sm={8}>
                                                    <span className='operations-sync-type'>
                                                        {item.entityType}&nbsp;{item.entityID}
                                                    </span>
                                                </Col>
                                                <Col sm={8}>
                                                    <span className='operations-sync-name'>
                                                        {this.processName(item)}&nbsp;
                                                    </span>
                                                </Col>
                                                <Col sm={8}>
                                                    <span className='operations-sync-status'>
                                                        <Badge status={this.processSynced(item)} />
                                                    </span>
                                                </Col>
                                            </Row>
                                        }

                                        description={
                                            <div><Display item={item} /></div>
                                        }
                                    />
                                    </Skeleton>
                                </List.Item>
                            )
                        }}
                    />
                </Card>
            </Scroller>
        )
    }
}

export default connect((props)=>({
    data: props.sync,
    syncStatus: props.syncStatus,
    integrationConfigs: props.integrationConfigs
}))(IntegrationEntities)



