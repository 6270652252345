import React, {Component, Fragment} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import { 
    Input,
    Card,
    List,
    Row,
    Col
} from 'antd';
import moment from 'moment'
import Scroller from 'components/elements/Scroller'
import {
    administratorActions,
    organizationActions
} from 'actions'
import { apiClient } from 'lib';
import { Link, withRouter } from 'react-router-dom';

class Notes extends Component {
    constructor(props){
        super(props)
        this.state = {
            notes: []
        };

        this.fetchData = this.fetchData.bind(this)
        this.createNote = this.createNote.bind(this)
        this.formRef = React.createRef()
    
        this.markAsReadNote = this.markAsReadNote.bind(this)
        this.resolvedNote = this.resolvedNote.bind(this)
        this.handleOrganizationSelect = this.handleOrganizationSelect.bind(this)
    }
    
    async fetchData(){    
        const response = await apiClient.organizations.operations.notes.fetch({
            markedAsRead: false,
            orderCreatedAt: 'desc'
        })
        if(response){
            this.setState({
                notes: response.data
            })
        }
    }
    componentDidMount(){
        this.fetchData()
        administratorActions.fetch()
    }
    async createNote(values){
        values = JSON.parse(JSON.stringify(values))
        this.formRef.current.setFieldsValue({
            content: ''
        })
        const response = await apiClient.organizations.operations.notes.create(values)
        if(response){
            this.fetchData()

        }
    }
    
    async markAsReadNote(id){
        // console.log(id)
        const response = await apiClient.organizations.operations.notes.markAsRead({id})

        if(response){
            this.fetchData()

        }
    }
    
    async resolvedNote(id){
        // console.log(id)
        const response = await apiClient.organizations.operations.notes.resolved({id})

        if(response){
            this.fetchData()

        }
    }

    get notes(){
        return this.state.notes || []
    }

    itemResolvedBy(item){
        return _.get(this, `props.administrators[${item.markedResolvedByID}]`, {})
    }

    handleChangeOrganization(organization){
        organizationActions.changeCurrent(organization.id)
        this.props.history.push('/admin')
    }

    
    handleOrganizationSelect(organization, e){
        e.preventDefault()
        this.handleChangeOrganization(organization)
        this.props.history.push('/admin/operations')
    }

    render(){
        return (
            <Fragment>
                <Card bordered={false}>
                    <h3>Issues</h3>
                    <Card bodyStyle={{padding: "0"}} noPadding={true}>
                        <Scroller>
                            <List
                                className="demo-loadmore-list"
                                loading={false}
                                itemLayout="horizontal"
                                //loadMore={loadMore}
                                dataSource={this.notes}
                                renderItem={(item)=>{
                                    return (
                                        <List.Item
                                            actions={([
                                                <a key="list-loadmore-mark-as-read" onClick={this.markAsReadNote.bind(null, item.id)}>
                                                    {Object(item.readByAccountIDs)[_.get(this, 'props.session.account.id')] ? "Mark as UnRead" : "Mark as Read"}
                                                </a>,
                                                item.markedResolvedByID ? (
                                                    `${this.itemResolvedBy(item).firstName} ${this.itemResolvedBy(item).lastName}`
                                                ):(
                                                    <a key="list-loadmore-resolved" onClick={this.resolvedNote.bind(null, item.id)}>
                                                        resolved
                                                    </a>
                                                )
                                            ])}
                                        >
                                            {/* <Skeleton avatar title={false} active> */}
                                            <List.Item.Meta
                                                title={
                                                    <Row>
                                                        <Col sm={12} style={{paddingLeft: '1em'}}>
                                                                <Link onClick={this.handleOrganizationSelect.bind(null, _.get(item, 'organization', {}))}>
                                                                    {_.get(item, 'organization').name}
                                                                </Link>
                                                                {_.get(item, 'administrator.account').firstName}
                                                                {_.get(item, 'administrator.account').lastName}
                                                        </Col>
                                                        <Col sm={12}>
                                                            {moment(item.createdAt).format('M/DD/YY h:mm a')}
                                                        </Col>
                                                    </Row>
                                                }

                                                description={
                                                    <div style={{marginLeft: '1em'}}>
                                                        {item.content}
                                                        {item.issueID &&
                                                            <div>
                                                                Issue: <a href={`https://github.com/next-in-line/ocean/issues/${item.issueID}`} target="_blank">
                                                                    {item.issueID}
                                                                </a>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    )
                                }}
                            />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </Scroller>
                    </Card>
                </Card>
            </Fragment>
        )
    }
}

export default withRouter(connect(({session, administrators})=>({session, administrators}))(Notes))