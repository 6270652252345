import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Input, Select, Button } from 'antd';
import {
    companyActions
} from 'actions'

class CompanyForm extends Component {
    constructor(){
        super();
        this.state = {
          officeHours:[],
          visible: false,
          createCompanyVisible: false,
          companySearch: '',
          createOrEditSpeciesVisible: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDestroyCompany = this.handleDestroyCompany.bind(this)
        this.companySearchTimeout = ''
        this.handleCancel = this.handleCancel.bind(this)
        this.handleNewCompany = this.handleNewCompany.bind(this)
    }
    async handleSubmit(values){
        if(this.company.id){
            await companyActions.update({
                id: this.company.id,
                ...values
            })
            this.props.onClose()
        }else{
            const company = await companyActions.create({
                ...values
            })
            this.props.onSetCurrentCompany(company.id)
            this.props.onClose()
        }
    }
    async handleDestroyCompany(e){
        e.preventDefault()
        
        await companyActions.destroy(this.company.id)
        this.props.onSetCurrentCompany('')
        this.props.onClose()
    }
    get company(){
        return {
            ...(this.props.company || {})
        }
    }
    get form() {
        return Object(this.props.formRef).current
    }
    handleNewCompany(value){
        if(value === 'new'){
            this.setState({
                createCompanyVisible: true
            })
            setTimeout(()=>{
                // Huge Hack
                // Huge Hack
                // Huge Hack
                // Huge Hack
                this.form && this.form.setFieldsValue({companyID: ''});
            }, 1)
        }
    }
 
    handleCancel(){
        if(this.current.id){
            this.props.history.push(`/admin/companies/${this.current.id}`)
        } else {
            this.props.history.push(`/admin/companies`)
        }
    }
    render(){
        return (
            <div> 
                <Form.Item 
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input Company name!' }]}
                >
                    <Input placeholder="Name of Company" />
                </Form.Item>
                <Form.Item 
                    label="Description"
                    name="description"
                >
                    <Input.TextArea placeholder="Description" />
                </Form.Item>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
}

export default withRouter(
    (
        connect(mapStateToProps)(CompanyForm)
    )
)
