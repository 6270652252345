import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';

import store from 'store'

export const fetch = async(params = {})=>{
    if(_.get(store.getState(), 'currentOrganization.id')){
        const response = await apiClient.organizations.manualWorkerExecutions.fetch({})

        store.dispatch({ 
            type: ActionTypes.FETCH_MANUAL_WORKER_EXECUTIONS,
            payload: response.data
        })
    }
}

export const create = async(params = {}) => {
    const response = await apiClient.organizations.manualWorkerExecutions.create(params)

    fetch()

    return response.data
}

export const cancel = async(params = {}) => {
    const response = await apiClient.organizations.manualWorkerExecutions.cancel(params)

    fetch()

    return response.data
}

export const retry = async(params = {}) => {
    const response = await apiClient.organizations.manualWorkerExecutions.retry(params)

    fetch()

    return response.data
}