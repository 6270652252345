import React from 'react'
import { Card } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Scroller from 'components/elements/Scroller'

class Show extends React.Component {
  constructor(props){
    super(props)
    this.handleClose = this.handleClose.bind(this)
  }
  
  get current(){
    return this.props.current
  }

  handleClose(){
  }

  render() {
    return (
      <Scroller>
        <Card bordered={false}>
          <div className='edit-switch'>
            <Link to={`/admin/sexes/${this.props.current.id}/edit`}>Edit</Link>
            
            <h2 className='text-center'>
              {this.current.name}
            </h2>
            <br />
            {this.current.description &&
              <div>
                <label>Description:</label> {this.current.description}
                <br />
                <br />
              </div>
            }
            <label>ID:</label> {this.current.id} 
            <br />
            <br />
          </div>
        </Card>
      </Scroller>
    )
  }
}

export default withRouter(connect((state)=>(state))(Show))