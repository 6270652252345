import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import moment from 'moment'
import {genderActions} from 'actions'
import {connect} from 'react-redux'
const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;

class CreateOrEditGender extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createAppointmentTypeVisible: false,
      patientsUsers: []
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDestroyGender = this.handleDestroyGender.bind(this)
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  get account(){
    return this.props.account || {}
  }

  async handleSubmit (values) {
    const _gender = {
        name: values.name,
        description: values.description
    }
    let gender
    if(this.gender.id){
      gender = await genderActions.update({
          id: this.gender.id,
          ..._gender
      })
    }else{
      gender = await genderActions.create(_gender)
    }
    this.props.onSetCurrentGender(gender.id)
    this.props.onClose()
  }

  get form() {
    return this.formRef.current
  }

  get gender(){
    const _gender = this.props.gender || {}
    
    return {
      id: _gender.id,
      name: _gender.name,
      description: _gender.description,
    }
  }

  componentDidUpdate(nextProps){
    if(this.props.visible && !nextProps.visible){
      this.form && this.form.resetFields();
    }
  }

  async handleDestroyGender(e){
    e.preventDefault()

    await genderActions.destroy(this.gender.id)
    this.props.onClose()
  }

  handleNewSex(value){
    if(value === 'new'){
      this.setState({
        createOrEditSexVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({gender: ''});
      }, 1)
    }
  }

  render() {

    const { 
      name,
      description
    } = this.gender

    const initialValues = {
      name,
      description
    }

    let edit = false;
    let editSex = false;
    
    if(this.gender.id){
      edit = true
    }
    if(this.form && this.form.getFieldValue('genderID') !== ''){
      editSex = true
    }
    return (
      <Drawer
        title={this.gender.id ? 'Edit Gender' : 'Create Gender'}
        width={520}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <Form initialValues={initialValues} ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Name"
                name="name"
                rules={[{ message: 'Please select an address' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Description"
                name="description"
                rules={[{ message: 'Please select an address' }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyGender} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Appointment</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.gender.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  (CreateOrEditGender)
)