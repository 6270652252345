import React from 'react'
import ReactMaskedInput from 'react-text-mask'
import {Input} from 'antd'

export default class MaskedInput extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            value: props.value
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }
    
    process(value){
        switch(this.props.type){
            case 'phone':
                value = value.replace(/\D/g, "")
                value = value.split("")
        
                if(value.length > 3){
                    value.splice(3, 0, '-')
                }
        
                if(value.length > 7){
                    value.splice(7, 0, '-')
                }
        
                if(value.length < 13){
                    return value.join('')
                }
        }
        return false
    }
    handleChange(e){
        let value = e.target.value
        
        if(this.process(value) !== false){
            value = this.process(value)
            
            this.setState({
                value
            }, ()=>
                this.props.onChange(this.fakeEvent)
            )
        }else{
            this.setState({
                value
            }, ()=>
                this.props.onChange(this.fakeEvent)
            )
        }
    }
    handleValidation(){
        let value = this.state.value

        if((this.props.types || []).includes('phone') && !value.match(/\D/g)){
            value = value.replace(/\D/g, '')
            value = value.split('')
            
            
            if(value.length > 3){
                value.splice(3, 0, '-')
            }
            
            if(value.length > 7){
                value.splice(7, 0, '-')
            }
            
            if(value.length < 13){
                this.setState({
                    value: value.join('')
                }, ()=>{
                    this.props.onChange(this.fakeEvent)
                })
            }
        }
    }
    get fakeEvent(){
        return {
            preventDefault: ()=>{},
            target: this.inputElement.input
        }
    }
    componentDidUpdate(previousProps = {}){
        if(this.props.value !== previousProps.value){
            this.setState({
                value: this.props.value
            })
        }
    }
    render(){
        return (
            <Input
                {...this.props}
                value={this.state.value}
                onChange={this.handleChange}
                onBlur={this.handleValidation}
                ref={(a)=>{ this.inputElement = a}}
            />
        )
    }
}

