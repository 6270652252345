import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import moment from 'moment'
import {waitListMessageActions} from 'actions'
import {connect} from 'react-redux'
import {apiClient} from 'lib'
const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;

class CreateOrEditWaitListMessage extends React.Component {
    constructor(){
        super();
        this.formRef = React.createRef()
        this.state = { 
            visible: false,
            createAppointmentTypeVisible: false,
            createOrEditAccountVisible: false,
            patientsUsers: []
        };
        this.search = this.search.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAccountSearch = this.handleAccountSearch.bind(this)
        this.handleAccountChange = this.handleAccountChange.bind(this)
        this.handleEditAccount = this.handleEditAccount.bind(this)
    }

    componentDidUpdate(previousProps){
        if(previousProps.visible != this.props.visible){
            this.form && this.form.resetFields();
            this.setState(this.state)
        }
    }

    async handleSubmit (values) {
        let date = moment(values.date)
        let startsAt = moment(values.startTime).date(date.date()).month(date.month()).year(date.year())
        let endsAt = moment(values.endTime).date(date.date()).month(date.month()).year(date.year())
        const _waitListMessage = {
            administratorID: values.administratorID,
            appointmentTypeID: values.appointmentTypeID,
            startsAt,
            endsAt
        }
        if(this.waitListMessage.id){
            waitListMessageActions.update({
                id: this.waitListMessage.id,
                ..._waitListMessage
            })
        }else{
            let waitListMessage = await waitListMessageActions.create(_waitListMessage)
        }
        this.props.onClose()
    }

    handleAccountChange(value){
        if(value === 'new'){
            this.setState({
                createOrEditAccountVisible: true
            })
            setTimeout(()=>{ 
                // Huge Hack
                // Huge Hack
                // Huge Hack
                // Huge Hack
                this.form && this.form.setFieldsValue({accountID: ''});
            }, 1)
        }

    }

    async search(search = ''){
        const {data} = await apiClient.organizations.accounts.fetch({limit:5, search})
    
        this.setState({
            accounts: data
        })
    }

    handleAccountSearch(value){
        if(this.accountSearchTimeout){
            clearTimeout(this.accountSearchTimeout)
        }
        this.accountSearchTimeout = setTimeout(()=>{this.search(value)},1000)
    }

    handleEditAccount(){
        this.setState({
            createOrEditAccountVisible: true
        })
    }
    
    get form() {
        return this.formRef.current
    }

    get account(){
        return this.props.account || {}
    }

    get waitListMessage(){
        let _waitList = {
            ...this.props.waitListMessage
        }
        if(_waitList.startsAt){
            _waitList.startsAt = moment(_waitList.startsAt)
        }
        if(_waitList.endsAt){
            _waitList.endsAt = moment(_waitList.endsAt)
        }
        return _waitList
    }

    get appointmentTypes(){
        return Object.values(this.props.appointmentTypes || {}).filter((appointmentType)=>(appointmentType.active))
    }

    get accounts(){
        let accounts = this.state.accounts || Object.entries(this.props.accounts || {}).map(([_, a])=> a)
        if(this.waitListMessage.account &&! _.find(accounts, (a)=>(a.id == this.waitListMessage.account.id))){
            accounts.push(this.waitListMessage.account)
        }
        return accounts
    }

    get administrators(){
        return Object.values(this.props.administrators || {})
    }

    render() {
        const initialValues = {
            accountID: (this.waitListMessage.account || {}).id || undefined,
            appointmentTypeID: this.waitListMessage.appointmentTypeID || undefined,
            date: this.waitListMessage.startsAt || undefined,
            startTime: this.waitListMessage.startsAt || undefined,
            endTime: this.waitListMessage.endsAt || undefined
        }

        let edit = false
        if(this.waitListMessage.id){
            edit = true
        }

        return (
            <Drawer
                title={edit ? 'Edit Wait List Message' : 'Create Wait List Message'}
                width={520}
                placement="right"
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
                <Form layout="vertical" ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Row>
                                <Col span={8}>
                                    <Form.Item 
                                        label="Date"
                                        name="date"
                                        rules={[{ required: true, message: 'Please select a start date' }]}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item 
                                        label="Start Time"
                                        name="startTime"
                                        rules={[{ required: true, message: 'Please choose the start time' }]}
                                    >
                                        <TimePicker use12Hours format="h:mm A" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item 
                                        label="End Time"
                                        name="endTime"
                                        rules={[{ required: true, message: 'Please choose the end time' }]}
                                    >
                                        <TimePicker use12Hours format="h:mm A" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label="Appointment Type"
                                name="appointmentTypeID"
                                rules={[{ message: 'Please select an appointment type' }]}
                            >
                                <Select
                                    style={{ width: '100%'}}
                                    placeholder="Select Appointment Type"
                                    key={`select-appointment-type`}
                                >
                                    {this.appointmentTypes.map((appointmentType)=>{
                                        return (
                                            <Select.Option value={`${appointmentType.id}`} key={`appointment-type-select_${appointmentType.id}`}>
                                                {appointmentType.name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label="Administrator"
                                name="administratorID"
                                rules={[{ message: 'Please select an administrator' }]}
                            >
                                <Select
                                    style={{ width: '100%'}}
                                    placeholder="Select Administrator"
                                    key={`select-administrator`}
                                >
                                    {this.administrators.map((administrator)=>{
                                        return (
                                            <Select.Option value={`${administrator.id}`} key={`administrator-select_${administrator.id}`}>
                                                {administrator.firstName} {administrator.lastName}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={this.props.onClose}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">{edit ? 'Update' : 'Create' }</Button>
                    </div>
                </Form>
            </Drawer>
        );
    }
}
export default connect((state)=> state)(
  (CreateOrEditWaitListMessage)
)