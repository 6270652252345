import React from 'react'
import { Modal } from 'antd'
import { TableRowEditButton } from 'components/elements/AppButton'
import {SnapList} from 'components/elements/ListEditor'
import {withRouter} from 'react-router-dom'

class AccountCards extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleDeleteButtonOnClick = this.handleDeleteButtonOnClick.bind(this)
  }

  handleDeleteButtonOnClick() {
    const handleTableOnDelete = () => { this.props.onDelete() }
    Modal.confirm({
      title: 'Delete Item',
      content: 'Are you sure to delete this item?',
      onOk() {
        handleTableOnDelete()
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  
  get dataSource() {
    return this.props.dataSource || {}
    // return Object.values(this.props.dataSource).sort((a,b)=>{
    //   if(a.lastName > b.lastName) return 1
    //   if(a.lastName < b.lastName) return -1
    //   return 0
    // }) || []
  }

  render() {
    return (
      <SnapList
        data={this.dataSource}
        listType={'Accounts'}
        search={this.props.search || ''}
        sortBy={'lastName'}
        initial={this.props.match.params.id}
        onSelect={this.props.onSelect}
        onCreate={this.props.onCreate}
        onSearch={this.props.onSearch}
        row={(props)=>(
          <div>
            {props.firstName} {props.lastName}
            <br />
            {props.email || props.phone}
          </div>
        )}
      />
    )
  }
}

export default withRouter(AccountCards)