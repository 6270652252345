import React from 'react';
import { withRouter } from 'react-router-dom'
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker, Switch } from 'antd';
import moment from 'moment'
import {serviceTimeActions} from 'actions'
import {connect} from 'react-redux'
import _ from 'lodash'

const {Option} = Select;

class CreateOrEditServiceTime extends React.Component {
  constructor(){
      super();
      this.formRef = React.createRef()
      this.state = { 
          visible: false
      };
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleDestroyServiceTime = this.handleDestroyServiceTime.bind(this)
      this.handleChanges = this.handleChanges.bind(this)
  }

  componentDidUpdate(prevProps){
    if(!this.props.visible && prevProps.visible){
      const serviceTime = Object.keys(this.serviceTime).reduce((acc, key)=>({
        ...acc,
        [`${key}`]: undefined
      }), {})
      this.form.setFieldsValue(serviceTime)
    }
    if(JSON.stringify(this.props.serviceTime) != JSON.stringify(prevProps.serviceTime)){
      this.setState(this.state, ()=>{
        this.form && this.form.resetFields();
      })
    }
  }

  get serviceTime(){
    const _event = this.props.serviceTime || {}

    let sequence = (this.form && this.form.getFieldValue('sequence')) 
    if(_.isNil(sequence) && _event.startsAt) sequence = _event.sequence
    
    let dayOfMonth = (this.form && this.form.getFieldValue('dayOfMonth'))
    if(_.isNil(dayOfMonth) && _event.startsAt) dayOfMonth = _event.dayOfMonth

    let dayOfWeek = (this.form && this.form.getFieldValue('dayOfWeek'))
    if(_.isNil(dayOfWeek) && _event.startsAt) dayOfWeek = _event.dayOfWeek

    let repeatingType = (this.form && this.form.getFieldValue('repeatingType'))
    if(_.isNil(repeatingType) && _event.startsAt) repeatingType = this.repeatingType

    let skipType = (this.form && this.form.getFieldValue('skipType'))
    if(_.isNil(skipType) && _event.startsAt) skipType = _event.skipType

    let skipValue = (this.form && this.form.getFieldValue('skipValue'))
    if(_.isNil(skipValue) && _event.startsAt) skipValue = _event.skipValue

    let repeatsUntil = (this.form && this.form.getFieldValue('repeatsUntil'))
    if(_.isNil(repeatsUntil) && _event.startsAt) repeatsUntil = _event.repeatsUntil
    
    let repeatsUntilType = (this.form && this.form.getFieldValue('repeatsUntilType'))
    if(_.isNil(repeatsUntilType) && _event.startsAt) repeatsUntilType = _event.repeatsUntil ? 'end' : ''

    return {
      id: _event.id,
      sequence,
      dayOfMonth,
      dayOfWeek,
      repeatingType,
      skipType,
      skipValue,
      repeatsUntil, 
      repeatsUntilType, 
      date: (this.form && this.form.getFieldValue('date')) ||  _event.startsAt, 
      startsAt: (this.form && this.form.getFieldValue('startsAt')) ||  _event.startsAt,
      endsAt: (this.form && this.form.getFieldValue('endsAt')) || _event.endsAt
    }
  }

  showDrawer () {
      this.setState({
          visible: true,
      });
  };

  async handleSubmit (values) { 
    const date = moment(values.date).date()
    const month = moment(values.date).month()
    const year = moment(values.date).year()

    const startsAt = moment(values.startsAt).date(date).month(month).year(year)
    const endsAt = moment(values.endsAt).date(date).month(month).year(year)
    
    let _serviceTime = {
      startsAt,
      endsAt,
      appointmentTypeID: this.props.match.params.id,
      repeating: true,
      sequence: null,
      dayOfMonth: null,
      dayOfWeek: null,
      skipType: null,
      skipValue: null,
    }
    
    switch(values.repeatingType){
      case 'daily':
        _serviceTime.dayOfWeek = 'Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday'
        break;
      case 'weekday':
        _serviceTime.dayOfWeek = 'Monday,Tuesday,Wednesday,Thursday,Friday'
        break;
      case 'weekly':
        _serviceTime.dayOfWeek = this.dayOfWeek
        break;
      case 'nthOfMonth':
        _serviceTime.dayOfMonth = date
        break;
      case 'nthDayOfMonth':
        _serviceTime.dayOfWeek = this.dayOfWeek
        _serviceTime.sequence = Math.ceil(date / 7)
        break;
      default:
        _serviceTime.repeating = false
    }

    switch(values.skipType){
      case 'week':
        _serviceTime.skipValue = 1
        _serviceTime.skipType = 'week'
        break;
      case 'month':
        _serviceTime.skipValue = 1
        _serviceTime.skipType = 'month'
        break;
      case 'xWeeks':
        _serviceTime.skipValue = values.skipValue
        _serviceTime.skipType = 'week'
        break;
      case 'xMonths':
        _serviceTime.skipValue = values.skipValue
        _serviceTime.skipType = 'month'
        break;
    }

    if(values.repeatsUntil){
      _serviceTime.repeatsUntil = values.repeatsUntil.format()
    } else {
      _serviceTime.repeatsUntil = null
    }

    if(this.serviceTime.id){
      await serviceTimeActions.update({
          id: this.serviceTime.id,
          ..._serviceTime
      })
    }else{
      await serviceTimeActions.create(_serviceTime)
    }
    this.props.onClose(true)
  }


  async handleDestroyServiceTime(e){
    e.preventDefault()

    await serviceTimeActions.destroy({
        id: this.serviceTime.id,
        appointmentTypeID: this.props.match.params.id
    })
    this.props.onClose(true)
  }

  
  get repeatingType(){
    const serviceTime = this.props.serviceTime || {}
    if(!serviceTime.repeating){
      return ''
    }
    if(serviceTime.sequence){
      return 'nthDayOfMonth'
    }
    if(serviceTime.dayOfWeek){
      let days = serviceTime.dayOfWeek.split(',')
      if(days.length == 7){
        return 'daily'
      }
      if(days.length == 5){
        return 'weekday'
      }
      if(days.length == 1){
        return 'weekly'
      }
    }
    if(serviceTime.dayOfMonth){
      return 'nthOfMonth'
    }
    
    return '' 
  }


  get dayOfWeek(){
    return moment(this.serviceTime.date).format('dddd')
  }

  get dateOfMonth(){
    return moment(this.serviceTime.date).format('Do')
  }

  get nthDay(){
    let nth = Math.ceil(moment(this.serviceTime.date).date() / 7)
    switch(nth){
      case 1:
        return `${nth}st`
      case 2:
        return `${nth}nd`
      case 3:
        return `${nth}rd`
      default:
        return `${nth}th`
    }
  }

  get skipOptions(){
    const repeatingType = this.serviceTime.repeatingType
    let skipOptions;
    if('weekly,daily.weekday'.includes(repeatingType)){
      skipOptions = [
        {value:'week',text:"Skip Every Other Week"},
        {value:'xWeeks',text:"Skip Every X Week"}
      ]
    }
    if('nthDayOfMonth,nthOfMonth'.includes(repeatingType)){
        skipOptions = [
          {value:'month',text:'Skip Every Other Month'},
          {value:'xMonths',text:'Skip Every X Month'}
        ]
    }
   
    if(!repeatingType){
      skipOptions = []
    }

    return skipOptions
  }

  get specificDate(){
    if(this.form && this.form.getFieldValue('repeatsUntil') == 'end'){
      return true
    }
    return false
  }

  get form() {
    return this.formRef.current
  }
  
  handleChanges(){
    if(!(this.form.getFieldValue('skipType') || '').includes('x')){
      this.form.setFieldsValue({'skipValue': 1})
    }
    this.setState(this.state, ()=>{
      this.form.resetFields()
    })
  }

  get skipType(){
    const serviceTime = this.props.serviceTime || {}

    const skipType = this.form && this.form.getFieldValue('skipType')

    if(`${skipType}`.includes('x') || (serviceTime.skipValue && serviceTime.skipValue > 1)){
      const skipType = _.capitalize(serviceTime.skipType)
      return `x${skipType}s`
    }else{
      return `${serviceTime.skipType || ''}`
    }
  }

  render() {
    // check later

    const initialValues = {
      repeatingType: this.serviceTime.repeatingType,
      skipType: this.serviceTime.skipType,
      skipValue: this.serviceTime.skipValue || 2,
      repeatsUntil: this.serviceTime.repeatsUntil ? moment(this.serviceTime.repeatsUntil) : moment(),
      repeatsUntilType: this.serviceTime.repeatsUntilType
    }
    
    if(this.serviceTime.startsAt){
      initialValues.startsAt = moment(this.serviceTime.startsAt);
    }
    if(this.serviceTime.date){
      initialValues.date = moment(this.serviceTime.date);
    }
    if(this.serviceTime.endsAt){
      initialValues.endsAt = moment(this.serviceTime.endsAt);
    }

    let edit = false;
    
    if(this.serviceTime.id){
      edit = true
    }

    return (
      <Drawer
        title={edit ? 'Edit Service Time' : 'Create Service Time'}
        width={520}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <Form 
          layout="vertical" 
          ref={this.formRef} 
          initialValues={initialValues} 
          onFinish={this.handleSubmit}
          onValuesChange={this.handleChanges}
        >
        <Row gutter={16}>
            <Col span={8}>
              <Form.Item 
                label="Date"
                name="date"
                rules={[{ required: true, message: 'Please select a start date' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="Start Time"
                name="startsAt"
                rules={[{ required: true, message: 'Please choose the start time' }]}
              >
                <TimePicker use12Hours format="h:mm A" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="End Time"
                name="endsAt"
                rules={[{ required: true, message: 'Please choose the end time' }]}
              >
                <TimePicker use12Hours format="h:mm A" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item 
                label="Repeating"
                name="repeatingType"
                rules={[{ message: 'Please select a classification' }]}
              >
                <Select>
                  <Option value=''>Does Not Repeat</Option>
                  <Option value='daily'>Repeat Daily</Option>
                  <Option value='weekday'>Repeat Every Weekday (Monday - Friday)</Option>
                  <Option value='weekly'>Repeat Every {this.dayOfWeek}</Option>
                  <Option value='nthOfMonth'>Repeat on the {this.dateOfMonth} of every month</Option>
                  <Option value='nthDayOfMonth'>Repeat Monthly on the {this.nthDay} {this.dayOfWeek}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {this.repeatingType && (
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item 
                  label="Skip"
                  name="skipType"
                >
                  <Select>
                    <Option value=''>Does Not Skip</Option>
                    {this.skipOptions.map((skip,i)=>{
                      return (
                        <Option key={`skip-${i}`} value={skip.value}>{skip.text}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {['xMonths', 'xWeeks'].includes(this.skipType) &&
                <Col span={4}>
                  <Form.Item 
                    label="X"
                    name="skipValue"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              }
            </Row>
          )}
          {this.repeatingType && (
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item 
                  label="Repeats Until"
                  name="repeatsUntilType"
                >
                  <Select>
                    <Option value=''>Does Not End</Option>
                    <Option value='end'>Repeat Until a Specific Date</Option>
                  </Select>
                </Form.Item>
              </Col>
              {!!this.serviceTime.repeatsUntilType &&
                <Col span={8}>
                  <Form.Item 
                    label="End Date"
                    name="repeatsUntil"
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
              }
            </Row>
          )}
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyServiceTime} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Service Time</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.serviceTime.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}

export default withRouter(connect((state)=>({
  store: state
}))(
  CreateOrEditServiceTime
))