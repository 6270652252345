import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const ReadyRoute = (props) => {
  const {component: Component} = props
  console.log(props)
  return (
    props.isSetup ? (
      <Redirect to={{ pathname: '/setup' }} />
    ):(
      <Component {...props} />
    )
  )
}

export default connect((state) => {
  const account = _.get(state, 'session.account', {})
  const accountSetup = (
    account.firstName 
    && account.lastName
    && (
      account.email || account.mobilePhone
    )
  )

  const isSetup = (
    !accountSetup || Object.keys(_.get(state, 'organizations', {})).length == 0
  )

  return { isSetup }
})(ReadyRoute)
