import Collection from 'lib/client/collections/collection'

class Restrictions extends Collection{
    fetch(options){
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/restrictions`, options);
    }

    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/restrictions`, options);
    }

    update(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/restrictions/${options.id}`, options);
    }
    
    destroy(id){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/restrictions/${id}`);
    }
}

export default Restrictions;