import React, {Component} from 'react'
import {Drawer} from 'antd'

export default class Sexes extends Component {
    constructor(){
        super()
        this.state = {}
    }
    render(){
        return (
            <Drawer
                title="Sexes"
                placement="right"
                width={700}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{maxWidth: 800, width: '100%'}}
            >
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Drawer>
        )
    }
}