import Collection from 'lib/client/collections/collection'
import Patients from './patients'

class Account extends Collection{
    fetch(options){
        let id = ''
        if(options.id) id = `/${options.id}`
        delete options.id
        
        let params = ''
        
        Object.keys(options).forEach((key)=>{
            if(!params){
                params = "?"
            } else {
                params += "&"
            }
            params += `${key}=${options[key]}` 
        })
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/accounts${id}${params}`);
    }

    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/accounts`,{
            ...options
        });
    }

    update(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/accounts/${options.id}`,{
            ...options
        });
    }

    requestToken(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/accounts/${options.id}/request-token`);
    }

    get patients(){
        return new Patients(this.options)
    }
}

export default Account;