import {
  CREATE_ORGANIZATION,
  LOGIN_ACCOUNT,
  LOGOUT_ACCOUNT,
  UPDATE_ORGANIZATION,
  SET_ALL_ORGANIZATIONS
} from '../constants/actionTypes'

export default (state = [], action) => {
    switch (action.type) {
        case LOGIN_ACCOUNT:
            let _organizations = (action.organizations || []).reduce((acc,org)=>{
                acc[org.id] = org
                return acc
            },{})

            return _organizations
        case CREATE_ORGANIZATION:
            if(action.value && action.value.id){
                return {
                    ...Object(state), 
                    [action.value.id]: action.value
                }
            }else{
                return state
            }        
        case UPDATE_ORGANIZATION:
            if(action.payload){
                return {
                    ...state,
                    [`${action.payload.id}`]: action.payload
                }
            }else{
                return state
            }
        case SET_ALL_ORGANIZATIONS:
            if(action.payload){
                let _organizations = (action.payload || []).reduce((acc,org)=>{
                    acc[org.id] = org
                    return acc
                },{})
                return _organizations
            }else{
                return state
            }
        case LOGOUT_ACCOUNT:
            return ''
        default:
            return state
    }
}
