import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import moment from 'moment'
import store from '../store'
import processRepeating from '../lib/processRepeating'

const getRepeatingRestrictions = (responseData)=>{
  let data = processRepeating({
    data: responseData,
    startingAt: moment(),
    endingAt: moment().add(6,'months'),
    getPastItems: true
  })

  data = data.reduce((acc, item)=>{
    acc[item.repeatingID || item.id] = {
        ...item,
        startsAt: moment(item.startsAt),
        endsAt: moment(item.endsAt)
    }
    return acc;
  }, {})
  
  data = {
    ...data
  }

  return data
}

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.restrictions.fetch(params)

  let data = response.data
  if(!params.skipRepeating){
    data = getRepeatingRestrictions(data)
  }

  store.dispatch({
    type: ActionTypes.FETCH_RESTRICTIONS,
    payload: data
  })

  return response.data
}

export const update = async (params = {}) => {
  const response = await apiClient.organizations.restrictions.update(params)

  const data = getRepeatingRestrictions([response.data])

  store.dispatch({
    type: ActionTypes.APPEND_RESTRICTION,
    payload: data
  })

  return response.data
}

export const create = async (params = {}) => {
  const response = await apiClient.organizations.restrictions.create(params)

  const data = getRepeatingRestrictions([response.data])

  store.dispatch({
    type: ActionTypes.APPEND_RESTRICTION,
    payload: data
  })
  
  return response.data
}

export const destroy = async (params = {}) => {
  await apiClient.organizations.restrictions.destroy(params.id)

  store.dispatch({
    type: ActionTypes.DESTROY_RESTRICTION,
    payload: params.id
  })
}