const workerNames = [
    {
        value: 'ClassificationsWorker',
        label: 'Classifications'
    },
    {
        value: 'BreedsWorker',
        label: 'Breeds'
    },
    {
        value: 'GendersWorker',
        label: 'Genders'
    },
    {
        value: 'AppointmentTypesWorker',
        label: 'Appointment Types'
    },
    {
        value: 'ProvidersWorker',
        label: 'Providers'
    },
    {
        value: 'AppointmentProvidersWorker',
        label: 'Appointment Providers'
    },
    {
        value: 'ClientsWorker',
        label: 'Clients'
    },
    {
        value: 'PatientsWorker',
        label: 'Patients'
    },
    {
        value: 'AppointmentsWorker',
        label: 'Appointments'
    }
]

export default workerNames