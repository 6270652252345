import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';

import store from 'store'

export const fetch = async(params = {})=>{
    let response = await apiClient.organizations.customFields.fetch(params)

    const data = response.data.reduce((acc, item)=>{
        acc[item.id] = item
        return acc;
    }, {})

    store.dispatch({ 
        type: ActionTypes.FETCH_CUSTOM_FIELDS,
        payload: data
    })
}

export const create = async(params = {}) => {
    const response = await apiClient.organizations.customFields.create(params)

    store.dispatch({
        type: ActionTypes.CREATE_CUSTOM_FIELD,
        payload: response.data
    })

    return response.data
}

export const update = async(params = {}) => {
    const response = await apiClient.organizations.customFields.update(params)

    store.dispatch({
        type: ActionTypes.UPDATE_CUSTOM_FIELD,
        payload: response.data
    })

    return response.data
}

export const destroy = async(id) => {
  await apiClient.organizations.customFields.destroy(id)

  store.dispatch({
    type: ActionTypes.DESTROY_CUSTOM_FIELD,
    payload: id
  })
}

