import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';

import store from '../store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.integrationConfigs.fetch()
  
  const data = response.data.reduce((acc, item)=>{
    acc[item.integration] = item
    return acc;
  }, {})

  store.dispatch({
    type: ActionTypes.FETCH_INTEGRATION_CONFIGS,
    payload: data
  })
}

export const update = async (params = {}) => {
  const response = await apiClient.organizations.integrationConfigs.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_INTEGRATION_CONFIG,
    payload: response.data
  })
}

export const googleAuth = async (params = {}) => {
  const response = await apiClient.organizations.integrationConfigs.googleAuth(params)
  store.dispatch({
    type: ActionTypes.UPDATE_INTEGRATION_CONFIG,
    payload: response.data
  })
}