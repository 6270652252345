import {
  FETCH_SYNC,
  UPDATE_SYNC
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SYNC:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_SYNC:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
