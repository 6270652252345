import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Modal, List, Row, Col, Select, Menu, Form, Drawer, Dropdown } from 'antd'
import { SwapOutlined, CaretUpOutlined, CaretDownOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import {AdminLayout, Scroller, SectionContent, Spinner} from 'components/elements'
import { waitingRoomActions, administratorActions, appointmentActions, appointmentTypeActions } from 'actions'
import { CreateOrEditWaitingRoom } from 'components/partials'
import moment from 'moment'
const _ = require('lodash')

class WaitingRoom extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            resetVisible: false,
            appointmentAdministrators: {},
            saveAppointmentSpinner: {},
            removeAppointmentSpinner: {},
            waitingRoomSpinner: false,
            search: '',
            createOrEditWaitingRoomVisible: false
        }

        this.handleCancel = this.handleCancel.bind(this)
        this.handleClearWaitingRoom = this.handleClearWaitingRoom.bind(this)
        this.handleClearPrompt = this.handleClearPrompt.bind(this)
        this.handleChangeAdministrator = this.handleChangeAdministrator.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.cancelReorder = this.cancelReorder.bind(this)
        this.fetch = this.fetch.bind(this)
        this.handleWaitingRoomDrawer = this.handleWaitingRoomDrawer.bind(this)
        this.handleWaitingRoomClose = this.handleWaitingRoomClose.bind(this)
    }

    fetch(){
        this.setState({waitingRoomSpinner: true})
        return waitingRoomActions.fetch({fullAppointments: true}).then((res)=>{
            this.setState({waitingRoomSpinner: false})
            return res
        })
    }

    componentDidMount() {
        this.fetch()
        administratorActions.fetch()
        appointmentTypeActions.fetch()
        waitingRoomActions.fetch()
        if(!this.waitingRoomFetchInterval){
            this.waitingRoomFetchInterval = setInterval(()=>{
                this.fetch()
            }, 30000)
        }
    }

    componentWillUnmount(){
        if(this.waitingRoomFetchInterval) clearInterval(this.waitingRoomFetchInterval)
    }

    handleCancel(){
        this.setState({resetVisible: false})
    }

    handleClearPrompt(){
        this.setState({
            resetVisible: true
        })
    }

    handleClearWaitingRoom(){
        waitingRoomActions.destroy()
        this.setState({
            resetVisible: false
        })
    }

    handleChangeAdministrator(value){
        if(value){
            let [appointmentID, administratorID] = value.split('--')
            this.setState({
                appointmentAdministrators:{
                    ...this.state.appointmentAdministrators,
                    [`${appointmentID}`]: administratorID
                }
            })
        }
    }
    
    handleSearch(value){
        this.setState({
            search: value
        })
    }

    handleUpdateAppointment(appointment){
        if(this.state.appointmentAdministrators[appointment.id]){
            this.setState({
                saveAppointmentSpinner: {
                    ...this.state.saveAppointmentSpinner,
                    [`${appointment.id}`]: true
                }
            })
            appointmentActions.update({
                id: appointment.id,
                administratorID: this.state.appointmentAdministrators[appointment.id],
            }).then((res)=>{
                this.fetch().then((res)=>{
                    this.setState({
                        saveAppointmentSpinner: {
                            ...this.state.saveAppointmentSpinner,
                            [`${appointment.id}`]: false
                        }
                    })
                })
            })

        }
    }

    handleRemoveAppointment(appointment){
        this.setState({
            removeAppointmentSpinner: {
                ...this.state.removeAppointmentSpinner,
                [`${appointment.id}`]: true
            }
        })

        waitingRoomActions.destroy(appointment.id)
        .then((res)=>{
            this.fetch().then((res)=>{
                this.setState({
                    removeAppointmentSpinner: {
                        ...this.state.removeAppointmentSpinner,
                        [`${appointment.id}`]: false
                    }
                })
            })
        })
    }

    handleWaitingRoomDrawer(){
        this.setState({
            createOrEditWaitingRoomVisible: true
        })
    }

    handleWaitingRoomClose(){
        this.setState({
            createOrEditWaitingRoomVisible: false,
            currentWaitingRoom: ''
        })
    }

    toggleReorder(appointment){
        this.setState({
            reordering: appointment.id
        })
    }

    moveAppointmentUp(appointment){
        this.setState({
            reorderSpinner: appointment.id
        })
                
        waitingRoomActions.reorder({
            appointmentID: appointment.id,
            indexShift: -1
        })
        .then((res)=>{
            this.setState({
                reorderSpinner: ''
            })
        })
    }

    moveAppointmentDown(appointment){
        this.setState({
            reorderSpinner: appointment.id
        })
                
        waitingRoomActions.reorder({
            appointmentID: appointment.id,
            indexShift: 1
        })
        .then((res)=>{
            this.setState({
                reorderSpinner: ''
            })
        })
    }

    cancelReorder(){
        this.setState({
            reordering: ''
        })
    }

    get waitingRoom(){
        return _.flatten(this.props.waitingRoom || [])
    }

    get administrators(){
        return Object.values(this.props.administrators || {})
    }

    get appointmentTypes(){
        return Object.values(this.props.appointmentTypes || {})
    }

    render() {
        const initialValues = {
            accountID: (this.waitingRoom.account || {}).id || '',
            appointmentTypeID: this.waitingRoom.appointmentTypeID || ''
        }

        let edit = false
        if(this.waitingRoom.id){
            edit = true
        }

        let editAccount = false
        if(this.form && this.form.getFieldValue('accountID') && this.form.getFieldValue('accountID') !== 'new'){
            editAccount = true
        }

        return (
            <AdminLayout>
                <CreateOrEditWaitingRoom 
                    visible={this.state.createOrEditWaitingRoomVisible}
                    onClose={this.handleWaitingRoomClose}
                    waitingRoom={this.state.currentWaitingRoom}
                />
                <SectionContent noPadding={true} noMargin={true}>
                    <Scroller>
                        <Modal
                            title="Clear Waiting Room"
                            visible={this.state.resetVisible}
                            onOk={this.handleClearWaitingRoom}
                            onCancel={this.handleCancel}
                        >
                            <p>Are you sure you want to clear the waiting room?</p>
                            <p>This action is irreversable.</p>
                        </Modal>
                        

                        <div style={{margin: "20px"}}>
                            Number of people in the queue: {this.state.waitingRoomSpinner ? (
                                <Spinner style={{display: "inline", marginLeft: '10px'}}/> 
                            ) : (
                                this.waitingRoom.length
                            )}
                        </div>
                        <div style={{ margin: "20px"}}>
                            <Button 
                                type="primary"
                                onClick={this.handleClearPrompt}
                            >
                                Clear Waiting Room
                            </Button>
                            <div style={{float: 'right'}}>
                                <Button 
                                    type='primary'
                                    onClick={this.handleWaitingRoomDrawer}
                                >
                                    Add To Waiting Room
                                </Button>
                            </div>
                        </div>
                        <div>
                        <div>
                        <Form style={{height: '100px'}}>
                            <Row style={{margin: '0px 40px 10px'}}>
                                <Col md={2} lg={2}>
                                    Reorder
                                </Col>
                                <Col md={5} lg={5}>
                                    Name
                                </Col>
                                <Col md={6} lg={6}>
                                    Time Checked In
                                </Col>
                                <Col md={7} lg={7}>
                                    Move Appointment
                                </Col>
                                <Col md={4} lg={4} align='center'>
                                    Remove From List
                                </Col>
                            </Row>
                            <List 
                                style={{margin: '0px 20px'}}
                                bordered
                                dataSource={this.waitingRoom}
                                renderItem={(appointment, i) => (
                                    <List.Item>
                                        <Row style={{width: '100%'}}> 
                                            <Col md={2} lg={2} style={{"paddingTop": '5px',"cursor":'pointer'}}>
                                                {this.state.reordering == appointment.id ? (
                                                    this.state.reorderSpinner == appointment.id ? (
                                                        <Spinner style={{display: "inline", marginLeft: '10px'}}/> 
                                                    ) : (
                                                        <div style={{"fontSize":'20px'}}>
                                                            <CloseOutlined onClick={this.cancelReorder} style={{    
                                                                "fontSize": '12px',
                                                                position: 'absolute',
                                                                "marginLeft": '-15px',
                                                                "marginTop": '4px'
                                                            }}/>
                                                            {i > 0 ? (
                                                                <CaretUpOutlined onClick={this.moveAppointmentUp.bind(this, appointment)}/>
                                                            ):(
                                                                <span style={{"marginRight": '20px'}}></span>
                                                            )}
                                                            {i < this.waitingRoom.length - 1 && <CaretDownOutlined onClick={this.moveAppointmentDown.bind(this, appointment)} style={{"paddingLeft": '5px'}} />}
                                                        </div>
                                                    )
                                                ) : (
                                                    <SwapOutlined onClick={this.toggleReorder.bind(this,appointment)}/>
                                                )}
                                            </Col>
                                            <Col md={5} lg={5}>
                                                {appointment.account ? (
                                                    `${appointment.account.firstName} ${appointment.account.lastName}`
                                                ):(
                                                    ''
                                                )}
                                            </Col>
                                            <Col md={6} lg={6}>
                                                {moment(appointment.startsAt).format("MM/DD/YYYY hh:mm a")}  
                                            </Col>
                                            <Col md={4} lg={4}>
                                                <Select
                                                    showSearch
                                                    style={{ width: '100%'}}
                                                    placeholder="Move To Administrator"
                                                    onSelect={this.handleChangeAdministrator}
                                                    onSearch={this.handleSearch}
                                                    filterOption={false}
                                                    key={`select_${appointment.id}`}
                                                >
                                                    {this.administrators.filter((admin)=>(
                                                        admin.id != appointment.administratorID &&
                                                        (`${admin.firstName} ${admin.lastName}`).toLowerCase().includes(this.state.search.toLowerCase())
                                                    )).map((admin)=>{
                                                        return (
                                                            <Select.Option value={`${appointment.id}--${admin.id}`} key={`admin-select_${admin.id}`}>
                                                                {admin.firstName} {admin.lastName}
                                                            </Select.Option>
                                                        )
                                                    })}
                                                    <Select.Option value={`${appointment.id}--`} key={`admin-select_none`}>
                                                        None
                                                    </Select.Option>
                                                </Select>
                                            </Col>
                                            <Col md={3} lg={3} align='center'>
                                                {this.state.appointmentAdministrators[appointment.id] &&
                                                this.state.appointmentAdministrators[appointment.id] != appointment.administratorID && 
                                                    <Button 
                                                        type='primary'
                                                        loading={this.state.saveAppointmentSpinner[appointment.id]}
                                                        onClick={this.handleUpdateAppointment.bind(this,appointment)}
                                                    >
                                                        Save
                                                    </Button>
                                                }
                                            </Col>
                                            <Col md={4} lg={4} align='center'>
                                                <Button 
                                                    type='danger'
                                                    loading={this.state.removeAppointmentSpinner[appointment.id]}
                                                    onClick={this.handleRemoveAppointment.bind(this,appointment)}
                                                >
                                                    Remove
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                            </Form>
                        </div>
                        </div>

                    </Scroller>
                </SectionContent>
            </AdminLayout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        waitingRoom: state.waitingRoom || [],
        administrators: state.administrators || {}
    }
}

export default withRouter(connect(mapStateToProps)(WaitingRoom))
