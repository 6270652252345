import * as ActionTypes from '../constants/actionTypes'
import {apiClient} from '../lib'
import store from '../store'

export const fetch = async ()=>{
  const response = await apiClient.partners.fetch()
    
  store.dispatch({ 
    type: ActionTypes.SET_ALL_PARTNERS,
    payload: response.data
  })
}