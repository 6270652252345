import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Route } from 'react-router-dom'
import { Row, Col, Input, Form } from 'antd'
import { Fa } from 'components/elements'
import {
  messengerTemplatesActions
} from 'actions'

import MessengerTemplateCards from './MessengerTemplateCards'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import _ from 'lodash'

import './style.less'

import Item from './Item'
import messengerTemplates from 'reducers/messengerTemplates'


class MessengerTemplates extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {}
    this.handleOnSelect = this.handleOnSelect.bind(this)
  }
  componentDidMount(){
    messengerTemplatesActions.fetch()
  }
  get messengerTemplates(){
    return this.props.messengerTemplates
  }
  get messengerTemplate(){
    return this.props.messengerTemplates[this.props.computedMatch.params.messengerTemplateID]
  }
  handleOnSelect(messengerTemplate = {}){
    if(messengerTemplate.id){
      this.props.history.push(`/admin/messenger-templates/${messengerTemplate.id}`)
    }
  }
  render() {
    return (
      <div>
        <AdminLayout>
          <SectionContent noPadding noMargin title='Messenger Templates'>
            <Row style={{height: "100%"}}>
              <Col xs={24} lg={8}>
                <MessengerTemplateCards
                  loading={this.props.isFetchItemsLoading}
                  search={this.state.search || ''}
                  dataSource={this.messengerTemplates}
                  pagination={this.props.pagination}
                  filters={this.props.filters}
                  onSelect={this.handleOnSelect}
                  onSearch={this.handleSearch}
                  editPath={'/admin/patients/edit'}
                />
              </Col>
              <Col xs={24} lg={16}>
                {this.messengerTemplate && (
                  <Item messengerTemplate={this.messengerTemplate} />
                )}
              </Col>
            </Row>
          </SectionContent>
        </AdminLayout>
      </div>
    )
  }
}

export default withRouter(
  connect((state)=>state)(
    (MessengerTemplates)
  )
)
