import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';

import store from 'store'

export const fetch = async(params = {})=>{
  let response
  
  response = await apiClient.groups.fetch(params)

  const data = response.data.reduce((acc, item)=>{
    acc[item.id] = item
    return acc;
  }, {})

  store.dispatch({ 
    type: ActionTypes.FETCH_GROUPS,
    payload: data
  })
}

export const update = async(params = {}) => {
   const response = await apiClient.groups.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_GROUP,
    payload: response.data
  })

  return response.data
}

export const addOrganization = async({id}) => {
    const response = await apiClient.groups.addOrganization({
        organizationID: store.getState().currentOrganization.id,
        groupID: id
    })

    // store.dispatch({
    //     type: ActionTypes.UPDATE_GROUPS,
    //     payload: response.data
    // })

    return response.data
}

export const removeOrganization = async({id}) => {
    const response = await apiClient.groups.removeOrganization({
        organizationID: id
    })

  store.dispatch({
    type: ActionTypes.UPDATE_GROUPS,
    payload: response.data
  })

  return response.data
}

export const destroy = async({id}) => {
  await apiClient.groups.destroy(id)

  store.dispatch({
    type: ActionTypes.DESTROY_GROUP,
    payload: id
  })
}

export const create = async(params = {}) => {
  const response = await apiClient.groups.create(params)

  store.dispatch({
    type: ActionTypes.CREATE_GROUP,
    payload: response.data
  })

  return response.data
}

