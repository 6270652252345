import Collection from 'lib/client/collections/collection'

class Appointments extends Collection{
    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/appointments`, options);
    }
    update(options){
        const id = options.id
        delete options.id
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/appointments/${id}`, options);
    }
    destroy(id){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/appointments/${id}`);
    }
    fetch(options = {}){
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/appointments`, {params: options});
    }
}

export default Appointments;