import React from 'react'
import { Modal } from 'antd'
import { TableRowEditButton } from 'components/elements/AppButton'
import {SnapList} from 'components/elements/ListEditor'
import moment from 'moment';
import {withRouter} from 'react-router-dom'

class PatientCards extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleDeleteButtonOnClick = this.handleDeleteButtonOnClick.bind(this)
  }

  handleDeleteButtonOnClick() {
    const handleTableOnDelete = () => { this.props.onDelete() }
    Modal.confirm({
      title: 'Delete Item',
      content: 'Are you sure to delete this item?',
      onOk() {
        handleTableOnDelete()
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }

  render() {
    return (
      <SnapList
        data={this.props.dataSource || []}
        initial={this.props.match.params.id}
        search={this.props.search || ''}
        listType={'Patients'}
        sortBy={'lastName'}
        onSelect={this.props.onSelect}
        onCreate={this.props.onCreate}
        onSearch={this.props.onSearch}
        row={(props)=>{
          if(props.id == "searchHolder"){
            return(
              <div>
                {props.firstName}
                <br />
              </div>
            )
          } else {
            return(
              <div>
                {props.accountFirstName} {props.accountLastName} / {props.firstName} {props.lastName}
                <br />
                {moment(props.birthday).format('MM/DD/YY')}
              </div>
            )
          }
        }}
      />
    )
  }
}

export default withRouter(PatientCards)