import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import Edit from './Edit'
import Show from './Show'

class Item extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleNew = this.handleNew.bind(this)
  }

  get current(){
    return this.props.classifications[this.props.match.params.id] || {};
  }

  handleNew(){
    this.props.history.push(`/admin/species/new`)
  }

  render() {
    return (
      <div className='edit-switch'>
        <Switch>
          <Route exact path='/admin/species/new' render={()=>
            <Edit current={this.current} type='new' />
          }/>
          <Route exact path='/admin/species/:id' render={()=>
            <Show current={this.current} />
          }/>
          <Route exact path='/admin/species/:id/edit' render={()=>
            <Edit current={this.current} type='edit' />
          }/>
        </Switch>
      </div>
    )
  }
}

export default withRouter(connect((state)=>(state))(Item))