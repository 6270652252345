import React from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Input, message, Tabs, Form, Card, Transfer, Select, Button, Switch} from 'antd'
import { Fa } from 'components/elements'
import {
    breedActions,
    classificationActions
} from 'actions'
import _ from 'lodash'

class WidgetSpeciesAndBreed extends React.Component {
    constructor(){
        super();
        this.state = {
            currentClassificationID: ''
        }
        this.handleBreedChange = this.handleBreedChange.bind(this)
        this.handleClassificationChange = this.handleClassificationChange.bind(this)
        this.updateClassifications = this.updateClassifications.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
    }   

    

    componentDidMount() {
        classificationActions.fetch();
      }

      componentDidUpdate(previousProps) {
      if(!this.state.currentClassificationID && this.props.classifications && this.props.classifications != previousProps.classifications) {
        this.setState({currentClassificationID: (this.props.classifications[0] || {}).id})
      }
    }

    get breeds(){
        let _breeds = Object.values(this.props.breeds).filter((breed)=>(breed.classificationID == this.currentClassification.id)).map((breed)=>{
          let _breed = {...breed}
          _breed.key = breed.id
          _breed.title = `${(this.props.classifications[breed.classificationID] || {}).name} / ${breed.name}`
          return _breed
        }) || []
        
        return _breeds.sort((a,b)=>{
          let aName = (this.props.classifications[a.classificationID] || {}).name
          let bName = (this.props.classifications[b.classificationID] || {}).name
          if(aName > bName) return 1
          if(aName < bName) return -1
          if(a.name > b.name) return 1
          if(a.name < b.name) return -1
          return 0
        })
      }

      handleTabChange(tab){
        if(tab == 'speciesBreeds'){
          breedActions.fetch({classificationID: (this.classifications[0] || {}).id});
        }
      }

      handleClassificationChange(value){
        breedActions.fetch({classificationID: value});
        this.setState({
          currentClassificationID: value
        })
      }

      get classifications(){
        return Object.values(this.props.classifications || {}).filter((c)=>(_.isNil(c.siblingID))).sort((a,b)=>{
          if(a.name > b.name) return 1
          if(a.name < b.name) return -1
          return 0
        });
      }

      get currentClassification(){
        return this.props.classifications[this.state.currentClassificationID] || {}
      }

      get activeBreeds(){
        return this.breeds
        .filter((breed)=>breed.active)
      }
    
      get activeBreedIDs(){
        return this.activeBreeds
        .map((breed)=>breed.id)
      }
    
      async handleBreedChange(targetBreeds){
        let currentActive = this.activeBreedIDs
        let breedIDs = []
    
        this.breeds.forEach((breed)=>{
          if((targetBreeds.includes(breed.id) || currentActive.includes(breed.id)) &&
            !(targetBreeds.includes(breed.id) && currentActive.includes(breed.id))){
              breedIDs.push(breed.id)
          }
        })
    
        await breedActions.activate(breedIDs)
    
        this.updateClassifications()
      }
    
      async updateClassifications(){
        let classificationIDs = []
        let activeClassifications = {}
    
        if(this.activeBreeds.length == 0 && this.currentClassification.active){
          classificationIDs.push(this.currentClassification.id)
        } else if (this.activeBreeds.length != 0 && !this.currentClassification.active){
          classificationIDs.push(this.currentClassification.id)
        }
    
        await classificationActions.activate(classificationIDs)
      }

    render() {
        return (
            <Row>
                <Col span={12}>
                    <Select
                    value={this.currentClassification.id}
                    style={{width:'100%'}}
                    placeholder="Filter breeds by species"
                    onChange={this.handleClassificationChange}
                    >
                    {this.classifications.map((classification)=>(
                    <Select.Option key={classification.id} value={classification.id}>{classification.name}</Select.Option>
                    ))}
                    </Select>
                    <br />
                    <br />
                    <Transfer 
                    titles={['Inactive', 'Active']}
                    listStyle={{width:'calc(50% - 25px)'}}
                    dataSource={this.breeds}
                    targetKeys={this.activeBreedIDs}
                    render={item => item.title}
                    onChange={this.handleBreedChange}
                    />
                </Col>
            </Row>
        );
    }
}
export default withRouter(connect((state)=>state)(WidgetSpeciesAndBreed))
