import { AxiosRequestConfig } from "axios";

const requests = {
    formCompletions: (organizationId: string, from: string, to: string) : AxiosRequestConfig=> (
        {
            method: 'GET',
            url: `/organizations/${organizationId}/form-completions`,
            params: {
                from,
                to
            }
            
        }
    ),
}

export default requests;