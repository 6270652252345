import React from 'react'
import { Modal, Input } from 'antd'

import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import Edit from './Edit'
import Show from './Show'
import {patientActions,accountActions, appointmentActions} from 'actions'

class Patient extends React.Component {
  constructor(props, context) {
    super(props, context)
  }

  componentDidMount() {
    accountActions.fetch({id: this.patient.primaryAccountID})
    appointmentActions.fetch({patientID: this.patient.id})
  }

  get patients(){
    return this.props.patients || {}
  }

  get appointments(){
    return Object.values(this.props.appointments || {}) || []
  }

  get patient(){
    return this.patients[this.props.match.params.id] || {};
  }

  get account(){
    return this.props.accounts[this.patient.primaryAccountID] || {}
  }

  render() {
    return (
      <div className='edit-switch'>
        <Switch>
          <Route exact path='/admin/patients/new' render={()=>
            <Edit patient={this.patient} />
          }/>
          <Route exact path='/admin/patients/:id' render={()=>
            <Show 
              patient={this.patient} 
              appointments={this.appointments} 
              account={this.account}/>
          }/>
          <Route exact path='/admin/patients/:id/edit' render={()=>
            <Edit patient={this.patient} />
          }/>
        </Switch>
      </div>
    )
  }
}

export default withRouter(connect((state)=> state)(Patient))