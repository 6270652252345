import {
    CREATE_APPOINTMENT, FETCH_APPOINTMENTS, UPDATE_APPOINTMENT, DESTROY_APPOINTMENT,
    SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
    switch (action.type) {
        case CREATE_APPOINTMENT:
            return {
                ...state, 
                [`${action.payload.id}`]: action.payload
            }
        case UPDATE_APPOINTMENT:
            return {
                ...state, 
                [`${action.payload.id}`]: action.payload
            }
        case DESTROY_APPOINTMENT:
            state = {
                ...state
            }
            delete state[action.payload] 
            return state
        case FETCH_APPOINTMENTS:
            return action.payload || state;
        case SET_ORGANIZATION_CURRENT:
            return {}
        default:
            return state
    }
}
  