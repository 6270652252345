import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import store from '../store'

export const fetch = async(params = {})=>{
    const response = await apiClient.organizations.waitLists.fetch(params)

    if(response.data){
        store.dispatch({
            type: ActionTypes.FETCH_WAIT_LISTS,
            payload: response.data
        })
    }

    return response.data
}

export const create = async(params = {})=>{
    const response = await apiClient.organizations.waitLists.create(params)

    if(response.data){
        store.dispatch({
            type: ActionTypes.APPEND_WAIT_LIST,
            payload: response.data
        })
    }

    return response.data
}

export const update = async(params = {})=>{
    const response = await apiClient.organizations.waitLists.update(params)

    if(response.data){
        store.dispatch({
            type: ActionTypes.DESTROY_WAIT_LIST,
            payload: params.id
        })
        store.dispatch({
            type: ActionTypes.APPEND_WAIT_LIST,
            payload: response.data
        })
    }

    return response.data
}

export const destroy = async (id = '') => {
    const response = await apiClient.organizations.waitLists.destroy(id)

    if(!response.data){
        store.dispatch({
            type: ActionTypes.DESTROY_WAIT_LIST,
            payload: id
        })
    }
}