import Collection from 'lib/client/collections/collection'

class IntegrationConfigs extends Collection{
    fetch(options){
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/integration-configs`, options);
    }

    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/integration-configs`, options);
    }

    update(options){
        if(options.id){
            return this.axios.put(`organizations/${this.props.currentOrganization.id}/integration-configs/${options.id}`, options);
        }else{
            return this.axios.put(`organizations/${this.props.currentOrganization.id}/integration-configs`, options);
        }
    }
    
    googleAuth(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/integration-configs/google-auth`, options);
    }
    
    destroy(id){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/integration-configs/${id}`);
    }
}

export default IntegrationConfigs;