import {
  PATIENTCURRENT_SET,
} from '../constants/actionTypes'

export default (state = -1, action) => {
  switch (action.type) {
    case PATIENTCURRENT_SET:
      return action.value
    default:
      return state
  }
}
