import React from 'react';
import { Drawer, Form, Button, Popconfirm } from 'antd';
import {connect} from 'react-redux'
import {PatientForm} from 'components/partials'
import {patientActions, accountActions} from 'actions'
import _ from 'lodash'

class CreateOrEditMessages extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createGendersVisible: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  get form() {
    return this.formRef.current
  }

  get patient(){
    return this.props.patient || {}
  }

  async handleSubmit (values) {
        const _patient = {
            accountID: values.accountID || this.props.account.id,
            pet: values.pet,
            firstName: values.firstName,
            middleInitial: values.middleInitial,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            address: values.address,
            unit: values.unit,
            city: values.city,
            county: values.county,
            country: values.country,
            state: values.state,
            zip: values.zip,
            genderID: values.genderID,
            breedID: values.breedID,
            birthday: values.birthday,
            timeZone: values.timeZone,
            weight: values.weight
        }
        let patient
        if(this.patient.id){
          patient = await patientActions.update({
              id: this.patient.id,
              ..._patient
          })
        } else {
          patient = await patientActions.create(_patient)
          this.props.onSetCurrentPatient && this.props.onSetCurrentPatient(patient.id)
        }
        this.form && this.form.resetFields();
        this.props.onClose && this.props.onClose(patient)
  }

  onClose(){
    this.form && this.form.resetFields();
    
    this.props.onClose()
  }

  get account(){
    return this.props.accounts[this.patient.primaryAccountID]
  }
  componentDidUpdate(nextProps){
    if(!this.account && this.patient.primaryAccountID){
      accountActions.safeFetchByID({id: this.patient.primaryAccountID})
    }
  }

  render() {
        
    let edit = false;
    
    if(this.patient.id){
      edit = true
    }

    return (
      <Drawer
        title={this.patient.id ? 'Edit Patient' : 'Create Patient'}
        width={720}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
          <PatientForm form={this.formRef.current} patient={this.patient} account={this.props.account}/>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyPatient} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Patient</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.patient.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(CreateOrEditMessages)