import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import store from '../store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.serviceAreas.fetch(params)

  store.dispatch({
    type: ActionTypes.FETCH_SERVICE_AREAS,
    payload: response.data.reduce((acc,d)=>{
      acc[d.id] = d
      return acc
    },{})
  })

  return response.data
}

export const update = async (params = {}) => {
  const response = await apiClient.organizations.serviceAreas.update(params)
  
  store.dispatch({
    type: ActionTypes.APPEND_SERVICE_AREA,
    payload: response.data
  })

  return response.data
}

export const create = async (params = {}) => {
  const response = await apiClient.organizations.serviceAreas.create(params)

  store.dispatch({
    type: ActionTypes.APPEND_SERVICE_AREA,
    payload: response.data
  })
  
  return response.data
}

export const destroy = async (params = {}) => {
  await apiClient.organizations.serviceAreas.destroy(params)

  store.dispatch({
    type: ActionTypes.DESTROY_SERVICE_AREA,
    payload: params.id
  })
}