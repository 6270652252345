import {
    FETCH_OPEN_TIMES,
    APPEND_OPEN_TIME,
    DESTROY_OPEN_TIME,
    SET_ORGANIZATION_CURRENT
  } from '../constants/actionTypes'
  
  export default (state = {}, action) => {
    switch (action.type) {
      case FETCH_OPEN_TIMES:
        return {
          ...state,
          ...action.payload
        }
      case APPEND_OPEN_TIME:
        let baseID = Object.values(action.payload)[0].id
        state = Object.values(state).filter((s)=>s.id != baseID).reduce((acc, item)=>{
          acc[item.repeatingID || item.id] = item
          return acc;
        }, {})
        return {
          ...state,
          ...action.payload
        }
      case DESTROY_OPEN_TIME:
        state = Object.values(state).filter((s)=>s.id != action.payload).reduce((acc, item)=>{
          acc[item.repeatingID || item.id] = item
          return acc;
        }, {})
        
        return state
      case SET_ORGANIZATION_CURRENT:
          return {}
      default:
        return state
    }
  }
  