import {
  FETCH_MESSAGES,
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  DESTROY_MESSAGE,
  APPEND_MESSAGE,
  FETCH_NOTIFICATIONS,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  
  const response = {
    ...state,
    ...action.payload
  }

  switch (action.type) {

    case FETCH_MESSAGES:
      return {
        ...state,
        ...action.payload
      }
    case APPEND_MESSAGE:
      return {
        ...state,
        ...action.payload
      }
    case CREATE_MESSAGE:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    
    case UPDATE_MESSAGE:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    
    case DESTROY_MESSAGE:
      state = {
        ...state
      }
      delete state[action.payload]
      return state

    case SET_ORGANIZATION_CURRENT:
        return {}
        
    default:
      return state
  }
}
