import {
    FETCH_INTEGRATION_CONFIGS,
    UPDATE_INTEGRATION_CONFIG,
    DESTROY_INTEGRATION_CONFIG,
    SET_ORGANIZATION_CURRENT
  } from '../constants/actionTypes'
  
  export default (state = {}, action) => {
    switch (action.type) {
      case FETCH_INTEGRATION_CONFIGS:
        return {
          ...state,
          ...action.payload
        }
      case UPDATE_INTEGRATION_CONFIG:
        return {
            ...state,
            [`${action.payload.integration}`]: action.payload
        }
      case DESTROY_INTEGRATION_CONFIG:
        return Object.values(state).filter((s)=>s.id != action.payload).reduce((acc, item)=>{
          acc[item.id] = item
          return acc;
        }, {})
      case SET_ORGANIZATION_CURRENT:
          return {}
      default:
        return state
    }
  }
  