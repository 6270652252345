import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker, AutoComplete } from 'antd';
import moment from 'moment'
import {AccountForm} from 'components/partials'
import {accountActions, patientActions, genderActions, breedActions, classificationActions} from 'actions'
import {connect} from 'react-redux'

const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;

class CreateOrEditAccount extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.triggerSubmit = this.triggerSubmit.bind(this)
  }

  get account(){
    return this.props.account || {}
  }

  triggerSubmit() {
    return this.form && this.form.submit()
  }

  async handleSubmit (values) {
    const _account = {
      firstName: values.firstName,
      middleInitial: values.middleInitial,
      lastName: values.lastName,
      email: values.email,
      mobilePhone: values.mobilePhone,
      workPhone: values.workPhone,
      homePhone: values.homePhone,
      address: values.address,
      unit: values.unit,
      city: values.city,
      state: values.state,
      zip: values.zip,
      county: values.county,
      birthday: values.birthday
    }

    try{
      let account
      if(this.account.id){
        account = await accountActions.update({
            id: this.account.id,
            ..._account
        })
      } else {
        account = await accountActions.create(_account)
      }
      this.props.onSetCurrentAccount(account.id)
      this.form && this.form.resetFields();
      this.props.onClose()
    } catch(error){
      console.log(error)
    } 
  }


  componentDidUpdate(previousProps){
    if(previousProps.visible != this.props.visible || this.props.account.id != previousProps.account.id){
      this.form && this.form.resetFields();
    }
  }

  async handleDestroyAccount(e){
    e.preventDefault()
    
    // await accountActions.destroy({
    //   id: this.account.id
    // })
    this.props.onClose()
  }
  
  get form() {
    return this.formRef.current
  }

  render() {
    let edit = false;
    
    if(this.account.id){
      edit = true
    }    

    return (
      <Drawer
        title={this.account.id ? 'Edit Account' : 'Create Account'}
        width={720}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <AccountForm formRef={this.formRef} onFinish={this.handleSubmit} account={this.account} />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}
          >
          {edit && 
              <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyAccount} cancelText="No">
                  <Button type='danger' className='pull-left'>Delete Account</Button>
              </Popconfirm>
          }
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={this.props.onClose}
            >
            Cancel
          </Button>
          <Button onClick={this.triggerSubmit} type="primary" htmlType="submit">{this.account.id ? 'Update' : 'Create' }</Button>
        </div>
      </Drawer>
    );
  }
}
export default connect((state)=> state )(
  (CreateOrEditAccount))