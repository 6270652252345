import React from 'react'
import { Spin } from 'antd'
import './index.less'
import { LoadingOutlined } from '@ant-design/icons';


class Spinner extends React.Component {
  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24, ...Object(this.props.iconStyle) }} spin />;
    return (
      <div className='spinner' style={this.props.style}>
        <Spin indicator={antIcon}/>
      </div>
    )
  }
}

export default Spinner
