import React from 'react'
import {
  Card,
  message,
  Steps,
  Row,
  Col,
} from 'antd'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { changeFormFields } from 'actions/sessions'
import {
  organizationActions,
  currentAccountActions,
} from 'actions'

import AppFooter from 'components/elements/AppFooter'
import UserInformation from './UserInformation'
import CreateOrganization from './CreateOrganization'

import './index.less'

const { Step } = Steps

class Setup extends React.Component {
  constructor(props) {
    super(props)

    props.fetchCurrentAccount()

    organizationActions.fetch()
  }

  componentDidUpdate(previousProps) {
    if (previousProps.isLoginLoading && this.props.isLoginSuccess) {
      message.success('You have successfully logged in.')
    } else if (previousProps.isLoginLoading && this.props.loginError) {
      message.error(this.props.loginError)
    }
  }

  render() {
    if (!this.props.isSetup) {
      return <Redirect to={{ pathname: '/admin' }} />
    }
    return (
      <div
        className='setup'
        style={{
          backgroundColor: 'rgb(46, 44, 62)'
        }}
      >
        <div className='container'>
          <div className='top-bar'></div>
          <div className='panels'>
            <Row style={{ height: '100%' }}>
              <Col span={8}>
                <Steps direction='vertical' current={this.props.step} className='text-left'>
                  <Step title="Create Credentials" description='Username and password' />
                  <Step title="Setup User Information" description='Contact, and other account details.' />
                  <Step title="Create Organization" description='Setup your practice.' />
                </Steps>
              </Col>
              <Col span={16}>
                <Card className='no-padding'>
                  {this.props.step == 1 && (
                    <UserInformation
                      onSubmit={this.props.handleFormOnSubmit}
                      onFieldsChange={this.props.handleFormOnFieldsChange}
                      formFieldValues={this.props.formFieldValues}
                      isLoading={this.props.isLoginLoading}
                    />
                  )}
                  {this.props.step == 2 && (
                    <CreateOrganization
                      onSubmit={this.props.handleFormOnSubmit}
                      onFieldsChange={this.props.handleFormOnFieldsChange}
                      formFieldValues={this.props.formFieldValues}
                      isLoading={this.props.isLoginLoading}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <br />
        <AppFooter />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    formFieldValues,
    isLoginLoading,
    isLoginSuccess,
    loginError,
  } = state.login
  const isLoggedIn = !!state.session.accessToken

  let step = 3

  if (Object.keys(state.organizations).length === 0) {
    step = 2
  }

  const accountSetup = (
    state.session.account.firstName
    && state.session.account.lastName
    && (state.session.account.email || state.session.account.mobilePhone)
  )

  if (!accountSetup) {
    step = 1
  }

  const isSetup = !accountSetup || Object.keys(state.organizations).length === 0
  return {
    step,
    formFieldValues,
    isLoginLoading,
    isLoggedIn,
    isSetup,
    isLoginSuccess,
    loginError,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCurrentAccount: currentAccountActions.fetch,
  // handleFormOnSubmit: formValues => dispatch(sendLoginRequest(formValues)),
  handleFormOnFieldsChange: formFieldsChange => dispatch(changeFormFields(formFieldsChange)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Setup)
