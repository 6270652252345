import {
  SET_ORGANIZATION_CURRENT,
  LOGOUT_ACCOUNT
} from '../constants/actionTypes'

export default (state = 0, action) => {
  switch (action.type) {
    case SET_ORGANIZATION_CURRENT:
      return action.payload
    case LOGOUT_ACCOUNT:
      return ''
    default:
      return state
  }
}
