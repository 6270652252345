import './index.less'
import React from 'react'

export default (props)=>{
    const className = []
    props.className && className.push(props.className);
    className.push('scroller scroll-container');
    
    return (
        <div className={className.join(' ')}>
            <div className={'scroller-body'}>
                {props.children}
            </div>
        </div>
    )
}