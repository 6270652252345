import Collection from 'lib/client/collections/collection'

class Shifts extends Collection{
    fetch(options = {}){
        if(options.administratorID){
            return this.axios.get(`organizations/${this.props.currentOrganization.id}/administrators/${options.administratorID}/shifts`);
        }else{
            return this.axios.get(`organizations/${this.props.currentOrganization.id}/shifts`);
        }
    }

    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/administrators/${options.administratorID}/shifts`, options);
    }

    update(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/administrators/${options.administratorID}/shifts/${options.id}`, options);
    }

    destroy(options){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/administrators/${options.administratorID}/shifts/${options.id}`);
    }
}

export default Shifts;