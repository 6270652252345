import React, {Fragment} from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, Divider, InputNumber } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons'
import {serviceAreaActions} from 'actions'
import {connect} from 'react-redux'
import {countries} from 'lib'

const TextArea = Input.TextArea;

class CreateOrEditServiceArea extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      newSegments: {},
      toDelete: []
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.addSegment = this.addSegment.bind(this)
    this.handleDestroyServiceArea = this.handleDestroyServiceArea.bind(this)
  }
  
  async handleSubmit (values) {
    let segments = {}
    for(let key of Object.keys(values)){
      let [keyName, keyID] = key.split("--")
      if(keyID){
        if(!segments[keyID])segments[keyID] = {}
        if(keyName == 'value' && segments[keyID].segmentType){
          segments[keyID][segments[keyID].segmentType] = values[key]
        } else {
          segments[keyID][keyName] = values[key]
        }
      }
    }
    let newKeys = Object.keys(this.state.newSegments)
    for(let key of Object.keys(segments)){
      if(!newKeys.includes(key)){
        segments[key].id = key
      }
    }

    for(let id of this.state.toDelete){
      if(!newKeys.includes(id)){
        segments[id] = {id, archived: true}
      }
    }

    const _serviceArea = {
        name: values.name,
        description: values.description,
        segments: Object.values(segments)
    }

    if(this.serviceArea.id){
      await serviceAreaActions.update({
          id: this.serviceArea.id,
          ..._serviceArea
      })
    }else{
      let serviceArea = await serviceAreaActions.create(_serviceArea)
      this.props.setServiceArea && this.props.setServiceArea(serviceArea.id)
    }
    this.setState({
      newSegments: {}
    })
    this.props.onClose()
  }

  async handleDestroySegment(segmentI){
    let toDelete = this.state.toDelete || []
    let newSegments = this.state.newSegments
    let newIDs = Object.keys(newSegments)
    for(let segment of this.segments){
      if(!newIDs.includes(segment.id) && segment.id == segmentI){
        toDelete.push(segment.id)
      }
    }
    delete newSegments[segmentI]

    this.setState({
      newSegments,
      toDelete
    })
  }

  get states(){
		return countries.statesOrProvinces(this.organization.country).map((state)=>({
      name: state.name, value: state.code
    }))
  }

  get form() {
    return this.formRef.current
  }

  get organization(){
    return this.props.organizations[this.props.currentOrganizationID] || {country: 'US'}
  }

  get serviceArea(){
    const _serviceArea = this.props.serviceArea || {}
    
    return {
      id: _serviceArea.id,
      name: _serviceArea.name,
      description: _serviceArea.description,
      segments: _serviceArea.segments || [],
      serviceAreaSegmentID: ((_serviceArea.segments || [])[0] || {}).id
    }
  }

  get segments(){
    let _segments = [...this.serviceArea.segments] || []
    if(Object.keys(this.state.newSegments).length > 0){
      _segments = _segments.concat(Object.values(this.state.newSegments))
    }
    return _segments.filter((segment)=>!this.state.toDelete.includes(segment.id))
  }

    segmentType(segment){
        if(this.form && this.form.getFieldValue(`segmentType--${segment.id}`)){
            return this.form.getFieldValue(`segmentType--${segment.id}`)
        }
        return segment.segmentType
    }

  componentDidUpdate(nextProps){
    if(this.props.visible && !nextProps.visible){
      this.form && this.form.resetFields();
    }
  }

  async handleDestroyServiceArea(e){
    e.preventDefault()
    await serviceAreaActions.destroy({id:this.serviceArea.id})
    this.props.onClose()
  }

  addSegment(){
    this.setState({
      newSegments: {
        ...this.state.newSegments,
        [`new-segment-${Object.keys(this.state.newSegments).length}`]:{id: `new-segment-${Object.keys(this.state.newSegments).length}`}
      }
    })
  }

  get keys(){
    return [
      {name: 'Zipcode', key: 'zip'},
      {name: 'City', key: 'city'},
      {name: 'State', key: 'state'},
      {name: 'Address', key: 'address'},
    ]
  }

  handleTypeChange(id, value){
    if(this.state.newSegments[id]){
      let segment = this.state.newSegments[id]
      segment.segmentType = value
      this.setState({
        newSegments: {
          ...this.state.newSegments,
          [id]: segment
        }
      })
    }
    this.setState(this.state)
  }

  handleValueChange(id, e){
    if(this.state.newSegments[id]){
      let value = e.target.value
      let segment = this.state.newSegments[id]
      segment[segment.segmentType || ''] = value
      this.setState({
        newSegments: {
          ...this.state.newSegments,
          [id]: segment
        }
      })
    }
  }

  render() {

    const { 
      name,
      description
    } = this.serviceArea

    let initialValues = {
      name,
      description,
    }
    
    for(let segment of this.segments) {
        const key = `--${segment.id}`
        initialValues[`segmentType${key}`] = segment.segmentType
        if(segment.segmentType == 'address'){
            initialValues = {
                ...initialValues,
                [`address${key}`]: segment.address,
                [`range${key}`]: segment.range,
                [`city${key}`]: segment.city,
                [`state${key}`]: segment.state,
                [`zip${key}`]: segment.zip
            }
        } else if(segment.segmentType == 'state'){
            initialValues[`state${key}`] = segment.state
        } else {
            initialValues[`value${key}`] = segment[segment.segmentType]
        }
    }
    
    let edit = false;
    
    if(this.serviceArea.id){
      edit = true
    }
    return (
      <Drawer
        title={this.serviceArea.id ? 'Edit Service Area' : 'Create Service Area'}
        width={520}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <Form ref={this.formRef} initialValues={initialValues} layout="vertical" onFinish={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Name"
                name="name"
                rules={[{ message: 'Please select a name' }]}
              >
                <Input style={{width: '100%'}}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Description"
                name="description"
                rules={[{ message: 'Please select a description' }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
            <div style={{width: '100%'}}>
              {this.segments.length != 0 && (
                <h3 style={{marginTop: 2}}>Where</h3>
              )}
              <br />
              <br />
              {this.segments.map((segment, segmentI)=>{
                return (
                    <Row key={segment.id} gutter={10}>
                        <Col span={24}>
                            {segmentI !== 0 && (
                                <Fragment>
                                    <Divider>And</Divider>
                                    <br />
                                </Fragment>
                            )}
                            <CloseCircleOutlined 
                                onClick={this.handleDestroySegment.bind(this,segment.id)} 
                                style={{
                                    position: "absolute", 
                                    right: "0", cursor: "pointer",
                                    zIndex: 2,
                                    marginTop: "-25px",
                                    marginRight: "5px"
                                }}
                            />
                            <Form.Item>
                                <Input.Group compact >
                                    <Form.Item  
                                        style={{ display: 'inline-block', width: '150px' }}
                                        name={`segmentType--${segment.id}`}
                                    >
                                        <Select 
                                            style={{ display: 'block'}}
                                            onChange={this.handleTypeChange.bind(this, segment.id)}
                                            placeholder="Area Type"
                                        >
                                            {this.keys.map((key, keyI)=>(
                                                <Select.Option key={`types-${segment.id}${keyI}`} value={key.key}>{key.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    {this.segmentType(segment) == 'address' ? (
                                        <Fragment>
                                            <Row gutter={10} style={{display: "inline", margin: "10px 5px"}}>
                                                <Form.Item 
                                                    label="Range (miles)"
                                                    name={`range--${segment.id}`}
                                                    style={{margin: "0px"}}
                                                > 
                                                    <InputNumber style={{width: '100%'}}/>
                                                </Form.Item>
                                                <Form.Item 
                                                    label="Address"
                                                    name={`address--${segment.id}`}
                                                    style={{margin: "0px"}}
                                                >
                                                    <Input style={{width: '100%'}}/>
                                                </Form.Item>
                                                <Form.Item
                                                    label="City"
                                                    name={`city--${segment.id}`}
                                                    style={{margin: "0px"}}
                                                >
                                                    <Input style={{width: '100%'}}/>
                                                </Form.Item>
                                                <Form.Item
                                                    label="State"
                                                    name={`state--${segment.id}`}
                                                    style={{margin: "0px"}}
                                                >
                                                    <Input style={{width: '100%'}}/>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Zipcode"
                                                    name={`zip--${segment.id}`}
                                                    style={{margin: "0px"}}
                                                >
                                                    <Input style={{width: '100%'}}/>
                                                </Form.Item>
                                            </Row>
                                        </Fragment>
                                    ) : this.segmentType(segment) == 'state' ? (
                                        <Form.Item
                                            name={`state--${segment.id}`}
                                            style={{ display: 'inline-block', width: 'calc(100% - 150px)' }}
                                        >
                                            <Select
                                                style={{ display: 'block'}}
                                                showSearch
                                                placeholder={"Select State / Province"}
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={false}
                                                notFoundContent={null}
                                                filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {this.states.map((state, i)=>(
                                                    <Select.Option key={`state-${i}`} value={state.value}>{state.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>  
                                    ) : (
                                        <Form.Item
                                            name={`value--${segment.id}`}
                                            style={{ display: 'inline-block', width: 'calc(100% - 150px)' }}
                                        >
                                            <Input 
                                                onChange={this.handleValueChange.bind(this, segment.id)} 
                                                placeholder="Value"
                                            />
                                        </Form.Item>
                                    )}
                                </Input.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                )
              })}
              <Button onClick={this.addSegment} style={{marginBottom: '4em'}}>Add Segment</Button>
            </div>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
              <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyServiceArea} cancelText="No">
                  <Button type='danger' className='pull-left'>Delete Service Area</Button>
              </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.serviceArea.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  (CreateOrEditServiceArea)
)