import React, { Component, Fragment } from 'react';
import { Form, Card, Input, Select, Switch, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { Scroller, AdminLayout, SectionContent, GeneralEditor } from 'components/elements';
import { connect } from 'react-redux';
import {organizationActions, sessionActions, companyActions} from 'actions'
import {partnerActions, accountActions} from 'actions'
import moment from 'moment'


class General extends Component { 
    
    constructor(props) { 
        super(props)
        this.formRef = React.createRef()
        this.state = {
            partnerIndex: 0
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleReminders = this.handleReminders.bind(this)
        this.handlePartnerChange = this.handlePartnerChange.bind(this)
        this.handleSavePartnerChange = this.handleSavePartnerChange.bind(this)
        this.handleRemovePartnerChange = this.handleRemovePartnerChange.bind(this)
    }

    componentDidMount(){
        organizationActions.fetch()
        partnerActions.fetch()
        companyActions.fetch()
    }

    get organization() { 
        return this.props.store.currentOrganization || {}
    }

    get organizations() { 
        return Object.values(
            this.props.store.organizations || {}
        )
    }

    get form() {
        return this.formRef.current
    }
    
    async handleSubmit (values) {
        const _values = _.omitBy(values, _.isNil)
        await organizationActions.update({
            ..._values,
            parentID: values.parentID,
            primarySibling: values.primarySibling
        })
    }    

    get isGrouped(){
        if(this.form){
            return this.form.getFieldValue('parentID')
        }else{
            return this.organization.parentID
        }
    }

    handleToggleKontakPartner(e){
        organizationActions.addPartner()
    }
    
    get partners(){
        const currentPartnerIDs = _.get(this, 'organization.partners',[]).filter(({id})=>(
            id
        ))
        return Object.values(Object(this.props.store.partners)).filter(({id})=>(
            !currentPartnerIDs.includes(id)
        ))
    }
    get companies(){
        return Object.values(Object(this.props.store.companies))
    }
    handleReminders(e){
        organizationActions.toggleReminders()
    }
    
    handlePartnerChange(id){
        this.setState({
            partnerID: id
        })
    }
    
    handleSavePartnerChange(){
        organizationActions.addPartner({
            partnerID: this.state.partnerID,
            organizationID: this.organization.id
        })
    }

    handleRemovePartnerChange(partnerID){
        organizationActions.removePartner({
            partnerID: partnerID,
            organizationID: this.organization.id
        })
    }

    render() {
        let initialValues = {
            ...(this.organization),
            waitList: (this.organization.settings || {}).waitList,
            waitListMessageDelay: (this.organization.settings || {}).waitListMessageDelay,
            sendReminders: (this.organization.settings || {}).reminders,
            genericPatients: (this.organization.settings || {}).genericPatients || false
        }

        return <AdminLayout>
            <SectionContent noPadding noMargin>
                <Form ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
                    <GeneralEditor onSave={true}>
                        <Scroller>
                            <Card bordered={false}>
                                <Form.Item
                                    name="name"
                                    rules={[{required: true, message: 'Please enter a name'}]}
                                >
                                    <Input placeholder={'name'} />
                                </Form.Item>
                                <Form.Item
                                    name="address"
                                >
                                    <Input placeholder={'address'} />
                                </Form.Item>
                                <Form.Item
                                    name="unit"
                                >
                                    <Input placeholder={'unit'} />
                                </Form.Item>                                                    
                                <Form.Item
                                    name="city"
                                >
                                    <Input placeholder={'city'} />
                                </Form.Item>
                                <Form.Item
                                    name="state"
                                >
                                    <Input placeholder={'state'} />
                                </Form.Item>
                                <Form.Item
                                    name="zip"
                                >
                                    <Input placeholder={'zip'} />
                                </Form.Item>
                                <Form.Item
                                    name="country"
                                >
                                    <Input placeholder={'country'} />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                >
                                    <Input placeholder={'email'} />
                                </Form.Item>
                                <Form.Item
                                    name="contact"
                                >
                                    <Input placeholder={'contact'} suffix="@mg.nextinline.com" />
                                </Form.Item>
                                <Form.Item
                                    name="practicePhone"
                                >
                                    <Input placeholder={'phone'} />
                                </Form.Item>
                                <Form.Item
                                    name="type"
                                >
                                    <Input placeholder={'type'} />
                                </Form.Item>
                                <Form.Item
                                    name="customTOS"
                                >
                                    <Input placeholder={'custom TOS'} />
                                </Form.Item>
                                {accountActions.isSuperAdministrator() &&
                                    <Form.Item
                                        name="companyID"
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Company"
                                            // onChange={this.handleCompanyChange.bind(null)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {this.companies.map((company, i)=>(
                                                <Select.Option key={i} value={company.id}>
                                                    {company.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                }
                                {accountActions.isSuperAdministrator() &&
                                    <Fragment>
                                        <Card>
                                            <label>Current Partners</label>
                                            {_.get(this, 'organization.partners', []).map((partner)=>(
                                                <div>
                                                    {Object(partner).name} <span onClick={this.handleRemovePartnerChange.bind(null, Object(partner).id)}><DeleteOutlined /></span>
                                                </div>
                                            ))}
                                            <Form.Item
                                                label="Add to Partner"
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Partner"
                                                    onChange={this.handlePartnerChange.bind(null)}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {this.partners.map((partner, i)=>(
                                                        <Select.Option key={i} value={partner.id}>
                                                            {partner.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Button onClick={this.handleSavePartnerChange}>Add</Button>
                                        </Card>
                                        <br />
                                    </Fragment>
                                }
                                <Form.Item>
                                    <Form.Item
                                        name="groupReminders"
                                        noStyle
                                        valuePropName='checked'
                                    >
                                        <Switch />
                                    </Form.Item>
                                    &nbsp;Group Reminders
                                </Form.Item>
                                <Form.Item
                                    name="timezone"
                                    rules={[{required: true, message: 'Please enter a timeZone'}]}
                                >
                                    <Select
                                    showSearch
                                    placeholder="Select Timezone"
                                    filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {[...moment.tz.names()].map((timezone, i)=>(
                                            <Select.Option key={timezone}>
                                            {timezone}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="mobilePhone"
                                >
                                    <Input placeholder={'Mobile Phone'} />
                                </Form.Item>
                                <Form.Item
                                    name="parentID"
                                >
                                    <Select 
                                        showSearch
                                        placeholder={'Parent Organization'}
                                        filterOption={(input, option) =>
                                            String(option.children || "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Select.Option value={null} key={`parent-organization--1`}>
                                            None
                                        </Select.Option>
                                        {this.organizations.map((organization, i)=>(
                                            <Select.Option key={`parent-organization-${i}`} value={organization.id}>
                                                {organization.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {this.isGrouped && (
                                    <Form.Item>
                                        <Form.Item
                                            name="primarySibling"
                                            noStyle
                                            valuePropName='checked'
                                        >
                                            <Switch />
                                        </Form.Item>
                                        &nbsp;Primary Sibling (Grouped orgs only)
                                    </Form.Item>
                                )}
                                {accountActions.isSuperAdministrator()&&(
                                    <Form.Item>
                                        <Form.Item
                                            name="isPartitioned"
                                            noStyle
                                            valuePropName='checked'
                                        >
                                            <Switch />
                                        </Form.Item>
                                        &nbsp;Full Data Partitioning
                                    </Form.Item>
                                )}
                                <Form.Item
                                    name="genericPatients"
                                >
                                    <Select 
                                        placeholder={'Patient Type'}
                                    >
                                        <Select.Option key={`patient-type-animal`} value={false}>
                                            Animal Patients
                                        </Select.Option>
                                        <Select.Option key={`patient-type-human`} value={true}>
                                            Human Patients
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item
                                        name="waitList"
                                        noStyle
                                        valuePropName='checked'
                                    >
                                        <Switch />
                                    </Form.Item>
                                    &nbsp;Activate Wait List
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item
                                        name="sendReminders"
                                        noStyle
                                        valuePropName='checked'
                                    >
                                        <Switch onChange={this.handleReminders} />
                                    </Form.Item>
                                    &nbsp;Send Reminders
                                </Form.Item>
                                {(this.organization.settings || {}).waitList &&
                                    <Form.Item>
                                        <Form.Item
                                            name="waitListMessageDelay"
                                        >
                                            <Input type='number' placeholder={'Message Delay'} />
                                        </Form.Item>
                                    </Form.Item>
                                }
                            </Card>
                        </Scroller>
                    </GeneralEditor>
                </Form>
            </SectionContent>
        </AdminLayout>
    }
}

export default connect((state)=> ({
    store: state
}))(General);