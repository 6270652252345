import React from 'react'
import { Modal } from 'antd'
import {SnapList} from 'components/elements/ListEditor'
import {withRouter} from 'react-router-dom'

class AppointmentStatusCards extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleDeleteButtonOnClick = this.handleDeleteButtonOnClick.bind(this)
  }

  handleDeleteButtonOnClick() {
    const handleTableOnDelete = () => { this.props.onDelete() }
    Modal.confirm({
      title: 'Delete Item',
      content: 'Are you sure to delete this item?',
      onOk() {
        handleTableOnDelete()
      },
      onCancel() {
      },
    })
  }
  

  render() {
    return (
      <SnapList
        data={this.props.dataSource || []}
        listType={'Appointment Status'}
        sortBy={'name'}
        createPrompt={'Create New Appointment Status'}
        initial={this.props.match.params.id}
        onSelect={this.props.onSelect}
        onCreate={this.props.onCreate}
        onSearch={this.props.onSearch}
        row={(props)=>(
          <div>
            {props.name}
          </div>
        )}
      />
    )
  }
}

export default withRouter(AppointmentStatusCards)