import React, {Component, Fragment} from 'react';
import { Card, Drawer, Form, Button, Col, Row, Input, Select } from 'antd';
import { UpOutlined } from '@ant-design/icons'
import moment from 'moment'
import {appointmentActions, patientActions} from 'actions'
import {connect} from 'react-redux'
import {CreateOrEditAppointment} from 'components/partials'
import { Fa } from 'components/elements'
import './index.less'

const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;
const ButtonGroup = Button.Group;

class SelectAppointmentWithRestrictions extends Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createAppointmentTypeVisible: false,
      providerSearch: '',
      minimized: {}
    };

    this.handleAppointmentEditClose = this.handleAppointmentEditClose.bind(this)
    this.handleAppointmentCreate = this.handleAppointmentCreate.bind(this)
    this.filterAppointments = this.filterAppointments.bind(this)
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  async handleSubmit (values) {
    if(this.appointment.id){
      appointmentActions.update({
        id: this.appointment.id,
        patientID: values.patientID || null,
        administratorID: values.administratorID || null,
        appointmentTypeID: values.appointmentTypeID || null,
        reason: values.reason,
        startsAt: values.startTime,
        endsAt: values.endTime
      })
    }else{
      appointmentActions.create({
        appointmentTypeID: values.appointmentTypeID || null,
        patientID: values.patientID || null,
        administratorID: values.administratorID || null,
        reason: values.reason,
        startsAt: values.startTime,
        endsAt: values.endTime
      })
    }
    this.props.onClose()
  }

  componentDidUpdate(previousProps){
    if(previousProps.visible != this.props.visible){
      this.form && this.form.resetFields();
    }

    let nextAppointments = _.get(nextProps, 'selectedAppointments.events', [])
    if(nextAppointments.length > 0 &&
    nextAppointments != this.appointments){
      this.filterAppointments(nextAppointments)
    }
  }

  async filterAppointments(nextAppointments){
    this.setState({
      loading: true
    })
    let promises = []
    for(let appointment of nextAppointments){
      if(appointment.patientID && !this.props.patients[appointment.patientID]){
        promises.push(patientActions.safeFetchByID({id: appointment.patientID}))
      }
    }

    try{
      await Promise.all(promises)
    } catch(e){
      console.log(e)
    }

    let appointments = {unrestricted:[]}
    for(let appointment of nextAppointments){
      let appointmentUnrestricted = true
      let patient = _.get(this, `props.patients[${appointment.patientID}]`)
      if(patient){
        for(let restriction of this.restrictions){
          if(appointment.appointmentTypeID && restriction.restrictionableID == appointment.appointmentTypeID){
            if(!appointments[appointment.appointmentTypeID]) appointments[appointment.appointmentTypeID] = {unrestricted:[]}
            if(!appointments[appointment.appointmentTypeID][restriction.id]) appointments[appointment.appointmentTypeID][restriction.id] = []
            
            let matchFound = true
            if(restriction.rules){
              for(let rule of restriction.rules){
                if(matchFound){
                  let key = rule.key
                  let value = rule.value || rule.restrictionRuleableID
                  let patientValue = patient[key]
                  if(key == 'age' && patient.birthday){
                    patientValue = moment().diff(moment(patient.birthday),"years")
                  }

                  if(patientValue){
                    switch(rule.operator){
                      case '==':
                        if(patientValue != value) matchFound = false
                        break;
                      case '!=':
                        if(patientValue == value) matchFound = false
                        break;
                      case '<':
                        if(parseFloat(patientValue) >= parseFloat(value)) matchFound = false
                        break;
                      case '<=':
                        if(parseFloat(patientValue) > parseFloat(value)) matchFound = false
                        break;
                      case '>':
                        if(parseFloat(patientValue) <= parseFloat(value)) matchFound = false
                        break;
                      case '>=':
                        if(parseFloat(patientValue) < parseFloat(value)) matchFound = false
                        break;
                      default:
                        matchFound = false
                    }
                  } else {
                    matchFound = false
                  }
                }
              }

              if(matchFound){
                appointments[appointment.appointmentTypeID][restriction.id].push(appointment)
                appointmentUnrestricted = false
              } 
            }
          }
        }
        if(appointmentUnrestricted){
          if(appointment.appointmentTypeID){
            if(!appointments[appointment.appointmentTypeID]) appointments[appointment.appointmentTypeID] = {unrestricted:[]}
            appointments[appointment.appointmentTypeID].unrestricted.push(appointment)
          } else {
            if(!appointments.unrestricted) appointments.unrestricted = []
            appointments.unrestricted.push(appointment)
          }
        } 
      }
    }

    this.setState({
      loading: false,
      appointments
    })
  }

  handleAppointmentTypeSearch(value){
    this.setState({
      appointmentTypes: appointmentTypes.filter((_)=>(
        _.title.toLocaleLowerCase().includes(value.toLowerCase())
      ))
    });
  }

  componentDidMount(){
    if(this.props.patientID){
      patientActions.findOrFetch(this.appointment.patientID)
    }
  }

  handleAppointmentEdit(appointment){
    this.setState({
      appointment
    }, ()=>{
      this.setState({
        createOrEditAppointmentVisible: true
      })
    })
  }

  handleAppointmentCreate(){
    this.setState({
      appointment: {
        start: this.props.selectedAppointments.startsAt,
        end: this.props.selectedAppointments.endsAt
      }
    }, ()=>{
      this.setState({
        createOrEditAppointmentVisible: true
      })
    })
  }
  
  handleAppointmentEditClose(){
    this.setState({
      appointment: {}
    }, ()=>{
      this.setState({
        createOrEditAppointmentVisible: false
      })
    })
  }

  toggleMinimize(restrictionID){
    this.setState({
      minimized: {
        ...this.state.minimized,
        [`${restrictionID}`]: !this.state.minimized[restrictionID]
      }
    })
  }

  get restrictedAppointments(){
    return this.state.appointments || {}
  }
  
  get appointments(){
    return _.get(this, 'props.selectedAppointments.events', [])
  }

  get appointment(){
    const _event = this.state.appointment || {}

    return {
      id: _event.id,
      title: _event.reason,
      appointmentTypeID: _event.appointmentTypeID,
      administratorID: _event.administratorID,
      patientID: _event.patientID,
      start: moment(_event.start),
      end: moment(_event.end),
    }
  }

  get restrictions(){
    return this.props.eventRestrictions || []
  }

  get form() {
    return this.formRef.current
  }

  get appointmentTypes(){
    return Object.values(this.props.appointmentTypes || {})
  }

  render() {

    let edit = false;
    let editAppointmentType = false;
    let editPatient = false;
    let editProvider = false;
    
    window.me = this

    if(this.appointment.id){
      edit = true
    }
    if(this.form && this.form.getFieldValue('appointmentTypeID')){
      editAppointmentType = true
    }

    if(this.form && this.form.getFieldValue('patientID')){
      editPatient = true
    }

    if(this.form && this.form.getFieldValue('administratorID')){
      editProvider = true
    }

    let _restrictedAppointments = this.restrictedAppointments

    const title = (<div
          style={{
            position: 'absolute',
            top: 0,
            zIndex:1,
            width: '100%',
            borderBottom: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'left',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}
          >Appointments on <b>
                {moment(_.get(this, 'props.selectedAppointments.startsAt')).format('M/DD/YYYY')}
          </b> from <b>
            {moment(_.get(this, 'props.selectedAppointments.startsAt')).format('h:mma')}
          </b> to <b>
            {moment(_.get(this, 'props.selectedAppointments.endsAt')).format('h:mma')}
          </b>
    </div>)
    return (
      <Drawer
        title={title}
        width={720}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <CreateOrEditAppointment 
          visible={this.state.createOrEditAppointmentVisible} 
          onClose={this.handleAppointmentEditClose}
          onSetCurrentAppointment={this.handleSetCurrentAppointment}
          appointment={this.appointment}
        />
        <Form layout="vertical" ref={this.formRef} onFinish={this.handleSubmit}>
          <Row>
            <Col span={24}>
              {this.state.loading ? 
                  <Fa icon='spinner' class='fa-spin restriction-loading-spinner'></Fa>
                :
                <Fragment>
                  {this.appointmentTypes.filter((appointmentType)=>_restrictedAppointments[appointmentType.id]).map((appointmentType)=>(
                    <div style={{paddingBottom: "10px"}}>
                      <h2 className="restriction-appointment-type-header">{appointmentType.name}</h2>
                      {this.restrictions.filter((restriction)=>restriction.restrictionableID == appointmentType.id).map((restriction,i)=>(
                        <div style={{position: "relative"}}>
                          <h3>{restriction.name} ({((_restrictedAppointments[appointmentType.id]||{})[restriction.id] || []).length}/{restriction.maxPatients || 0})</h3>
                          {this.state.minimized[restriction.id]? (
                            <UpOutlined onClick={this.toggleMinimize.bind(this,restriction.id)} className='appointment-restriction-minimize' /> 
                          ) : (
                            <DownOutlined onClick={this.toggleMinimize.bind(this,restriction.id)} className='appointment-restriction-minimize' />
                          )}
                          {this.state.minimized[restriction.id] && ((_restrictedAppointments[appointmentType.id]||{})[restriction.id] || []).map((appointment, i)=>(
                            <Card key={`${i}`} style={{marginBottom: 14}} onClick={this.handleAppointmentEdit.bind(this, appointment)} className='clickable-card'>
                              {appointment.reason}
                              <br />
                              {_.get(this, `props.patients[${appointment.patientID}].firstName`)}
                              {_.get(this, `props.patients[${appointment.patientID}].lastName`)}
                              <br />
                            </Card>
                          ))}
                        </div>
                      ))}
                    {(_restrictedAppointments[appointmentType.id]||{}).unrestricted &&
                    (_restrictedAppointments[appointmentType.id]||{}).unrestricted.length > 0 &&
                    (_restrictedAppointments[appointmentType.id]||{}).unrestricted.filter((appointment)=>
                      appointment.appointmentTypeID == appointmentType.id
                    ).length > 0 &&
                      <div style={{position: "relative"}}>
                        <h3>Unrestricted</h3>
                        {this.state.minimized[appointmentType.id]? (
                          <DownOutlined onClick={this.toggleMinimize.bind(this,appointmentType.id)} className='appointment-restriction-minimize' />
                        ) : (
                          <UpOutlined onClick={this.toggleMinimize.bind(this,appointmentType.id)} className='appointment-restriction-minimize' />
                        )}
                        {!this.state.minimized[appointmentType.id] && (_restrictedAppointments[appointmentType.id]||{}).unrestricted.filter((appointment)=>appointment.appointmentTypeID == appointmentType.id).map((appointment, i)=>(
                          <Card key={`${i}`} style={{marginBottom: 14}} onClick={this.handleAppointmentEdit.bind(this, appointment)} className='clickable-card'>
                            {appointment.reason}
                            <br />
                            {_.get(this, `props.patients[${appointment.patientID}].firstName`)}
                            {_.get(this, `props.patients[${appointment.patientID}].lastName`)}
                            <br />
                          </Card>
                        ))}
                      </div>
                    }
                    </div>
                  ))}
                  {_restrictedAppointments.unrestricted && _restrictedAppointments.unrestricted.length > 0 &&
                    <div style={{position: "relative"}}>
                      <h3>No Appointment Types</h3>
                      {this.state.minimized.unrestricted ? (
                        <DownOutlined onClick={this.toggleMinimize.bind(this,'unrestricted')} className='appointment-restriction-minimize' />
                      ) : (
                        <UpOutlined onClick={this.toggleMinimize.bind(this,'unrestricted')} className='appointment-restriction-minimize' />
                      )}
                      {!this.state.minimized.unrestricted && _restrictedAppointments.unrestricted.map((appointment, i)=>(
                        <Card key={`${i}`} style={{marginBottom: 14}} onClick={this.handleAppointmentEdit.bind(this, appointment)} className='clickable-card'>
                          {appointment.reason}
                          <br />
                          {_.get(this, `props.patients[${appointment.patientID}].firstName`)}
                          {_.get(this, `props.patients[${appointment.patientID}].lastName`)}
                          <br />
                        </Card>
                      ))}
                    </div>
                  }
                </Fragment>
              }
              <br />
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" onClick={this.handleAppointmentCreate}>Create New</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  (SelectAppointmentWithRestrictions)
)