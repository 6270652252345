import {
    FETCH_CUSTOM_FIELDS,
    CREATE_CUSTOM_FIELD,
    UPDATE_CUSTOM_FIELD,
    DESTROY_CUSTOM_FIELD,
    SET_ORGANIZATION_CURRENT
  } from '../constants/actionTypes'
  
export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_CUSTOM_FIELDS:
            return {
                ...state,
                ...action.payload
            }
        case CREATE_CUSTOM_FIELD:
            return {
                ...state,
                [`${action.payload.id}`]: action.payload
            }
        case DESTROY_CUSTOM_FIELD:
            state = {
                ...state
            }
            delete state[action.payload]
            return state
        case UPDATE_CUSTOM_FIELD:
            return {
                ...state,
                [`${action.payload.id}`]: action.payload
            }
        case SET_ORGANIZATION_CURRENT:
            return {}
        default:
            return state
    }
}
