import React from 'react'
import {
    List, Input, Row, Col
} from 'antd'

import moment from 'moment'
import { Spinner, Scroller } from 'components/elements'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { messageActions } from 'actions'
import parse from 'html-react-parser';

const TextArea = Input.TextArea

class ShowOrganization extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount(){
        this.setState({
            loading: true
        })

        messageActions.fetch({
            limit: 100,
            adminInbox: true
        }).then(()=>{
            this.setState({
                loading: false
            })
        })
    }

    handleMessageClick(message){
        this.props.history.push(`/admin/messages/${message.accountID}`)    
    }

    get messages(){
        return Object.values(this.props.messages || {})
    }

    render() {
        return (
            <div>
                <br />
                {this.state.loading ? 
                    <Spinner style={{marginTop: '100px'}}/>
                :
                    <div>
                        <Scroller className={'message-scroller'}>
                            <List 
                                style={{marginBottom: '60px'}}
                                bordered
                                dataSource={this.messages}
                                renderItem={(message, i) => (
                                    <List.Item>
                                        <Row 
                                            onClick={this.handleMessageClick.bind(this,message)} 
                                            style={{
                                                width: '100%',
                                                cursor: 'pointer'
                                            }}> 
                                                <Col xs={3}>
                                                    {message.sender.firstName} {message.sender.lastName}
                                                </Col>
                                                <Col xs={3}>
                                                    {moment(message.createdAt).format('hh:mma MM/DD/YYYY')}
                                                </Col>
                                                <Col xs={3}>
                                                    {message.direction}
                                                </Col>
                                                <Col xs={3}>
                                                    {message.services || message.service}
                                                </Col>
                                                <Col xs={12}>
                                                    {parse(`${message.body}`)}
                                                </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </Scroller>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {

  const {patientCurrent} = state

  return {
    accounts: state.accounts,
    currentAccount: Object(state.session).account,
    patientCurrent,
    patients: state.patients,
    accountsPatients: state.accountsPatients,
    breeds: state.breeds,
    genders: state.genders,
    classifications: state.classifications,
    messages: state.messages,
    notifications: state.notifications
  }
}

const mapDispatchToProps = dispatch => ({
  fetchItems: params => dispatch(fetchItems(params)),
  changeTable: params => dispatch(changeTable(params)),
  editSearch: search => dispatch(editSearch(search)),
  searchTable: search => dispatch(searchTable(search)),
  setCurrent: search => dispatch(setCurrent(search)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowOrganization))