import React, {Component, Fragment} from 'react'
import {Drawer} from 'antd'
import moment from 'moment'
import CreateAppointment from '../../../partials/CreateOrEditAppointment'
import SelectAppointmentWithRestrictions from '../../../partials/SelectAppointmentWithRestrictions'
import {connect} from 'react-redux'
import {
    appointmentActions
} from 'actions'
import { Calendar, momentLocalizer } from 'react-big-calendar'

const localizer = momentLocalizer(moment)
 
class InternalCalendar extends Component {
    constructor(){
        super()
        this.state = {
            view: 'week',
            events: []
        }
        this.handleChangeView = this.handleChangeView.bind(this)
        this.handleRangeChange = this.handleRangeChange.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
        this.hideCreate = this.hideCreate.bind(this)
        this.hideSelect = this.hideSelect.bind(this)
        this.eventRestrictions = this.eventRestrictions.bind(this)
    }
    handleChangeView(view){
        this.setState({
            view
        })
    }

    get events(){
        return this.state.events || []
    }
    handleRangeChange(days){
        appointmentActions.fetch({startingAt: days[0], unit: this.state.view|| 'week'})
    }
    handleSelect(event){
        this.setState({
            selectVisible: true,
            event
        })
    }
    handleCreate(event){
        this.setState({
            createVisible: true,
            event,
            events: [event]
        })
    }
    hideCreate(event){
        this.setState({
            createVisible: false,
            event,
            events: []
        })
    }
    hideSelect(event){
        this.setState({
            selectVisible: false,
            event,
            events: []
        })
    }
    eventRestrictions(event){
        if(!event){
            return []
        }
        let startsAt = moment(event.startsAt)
        let endsAt = moment(event.endsAt)
        return Object.values(this.props.restrictions).filter((restriction)=>{
            return endsAt.isAfter(moment(restriction.startsAt)) && startsAt.isBefore(moment(restriction.endsAt))
        })
    }
    get minTime(){
        let minTime = ''
        Object.values(this.props.openTimes || {}).forEach((openTime)=>{
            let openMin =  moment(openTime.startsAt).hour()
            if(!minTime || minTime > openMin){
                minTime = openMin
            }
        })
        if(minTime < 1 || !minTime){
            minTime = 1
        }
        return new Date(moment().startOf('day').hour((minTime - 1) || '0').format())
    }
    get maxTime(){
        let maxTime = ''
        Object.values(this.props.openTimes || {}).forEach((openTime)=>{
            let openMax =  moment(openTime.endsAt).hour()
            if(!maxTime || openMax > maxTime){
                maxTime = openMax
            }
        })
        if(maxTime > 22 || !maxTime){
            maxTime = 22
        }
        return new Date(moment().endOf('day').hour((maxTime + 1)||'23').format())
    }
    render(){
        return (
            <Fragment>
                <Calendar
                    localizer={localizer}
                    selectable
                    defaultDate={new Date()}
                    view={this.state.view}
                    onView={this.handleChangeView}
                    events={this.props.events}
                    onSelectEvent={this.handleSelect}
                    onSelectSlot={this.handleCreate}
                    onRangeChange={this.handleRangeChange}
                    views={['week', 'day']}
                    min={this.minTime}
                    max={this.maxTime}
                    step={15}
                    timeslots={4}
                />
                <CreateAppointment 
                    visible={this.state.createVisible} 
                    onClose={this.hideCreate} 
                    appointment={this.state.event} 
                />
                <SelectAppointmentWithRestrictions 
                    visible={this.state.selectVisible} 
                    onClose={this.hideSelect} 
                    selectedAppointments={this.state.event} 
                    eventRestrictions={this.eventRestrictions(this.state.event)} 
                />
            </Fragment>
        )
    }
}

export default connect((state)=> state)(InternalCalendar)