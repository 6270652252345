import React, {Component} from "react"
import { Button, Drawer, Form, Switch, Select } from "antd"
import { integrationConfigActions, manualWorkerExecutionActions } from "actions"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Fragment } from "react"
import covetrus from './covetrus'
import datapoint from './datapoint'
import evet from './evet'
import vetter from  './vetter'
import googleCalendar from  './googleCalendar'

class AddManualWorkerExecution extends Component {
    constructor(props){
        super(props)
        this.state = {
            open: false
        }
        
        this.showDrawer = this.showDrawer.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleManualWorkerExecution = this.handleManualWorkerExecution.bind(this);
    }

    componentDidUpdate(){
        const integrationConfigs = Object.values(this.props.integrationConfigs || {})
        const [activeIntegration] = integrationConfigs.filter(({fetchActive, integration})=>
            fetchActive && ['evet', 'covetrus', 'datapoint', 'vetter', 'googleCalendar'].includes(integration)
        )


        if(this.state.supervisorName == null){
            const activeIntegrationName = activeIntegration ? activeIntegration.integration : 'covetrus'
            this.setState({
                supervisorName:  `${activeIntegrationName}Fetchers`
            })
        }
    }
    showDrawer (e) {
        e.preventDefault()
        this.setState({
            open: true
        })
    }

    onClose () {
        this.setState({
            open: false
        })
    }

    handleManualWorkerExecution(params){
        params.supervisorName = this.state.supervisorName
        manualWorkerExecutionActions.create(params)
        this.onClose()
    }

    render(){
        const workerNames = {
            covetrusFetchers: covetrus,
            datapointFetchers: datapoint,
            evetFetchers: evet,
            vetterFetchers: vetter,
            googleCalendarFetchers: googleCalendar
        }


        return (
            <Fragment>
                <Button type="primary" onClick={this.showDrawer}>
                    Add Manual Job to Worker Queues
                </Button>
                <Drawer title="Worker Job" placement="right" onClose={this.onClose} visible={this.state.open}>
                    <Form onFinish={this.handleManualWorkerExecution} form={this.formRef}>
                        <Form.Item>
                            <Form.Item
                                name="skipActiveCheck"
                                noStyle
                                valuePropName='checked'
                            >
                                <Switch />
                            </Form.Item> Skip Active Check
                        </Form.Item>

                        <Form.Item
                            name="supervisorName"
                        >
                            <Select
                                showSearch
                                placeholder="Select a Supervisor"
                                optionFilterProp="children"
                                defaultValue={this.state.supervisorName ||  `${this.state.activeIntegrationName || "covetrus"}Fetchers`}
                                onChange={(value)=>{
                                    this.setState({
                                        supervisorName: value
                                    })
                                }}
                                value={this.state.supervisorName}
                                filterOption={(input, option) =>
                                    (Object(option).label || '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    {
                                        value: 'covetrusFetchers',
                                        label: 'Covetrus Fetchers'
                                    },
                                    {
                                        value: 'datapointFetchers',
                                        label: 'Datapoint Fetchers'
                                    },
                                    {
                                        value: 'evetFetchers',
                                        label: 'Evet Fetchers'
                                    },
                                    {
                                        value: 'vetterFetchers',
                                        label: 'Vetter Fetchers'
                                    },
                                    {
                                        value: 'googleCalendarFetchers',
                                        label: 'Google Calendar Fetchers'
                                    }
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            name="workerName"
                            rules={[{required: true, message: 'Please enter a name'}]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a Resource"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (Object(option).label || '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={workerNames[this.state.supervisorName] || []}
                            ></Select>
                        </Form.Item>
                        <Button htmlType="submit">Start</Button>
                    </Form>
                </Drawer>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => state

export default withRouter(connect(mapStateToProps)(AddManualWorkerExecution))