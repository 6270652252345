import { apiClient } from 'lib';
import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Drawer} from 'antd'
import Scroller from 'components/elements/Scroller'
import ReactJson from 'react-json-view'

export default (props)=>{
    const [state, setState] = useState({sessions:[]})
    useEffect(() => {
        apiClient.organizations.operations.widgetRequests.fetch().then(({data})=>{
            setState(($state)=>({
                ...$state,
                sessions: data
            }));
        })
    }, []);
    
    const getEmail = ($session)=>(
        Object(
            $session.requests.filter(({email})=>email)[0]
        ).email
    )
    const getMobilePhone = ($session)=>(
        Object(
            $session.requests.filter(({mobilePhone})=>mobilePhone)[0]
        ).mobilePhone
    )

    const showRequestsHandler = (id)=>{
        setState(($state)=>({
            ...$state,
            showSessionID: id
        }))
    }

    const showRequestHandler = ($session, $id, e)=>{
        e.preventDefault()
        e.stopPropagation();  
        const request = $session.requests.find(({id})=>id==$id)

        setState(($state)=>({
            ...$state,
            currentRequest: request
        }))
    }
    const handleClose = ()=>{
        setState((state)=>({
            ...state,
            currentRequest: undefined
        }))
    }
    return (
        <div style={{height: '100%', position: 'relative'}}>
            <Scroller>
                <div style={{padding: '0em 1em 2em 1em'}} onClick={handleClose}>
                    
                    {state.sessions.map(($session, $i)=>(
                        <div key={$i} style={{margin: '1em'}}>
                            {moment($session.requests[0].createdAt).format('D/MM/YY h:mma')} {getEmail($session)} 
                            <br />
                            {$session.sessionID}
                            <br />
                            {state.showSessionID == $session.sessionID ? (
                                $session.requests.map((request)=>{
                                    let style = {
                                        padding: '.2em .3em'
                                    }
                                    let color = "#000"
                                    if(request.response.status > 201){
                                        style = {backgroundColor: '#ff0000'}
                                        color = '#fff'
                                    }
                                    
                                    return (
                                        <div key={request.id} style={style}>
                                            <a href="#" onClick={showRequestHandler.bind(null, $session, request.id)} style={{color}}>
                                                <span style={{display: 'inline-block', width: '4em'}}>
                                                    {request.method}
                                                </span> 
                                                {request.url.split('?')[0]} 
                                            </a>
                                        </div>
                                    )
                                })
                            ):(
                                <button onClick={showRequestsHandler.bind(null, $session.sessionID)}>View Requests</button>
                            )}
                        </div>
                    ))}
                </div>
            </Scroller>
            <Drawer
                width={520}
                placement="right"
                mask={false}
                closable={false}
                visible={!!state.currentRequest}
                style={{
                    height: 'calc(100%)',
                    overflow: 'auto',
                    paddingBottom: 53,

                }}
                drawerStyle={{
                    borderLeft: '1px solid #eee'
                }}
                getContainer={false}
                style={{ position: 'absolute' }}
            >
                {!state.currentRequest?(
                    <div>Loading</div>
                ):(
                    <div>

                        <strong>url:</strong>
                        {
                            state.currentRequest.url.split('?')[0]
                        }
                        <br />
                        <strong>Headers:</strong>
                        <div style={{backgroundColor: 'rgb(39, 40, 34)', borderRadius: '4px', position: 'relative'}}>
                            <ReactJson name={null} src={Object(state.currentRequest.headers)} collapsed={true} theme="monokai" />
                        </div>
                        <br />
                        <strong>Params:</strong>
                        <div style={{backgroundColor: 'rgb(39, 40, 34)', borderRadius: '4px', position: 'relative', overflowX: 'auto'}}>
                            <ReactJson name={null} src={Object(state.currentRequest.params)} theme="monokai" />
                        </div>
                        <br />
                        <strong>status:</strong>{state.currentRequest.response.status}
                        <br />
                        <strong>Response:</strong>
                        <div style={{backgroundColor: 'rgb(39, 40, 34)', borderRadius: '4px', position: 'relative', overflowX: 'auto'}}>
                            <ReactJson name={null} src={state.currentRequest.response.body || {}} theme="monokai" />
                        </div>
                        <br />
                    </div>
                )}
            </Drawer>
        </div>
    )
}

