import {
  SET_SYNC_STATUS
} from '../constants/actionTypes'

export default (state = '', action) => {
  switch (action.type) {
    case SET_SYNC_STATUS:
      return action.payload
    default:
      return state
  }
}
