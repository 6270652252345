import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import * as administratorActions from './administrators'
import store from '../store'
import _ from 'lodash'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.accounts.fetch(params)
  let data = {};
  if(response.data.id){
    data[response.data.id] = response.data
  }else{
    data = response.data.reduce((acc, item)=>{
      acc[item.id] = item
      return acc;
    }, {})
  }

  store.dispatch({ 
    type: ActionTypes.FETCH_ACCOUNTS,
    payload: data
  })
}

const fetching = {}
export const safeFetchByID = async(params = {})=>{
  if(!fetching[params.id] && !store.getState().accounts[params.id]){
    fetching[params.id] = true
    await fetch({id: params.id})
    fetching[params.id] = false
  }
}

export const update = async(params = {}) => {
  const response = await apiClient.organizations.accounts.update(params)

  store.dispatch({
    type: ActionTypes.APPEND_ACCOUNT,
    payload: response.data
  })

  return response.data
}

export const create = async(params = {}) => {
  const response = await apiClient.organizations.accounts.create(params)

  store.dispatch({
    type: ActionTypes.APPEND_ACCOUNT,
    payload: response.data
  })

  return response.data
}

export const isSuperAdministrator = ()=>(
  administratorActions.test(
    store.getState().session.account
  )
)
export const isPartnerAdministrator = ()=>(
  !!['aphillips@getkontak.com'].includes(_.get(store.getState(), 'session.account.email'))
)