import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import store from '../store'

export const fetch = async(params = {})=>{
    const response = await apiClient.organizations.waitingRoom.fetch(params)

    if(response.data){
      store.dispatch({
          type: ActionTypes.FETCH_WAITING_ROOM,
          payload: response.data
      })
    }

    return response.data
}

export const reorder = async(params = {})=>{
    const response = await apiClient.organizations.waitingRoom.reorder(params)

    if(response.data){
        store.dispatch({
            type: ActionTypes.FETCH_WAITING_ROOM,
            payload: response.data
        })
    }

    return response.data
}

export const destroy = async (id = '') => {
  const response = await apiClient.organizations.waitingRoom.destroy(id)

  if(!response.data){
      store.dispatch({
        type: ActionTypes.DESTROY_WAITING_ROOM
      })
  }
}