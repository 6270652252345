import React, { Component } from 'react'
import { Drawer, Button, Form, Input, Popconfirm } from 'antd';
import {appointmentTypeActions} from 'actions'
import {CreateOrEditAppointmentTypeDurations} from 'components/partials'

class CreateAppointmentType extends Component {
    constructor(){
        super();
        this.formRef = React.createRef()
        this.state = { 
          createAppointmentTypeDurationsVisible: false,
          durations: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        appointmentTypeActions.fetch();
        this.handleDestroyAppointmentType = this.handleDestroyAppointmentType.bind(this)
        this.handleCreateOrEditAppointmentTypeDurationsClose = this.handleCreateOrEditAppointmentTypeDurationsClose.bind(this)
        this.handleCreateOrEditAppointmentTypeDurationsOpen = this.handleCreateOrEditAppointmentTypeDurationsOpen.bind(this)
        this.handleNewTypeDurations = this.handleNewTypeDurations.bind(this)
    }
    async handleSubmit(values){
        if(this.appointmentType.id){
            await appointmentTypeActions.update({
                id: this.appointmentType.id,
                ...values
            })
            this.props.onClose()
        }else{
            const appointmentType = await appointmentTypeActions.create({
                durations: this.state.durations || '',
                ...values
            })
            this.props.onSetCurrentAppointmentType(appointmentType.id)
            this.props.onClose()
        }
    }
    handleNewTypeDurations(durations){
        this.setState({durations})
    }
    handleCreateOrEditAppointmentTypeDurationsClose(){
        this.setState({
            createAppointmentTypeDurationsVisible: false
        })
    }
    handleCreateOrEditAppointmentTypeDurationsOpen(){
        this.setState({
            createAppointmentTypeDurationsVisible: true
        })
      }
    async handleDestroyAppointmentType(e){
        e.preventDefault()
        
        await appointmentTypeActions.destroy(this.appointmentType.id)
        this.props.onSetCurrentAppointmentType('')
        this.props.onClose()
    }
    componentDidUpdate(previousProps){
        if(previousProps.visible != this.props.visible){
            this.form && this.form.resetFields();
        }
    }
    get appointmentType(){
        return {
            ...(this.props.appointmentType || {})
        }
    }
    get durations(){
        if(!this.appointmentType.durations && !this.state.durations){
            return {}
        }

        let durationTimes = ((this.appointmentType.durations || this.state.durations || '').split(",") || []).map((duration)=> parseInt(duration))
        
        let maxDuration = 0
        durationTimes.forEach((duration)=>{
            if(duration > maxDuration){
                maxDuration = duration
            }
        })

        let maxPatients = durationTimes.length
        
        let durations = {}
        durationTimes.forEach((durationTime,i)=>{
            if(durationTime == 0){
                return
            }
            if(!durations[durationTime]){
                durations[durationTime] = {}
                durations[durationTime].patientCount = []
            }
            if(i == 0){
                durations[durationTime].patientCount.push('1 Patient')
            } else {
                durations[durationTime].patientCount.push(i+1)
            }
            durations[durationTime].style = {
                width: `${(durationTime / maxDuration) * 100}%`,
                zIndex: maxPatients - i
            }
        })

        return durations
    }

    get form() {
        return this.formRef.current
      }

    render(){

        const { 
            name,
            description,
            spacer
          } = this.appointmentType
      
          const initialValues = {
            name,
            description,
            spacer
          }

        let durations = this.durations
        return (
            <Drawer
                title={this.appointmentType.id?"Update Appointment Type":"Create Appointment Type"}
                width={420}
                visible={this.props.visible}
                onClose={this.props.onClose}
            >
                <CreateOrEditAppointmentTypeDurations
                    visible={this.state.createAppointmentTypeDurationsVisible}
                    onClose={this.handleCreateOrEditAppointmentTypeDurationsClose}
                    handleNewTypeDurations={this.handleNewTypeDurations}
                    appointmentType={this.appointmentType}
                />
                <Form ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
                    <Form.Item 
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input Appointment Type name!' }]}
                    >
                        <Input placeholder="Name of Appointment Type" />
                    </Form.Item>
                    <Form.Item 
                        label="Description"
                        name="description"
                    >
                        <Input.TextArea placeholder="Description" />
                    </Form.Item>
                    <label>Appointment Duration (Minutes):</label>
                    {Object.keys(durations).length?
                        <div className='appointment-length-container'>
                            <div className='appointment-length-indicator' style={{width: "0%"}}>
                                <div className='appointment-length-number'>
                                    0
                                </div>
                            </div>
                            {Object.keys(durations).map((time,i)=>{
                                let elementStyle=['appointment-length-contents']
                                if(i%2 == 1){
                                    elementStyle.push('off-color')
                                }
                                return (
                                    <span key={`duration-display-${i}`}>
                                        <div className='appointment-length-indicator' style={durations[time].style}>
                                            <div className='appointment-length-number'>
                                                {time}
                                            </div>
                                        </div>
                                        <div onClick={this.handleCreateOrEditAppointmentTypeDurationsOpen} className={elementStyle.join(' ')} style={durations[time].style}>
                                            <div className='appointment-length-contents-label'>
                                                {durations[time].patientCount.join(", ")}
                                            </div>
                                        </div>
                                    </span>
                                )
                            })}
                        </div>
                    : 
                        <Button onClick={this.handleCreateOrEditAppointmentTypeDurationsOpen} className="new-durations-btn">Set Appointment Durations</Button>
                    }
                    <Form.Item 
                        label="Time between generated appointments (Minutes)"
                        name="spacer"
                    >
                        <Input placeholder="spacer" />
                    </Form.Item>

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                        >
                        {this.appointmentType.id && 
                            <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyAppointmentType} cancelText="No">
                                <Button type='danger' className='pull-left'>Delete Appointment Type</Button>
                            </Popconfirm>
                        }
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={this.props.onClose}
                            >
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">{this.appointmentType.id ? 'Update' : 'Create'}</Button>
                    </div>
                </Form>
            </Drawer>
        )
    }
}

export default (CreateAppointmentType)