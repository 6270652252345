import Collection from 'lib/client/collections/collection'

class ServiceTimes extends Collection{
    fetch(options){
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/appointment-types/${options.appointmentTypeID}/service-times`, options);
    }

    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/appointment-types/${options.appointmentTypeID}/service-times`, options);
    }

    update(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/appointment-types/${options.appointmentTypeID}/service-times/${options.id}`, options);
    }
    
    destroy(options){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/appointment-types/${options.appointmentTypeID}/service-times/${options.id}`);
    }
}

export default ServiceTimes;