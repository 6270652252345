export default (organizationID)=>
`
<div id="nsa-widget"></div>
<script type="text/javascript">
    var script = document.createElement('script')
    script.onload = function() {
        var widgetContainer = document.querySelector('#nsa-widget');
        var query = window.location.search.substring(1);
        var params = {}
        if(query){
            var _params = query.split('&')
            for(var param of _params){
                var [key, value] = param.split('=')
                params[key] = value
            }
        }
        params.organizationID = '${organizationID}'
        let NILWidget = NextInLine.init(params)
        NILWidget.render(widgetContainer);
    }
    
    script.src = 'https://minnow.nextinline.io/bundle.js?version=' + (new Date()).getTime()
    document.head.appendChild(script)
</script>
`