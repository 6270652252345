import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import moment from 'moment'
import store from '../store'

export const fetch = async(params = {})=>{
    const response = await apiClient.organizations.serviceTimes.fetch(params)

    store.dispatch({
        type: ActionTypes.FETCH_SERVICE_TIMES,
        payload: response.data
    })

    return response.data
}

export const update = async (params = {}) => {
  const response = await apiClient.organizations.serviceTimes.update(params)

  store.dispatch({
    type: ActionTypes.APPEND_SERVICE_TIME,
    payload: response.data
  })

  return response.data
}

export const create = async (params = {}) => {
  const response = await apiClient.organizations.serviceTimes.create(params)

  store.dispatch({
    type: ActionTypes.APPEND_SERVICE_TIME,
    payload: response.data
  })

  return response.data
}

export const destroy = async (params = {}) => {
  await apiClient.organizations.serviceTimes.destroy(params)

  store.dispatch({
    type: ActionTypes.DESTROY_SERVICE_TIME,
    payload: params.id
  })
}