import {
  FETCH_ORGANIZATION_TYPES
} from '../constants/actionTypes'

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_ORGANIZATION_TYPES:
          return {
              ...action.payload
          }
        default:
            return state
    }
}
