import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';

import store from '../store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.operations.currentSyncStatus.fetch()
  let data = {};
  
  
  store.dispatch({ 
    type: ActionTypes.SET_SYNC_STATUS,
    payload: response.data
  })
}