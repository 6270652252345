import {
  FETCH_RESTRICTIONS,
  APPEND_RESTRICTION,
  UPDATE_RESTRICTIONS,
  DESTROY_RESTRICTION,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_RESTRICTIONS:
      return {
        ...state,
        ...action.payload,
      }
    case APPEND_RESTRICTION:
      let baseID = Object.values(action.payload)[0].id
      state = Object.values(state).filter((s)=>s.id != baseID).reduce((acc, item)=>{
        acc[item.repeatingID || item.id] = item
        return acc;
      }, {})
      return {
        ...state,
        ...action.payload
      }
    case DESTROY_RESTRICTION:
      state = Object.values(state).filter((s)=>s.id != action.payload).reduce((acc, item)=>{
        acc[item.repeatingID || item.id] = item
        return acc;
      }, {})
      return state
    case UPDATE_RESTRICTIONS:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
      case SET_ORGANIZATION_CURRENT:
          return {}
    default:
      return state
  }
}
