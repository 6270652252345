import React from 'react'
import { Modal, Input, Card, Button, Avatar} from 'antd'

import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import Scroller from 'components/elements/Scroller'

class Show extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      createPatientVisible: false
    }
    this.handleClose = this.handleClose.bind(this)
  }
  
  get current(){
    return this.props.current
  }

  handleClose(){
    this.setState({
      createPatientVisible: false
    })
  }

  render() {
    return (
      <Scroller>
        <Card bordered={false}>
          <div className='edit-switch'>
            <Link to={`/admin/appointment-types/${this.props.current.id}/edit`}>Edit</Link>
            <Link style={{"float":"right"}} to={`/admin/appointment-types/${this.props.current.id}/edit-service-times`}>Edit Service Times</Link>
            
            <h2 className='text-center'>
              {this.current.name}
            </h2>
            <br />
            {this.current.description &&
              <div>
                <label>Description:</label> {this.current.description}
                <br />
                <br />
              </div>
            }
            <label>ID:</label> {this.current.id} 
            <br />
            <br />
          </div>
        </Card>
      </Scroller>
    )
  }
}

export default withRouter(connect((state)=>(state))(Show))