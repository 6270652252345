import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import moment from 'moment'
import store from '../store'
import processRepeating from '../lib/processRepeating'

const getRepeatingOpenTimes = (responseData)=>{
  let data = processRepeating({
    data: responseData,
    startingAt: moment(),
    endingAt: moment().add(6,'months'),
    getPastItems: true
  })

  data = data.reduce((acc, item)=>{
    acc[item.repeatingID || item.id] = {
        ...item,
        startsAt: moment(item.startsAt),
        endsAt: moment(item.endsAt)
    }
    return acc;
  }, {})

  data = {
    ...data
  }

  return data
}

export const fetch = async(params = {})=>{
    const response = await apiClient.organizations.openTimes.fetch(params)

    let data = response.data
    if(!params.skipRepeating){
      data = getRepeatingOpenTimes(response.data)
    }

    store.dispatch({
        type: ActionTypes.FETCH_OPEN_TIMES,
        payload: data
    })
}

export const update = async (params = {}) => {
  const response = await apiClient.organizations.openTimes.update(params)

  const data = getRepeatingOpenTimes([response.data])

  store.dispatch({
    type: ActionTypes.APPEND_OPEN_TIME,
    payload: data
  })

  return response
}

export const create = async (params = {}) => {
  const response = await apiClient.organizations.openTimes.create(params)

  const data = getRepeatingOpenTimes([response.data])

  store.dispatch({
    type: ActionTypes.APPEND_OPEN_TIME,
    payload: data
  })

  return response
}

export const destroy = async (params = '') => {
  await apiClient.organizations.openTimes.destroy(params)

  store.dispatch({
    type: ActionTypes.DESTROY_OPEN_TIME,
    payload: params
  })
}