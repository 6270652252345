import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select } from 'antd';

import {superCreateOrganization} from 'actions/organizations'
import {CreateOrEditAdministrator} from 'components/partials'

import {connect} from 'react-redux'

import moment from 'moment'

const { Option } = Select;

class CreateOrganization extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCreateOrEditAdministratorClose = this.handleCreateOrEditAdministratorClose.bind(this)
    this.handleSetCurrentAdministrator = this.handleSetCurrentAdministrator.bind(this)
    this.handleEditAdministrator = this.handleEditAdministrator.bind(this)
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  async handleSubmit (values) {
    try {
      if(this.state.accountNotFound && !(values.firstName || values.lastName)){
        this.setState({
          errors: 'Please enter a first and last name for the organization owner.'
        })

        return
      }

      if(this.state.accountNotFound && !(values.firstName || values.lastName)){
        this.setState({
          errors: 'Please enter a first and last name for the organization owner.'
        })

        return
      }

      if(values.ownerAuthenticator.includes("@")){
        values.email = values.ownerAuthenticator
      } else {
        values.sms = values.ownerAuthenticator
      }
      let response = await superCreateOrganization(values)
      
      this.setState({
        accountNotFound: false
      })
      this.props.onClose()
    } catch(e) {

      this.setState({
        accountNotFound: true
      })
    }
  }

  componentDidUpdate(previousProps){
    if(previousProps.visible != this.props.visible){
      this.form && this.form.resetFields();
    }
  }

  handleCreateOrEditAdministratorClose(){
    this.setState({
      createAdministratorVisible: false
    })
  }

  handleSetCurrentAdministrator(administratorID){
    if(this.formRef.current) this.formRef.current.setFieldsValue({administratorID});
  }

  handleEditAdministrator(){
    this.setState({
      createAdministratorVisible: true
    })
  }

  get administrators(){
    return Object.values(this.props.administrators || {})
  }

  render() {

    const initialValues = {
      description: '',
      ownerAuthenticator: '',
      firstName: '',
      lastName: '',
      name: '',
      country: '',
      timezone: ''
    }
    
    let editProvider = false;

    if(this.formRef.current && this.formRef.current.getFieldValue('administratorID')){
        editProvider = true
    }

    return (
      <Drawer
        title={'Create Organization'}
        width={520}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <CreateOrEditAdministrator
          visible={this.state.createAdministratorVisible}
          onClose={this.handleCreateOrEditAdministratorClose}
          onSetCurrentAdministrator={this.handleSetCurrentAdministrator}
          administrator={this.administrator}
        />
        <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit} initialValues={initialValues}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                name="ownerAuthenticator" 
                label="Owner Email or Mobile Phone"
                rules={[{required: true, message: 'Please enter an email or mobile phone.'}]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {this.state.accountNotFound ?
            <div>
              <label style={{color: "red"}}>This account does not exist, please fill out a name.</label>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item 
                    label="First Name"
                    name="firstName"
                    rules={[{required: true, message: 'Please enter a first name.'}]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    label="Last Name"
                    name="lastName"
                    rules={[{required: true, message: 'Please enter a last name.'}]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          :''}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Organization Name"
                name="name"
                rules={[{required: true, message: 'Please enter an organization name.'}]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                name="description" 
                label="Organization Description" 
                rules={[{required: true, message: 'Please enter an organization description.'}]}
              > 
                <Input.TextArea placeholder="Description" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Country"
                name="country"
                rules={[{
                  type: 'string', message: 'Please select a country',
                }, {
                  required: true, message: 'Please select a country',
                }]}
              >
                <Select
                  showSearch
                  placeholder="Select Country"
                  filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                >
                    <Select.Option key='US' value='US'>United States of America</Select.Option>
                    <Select.Option key='CA' value='CA'>Canada</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Timezone"
                name="timezone"
                rules={[{
                  type: 'string', message: 'Please select a timezone',
                }, {
                  required: true, message: 'Please select a timezone',
                }]}
              >
                <Select
                  showSearch
                  placeholder="Select Timezone"
                  filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                >
                  {moment.tz.names().map((timezone, i)=>
                    <Select.Option key={timezone}>
                      {timezone}
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div
              style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
              }}
              >
              <Button
              style={{
                  marginRight: 8,
              }}
              onClick={this.props.onClose}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">Create</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  (CreateOrganization)
)