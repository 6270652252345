import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import moment from 'moment'
import {accountActions, waitListActions} from 'actions'
import {connect} from 'react-redux'
import {apiClient} from 'lib'
import CreateOrEditAccount from 'components/partials/CreateOrEditAccount'
const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;

class CreateOrEditWaitList extends React.Component {
    constructor(){
        super();
        this.formRef = React.createRef()
        this.state = { 
            visible: false,
            createAppointmentTypeVisible: false,
            createOrEditAccountVisible: false,
            patientsUsers: []
        };
        this.search = this.search.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAccountSearch = this.handleAccountSearch.bind(this)
        this.handleAccountChange = this.handleAccountChange.bind(this)
        this.handleEditAccount = this.handleEditAccount.bind(this)
        this.handleCreateOrEditAccountClose = this.handleCreateOrEditAccountClose.bind(this)
        this.selectAccountByID = this.selectAccountByID.bind(this)
        this.onCreateAccount = this.onCreateAccount.bind(this)
    }

    componentDidUpdate(previousProps){
        if(previousProps.visible != this.props.visible){
            this.form && this.form.resetFields();
            this.setState(this.state)
            let _account = this.waitList.account || {}
            let _prevAccount = (previousProps.waitList || {}).account || {}
            if(this.props.visible && _account.id && _account.id != _prevAccount.id){
                accountActions.safeFetchByID({
                    id: _account.id,
                })
            }
        }
    }

    async handleSubmit (values) {
        const _waitList = {
            accountID: values.accountID,
            appointmentTypeID: values.appointmentTypeID,
            administratorID: values.administratorID
        }
        if(this.waitList.id){
            waitListActions.update({
                id: this.waitList.id,
                ..._waitList
            })
        }else{
            let waitList = await waitListActions.create(_waitList)
        }
        this.props.onClose()
    }

    handleAccountChange(value){
        if(value === 'new'){
            this.setState({
                createOrEditAccountVisible: true
            })
            setTimeout(()=>{ 
                // Huge Hack
                // Huge Hack
                // Huge Hack
                // Huge Hack
                this.form && this.form.setFieldsValue({accountID: ''});
            }, 1)
        } 
        else {
            this.setState({
                accountID: value
            })
        }

    }

    async search(search = ''){
        const {data} = await apiClient.organizations.accounts.fetch({limit:5, search})
        this.setState({
            accounts: data
        })
    }

    handleAccountSearch(value){
        if(this.accountSearchTimeout){
            clearTimeout(this.accountSearchTimeout)
        }
        this.accountSearchTimeout = setTimeout(()=>{this.search(value)},1000)
    }

    handleEditAccount(){
        this.setState({
            createOrEditAccountVisible: true
        })
    }

    onCreateAccount(accountID){
        this.selectAccountByID(accountID)
    }

    handleCreateOrEditAccountClose(){
        this.setState({
          createOrEditAccountVisible: false
        })
    }

    async selectAccountByID(accountID){
        const {data} = await apiClient.organizations.accounts.requestToken({id: accountID});
        try{Object.keys(this.props.accounts)[0]}
        catch(error){
            console.log(error)
        }
    }
    
    get form() {
        return this.formRef.current
    }

    get account(){
        let accountID = this.form && this.form.getFieldValue('accountID')
        let account = {}
        for(let _account of this.accounts){
            if(_account.id == accountID){
                account = _account
            }
        }
        return account
    }

    get waitList(){
        return this.props.waitList || {}
    }

    get appointmentTypes(){
        return Object.values(this.props.appointmentTypes || {})
    }

    get administrators(){
        return Object.values(this.props.administrators || {})
    }

    get accounts(){
        let accounts = this.state.accounts || Object.entries(this.props.accounts || {}).map(([_, a])=> a)
        if(this.waitList.account &&! _.find(accounts, (a)=>(a.id == this.waitList.account.id))){
            accounts.push(this.waitList.account)
        }
        return accounts
    }

    render() {
        const initialValues = {
            accountID: (this.waitList.account || {}).id || '',
            appointmentTypeID: this.waitList.appointmentTypeID || '',
            administratorID: this.waitList.administratorID || ''
        }

        let edit = false
        if(this.waitList.id){
            edit = true
        }

        let editAccount = false
        if(this.form && this.form.getFieldValue('accountID') && this.form.getFieldValue('accountID') !== 'new'){
            editAccount = true
        }
        return (
            <Drawer
                title={edit ? 'Edit Wait List Subscription' : 'Create Wait List Subscription'}
                width={520}
                placement="right"
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
            <CreateOrEditAccount
                visible={this.state.createOrEditAccountVisible}
                onClose={this.handleCreateOrEditAccountClose}
                onSetCurrentAccount={this.onCreateAccount}
                account={this.account}
                onAccountSelect={Object.keys(this.props.accounts)[0]}
            />
                <Form layout="vertical" ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item 
                                label="Account" 
                            >
                                <Input.Group style={{ width: '100%' }} compact>
                                    <Form.Item
                                        noStyle
                                        name="accountID"
                                        rules={[{required: true, message: 'Please select an Account'}]}
                                    >
                                        <Select
                                            style={{width: editAccount ? 'calc(100% - 57px)' : '100%'}}
                                            showSearch
                                            placeholder={"Select Account"}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            notFoundContent={null}
                                            onChange={this.handleAccountChange}
                                            onSearch={this.handleAccountSearch}
                                        >
                                            {this.accounts.map((account, i)=>(
                                                <Option key={`accounts-${i}`} value={account.id}>
                                                    {account.firstName} {account.lastName}
                                                </Option>
                                            ))}
                                            <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                                            <Option value=''>None</Option>
                                            <Option key='new'>New Account</Option>
                                        </Select>
                                    </Form.Item>
                                    {editAccount && 
                                        <Button onClick={this.handleEditAccount}>Edit</Button>
                                    }
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label="Appointment Type"
                                name="appointmentTypeID"
                                rules={[{ message: 'Please select an appointment type' }]}
                            >
                                <Select
                                    style={{ width: '100%'}}
                                    placeholder="Select Appointment Type"
                                    key={`select-appointment-type`}
                                >
                                    {this.appointmentTypes.map((appointmentType)=>{
                                        return (
                                            <Select.Option value={`${appointmentType.id}`} key={`appointment-type-select_${appointmentType.id}`}>
                                                {appointmentType.name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item 
                                label="Administrator"
                                name="administratorID"
                                rules={[{ message: 'Please select an administrator' }]}
                            >
                                <Select
                                    style={{ width: '100%'}}
                                    placeholder="Select Administrator"
                                    key={`select-administrator`}
                                >
                                    {this.administrators.map((administrator)=>{
                                        return (
                                            <Select.Option value={`${administrator.id}`} key={`administrator-select_${administrator.id}`}>
                                                {administrator.firstName} {administrator.lastName}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={this.props.onClose}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">{edit ? 'Update' : 'Create' }
                        </Button>
                    </div>
                </Form>
            </Drawer>
        );
    }
}
export default connect((state)=> state)(
  (CreateOrEditWaitList)
)