import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';

import store from 'store'

export const fetch = async(params = {})=>{
  let response
  
  response = await apiClient.organizations.workers.fetch({
    organizationID: store.getState().currentOrganization.id
  })

  store.dispatch({ 
    type: ActionTypes.FETCH_WORKERS,
    payload: response.data
  })
}

export const update = async(params = {}) => {
  const response = await apiClient.organizations.workers.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_WORKERS,
    payload: response.data
  })
  
  return response.data
}