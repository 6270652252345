import React from 'react'
import { Card, Layout, Col, Row, List, Select } from 'antd'
import CreateAppointment from '../../../partials/CreateOrEditAppointment'
import SelectAppointment from '../../../partials/SelectAppointment'
import {utils} from 'lib';

import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import './index.less'
import moment from 'moment'
import _ from 'lodash'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import {
    appointmentActions,
    patientActions,
    administratorActions,
    openTimeActions
} from 'actions'

import { connect } from 'react-redux';
 
const localizer = momentLocalizer(moment)
 
const { Content } = Layout

class InternalCalendar extends React.Component {
    constructor(props){
        super(props)

        let startingAt;
        if(props.match.params.calendar == 'day'){
            startingAt = moment()
        }else{
            startingAt = moment().startOf('day').startOf('week')
        }
        this.state = {
            events: [],
            adminFilter: '',
            startingAt,
            unit: props.match.params.calendar
        };

        this.handleCreate = this.handleCreate.bind(this)
        this.hideCreate = this.hideCreate.bind(this)
        this.hideSelect = this.hideSelect.bind(this)
        this.handleChangeView = this.handleChangeView.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleRangeChange = this.handleRangeChange.bind(this)
        this.handleAdministratorsFilter = this.handleAdministratorsFilter.bind(this)
    }
    componentDidMount(){
        //appointmentActions.fetch({startingAt: moment().startOf('week').format(), unit: this.props.match.params.calendar || 'week'})
        this.handleDataFetch({
            startingAt: this.state.startingAt,
            unit: this.state.unit
        })
        patientActions.fetchActive()
        administratorActions.fetch()
        openTimeActions.fetch({skipRepeating: true})
    }
    componentDidUpdate(prevProps, prevState){
        const {
            startingAt,
            unit
        } = prevState

        let isStartingAt = false;
        if(startingAt && this.state.startingAt){
           isStartingAt = startingAt.toString() !== this.state.startingAt.toString()
        }
        
        let isUnit = false;
        if(unit && this.state.unit){
            isUnit = unit.toString() !== this.state.unit.toString()
        }

        if(isStartingAt || isUnit){
            this.handleDataFetch({
                startingAt: this.state.startingAt,
                unit: this.state.unit
            })
        }
    }
    handleSelect(event){
        if(event.events.length == 1){
            this.setState({
                event
            }, ()=>{
                this.setState({
                    createVisible: true,
                })
            })
        }else{
            this.setState({
                selectVisible: true,
                event
            })
        }
    }
    handleCreate(event){
        this.setState({
            createVisible: true,
            event,
            events: [event]
        })
    }
    hideCreate(event){
        this.setState({
            createVisible: false,
            event,
            events: []
        })
    }
    hideSelect(event){
        this.setState({
            selectVisible: false,
            event,
            events: []
        })
    }
    handleChangeView(type){
        this.props.history.push(`/admin/appointments/${type}`)
    }
    handleRangeChange(days){
        const startingAt = days[0]
        const unit =  this.props.match.params.calendar || 'week'

        if(startingAt.toString() !== this.state.startingAt.toString() || unit.toString() !== this.state.unit.toString()){
            this.setState({
                startingAt,
                unit
            })
        }
    }
    handleDataFetch({startingAt, unit}){
        appointmentActions.fetch({
            startingAt: moment(startingAt).startOf('day').format(), 
            unit
        })
    }
    handleAdministratorsFilter(e){
        this.setState({
            adminFilter: e
        })
    }
    handleFilterSearch(input, option){
        return `${option.props.children}`.toLowerCase().indexOf(`${input}`.toLowerCase()) >= 0
    }
    get events(){
        let events = Object.values(this.props.appointments || {}).filter((event)=>{
            return !this.state.adminFilter || event.administratorID == this.state.adminFilter 
        }).map((_)=>({
            ..._,
            title: _.reason,
            start: moment(_.startsAt).toDate(),
            end: moment(_.endsAt).toDate()
        })).concat(this.state.events)
        .reduce((acc, e)=>{
            const key = `${moment(e.start).format()}-${moment(e.end).format()}`
            const p = _.get(acc, `[${key}].events`,[])
            let title = e.title;

            if(p.length > 0){
                title = `${p.length + 1} appointments`
            }

            acc[key] = {
                events:[
                    ...p,
                    e
                ],
                ...e,
                title,
                start: e.start,
                end: e.end
            }
            
            return acc
        }, {})

        return Object.values(events)
    }
    get minTime(){
        let minTime = ''
        Object.values(this.props.openTimes || {}).forEach((openTime)=>{
            let openMin =  moment(openTime.startsAt).hour()
            if(moment(openTime.endsAt).day() != moment(openTime.startsAt).day()){
                minTime = 0
            } else if(!minTime || minTime > openMin){
                minTime = openMin
            }
        })
        if(minTime < 1 || !minTime){
            minTime = 1
        }
        return new Date(moment().startOf('day').hour((minTime - 1) || '0').format())
    }
    get maxTime(){
        let maxTime = ''
        Object.values(this.props.openTimes || {}).forEach((openTime)=>{
            let openMax =  moment(openTime.endsAt).hour()
            if(moment(openTime.endsAt).day() != moment(openTime.startsAt).day()){
                maxTime = 23
            } else if(!maxTime || openMax > maxTime){
                maxTime = openMax
            }
        })
        if(maxTime > 22 || !maxTime){
            maxTime = 22
        }
        return new Date(moment().endOf('day').hour((maxTime + 1)||'23').format())
    }
    get administrators(){
        return Object.values(this.props.administrators || {}).sort((a,b)=>{
            if(a.firstName < b.firstName) return -1
            if(a.firstName > b.firstName) return 1
            return 0
        })
    }
    render() {
        const calendar = this.props.match.params.calendar
        
        return (
            <AdminLayout fullHeight={true}>
                <SectionContent noPadding noMargin noHeader className={'appointments'}>
                    <Select
                        style={{width:'100%'}}
                        className='calendar-admin-select'
                        placeholder="Select an administrator to filter"
                        showSearch
                        onChange={this.handleAdministratorsFilter}
                        filterOption={this.handleFilterSearch}
                        optionFilterProp="children"
                    >
                        <Select.Option key='admin-default' value=''>No Admin</Select.Option>
                        {this.administrators.map((admin)=>(
                          <Select.Option key={admin.id} value={admin.id}>{`${admin.firstName} ${admin.lastName}`}</Select.Option>
                        //   Hack for name search to work, string interpolation requiredto remove commas.
                        ))}
                    </Select>
                    <Calendar
                        selectable
                        localizer={localizer}
                        defaultDate={new Date()}
                        view={calendar}
                        onView={this.handleChangeView}
                        onRangeChange={this.handleRangeChange}
                        events={this.events}
                        onSelectEvent={this.handleSelect}
                        onSelectSlot={this.handleCreate}
                        views={['week', 'day']}
                        min={this.minTime}
                        max={this.maxTime}
                        step={15}
                        timeslots={4}
                    />
                    <CreateAppointment visible={this.state.createVisible} onClose={this.hideCreate} appointment={this.state.event} />
                    <SelectAppointment visible={this.state.selectVisible} onClose={this.hideSelect} selectedAppointments={this.state.event} />
                </SectionContent>
            </AdminLayout>
        )
    }
}

export default connect((state)=> state)(InternalCalendar)