import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';

import store from 'store'

export const fetch = async(params = {})=>{
    let response
    
    response = await apiClient.organizations.partitions.fetch(params)

    const data = response.data.reduce((acc, item)=>{
        acc[item.id] = item
        return acc;
    }, {})

    store.dispatch({ 
        type: ActionTypes.FETCH_PARTITIONS,
        payload: data
    })
}

export const create = async(params = {}) => {
    const response = await apiClient.organizations.partitions.create(params)

    store.dispatch({
        type: ActionTypes.CREATE_PARTITION,
        payload: response.data
    })

    return response.data
}

export const update = async(params = {}) => {
    const response = await apiClient.organizations.partitions.update(params)

    store.dispatch({
        type: ActionTypes.UPDATE_PARTITION,
        payload: response.data
    })

    return response.data
}

export const destroy = async(id) => {
    await apiClient.organizations.partitions.destroy(id)

    store.dispatch({
        type: ActionTypes.DESTROY_PARTITION,
        payload: id
    })
}