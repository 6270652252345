import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import store from '../store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.serviceRegions.fetch(params)

  store.dispatch({
    type: ActionTypes.FETCH_SERVICE_REGIONS,
    payload: response.data.reduce((acc,d)=>{
      acc[d.id] = d
      return acc
    },{})
  })

  return response.data
}

export const update = async (params = {}) => {
  const response = await apiClient.organizations.serviceRegions.update(params)

  store.dispatch({
    type: ActionTypes.APPEND_SERVICE_REGION,
    payload: {[`${response.data.id}`]: response.data}
  })

  return response.data
}

export const create = async (params = {}) => {
  const response = await apiClient.organizations.serviceRegions.create(params)

  store.dispatch({
    type: ActionTypes.APPEND_SERVICE_REGION,
    payload: {[`${response.data.id}`]: response.data}
  })
  
  return response.data
}

export const destroy = async (params = {}) => {
  await apiClient.organizations.serviceRegions.destroy(params)

  store.dispatch({
    type: ActionTypes.DESTROY_SERVICE_REGION,
    payload: params.id
  })
}