import {
  FETCH_SERVICE_REGIONS,
  APPEND_SERVICE_REGION,
  DESTROY_SERVICE_REGION,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SERVICE_REGIONS:
      return {
        ...state,
        ...action.payload
      }
    case APPEND_SERVICE_REGION:
      let baseID = action.payload.id
      state = Object.values(state).filter((s)=>s.id != baseID).reduce((acc, item)=>{
        acc[item.repeatingID || item.id] = item
        return acc;
      }, {})
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case DESTROY_SERVICE_REGION:
      state = Object.values(state).filter((s)=>s.id != action.payload).reduce((acc, item)=>{
        acc[item.repeatingID || item.id] = item
        return acc;
      }, {})
      
      return state
    case SET_ORGANIZATION_CURRENT:
        return {}
    default:
      return state
  }
}
