import {
  FETCH_CLASSIFICATIONS,
  CREATE_CLASSIFICATION,
  UPDATE_CLASSIFICATION,
  UPDATE_CLASSIFICATIONS,
  DESTROY_CLASSIFICATION,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CLASSIFICATIONS:
      return {
        ...state,
        ...action.payload
      }
    case CREATE_CLASSIFICATION:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case DESTROY_CLASSIFICATION:
        state = {
            ...state
        }
        delete state[action.payload]
        return state
    case UPDATE_CLASSIFICATION:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case UPDATE_CLASSIFICATIONS:
      let payload = action.payload.reduce((acc, item)=>{
          acc[item.repeatingID || item.id] = item
          return acc;
      }, {})

      return {
          ...state,
          ...payload
      }
    case SET_ORGANIZATION_CURRENT:
        return {}
    default:
      return state
  }
}
