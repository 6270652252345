import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import moment from 'moment'
import {classificationActions} from 'actions'
import {connect} from 'react-redux'
const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;

class CreateOrEditSpecies extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createAppointmentTypeVisible: false,
      patientsUsers: []
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDestroySpecies = this.handleDestroySpecies.bind(this)
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  get account(){
    return this.props.account || {}
  }
  async handleSubmit (values) {
    const _classification = {
        name: values.name,
        description: values.description
    }
    if(this.classification.id){
      classificationActions.update({
          id: this.classification.id,
          ..._classification
      })
    }else{
      let classification = await classificationActions.create(_classification)
      this.props.setClassification && this.props.setClassification(classification.id)
    }
    this.props.onClose()
  }
    
  get form() {
    return this.formRef.current
  }

  get classification(){
    const _classification = this.props.classification || {}
    
    return {
      id: _classification.id,
      name: _classification.name,
      description: _classification.description,
    }
  }

  componentDidUpdate(previousProps){
    if(previousProps.visible != this.props.visible){
      this.form && this.form.resetFields();
      this.setState(this.state)
    }
  }

  async handleDestroySpecies(e){
    e.preventDefault()

    await classificationActions.destroy(this.classification.id)
    this.props.onClose()
  }

  handleNewSex(value){
    if(value === 'new'){
      this.setState({
        createOrEditSexVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({classification: ''});
      }, 1)
    }
  }

  render() {

    const {
      name,
      description
    } = this.state

    const initialValues = {
      name: this.classification.name || '',
      description: this.classification.description || ''
    }
    
    let edit = false;
    
    if(this.classification.id){
      edit = true
    }
    return (
      <Drawer
        title={this.classification.id ? 'Edit Species' : 'Create Species'}
        width={520}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <Form layout="vertical" ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Name"
                name="name"
                rules={[{ message: 'Please select a name' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Description"
                name="description"
                rules={[{ message: 'Please select a description' }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroySpecies} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Species</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.classification.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  (CreateOrEditSpecies)
)