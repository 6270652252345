import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';

import store from 'store'

export const fetch = async(params = {})=>{
  let response
  
  response = await apiClient.organizations.classifications.fetch({
    organizationID: store.getState().currentOrganization.id
  })
  
  const data = response.data.reduce((acc, item)=>{
    acc[item.id] = item
    return acc;
  }, {})

  store.dispatch({ 
    type: ActionTypes.FETCH_CLASSIFICATIONS,
    payload: data
  })
}

export const update = async(params = {}) => {
   const response = await apiClient.organizations.classifications.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_CLASSIFICATION,
    payload: response.data
  })
  return response.data
}


export const activate = async(ids = []) => {
  const response = await apiClient.organizations.classifications.activate({ids})

  store.dispatch({
    type: ActionTypes.UPDATE_CLASSIFICATIONS,
    payload: response.data
  })

  return response.data
}

export const destroy = async(id) => {
  await apiClient.organizations.classifications.destroy(id)

  store.dispatch({
    type: ActionTypes.DESTROY_CLASSIFICATION,
    payload: id
  })
}

export const create = async(params = {}) => {
  const response = await apiClient.organizations.classifications.create(params)

  store.dispatch({
    type: ActionTypes.CREATE_CLASSIFICATION,
    payload: response.data
  })
  return response.data
}