import Collection from 'lib/client/collections/collection'
import Patients from './patients'

class Administrators extends Collection{
    fetch(options = {}){
        let params = ''
        Object.keys(options).forEach((key)=>{
            if(!params){
                params = "?"
            } else {
                params += "&"
            }
            params += `${key}=${options[key]}` 
        })
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/administrator-types${params}`);
    }
}

export default Administrators;