import React from 'react'
import { connect } from 'react-redux'
import { Layout, Menu, Select, Card } from 'antd'
import Scroller from '../Scroller'
import { 
  TableOutlined, 
  UserOutlined, 
  LockOutlined,
  MehOutlined,
  MessageOutlined,
  HomeOutlined,
  FormOutlined,
  FileTextOutlined,
  ScheduleOutlined,
  SlidersOutlined,
  SettingOutlined,
  ApiOutlined,
  ProfileOutlined,
  DatabaseOutlined, 
  HourglassOutlined, 
  GlobalOutlined
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom'
import Header from './Header'
import AppFooter from 'components/elements/AppFooter'
import { openChangeMenu } from 'actions/admin'
import './index.less'
import * as ActionTypes from 'constants/actionTypes'
import { organizationActions, administratorActions } from  'actions';
import {CreateOrganization} from 'components/partials'

const { Sider, Content } = Layout
const { SubMenu } = Menu

class AdminLayout extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      collapsed: false,
      openKeys: ['dashboard'],
      showChildren: true
    }
    this.handleHeaderOnToggle = this.handleHeaderOnToggle.bind(this)
    this.handleMenuItemOnClick = this.handleMenuItemOnClick.bind(this)
    this.handleOrganizationChange = this.handleOrganizationChange.bind(this)
    this.handleMenuOnOpenChange = this.handleMenuOnOpenChange.bind(this)
    this.toggleNewOrganizationDrawer = this.toggleNewOrganizationDrawer.bind(this)
    this.handleCreateOrganizationClose = this.handleCreateOrganizationClose.bind(this)
    this.toggleShowOrgDropdown = this.toggleShowOrgDropdown.bind(this)
  }

  handleHeaderOnToggle() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  handleMenuItemOnClick(item) {
    this.props.history.push(`/admin/${item.key}`)
  }

  handleMenuOnOpenChange(openKeys){
    this.props.dispatch(openChangeMenu(openKeys))
  }

  handleOrganizationChange(e){
    let organizationID = e.split("_")[1]
    this.toggleShowOrgDropdown()
    organizationActions.changeCurrent(organizationID)
  }

  toggleNewOrganizationDrawer(){
    this.setState({
      createOrganizationVisible: !this.state.createOrganizationVisible
    })
  }

  handleCreateOrganizationClose(){
    this.setState({
      createOrganizationVisible: false
    })
  }

  toggleShowOrgDropdown(){
    this.setState({
      showOrganizationsDropdown: !this.state.showOrganizationsDropdown
    })
  }

  get waitingRoom(){
    return this.currentOrganization.waitingRoom
  }

  get waitList(){
    return (this.currentOrganization.settings || {}).waitList
  }

  get organizations(){
    return (
      Object.values(this.props.organizations) || []
    ).sort((a, b)=> 
      (a.name || '').toLowerCase() < (b.name || '').toLowerCase() ? -1 : 1 
    ).filter((organization)=>
        organization.active
    )
  }

  componentDidUpdate(previousProps){
    const current = Object(this.props.currentOrganization)
    const previous = Object(previousProps.currentOrganization)
    if(current && current.id != previous.id){
      this.setState({
        showChildren: false
      }, ()=>{
        this.childrenTimeout = setTimeout(()=>
          this.setState({
            showChildren: true
          })
        , 10)
      })
    }
  }

  componentDidMount(props){
    organizationActions.fetch()
  }

  componentWillUnmount(){
    if(this.childrenTimeout) clearTimeout(this.childrenTimeout)
  }
  
  get organizationType(){
    return this.currentOrganization.type || ''
  }

  get currentOrganization(){
    return this.props.currentOrganization || this.props.organizations[this.props.currentOrganizationID] || {}
  }

  get menu(){
    if(this.organizations.length > 1){
      return (
        <Menu>
          {this.organizations.map((org)=>{
            return (
              <Menu.Item key={`change-org-${org.id}`}>
                <a onClick={this.handleOrganizationChange.bind(null, org)} href="#">{org.name}</a>
              </Menu.Item>
            )
          })}
        </Menu>
      )
    }
  }

  render() {
    const fullHeight = this.props.fullHeight == false ? false : true
    const { match } = this.props
    const { openKeys } = (this.props.admin || {})
    const pathArray = match.path.split('/')
    let selectedKey = pathArray[2];

    let children = <div />
    if(this.state.showChildren){
      children = this.props.children
    }

    if(pathArray[2] == 'appointments'){
      selectedKey = pathArray.slice(2, pathArray.length).join("/")
      if(selectedKey == 'appointments/:calendar'){
        selectedKey = `appointments/${match.params.calendar}`
      }
    }

    return (
      <Layout className='admin-page'>
        <CreateOrganization 
          visible={this.state.createOrganizationVisible} 
          onClose={this.handleCreateOrganizationClose}
          onSetCurrentAppointmentType={this.handleSetCurrentAppointmentType}
        />
        <Sider
          className='admin-sider'
          collapsible
          collapsed={this.state.collapsed}
          trigger={null}
          width={226}
          >
          <div className='logo' onClick={()=>(this.props.history.push("/"))}>
            {!this.state.collapsed && (
              <React.Fragment>
                <h2 className='n' key='n'>N</h2>
                <h2 key='pa'>Scheduling Assistant</h2>
              </React.Fragment>
            )}
          </div>
          {this.organizations.length > 1 ?
            <div className='organizationName'>
              {!this.state.showOrganizationsDropdown ? (
                <div onClick={this.toggleShowOrgDropdown}>{this.currentOrganization.name}</div>
              ):
                <Select
                  showSearch
                  style={{ width: '100%', marginTop: "-5px", marginBottom: '-5px' }}
                  placeholder="Switch to Organization"
                  optionFilterProp="children"
                  filterOption={(input, option) => `${option.props.children}`.toLowerCase().includes(input.toLowerCase())}
                  onSelect={this.handleOrganizationChange}
                >
                  {this.organizations.map((org)=>{
                    return (
                      <Select.Option key={`org-select_${org.id}`}>
                        {org.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              }
              {administratorActions.test(this.props.session.account) ?
                <div className='add-organization-icon' onClick={this.toggleNewOrganizationDrawer}>+</div>
              : '' }
            </div>
          :
            <div className='organizationName'>
              {this.currentOrganization.name}
              {this.props.session.account.isSuperAccount ?
                <div className='add-organization-icon' onClick={this.toggleNewOrganizationDrawer}>+</div>
              : '' }
            </div>
          }
          <Scroller>
            <Card bodyStyle={{padding: "0", backgroundColor: "transparent"}} style={{backgroundColor: "transparent"}} bordered={false}>
              <Menu
                className='menu'
                theme='dark'
                mode='inline'
                openKeys={this.state.collapsed ? [] : openKeys}
                selectedKeys={[selectedKey]}
                onClick={this.handleMenuItemOnClick}
                onOpenChange={this.handleMenuOnOpenChange}
                style={{marginBottom: "200px"}}
              >
                {/* <SubMenu
                  key='general'
                  title={<span><Icon type='home' /><span>General</span></span>}
                >
                  <Menu.Item key='dashboard'>
                    <Icon type='dashboard' /><span>Dashboard</span>
                  </Menu.Item>
                  <Menu.Item key='schedules'>
                    <Icon type='profile' /><span>My Schedule(s)</span>
                  </Menu.Item>
                </SubMenu> */}
                <Menu.Item
                  key='dashboard'
                  >
                  <ScheduleOutlined /><span>Dashboard</span>
                </Menu.Item>
                <Menu.Item
                  key='booking'
                  >
                  <ScheduleOutlined /><span>Booking</span>
                </Menu.Item>
                <SubMenu
                  key='appointments'
                  title={<span><ScheduleOutlined /><span>Appointments</span></span>}
                >
                  <Menu.Item key='appointments/week'>
                  <TableOutlined /><span>Week</span>
                  </Menu.Item>
                  <Menu.Item key='appointments/day'>
                  <DatabaseOutlined /><span>Day</span>
                  </Menu.Item>
                  {this.organizationType == '7f1a0896-50b6-4461-a291-dc718a49d90e' && 
                    <Menu.Item key='appointments/restrictions'>
                      <LockOutlined /><span>Restrictions</span>
                    </Menu.Item>
                  }
                </SubMenu>
                <SubMenu
                  key='users'
                  title={<span><UserOutlined /><span>Users</span></span>}
                >
                  <Menu.Item key='accounts'>
                    <UserOutlined /><span>Accounts</span>
                  </Menu.Item>
                  <Menu.Item key='patients'>
                    <MehOutlined /><span>Patients</span>
                  </Menu.Item>
                  {/* <Menu.Item key='messages'>
                    <Icon type='mail' /><span>Messages</span>
                  </Menu.Item> */}
                </SubMenu>
                <Menu.Item key='messages'>
                  <MessageOutlined /><span>Messages</span>
                </Menu.Item>
                {this.waitingRoom &&
                  <Menu.Item key='waitingRoom'>
                    <HourglassOutlined /><span>Waiting Room</span>
                  </Menu.Item>
                }
                {this.waitList &&
                    <SubMenu
                        key='waitList'
                        title={<span><SettingOutlined /><span>Wait List</span></span>}
                    >
                        <Menu.Item key='waitListMembers'>
                            <UserOutlined /><span>Members</span>
                        </Menu.Item>
                        <Menu.Item key='waitListMessages'>
                            <MessageOutlined /><span>Messages</span>
                        </Menu.Item>
                    </SubMenu>
                }
                <SubMenu
                  key='settings'
                  title={<span><SettingOutlined /><span>Settings</span></span>}
                >
                  <Menu.Item key='general'>
                    <UserOutlined /><span>General</span>
                  </Menu.Item>
                  <Menu.Item key='administrators'>
                    <UserOutlined /><span>Administrators</span>
                  </Menu.Item>
                  <Menu.Item key='species'>
                    <UserOutlined /><span>Species</span>
                  </Menu.Item>
                  <Menu.Item key='breeds'>
                    <UserOutlined /><span>Breeds</span>
                  </Menu.Item>
                  <Menu.Item key='sexes'>
                    <UserOutlined /><span>Sexes</span>
                  </Menu.Item>
                  <Menu.Item key='organization'>
                    <HomeOutlined /><span>Business Hours</span>
                  </Menu.Item>
                  <Menu.Item key='appointment-types'>
                    <ProfileOutlined /><span>Appointment Types</span>
                  </Menu.Item>
                  <Menu.Item key='appointment-statuses'>
                    <ProfileOutlined /><span>Appointment Statuses</span>
                  </Menu.Item>
                  <Menu.Item key='integrations'>
                    <ApiOutlined /><span>Integrations</span>
                  </Menu.Item>
                  <Menu.Item key='widgets'>
                    <FormOutlined /><span>Widget</span>
                  </Menu.Item>
                  <Menu.Item key='restrictions'>
                    <LockOutlined /><span>Restrictions</span>
                  </Menu.Item>
                  {administratorActions.test(_.get(this, 'props.session.account')) && (
                    <Menu.Item key='operations'>
                      <SlidersOutlined /><span>Operations</span>
                    </Menu.Item>
                  )}
                  {administratorActions.test(_.get(this, 'props.session.account')) && (
                    <Menu.Item key='manual-worker-executions'>
                      <SlidersOutlined /><span>Manual Worker Executions</span>
                    </Menu.Item>
                  )}
                  <Menu.Item key='messenger-templates'>
                    <FileTextOutlined /><span>Messenger Templates</span>
                  </Menu.Item>
                </SubMenu>
                {administratorActions.test(_.get(this, 'props.session.account')) && (
                    <Menu.Item key='global'>
                      <GlobalOutlined /><span>Global</span>
                    </Menu.Item>
                )}
              </Menu>
            </Card>
          </Scroller>
        </Sider>
        <Layout>
          <Header handleHeaderOnToggle={this.handleHeaderOnToggle} />
          <Content className={fullHeight ? 'full-height' : ''}>
            {children}
          </Content>
          <AppFooter />
        </Layout>
      </Layout>
    )
  }
}
export default withRouter(connect((state)=> state )(AdminLayout))