import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import { Col, Row, Input, Button, Select, Popconfirm } from 'antd';
import Scroller from 'components/elements/Scroller'
import {companyActions, organizationActions} from 'actions'
import { CreateOrEditCompany } from 'components/partials';
import { List } from 'antd';

class Companies extends Component {
    constructor(props){
        super(props)
        this.state = {
            workers: {
                evet: {}
            },
            filters: {}
        }
        
        this.handleCreateCompany = this.handleCreateCompany.bind(this)
        this.handleUpdateCompany = this.handleUpdateCompany.bind(this)
        this.handleDestroyCompany = this.handleDestroyCompany.bind(this)
        this.handleShowCompanyCreate = this.handleShowCompanyCreate.bind(this)
        this.handleHideCompanyCreate = this.handleHideCompanyCreate.bind(this)
        this.handleSelectOrganization = this.handleSelectOrganization.bind(this)
    }
    
    componentDidMount(){
        companyActions.fetch()
    }
    handleCreateCompany(company){
        companyActions.create(company)
    }
    
    handleUpdateCompany(company){
        companyActions.update(company)
    }
    
    handleDestroyCompany(company){
        companyActions.destroy(company)
    }
    
    get data(){
        return Object.values(Object(this.props.companies))
    }
    handleShowCompanyCreate(){
        this.setState({
            companyCreateVisible: true
        })
    }
    handleHideCompanyCreate(){
        this.setState({
            companyCreateVisible: false
        })
    }
    
    handleSelectOrganization(organizationID){
        organizationActions.changeCurrent(organizationID)
    }
    render(){
        return (
            <Fragment>

                <Row>
                    <Col xs={14} style={{padding: 10}} >
                        <Input onChange={this.handleNameFilter} />
                    </Col>
                    <Col xs={10} style={{padding: 10, textAlign: 'right'}} >
                        <Button onClick={this.handleShowCompanyCreate}>Create New Company</Button>
                    </Col>
                </Row>
                <CreateOrEditCompany visible={this.state.companyCreateVisible} onClose={this.handleHideCompanyCreate} />
                <Scroller>
                    <List
                        size="large"
                        bordered
                        dataSource={this.data}
                        renderItem={(company)=> (
                            <List.Item>
                                <Row style={{width: '100%'}} gutter={12}>
                                    <Col xs={6}>
                                        {company.name || 'No Name Provided'}
                                    </Col>
                                    <Col xs={6}>
                                        {company.description || 'No Description Provided'}
                                    </Col>
                                    <Col xs={8}>
                                        <Select
                                            style={{ width: '100%' }}
                                            onChange={this.handleSelectOrganization}
                                        >
                                            {(company.organizations || []).map((organization, i)=>(
                                                <Option key={`organization-${i}`} value={organization.id}>
                                                    {organization.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col xs={4} gutter={12}>
                                        <Popconfirm
                                            title="Delete Group"
                                            description="Are you sure to delete this company?"
                                            onConfirm={this.handleDestroyCompany.bind(null, company)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button danger>Delete</Button>
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            </List.Item>
                        )}
                    />
                </Scroller>
            </Fragment>
        )
    }
}

export default connect((state)=>(
    state
))(Companies)