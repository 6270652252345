import Collection from './collection'

class Account extends Collection{
    fetch(){
        return this.axios.get('/accounts')
    }
    create(options){
        return this.axios.post('/accounts', {
            ...options
        });
    }
    update(options){
        return this.axios.put('/accounts', {
            ...options
        });
    }
    testDeadToken(options){
        return this.axios.put('/accounts/test-dead-token', {
            ...options
        });
    }
    authenticate(options){
        return this.axios.put('/accounts/authenticate', {
            ...options
        });
    }
    resetPassword(options){
        return this.axios.put(`/accounts/reset-password`,{
            ...options
        });
    }
}

export default Account;