import React from 'react'
import { Form, Card } from 'antd'

import {PatientForm} from 'components/partials'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import GeneralEditor from 'components/elements/GeneralEditor'
import Scroller from 'components/elements/Scroller'

import {patientActions} from 'actions'

class Edit extends React.Component {
  constructor(){
    super()
    this.formRef = React.createRef()
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.triggerSubmit = this.triggerSubmit.bind(this)
  }
  get patient(){
    return this.props.patient || {}
  }
  get genders(){
    return Object.values(this.props.genders) || []
  }
  get species(){
    return Object.values(this.props.classifications) || []
  }
  get breeds(){
    return Object.values(this.props.breeds) || []
  }
  handleCancel(){
    if(this.patient.id){
      this.props.history.push(`/admin/patients/${this.patient.id}`)
    } else {
      this.props.history.push(`/admin/patients`)
    }
  }

  async handleSave(values){
    const _patient = {
        accountID: values.accountID || this.account.id,
        pet: values.pet,
        firstName: values.firstName,
        middleInitial: values.middleInitial,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        address: values.address,
        unit: values.unit,
        city: values.city,
        county: values.county,
        country: values.country,
        state: values.state,
        zip: values.zip,
        genderID: values.genderID,
        breedID: values.breedID,
        birthday: values.birthday,
        timeZone: values.timeZone,
        weight: values.weight
    }
    let patient
    if(this.patient.id){
      patient = await patientActions.update({
          id: this.patient.id,
          ..._patient
      })
    } else {
      patient = await patientActions.create(_patient)
      this.props.onSetCurrentPatient && this.props.onSetCurrentPatient(patient.id)
    }
    this.props.history.push(`/admin/patients/${patient.id}`)    
  }
  
  handleChange(key, e){
    console.log(key, e)
  }

  triggerSubmit() {
    return this.form.submit()
  }
  
  get form() {
    return this.formRef.current
  }
  
  render() {
    return (
      <div className='edit-switch'>
        <GeneralEditor onCancel={this.handleCancel} onSave={this.triggerSubmit}>
          <Scroller>
            <Card bordered={false}>
              <div className='edit-switch'>
                <PatientForm onFinish={this.handleSave} formRef={this.formRef} patient={this.patient}/>
              </div>
            </Card>
          </Scroller>
        </GeneralEditor>
      </div>
    )
  }
}

export default withRouter(
  connect((state)=> state)(
    (Edit)
  )
)