import Collection from 'lib/client/collections/collection'

class ServiceRegions extends Collection{
    fetch(options){
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/service-regions`, options);
    }

    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/service-regions`, options);
    }

    update(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/service-regions/${options.id}`, options);
    }
    
    destroy(options){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/service-regions/${options.id}`);
    }
}

export default ServiceRegions;