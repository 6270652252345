import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Input, Select, Button } from 'antd';
import {
    groupActions
} from 'actions'

class GroupForm extends Component {
    constructor(){
        super();
        this.state = {
          officeHours:[],
          visible: false,
          createGroupVisible: false,
          groupSearch: '',
          createOrEditSpeciesVisible: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDestroyGroup = this.handleDestroyGroup.bind(this)
        this.groupSearchTimeout = ''
        this.handleCancel = this.handleCancel.bind(this)
        this.handleNewGroup = this.handleNewGroup.bind(this)
    }
    async handleSubmit(values){
        if(this.group.id){
            await groupActions.update({
                id: this.group.id,
                ...values
            })
            this.props.onClose()
        }else{
            const group = await groupActions.create({
                ...values
            })
            this.props.onSetCurrentGroup(group.id)
            this.props.onClose()
        }
    }
    async handleDestroyGroup(e){
        e.preventDefault()
        
        await groupActions.destroy(this.group.id)
        this.props.onSetCurrentGroup('')
        this.props.onClose()
    }
    get group(){
        return {
            ...(this.props.group || {})
        }
    }
    get form() {
        return Object(this.props.formRef).current
    }
    handleNewGroup(value){
        if(value === 'new'){
            this.setState({
                createGroupVisible: true
            })
            setTimeout(()=>{
                // Huge Hack
                // Huge Hack
                // Huge Hack
                // Huge Hack
                this.form && this.form.setFieldsValue({groupID: ''});
            }, 1)
        }
    }
 
    handleCancel(){
        if(this.current.id){
            this.props.history.push(`/admin/groups/${this.current.id}`)
        } else {
            this.props.history.push(`/admin/groups`)
        }
    }
    render(){
        return (
            <div> 
                <Form.Item 
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input Group name!' }]}
                >
                    <Input placeholder="Name of Group" />
                </Form.Item>
                <Form.Item 
                    label="Description"
                    name="description"
                >
                    <Input.TextArea placeholder="Description" />
                </Form.Item>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
}

export default withRouter(
    (
        connect(mapStateToProps)(GroupForm)
    )
)
