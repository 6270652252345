import {
  FETCH_WORKERS,
  UPDATE_WORKERS
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_WORKERS:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_WORKERS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
