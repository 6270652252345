import Collection from 'lib/client/collections/collection'
import _ from 'lodash'

class Notes extends Collection {
    fetch(params = {}){
        let query = Object.entries(params).map(([key, value])=>
            `${key}=${value}`
        )
        if(query.length !== 0){
            query = `?${query.join('&')}`
        }else{
            query = ''
        }

        return this.axios.get(`/operations/${this.props.currentOrganization.id}/notes${query}`)
    }
    create(params){
        return this.axios.post(`/operations/${this.props.currentOrganization.id}/notes`, params)
    }
    destroy(params){
        return this.axios.delete(`/operations/${this.props.currentOrganization.id}/notes/${params.id}`)
    }
    markAsRead(params){
        return this.axios.put(`/operations/${this.props.currentOrganization.id}/notes/${params.id}/mark-as-read`)
    }
    resolved(params){
        return this.axios.put(`/operations/${this.props.currentOrganization.id}/notes/${params.id}/resolved`)
    }
}

export default Notes