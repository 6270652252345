import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Route } from 'react-router-dom'
import { apiClient } from 'lib';

import { Row, Col, message } from 'antd'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import AccountCards from './AccountCards'
import Item from './Item'

import { accountActions } from 'actions'

const createPath = '/admin/patients/create'
const editPath = '/admin/patients/edit'

class Users extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      accounts: ''
    }
    this.handleCreateButtonOnClick = this.handleCreateButtonOnClick.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
  }

  componentDidMount() {
    if(this.props.location.search){
      let params = this.props.location.search
      params = params.replace(/\?/, '')
      params = params.split("&")
      let _params = {}
      for(let param of params){
        let splitParam = param.split("=")
        _params[splitParam[0]] = splitParam[1]
      }
      this.setState({
        search: _params.search
      })
      this.fetch({search: _params.search, limit: 5})
    } else {
      this.fetch()
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.isFetchItemsLoading && this.props.fetchItemsError) {
      message.error(this.props.fetchItemsError)
    }
  }

  fetch(params = {}) {
    accountActions.fetch(params)
  }

  handleCreateButtonOnClick(e) {
    e.preventDefault()
    this.props.history.push(createPath)
  }

  handleOnSelect(account) {
    if((account || {}).id){
      this.props.history.push(`/admin/accounts/${account.id}${this.state.search?`?search=${this.state.search}`:''}`)
    }
  }

  handleSearch(e) {
    let data = e.target.value
    if(this.searchTimeout){
      clearTimeout(this.searchTimeout)
    }

    this.searchTimeout = setTimeout(async()=>{
      if(!data){
        accountActions.fetch()
      } else {
        accountActions.fetch({search: data, limit:5})
      }
      this.setState({
        search: data
      })
    },400)
  }

  handleCreate() {
    this.props.history.push(`/admin/accounts/new`)
  }

  get accounts(){
    let accounts = {}
    if(!this.state.search){
      accounts = {...this.props.accounts}
    } else {
      for(let account of Object.values(this.props.accounts)){
        let fullName = `${account.firstName} ${account.lastName}`
        if((account.firstName || '').toLowerCase().includes(this.state.search.toLowerCase()) || 
          (account.lastName || '').toLowerCase().includes(this.state.search.toLowerCase()) || 
          (account.email || '').toLowerCase().includes(this.state.search.toLowerCase()) || 
          (fullName|| '').toLowerCase().includes(this.state.search.toLowerCase())
        ){
          accounts[account.id] = {...account}
        }
      }
    }
    return accounts || {}
  }

  render() {
    return (
      <div>
        <AdminLayout>
          <SectionContent noPadding noMargin title='Accounts'>
            <Row
              style={{height: '100%'}}
            >
              <Col xs={24} lg={8}>
                <AccountCards
                  loading={this.props.isFetchItemsLoading}
                  search={this.state.search || ''}
                  dataSource={this.accounts}
                  pagination={this.props.pagination}
                  filters={this.props.filters}
                  onSelect={this.handleOnSelect}
                  onCreate={this.handleCreate}
                  onSearch={this.handleSearch}
                  editPath={editPath}
                />
              </Col>
              <Col xs={24} lg={16}>
                <Route path='/admin/accounts/:id'>
                  <Item />
                </Route>
              </Col>
            </Row>
          </SectionContent>
        </AdminLayout>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

export default withRouter(connect(mapStateToProps)(Users))
