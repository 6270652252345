import React from 'react'
import { Form, Input, Button, Col, Row } from 'antd'
import { LockOutlined, KeyOutlined, ContactsOutlined } from '@ant-design/icons'
import {connect} from 'react-redux'
import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';
import * as actions from 'actions';
import store from 'store'
import {withRouter} from 'react-router-dom'

const FormItem = Form.Item

class LoginForm extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.formRef = React.createRef()
    this.state = {
      showResetPrompt: false,
      requestText: '',
      passwordRequestText: '',
      authenticator: ''
    }
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleResetChange = this.handleResetChange.bind(this)
    this.handleTogglePasswordReset = this.handleTogglePasswordReset.bind(this)
    this.handleOnPasswordResetRequest = this.handleOnPasswordResetRequest.bind(this)
    this.handleSubmitNewPassword = this.handleSubmitNewPassword.bind(this)
    this.handleBackToLogin = this.handleBackToLogin.bind(this)
  }
  async componentDidMount(){
    let params = location.search
    if(params.includes("resetPassword")){
      params = params.replace(/\?/,'')
      params = params.split("&")
      let paramsObject = {}
      for(let param of params){
        param = param.split("=")
        paramsObject[param[0]] = param[1]
      }

      try{
        const response = await apiClient.accounts.testDeadToken({
          token: paramsObject.NILtoken
        })
        if(response.data.deadToken){
          sessionStorage.clear()
          localStorage.clear()
          this.props.history.push('/login')
          return this.handleBackToLogin()
        }
      }catch(error){
        console.log('Token not old')
      }

      if(paramsObject.NILtoken){
        store.dispatch({ 
          type: ActionTypes.LOGIN_ACCOUNT,
          tempAccessToken: paramsObject.NILtoken
        })
      }
    }
  }
  get form() {
    return this.formRef.current
  }
  handleOnSubmit(values) {
    this.props.onFinish(values)
  }
  async handleTogglePasswordReset(){
    await actions.sessionActions.logout()
    this.setState({
      showResetPrompt: !this.state.showResetPrompt
    })
  }
  handleResetChange(e){
    if(this.state.requestText){
      this.setState({
        requestText: ''
      })
    }
  }
  async handleOnPasswordResetRequest(values){
    try{
      let response = await apiClient.accounts.resetPassword({
        authenticator: values.value, 
        url: location.protocol + '//' + location.host + location.pathname
      })
      this.setState({
        requestText: "Request sent. You'll receive an email or text with further instructions."
      })
    } catch(e) {
      this.setState({
        requestText: "Sorry, we could not find this email or phone number."
      })
      console.log(e)
    }
  }
  async handleSubmitNewPassword(values){
        try{
          this.props.dispatch({ type: ActionTypes.LOGIN_LOGIN_LOAD })
          
          let response = await apiClient.accounts.update({
            password: values.password, 
            passwordConfirmation: values.passwordConfirmation
          })

          await actions.sessionActions.fetch()


          this.setState({
            passwordRequestText: "Password successfully reset. Click the link below to login."
          })
        } catch(e) {
          let error = e.response.data.errors
          if(Array.isArray(error)){
            let errorText = []
            if(error.includes("min")){
              errorText.push("8 characters minimum")
            }
            if(error.includes("uppercase")){
              errorText.push("1 capital letter")
            }
            if(error.includes("digits")){
              errorText.push("1 digit")
            }
            error = `Password is missing: ${errorText.join(", ")}`
          }
          this.setState({
            passwordRequestText: "Sorry, we could not find this email or phone number.",
            passwordError: error
          })
          console.log(e)
        }
  }
  handleBackToLogin(){
    store.dispatch({ 
      type: ActionTypes.LOGIN_ACCOUNT,
      tempAccessToken: undefined
    })
  }
  render() {

    const initialValues = {
      authenticator: this.state.authenticator
    }
    
    return (
      <div>
        {this.props.session && this.props.session.tempAccessToken ?
          <Form ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmitNewPassword}>
            <Row>
              <Col span={12}>
                {this.state.authenticator ? (
                  <div>
                    <h4>{this.state.passwordRequestText}</h4>
                    <a href="#" onClick={this.handleBackToLogin}>Login</a>
                  </div>
                ) : (
                  <div>
                    <h4>Enter a New Password</h4>
                    <br />
                    <FormItem
                      name="password"
                      rules={[{ required: true, type: 'string', message: 'Please input a valid password.' }]}
                    >
                      <Input size="large" prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='New Password' type='password'/>
                    </FormItem>
                    <FormItem
                      name="passwordConfirmation"
                      rules={[{ required: true, message: 'Please input a valid password.' }]}
                    >
                      <Input size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Confirm Password' type='password' />
                    </FormItem>
                    <FormItem>
                      <label>Password must include:</label>
                      <div className='passwordText'>
                        <ul>
                          <li>8 characters</li>
                          <li>1 capital letter</li>
                          <li>1 number</li>
                        </ul>
                        {this.state.passwordError ?
                          <label>{this.state.passwordError}</label>
                        :
                          ''
                        }
                      </div>
                    </FormItem>
                    <a 
                      href="#" 
                      onClick={this.handleTogglePasswordReset}
                      style={{
                        float: "left",
                        marginTop: "-15px",
                        marginBottom: "10px"
                      }}>
                        Request new "Password Reset" Email?
                    </a>
                    <Button
                      loading={this.props.isLoading}
                      type='primary'
                      htmlType='submit'
                      style={{ width: '100%' }}
                      size='large'
                      >
                      Submit Password
                    </Button>
                  </div>
                )}
              </Col>
              <Col span={12}>
                <br />
                <br />
                Need an Account? <a href='/signup'>Click here to Sign Up</a>
                <br />
              </Col>
            </Row>
          </Form>
        :
          this.state.showResetPrompt ?
            <Form onFinish={this.handleOnPasswordResetRequest} onChange={this.handleResetChange}>
              <Row>
                <Col span={12}>
                  <h4>Enter your email or phone number</h4>
                  <br />
                  <FormItem
                    name="value"
                    rules={[{ required: true, type: 'string', message: 'Please input a valid email or phone number.' }]}
                  >
                    <Input size="large" prefix={<ContactsOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Email or Phone Number' />
                  </FormItem>
                  {this.state.requestText && 
                    <h4 style={{marginBottom: "10px"}}>{this.state.requestText}</h4>
                  }
                  <a 
                    href="#" 
                    onClick={this.handleTogglePasswordReset}
                    style={{
                      float: "left",
                      marginTop: "-15px",
                      marginBottom: "10px"
                    }}>
                      Back
                  </a>
                  <Button
                    loading={this.props.isLoading}
                    type='primary'
                    htmlType='submit'
                    style={{ width: '100%' }}
                    size='large'
                    >
                    Reset Password
                  </Button>
                </Col>
                <Col span={12}>
                  <br />
                  <br />
                  Need an Account? <a href='/signup'>Click here to Sign Up</a>
                  <br />
                </Col>
              </Row>
            </Form>
          :
            <Form onFinish={this.handleOnSubmit}>
              <Row>
                <Col span={12}>
                  <h4>Have an Account?</h4>
                  <br />
                  <FormItem
                    name="value"
                    rules={[{ required: true, type: 'string', message: 'Please input a valid email or phone number.' }]}
                  >
                    <Input size="large" prefix={<ContactsOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Email or Phone Number' />
                  </FormItem>
                  <FormItem
                    name="password"
                    rules={[{ required: true, message: 'Please input a valid password.' }]}
                  >
                    <Input size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Password' type='password' />
                  </FormItem>
                    <a 
                      href="#" 
                      onClick={this.handleTogglePasswordReset}
                      style={{
                        float: "left",
                        marginTop: "-15px",
                        marginBottom: "10px"
                      }}>
                        Forgot Password?
                      </a>
                  <FormItem>
                    <Button
                      loading={this.props.isLoading}
                      type='primary'
                      htmlType='submit'
                      style={{ width: '100%' }}
                      size='large'
                      >
                      Login
                    </Button>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <br />
                  <br />
                  Need an Account? <a href='/signup'>Click here to Sign Up</a>
                  <br />
                </Col>
              </Row>
            </Form>
        }
      </div>
    )
  }
}

export default withRouter(
  connect((state)=> state)(LoginForm)
)