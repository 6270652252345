import Collection from 'lib/client/collections/collection'

class WaitListMessages extends Collection{
    fetch(options){
        let params = ''
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/wait-list-messages${params}`);
    }

    create(params){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/wait-list-messages`,params);
    }

    update(params){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/wait-list-messages/${params.id}`,params);
    }

    destroy(id = ''){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/wait-list-messages/${id}`);
    }
}

export default WaitListMessages;