import {
  FETCH_ADMINISTRATORS,
  UPDATE_ADMINISTRATOR,
  UPDATE_ADMINISTRATORS,
  CREATE_ADMINISTRATOR,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ADMINISTRATORS:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_ADMINISTRATOR:
    case CREATE_ADMINISTRATOR:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case UPDATE_ADMINISTRATORS:
      let payload = action.payload.reduce((acc, item)=>{
          acc[item.id] = item
          return acc;
      }, {})

      return {
          ...state,
          ...payload
      }
    case SET_ORGANIZATION_CURRENT:
        return {}
    default:
      return state
  }
}
