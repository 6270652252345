import React from 'react';
import { withRouter } from 'react-router-dom'
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, Slider } from 'antd';
import moment from 'moment'
import {appointmentTypeActions} from 'actions'
import {connect} from 'react-redux'
import './index.less'
const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;

class CreateOrEditAppointmentTypeDurations extends React.Component {
    constructor(){
        super();
        this.formRef = React.createRef()
        this.state = { 
            visible: false,
            durations: [],
            displayInput: {},
            extendedDurations: []
        };
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onDurationChange = this.onDurationChange.bind(this)
        this.onDurationSliderChange = this.onDurationSliderChange.bind(this)
        this.handleAddPatient = this.handleAddPatient.bind(this)
        this.handleRemovePatient = this.handleRemovePatient.bind(this)
    }
  
    componentDidMount(){
        this.setState({
            durations: (this.props.appointmentType.durations || '').split(',') || []
        }, ()=> {
            this.extendedValueCheck()
        })
    }
    componentDidUpdate(previousProps){
        if(previousProps.visible != this.props.visible){
            this.setState({
                durations: (this.props.appointmentType.durations || '').split(',') || []
            }, ()=> {
                this.extendedValueCheck()
            })
            this.form && this.form.resetFields();
        }
        if(this.props.appointmentType.id != this.appointmentType.id){
            this.setState({
                durations: (this.props.appointmentType.durations || '').split(',') || []
            }, ()=> {
                this.extendedValueCheck()
            })
            this.form && this.form.resetFields();
        }
    }

    get form() {
        return this.formRef && this.formRef.current
    }

    get appointmentType(){
        return this.props.appointmentType || {}
    }

    get durations(){
        return this.constructor.durations(this.appointmentType.durations || '')
    }

    static durations(durations){
        return (durations || '').split(',') || []
    }

    get initialValues(){
        return this.constructor.initialValues(this.durations, this.state.durations)
    }

    static initialValues(values){
        return values.reduce((acc, duration, i)=>({
            ...acc,
            [`duration-${i}`]: duration
        }), {})
    }

    showDrawer () {
        this.setState({
            visible: true,
        });
    }

    async handleSubmit (values) {
        if(this.appointmentType.id){
            await appointmentTypeActions.update({
                id: this.appointmentType.id,
                durations: Object.values(values).join(',')
            })
        } else {
            this.props.handleNewTypeDurations && this.props.handleNewTypeDurations(Object.values(values).join(','))
        }
        
        this.props.onClose()
    }

    handleAddPatient(){
        let durations = this.state.durations
        // _.last(this.state.durations)
        durations[this.state.durations.length] = [...this.state.durations].pop()
        this.setState({
            durations
        })
    }

    handleRemovePatient(){
        let durations = [...this.state.durations]
        durations.splice(-1,1)
        this.setState({
            durations
        })
    }

    extendedValueCheck() {
        let extendedDurations = [...this.state.extendedDurations]
        for(let [i,duration] of (this.state.durations || []).entries()){
            if(duration >= 120) {
                extendedDurations[i] = true
            }
        }
        this.setState({
            extendedDurations
        })
    }

    onDurationChange(i, e){
        let durations = this.state.durations
        let value = e.target.value
        durations[i] = value

        this.setState({
            durations
        }, ()=> {
            this.extendedValueCheck()
        })

        let fields = {}
        fields[`duration-${i}`] = durations[i]
        this.form && this.form.setFieldsValue(fields)
    }

    onDurationSliderChange(i, value){
        let durations = this.state.durations
        durations[i] = value
        if(value >= 120) {
            this.setState({
                displayInput: {
                    ...this.state.displayInput,
                    [`${i}`]: true
                }
            })
        }
        let fields = durations.reduce((fields, duration,j)=>{
            if((i < j && Number(duration) < value) || (i > j && Number(duration) > value)){
                durations[j] = value
            }
            fields[`duration-${j}`] = Number(durations[j])
            return fields
        }, {})
        this.form && this.form.setFieldsValue(fields)
        this.setState({
            durations
        }, ()=> {
            this.extendedValueCheck()
        })
    }

    render() {


        const initialValues = this.initialValues

        let edit = false;
        
        if(this.appointmentType.durations){
            edit = true
        }

        const title = (<div
            style={{
              position: 'absolute',
              top: 0,
              zIndex:1,
              width: '100%',
              borderBottom: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'left',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
                {edit ? 'Edit Appointment Type Durations' : 'Create Appointment Type Durations'}
        </div>)
        
        return (
            <Drawer
                title={title}
                width={520}
                placement="right"
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
                <Form ref={this.formRef} initialValues={initialValues} layout="vertical" onFinish={this.handleSubmit}>
                    {this.state.durations.map((duration,i)=>{
                        return (
                            <Row 
                                key={`duration-settings-${i}`} 
                                gutter={24} 
                            >
                            {/* there should be a 'style={{display: "flex"}} wrapped around both the minutes slider and the input-content, however display flex is not working for... some reason... */}
                                <Form.Item 
                                    label={`${i+1} Patients`} 
                                    style={{width: "100%"}}
                                >
                                    <Input.Group>
                                        <Row>
                                            <Col span={20}>
                                                <Form.Item
                                                    name={`duration-${i}`}
                                                    >
                                                    <Slider 
                                                        onChange={this.onDurationSliderChange.bind(null,i)} 
                                                        max={120} 
                                                        marks={{0: 'Off', 15: '15', 30: '30', 45: '45', 60: '60',75: '75', 90: '90', 105: '105', 120: '120+'}}        
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {this.state.extendedDurations[i] ? (
                                                <Col span={4}>
                                                    <Form.Item
                                                        name={`duration-${i}`}
                                                        noStyle
                                                    >
                                                        <Input className='duration-text-input' type='number' onBlur={this.onDurationChange.bind(null,i)} onChange={this.onDurationChange.bind(null, i)} />
                                                    </Form.Item>
                                                    {i == this.state.durations.length - 1 ?
                                                        <div onClick={this.handleRemovePatient} className='duration-close'></div>
                                                    : '' }
                                                </Col>
                                            ) : (
                                                <Col span={4}>
                                                    <div className='duration-text'>{this.state.durations[i]}</div>
                                                    {i == this.state.durations.length - 1 ?
                                                        <div onClick={this.handleRemovePatient} className='duration-close'></div>
                                                    : '' }
                                                </Col> 
                                            )}
                                        </Row>
                                    </Input.Group>
                                </Form.Item>
                            </Row>
                        )
                    })}
                    <Button onClick={this.handleAddPatient}>Add Another Patient</Button>
                    <div style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',
                        textAlign: 'right',
                        left: 0,
                        background: '#fff',
                        borderRadius: '0 0 4px 4px',
                    }} >
                        <Button style={{marginRight: 8}} onClick={this.props.onClose}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {this.appointmentType.id ? 'Update' : 'Set' }
                        </Button>
                    </div>
                </Form>
            </Drawer>
        );
    }
}
export default withRouter(connect((state)=> state)(
  (CreateOrEditAppointmentTypeDurations)
))