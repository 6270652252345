import React, { Component, Fragment } from 'react'
import { Switch, Button, Form, Input, Select } from 'antd';
import {
    appointmentStatusActions,
} from 'actions'
import {connect} from 'react-redux'

class CreateAppointmentStatus extends Component {
    constructor(){
        super();
        this.state = { 
            durations: ''
        };
        appointmentStatusActions.fetch();
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDestroyAppointmentStatus = this.handleDestroyAppointmentStatus.bind(this)
    }
    async handleSubmit(values){
        if(this.appointmentStatus.id){
            await appointmentStatusActions.update({
                id: this.appointmentStatus.id,
                ...values
            })
            this.props.onClose()
        }else{
            const appointmentStatus = await appointmentStatusActions.create({
                ...values
            })
            this.props.onSetCurrentAppointmentStatus(appointmentStatus.id)
            this.props.onClose()
        }
    }
    async handleDestroyAppointmentStatus(e){
        e.preventDefault()
        
        await appointmentStatusActions.destroy(this.appointmentStatus.id)
        this.props.onSetCurrentAppointmentStatus('')
        this.props.onClose()
    }
    get appointmentStatus(){
        return {
            ...(this.props.appointmentStatus || {})
        }
    }

    get types(){
        return [
            'Contacted',
            'In progress',
            'Confirmed',
            'Cancelled',
            'Admitted',
            'Ready-to-go',
            'Discharged',
            'No show',
            'Hospitalized',
            'Requested',
            'Request declined',
            'All charges in',
            'Arrived',
            'Scheduled'
        ]
    }
    
    get form() {
        return Object(this.props.formRef).current
    }

    render(){
        return (
            <div>
                <Form.Item 
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input Appointment Status name!' }]}
                >
                    <Input placeholder="Name of Appointment Status" />
                </Form.Item>
                <Form.Item 
                    label="Type"
                    name="type"
                >
                    <Select placeholder="type">
                        {this.types.map((type)=>(
                            <Select.Option key={type} value={type}>{type}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item 
                    label="Is this a No Show Status?"
                    name="isNoShow"
                    valuePropName='checked'
                >
                    <Switch />
                </Form.Item>
                <Form.Item 
                    label="Is this a Confirmed Status?"
                    name="isConfirmed"
                    valuePropName='checked'
                >
                    <Switch />
                </Form.Item>
            </div>
        )
    }
}

export default connect((state)=> state )(
    (CreateAppointmentStatus)
)