// Nameing: PAGE_ACTION_STATUS
export const FETCH_APPOINTMENT_STATUSES = 'fetch/appointmentStatuses'
export const UPDATE_APPOINTMENT_STATUS = 'update/appointmentStatus'
export const UPDATE_APPOINTMENT_STATUSES = 'update/appointmentStatuses'
export const DESTROY_APPOINTMENT_STATUS = 'destroy/appointmentStatus'
export const CREATE_APPOINTMENT_STATUS = 'create/appointmentStatus'

export const LOGIN_ACCOUNT = 'session/login'
export const LOGOUT_ACCOUNT = 'session/logout'

export const LOGIN_EDITFORM_CHANGE = 'login/editForm/change'
export const LOGIN_LOGIN_LOAD = 'login/login/load'
export const LOGIN_LOGIN_SUCCEED = 'login/login/succeed'
export const LOGIN_LOGIN_FAIL = 'login/login/fail'

export const ADMIN_OPENSUBMENU_CHANGE = 'admin/openSubMenu/change'

export const USERLIST_FETCHITEMS_LOAD = 'patientLists/fetchItems/load'
export const USERLIST_FETCHITEMS_SUCCEED = 'patientLists/fetchItems/succeed'
export const USERLIST_FETCHITEMS_FAIL = 'patientLists/fetchItems/fail'
export const USERLIST_CHANGETABLE_CHANGE = 'patientLists/changeTable/change'
export const USERLIST_SEARCHTABLE_EDIT = 'patientLists/searchTable/edit'
export const USERLIST_SEARCHTABLE_SEARCH = 'patientLists/searchTable/search'

export const PATIENTLIST_FETCHITEMS_LOAD = 'patientLists/fetchItems/load'
export const PATIENTLIST_FETCHITEMS_SUCCEED = 'patientLists/fetchItems/succeed'
export const PATIENTLIST_FETCHITEMS_FAIL = 'patientLists/fetchItems/fail'
export const PATIENTLIST_CHANGETABLE_CHANGE = 'patientLists/changeTable/change'
export const PATIENTLIST_SEARCHTABLE_EDIT = 'patientLists/searchTable/edit'
export const PATIENTLIST_SEARCHTABLE_SEARCH = 'patientLists/searchTable/search'
export const PATIENTCURRENT_SET = 'patientLists/current'

export const USERFORM_ENTER_RESET = 'patientForm/enter/reset'
export const USERFORM_EDITFORM_CHANGE = 'patientForm/editForm/change'
export const USERFORM_FETCHITEM_LOAD = 'patientForm/fetchItem/load'
export const USERFORM_FETCHITEM_SUCCEED = 'patientForm/fetchItem/succeed'
export const USERFORM_FETCHITEM_FAIL = 'patientForm/fetchItem/fail'
export const USERFORM_CREATEITEM_LOAD = 'patientForm/createItem/load'
export const USERFORM_CREATEITEM_SUCCEED = 'patientForm/createItem/succeed'
export const USERFORM_CREATEITEM_FAIL = 'patientForm/createItem/fail'
export const USERFORM_EDITITEM_LOAD = 'patientForm/editItem/load'
export const USERFORM_EDITITEM_SUCCEED = 'patientForm/editItem/succeed'
export const USERFORM_EDITITEM_FAIL = 'patientForm/editItem/fail'
export const USERFORM_DELETEITEM_LOAD = 'patientForm/deleteItem/load'
export const USERFORM_DELETEITEM_SUCCEED = 'patientForm/deleteItem/succeed'
export const USERFORM_DELETEITEM_FAIL = 'patientForm/deleteItem/fail'

export const CREATE_USER = 'patients/create'
export const CREATE_USER_ERROR = 'patients/create/error'

export const CREATE_ORGANIZATION = 'organizations/create'
export const UPDATE_ORGANIZATION = 'organizations/update'
export const CREATE_ORGANIZATION_ERROR = 'organizations/create/error'
export const SET_ORGANIZATION_CURRENT = 'organizations/set'
export const SET_ALL_ORGANIZATIONS = 'organizations/setAll'
export const SET_ORGANIZATION = 'organizations/set'
export const ORGANIZATIONS_SET_FAIL = 'organizations/set/fail'
export const SET_ORGANIZATIONS = 'organizations/set/fail'

export const SET_ALL_PARTNERS = 'partners/setAll'

export const FETCH_ORGANIZATION_TYPES = 'organizationsTypes/fetch'

export const FETCH_OPEN_TIMES = 'openTimes/fetch'
export const APPEND_OPEN_TIME = 'openTimes/append'
export const DESTROY_OPEN_TIME = 'openTimes/destroy'

export const FETCH_SERVICE_TIMES = 'serviceTimes/fetch'
export const APPEND_SERVICE_TIME = 'serviceTimes/append'
export const DESTROY_SERVICE_TIME = 'serviceTimes/destroy'

export const FETCH_SERVICE_REGIONS = 'serviceRegions/fetch'
export const APPEND_SERVICE_REGION = 'serviceRegions/append'
export const UPDATE_SERVICE_REGION = 'serviceRegions/update'
export const DESTROY_SERVICE_REGION = 'serviceRegions/destroy'

export const FETCH_SERVICE_AREAS = 'serviceAreas/fetch'
export const APPEND_SERVICE_AREA = 'serviceAreas/append'
export const UPDATE_SERVICE_AREA = 'serviceAreas/update'
export const DESTROY_SERVICE_AREA = 'serviceAreas/destroy'

export const UPDATE_ACCOUNT = 'accounts/update'
export const APPEND_ACCOUNT = 'accounts/append'

export const FETCH_ACCOUNTS = 'accounts/fetch'
export const FETCH_CURRENT_ACCOUNT = 'currentAccount/fetch'

export const FETCH_PATIENTS = 'patients/fetch'
export const MERGE_PATIENTS = 'patients/merge'
export const CREATE_PATIENT = 'patients/create'
export const UPDATE_PATIENT = 'patients/update'
export const DESTROY_PATIENT = 'patients/destroy'

export const FETCH_CURRENT_PATIENT = 'currentPatient/fetch'

export const FETCH_APPOINTMENTS = 'appointments/fetch'
export const CREATE_APPOINTMENT = 'appointment/create'
export const UPDATE_APPOINTMENT = 'appointment/update'
export const DESTROY_APPOINTMENT = 'appointment/destroy'

export const FETCH_APPOINTMENT_TYPES = 'appointment_types/fetch'
export const CREATE_APPOINTMENT_TYPE = 'appointment_type/create'
export const UPDATE_APPOINTMENT_TYPE = 'appointment_type/update'
export const UPDATE_APPOINTMENT_TYPES = 'appointment_type/update-multiple'
export const DESTROY_APPOINTMENT_TYPE = 'appointment_type/destroy'

export const FETCH_ADMINISTRATOR_TYPES = 'administrator_types/fetch'

export const FETCH_GENDERS = 'gender/fetch'
export const CREATE_GENDER = 'gender/create'
export const UPDATE_GENDER = 'gender/update'
export const UPDATE_GENDERS = 'gender/update-multiple'
export const DESTROY_GENDER = 'gender/destroy'

export const FETCH_BREEDS = 'breeds/fetch'
export const CREATE_BREED = 'breed/create'
export const UPDATE_BREED = 'breed/update'
export const UPDATE_BREEDS = 'breed/update-multiple'
export const DESTROY_BREED = 'breed/destroy'

export const FETCH_GROUPS = 'groups/fetch'
export const CREATE_GROUP = 'group/create'
export const UPDATE_GROUP = 'group/update'
export const UPDATE_GROUPS = 'group/update-multiple'
export const DESTROY_GROUP = 'group/destroy'
export const ADD_GROUP_TO_ORGANIZATION = 'group/add_to_organization'

export const FETCH_COMPANIES = 'companies/fetch'
export const CREATE_COMPANY = 'company/create'
export const UPDATE_COMPANY = 'company/update'
export const DESTROY_COMPANY = 'company/destroy'
export const UPDATE_COMPANIES = 'companies/update'

export const FETCH_CLASSIFICATIONS = 'classifications/fetch'
export const CREATE_CLASSIFICATION = 'classification/create'
export const UPDATE_CLASSIFICATION = 'classification/update'
export const UPDATE_CLASSIFICATIONS = 'classification/update-multiple'
export const DESTROY_CLASSIFICATION = 'classification/destroy'

export const FETCH_ADMINISTRATORS = 'administrators/fetch'
export const UPDATE_ADMINISTRATOR = 'administrators/update'
export const UPDATE_ADMINISTRATORS = 'administrators/update-multiple'
export const CREATE_ADMINISTRATOR = 'administrators/create'
export const DESTROY_ADMINISTRATOR = 'administrators/destroy'

export const FETCH_SHIFTS = 'shifts/fetch'
export const APPEND_SHIFT = 'shifts/append'
export const UPDATE_SHIFTS = 'shifts/update'
export const CREATE_SHIFTS = 'shifts/create'
export const DESTROY_SHIFT = 'shifts/destroy'
export const CLEAR_SHIFTS = 'shifts/clear'

export const FETCH_RESTRICTIONS = 'restrictions/fetch'
export const APPEND_RESTRICTION = 'restrictions/append'
export const UPDATE_RESTRICTIONS = 'restrictions/update'
export const CREATE_RESTRICTIONS = 'restrictions/create'
export const DESTROY_RESTRICTION = 'restrictions/destroy'

export const FETCH_INTEGRATION_CONFIGS = 'integrationConfigs/fetch'
export const UPDATE_INTEGRATION_CONFIG = 'integrationConfigs/update'
export const DESTROY_INTEGRATION_CONFIG = 'integrationConfigs/destroy'

export const FETCH_WORKERS = 'operations/fetch'
export const UPDATE_WORKERS = 'operations/update'
export const FETCH_MESSAGES = 'messages/fetch'
export const APPEND_MESSAGE = 'messages/append'
export const UPDATE_MESSAGE = 'messages/update'
export const CREATE_MESSAGE = 'messages/create'
export const DESTROY_MESSAGE = 'messages/destroy'

export const FETCH_NOTIFICATIONS = 'notifications/fetch'
export const APPEND_NOTIFICATION = 'notifications/append'
export const UPDATE_NOTIFICATION = 'notifications/update'
export const CREATE_NOTIFICATION = 'notifications/create'
export const DESTROY_NOTIFICATION = 'notifications/destroy'

export const FETCH_MESSENGER_TEMPLATES = 'messengerTemplates/fetch'
export const APPEND_MESSENGER_TEMPLATES = 'messengerTemplates/append'
export const UPDATE_MESSENGER_TEMPLATES = 'messengerTemplates/update'
export const CREATE_MESSENGER_TEMPLATES = 'messengerTemplates/create'
export const DESTROY_MESSENGER_TEMPLATES = 'messengerTemplates/destroy'

export const FETCH_SYNC = 'sync/fetch'
export const APPEND_SYNC = 'sync/append'
export const UPDATE_SYNC = 'sync/update'
export const CREATE_SYNC = 'sync/create'
export const DESTROY_SYNC = 'sync/destroy'

export const SET_SYNC_STATUS = 'syncStatus/set'
export const FETCH_WAITING_ROOM = 'waitingRoom/fetch'
export const DESTROY_WAITING_ROOM = 'waitingRoom/destroy'

export const FETCH_WAIT_LISTS = 'waitLists/fetch'
export const APPEND_WAIT_LIST = 'waitLists/append'
export const DESTROY_WAIT_LIST = 'waitLists/destroy'

export const FETCH_WAIT_LIST_MESSAGES = 'waitListMessages/fetch'
export const APPEND_WAIT_LIST_MESSAGE = 'waitListMessages/append'
export const DESTROY_WAIT_LIST_MESSAGE = 'waitListMessages/destroy'

export const FETCH_CUSTOM_FIELDS = 'customField/fetch'
export const APPEND_CUSTOM_FIELD = 'customField/append'
export const UPDATE_CUSTOM_FIELD = 'customField/update'
export const CREATE_CUSTOM_FIELD = 'customField/create'
export const DESTROY_CUSTOM_FIELD = 'customField/destroy'

export const FETCH_INSIGHTS = 'insights/fetch'

export const FETCH_MANUAL_WORKER_EXECUTIONS = 'manualWorkerExecutions/fetch'
export const UPDATE_MANUAL_WORKER_EXECUTIONS = 'manualWorkerExecutions/update'

export const FETCH_PARTITIONS = 'partition/fetch'
export const APPEND_PARTITION = 'partition/append'
export const UPDATE_PARTITION = 'partition/update'
export const CREATE_PARTITION = 'partition/create'
export const DESTROY_PARTITION = 'partition/destroy'