import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import { BrowserRouter, Route, Redirect, Link } from 'react-router-dom'
import { Card, Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, Tabs, DatePicker, Table, Switch } from 'antd';
import Scroller from 'components/elements/Scroller'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import {groupActions, organizationActions} from 'actions'
import { CreateOrEditGroup } from 'components/partials';
import { List, Typography, Divider } from 'antd';
const TabPane = Tabs.TabPane

class Organization extends Component {
    constructor(props){
        super(props)
        this.state = {
            workers: {
                evet: {}
            },
            filters: {}
        }
        this.handleAddOrganization = this.handleAddOrganization.bind(this)
        this.handleRemoveOrganization = this.handleRemoveOrganization.bind(this)
        this.handleCreateGroup = this.handleCreateGroup.bind(this)
        this.handleUpdateGroup = this.handleUpdateGroup.bind(this)
        this.handleDestroyGroup = this.handleDestroyGroup.bind(this)
        this.handleShowGroupCreate = this.handleShowGroupCreate.bind(this)
        this.handleHideGroupCreate = this.handleHideGroupCreate.bind(this)
        this.handleSelectOrganization = this.handleSelectOrganization.bind(this)
    }
    
    componentDidMount(){
        groupActions.fetch()
    }
    handleAddOrganization(group){
        console.log(this.group)
        groupActions.addOrganization(group)
        this.props.history.push('/admin')
    }
    handleRemoveOrganization(group){
        groupActions.removeOrganization(group.id)
        this.props.history.push('/admin')
    }
    
    handleCreateGroup(group){
        groupActions.create(group)
        this.props.history.push('/admin')
    }
    
    handleUpdateGroup(group){
        groupActions.update(group)
        this.props.history.push('/admin')
    }
    
    handleDestroyGroup(group){
        groupActions.destroy(group)
        this.props.history.push('/admin')
    }
    
    get data(){
        return Object.values(Object(this.props.groups))
    }

    handleShowGroupCreate(){
        this.setState({
            groupCreateVisible: true
        })
    }
    
    handleHideGroupCreate(){
        this.setState({
            groupCreateVisible: false
        })
    }

    handleSelectOrganization(organizationID){
        organizationActions.changeCurrent(organizationID)
    }
    
    render(){
        return (
            <Fragment>
                <Row>
                    <Col xs={14} style={{padding: 10}} >
                        <Input onChange={this.handleNameFilter} />
                    </Col>
                    <Col xs={10} style={{padding: 10, textAlign: 'right'}} >
                        <Button onClick={this.handleShowGroupCreate}>Create New Group</Button>
                    </Col>
                </Row>
                <CreateOrEditGroup visible={this.state.groupCreateVisible} onClose={this.handleHideGroupCreate} />
                <Scroller>
                        <List
                            size="large"
                            bordered
                            dataSource={this.data}
                            renderItem={(group)=> (
                                <List.Item>
                                    <Row style={{width: '100%'}} gutter={12}>
                                        <Col xs={4}>
                                            <Link onClick={this.handleCreateGroup.bind(null, group)}>{group.name || 'No Name Provided'} </Link>
                                        </Col>
                                        <Col xs={4}>
                                            {group.description || 'No Description Provided'}
                                        </Col>
                                        <Col xs={8}>
                                            <Select
                                                style={{ width: '100%' }}
                                                onChange={this.handleSelectOrganization}
                                            >
                                                {(group.organizations || []).map((organization, i)=>(
                                                    <Option key={`organization-${i}`} value={organization.id}>
                                                        {organization.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xs={8}>
                                            <Popconfirm
                                                title="Delete Group"
                                                description="Are you sure to delete this group?"
                                                onConfirm={this.handleDestroyGroup.bind(null, group)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button danger>Delete</Button>
                                            </Popconfirm>
                                            <Button
                                                onClick={this.handleAddOrganization.bind(null, group)}
                                            >
                                                Add Organization
                                            </Button>
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                </Scroller>
            </Fragment>
        )
    }
}

export default connect((state)=>(
    state
))(Organization)