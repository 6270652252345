export default (state)=>{
    return {
        ...state,
        classificationsBreeds:  Object.entries(state.breeds).reduce((acc, [id, breed])=>{
            const {classificationID} = breed
            
            const classification = state.classifications[classificationID] 
            acc[`${classificationID}--${id}`] = {
                classification,
                breed
            }
            return acc
        }, {})
    }
}