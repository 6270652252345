import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import widgetExample from './widget'
import _ from 'lodash'
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';


const WidgetCodeSnippet = (props)=> {
    useEffect(() => {
        hljs.highlightAll();
      }, []);

    return (
        <div>
            <pre>
                <code className='html'>
                    {widgetExample(props.currentOrganization.id)}
                </code>
            </pre>
            <a href={`https://minnow.nextinline.io/?organizationID=${props.currentOrganization.id}`}>
                Example 
            </a>
        </div>
    );
}
export default withRouter(connect((state)=>state)(WidgetCodeSnippet))
