import React, {Component} from 'react'
import { Card, Layout, Col, Row, List, Select } from 'antd'
import {utils} from 'lib';

import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
//import './index.less'
import InternalCalendar from './InternalCalendar'
import moment from 'moment'
import _ from 'lodash'

import {
    restrictionActions,
    appointmentActions,
    patientActions,
    administratorActions,
    openTimeActions
} from 'actions'

import { connect } from 'react-redux';

const { Content } = Layout

class Restrictions extends Component {
    constructor(){
        super()

        this.state = {
            events: [],
            adminFilter: ''
        };
        this.handleCreate = this.handleCreate.bind(this)
        this.handleChangeView = this.handleChangeView.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleRangeChange = this.handleRangeChange.bind(this)
        // this.handleAdministratorsFilter = this.handleAdministratorsFilter.bind(this)
    }
    componentDidMount(){
        restrictionActions.fetch()
        appointmentActions.fetch({startingAt: moment().startOf('week').format(), unit: this.props.match.params.calendar || 'week'})
        patientActions.fetchActive()
        administratorActions.fetch()
        openTimeActions.fetch({skipRepeating: true})
    }
    handleSelect(event){
        if(event.events.length == 1){
            this.setState({
                event
            }, ()=>{
                this.setState({
                    createVisible: true,
                })
            })
        }else{
            this.setState({
                selectVisible: true,
                event
            })
        }
    }
    handleCreate(event){
        this.setState({
            createVisible: true,
            event,
            events: [event]
        })
    }
    handleChangeView(type){
        // this.props.history.push(`/admin/appointments/${type}`)
        // appointmentActions.fetch({archived: false})
    }
    handleRangeChange(days){
        // appointmentActions.fetch({startingAt: days[0], unit: this.props.match.params.calendar || 'week'})
    }
    // handleAdministratorsFilter(e){
    //     this.setState({
    //         adminFilter: e
    //     })
    // }

    get events(){
        let events = Object.values(this.props.appointments || {}).filter((event)=>{
            return !this.state.adminFilter || event.administratorID == this.state.adminFilter 
        }).map((_)=>({
            ..._,
            title: _.reason,
            start: moment(_.startsAt).toDate(),
            end: moment(_.endsAt).toDate()
        })).concat(this.state.events)
        .reduce((acc, e)=>{
            const key = `${moment(e.start).format()}-${moment(e.end).format()}`
            const p = _.get(acc, `[${key}].events`,[])
            let title = e.title;

            if(p.length > 0){
                title = `${p.length + 1} appointments`
            }

            acc[key] = {
                events:[
                    ...p,
                    e
                ],
                ...e,
                title,
                start: e.start,
                end: e.end
            }
            
            return acc
        }, {})

        return Object.values(events)
    }
    get administrators(){
        return Object.values(this.props.administrators || {}).sort((a,b)=>{
            if(a.firstName < b.firstName) return -1
            if(a.firstName > b.firstName) return 1
            return 0
        })
    }
    render() {
        const calendar = this.props.match.params.calendar
        return (
            <AdminLayout fullHeight={true}>
                <SectionContent noPadding noMargin noHeader>
                    <InternalCalendar 
                        events={this.events}
                        calendar={calendar}
                    />
                </SectionContent>
            </AdminLayout>
        )
    }
}

export default connect((state)=> state)(Restrictions)