import Collection from '../collection'

class Notifications extends Collection{
    fetch(options = {}){
        let params = ''
        let id = ''
        if(options.accountID) params = `?accountID=${options.accountID}`
        if(options.id) id = `/${options.id}`
        delete options.id
        delete options.accountID
        // return this.axios.get(`/notifications${id}${params}`, {params:
        //     {...options}
        // });
    }
    fetchActive(options = {}){
        options.active = true
        // return this.axios.get(`/notifications`, {params:
        //     {...options}
        // });
    }
    markAsViewed(options = {}){
        return this.axios.put(`/notifications/${options.id}/viewed`, {
            params: {}
        });
    }
}

export default Notifications;