import currentOrganization from "./currentOrganization";
import classificationBreeds from "./classificationsBreeds";
import accountsPatients from "./accountsPatients";
import pipe from '../lib/pipe'

export default (state)=>(
    pipe(
        state,
        currentOrganization,
        classificationBreeds,
        accountsPatients
    )
)