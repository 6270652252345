import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Card, Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import Scroller from 'components/elements/Scroller'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import Panels from './Panels'
import {workersActions} from 'actions'
const TextArea = Input.TextArea;

class Organization extends Component {
    constructor(props){
        super(props)
        this.state = {
            workers: {
                evet: {}
            }
        }
        workersActions.fetch()
        this.handleChange = this.handleChange.bind(this)
        this.handleEditToggle = this.handleEditToggle.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }
    
    componentDidUpdate(previousProps){
        if(this.props.workers != previousProps.workers){
            this.setState({
                workers: JSON.parse(
                    JSON.stringify(this.props.workers)
                )
            })
        }
    }
    handleChange(scope, key, e){
        this.state.workers[scope][key] = JSON.parse(e.target.value)
        this.setState({
            workers: this.state.workers
        })
    }

    handleEditToggle(key){
        this.setState({
            edit: key
        })
    }
    handleSave(scope, key){

        workersActions.update({
            scope,
            key,
            data: this.state.workers[scope][key]
        })
        this.setState({
            edit: null
        })
    }
    handleCancel(){
        this.setState({
            edit: null
        })
    }
    render(){
        return (
            <AdminLayout fullHeight={true}>
                <SectionContent noPadding noMargin noHeader>
                    <Panels />
                </SectionContent>
            </AdminLayout>
        )
    }
}

export default connect((state)=>(
    state
))(Organization)