import React from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {Button} from 'antd';
import { Fa } from 'components/elements'
import {
    availabilityActions 
} from 'actions'
import _ from 'lodash'



class WidgetSuperAdmin extends React.Component {
    constructor(){
        super();
        this.state = {
            resetSpinner: '',
            resetSuccess: ''
        }
        this.resetAvailabilities = this.resetAvailabilities.bind(this)
    }   

    async resetAvailabilities(){
        this.setState({
          resetSpinner: true
        })
        let res = await availabilityActions.reset({})
        if(res.data && res.data == 'success'){
          this.setState({
            resetSpinner: false,
            resetSuccess: true
          })
        } else {
          this.setState({
            resetSpinner: false,
            resetSuccess: "fail"
          })
        }
      }

    render() {
        return (
            <div>
                <Button type="primary" onClick={this.resetAvailabilities}>Reset Availabilities</Button>
                {this.state.resetSpinner &&
                <Fa icon='spinner' className='fa-spin' style={{height: "20px", marginLeft: "10px"}}/>
                }
                {!!this.state.resetSuccess &&
                <Fa icon='check' style={{marginLeft: "10px"}}/>
                }
                {this.state.resetSuccess == "fail" &&
                <Fa icon='times' style={{marginLeft: "10px"}}/>
                }
            </div>
        );
    }
}
export default withRouter(connect((state)=>state)(WidgetSuperAdmin))
