import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';

import store from '../store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.appointments.fetch(params)
  
  const data = response.data.reduce((acc, item)=>{
    acc[item.id] = item
    return acc;
  }, {})

  store.dispatch({ 
    type: ActionTypes.FETCH_APPOINTMENTS,
    payload: data
  })
}

export const update = async(params = {}) => {
  const response = await apiClient.organizations.appointments.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_APPOINTMENT,
    payload: response.data
  })
}

export const destroy = async(id) => {
  const response = await apiClient.organizations.appointments.destroy(id)

  store.dispatch({
    type: ActionTypes.DESTROY_APPOINTMENT,
    payload: id
  })
}

export const create = async(params = {})=>{
  const response = await apiClient.organizations.appointments.create(params)

  store.dispatch({
    type: ActionTypes.CREATE_APPOINTMENT,
    payload: response.data
  })
}