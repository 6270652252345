import Collection from 'lib/client/collections/collection'
import Patients from './patients'

class Administrators extends Collection{
    fetch(options = {}){
        let params = ''
        Object.keys(options).forEach((key)=>{
            if(!params){
                params = "?"
            } else {
                params += "&"
            }
            params += `${key}=${options[key]}` 
        })
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/administrators${params}`);
    }
    
    fetchOne(id){
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/administrators/${id}`);
    }

    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/administrators`, options);
    }

    update(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/administrators/${options.id}`, options);
    }

    activate(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/administrators/activate`, options);
    }
}

export default Administrators;