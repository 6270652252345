import React from 'react';
import { Form, Col, Row, Input, DatePicker, Select } from 'antd';
import moment from 'moment'
import {accountActions} from 'actions'
import {connect} from 'react-redux'
import {countries} from 'lib'

class AccountForm extends React.Component {
  constructor(){
    super();
    this.state = { 
      visible: false
    };
    this.handleWorkPhoneChange = this.handleWorkPhoneChange.bind(this)
    this.handleHomePhoneChange = this.handleHomePhoneChange.bind(this)
    this.handleMobilePhoneChange = this.handleMobilePhoneChange.bind(this)
  }

  get account(){
    return this.props.account || {}
  }

  get organization(){
    return this.props.organizations[this.props.currentOrganizationID] || {country: 'US'}
  }

  get states(){
		return countries.statesOrProvinces(this.organization.country).map((state)=>({
      name: state.name, value: state.code
    }))
  }

  handleWorkPhoneChange(e){
    let value = e.target.value
    value = this.maskPhone(value)

    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({workPhone: value});
    }, 1)
  }

  handleHomePhoneChange(e){
    let value = e.target.value
    value = this.maskPhone(value)

    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({homePhone: value});
    }, 1)
  }

  handleMobilePhoneChange(e){
    let value = e.target.value
    value = this.maskPhone(value)

    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({mobilePhone: value});
    }, 1)
  }

  maskPhone(value){
    value = value.replace(/\D/g,'');

    value = value.substring(0,10);

    var size = value.length;
    if(size == 0){
      value = value;
    }else if(size < 4){
      value = value;
    }else if(size < 7){
      value = value.substring(0,3)+'-'+value.substring(3,6);
    }else{
      value = value.substring(0,3)+'-'+value.substring(3,6)+'-'+value.substring(6,10);
    }
    return value; 
  }

  componentDidUpdate(previousProps){
    if(JSON.stringify(previousProps.account) != JSON.stringify(this.props.account)){
      this.form && this.form.resetFields();
    }
  }

  async handleDestroyAccount(e){
    e.preventDefault()
    
    // await accountActions.destroy({
    //   id: this.account.id
    // })
    this.props.onClose()
  }

  get form() {
    return this.props.formRef.current
  }

  render() {
    console.log(this.account)
    let edit = false;
    
    if(this.account.id){
      edit = true
    }

    const initialValues = {
      title: this.account.title,
      firstName: this.account.firstName,
      lastName: this.account.lastName,
      email: this.account.email,
      mobilePhone: this.account.mobilePhone,
      homePhone: this.account.homePhone,
      workPhone: this.account.workPhone,
      birthday: moment(this.account.birthday),
      address: this.account.address,
      unit: this.account.unit,
      city: this.account.city,
      county: this.account.county,
      state: this.account.state,
      zip: this.account.zip
    }
    
    return (
        <div>
          <Form
            initialValues={initialValues}
            ref={this.props.formRef}
            onFinish={this.props.onFinish}
          >            
            <Row 
              gutter={10}
            >
              <Col span={3}>
                <label>Title:</label>
                <Form.Item
                  {...Form.ItemLayout}
                  name="title"
                >
                  <Input placeholder={'Mr.'} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <label>First Name:</label>
                <Form.Item
                  {...Form.ItemLayout}
                  required={true}
                  name="firstName"
                  rules={[{required: true, message: 'Please enter a First Name'}]}
                >
                  <Input placeholder={'John'} />
                </Form.Item>
              </Col>
              <Col span={11}>
                <label>Last Name:</label>
                <Form.Item
                  {...Form.ItemLayout}
                  name="lastName"
                  rules={[{required: true, message: 'Please enter a Last Name'}]}
                >
                  <Input placeholder={'Dolittle'} />
                </Form.Item>
              </Col>
            </Row>
            
            <Form.Item
              {...Form.ItemLayout}
              label="E-mail"
              name="email"
              rules={[{
                type: 'email', message: 'This not valid E-mail',
              }]}
            >
              <Input placeholder={'drdolittle@example.com'} />
            </Form.Item>
            <Form.Item
              {...Form.ItemLayout}
              label="Mobile Phone"
              name="mobilePhone"
            >
              <Input onChange={this.handleMobilePhoneChange} placeholder={'822-555-0405'} />
            </Form.Item>
            <Form.Item
              {...Form.ItemLayout}
              label="Home Phone"
              name="homePhone"
              rules={[{required: false, message: 'Please enter a Home Phone'}]}
            >
              <Input onChange={this.handleHomePhoneChange} placeholder={'822-555-0405'} />
            </Form.Item>
            <Form.Item
              {...Form.ItemLayout}
              label="Work Phone"
              name="workPhone"
              rules={[{required: false, message: 'Please enter a Work Phone'}]}
            >
              <Input onChange={this.handleWorkPhoneChange} placeholder={'822-555-0405'} />
            </Form.Item>
            
            <Form.Item
              {...Form.ItemLayout}
              label="Date of Birth"
              name="birthday"
              rules={[{required: false, message: 'Please enter a Date of Birth'}]}
            >
              <DatePicker />
            </Form.Item>
            <Row gutter={16}>
              <Col span={18}>
                <Form.Item 
                  label="Address"
                  name="address"
                  rules={[
                    { message: 'Please enter an address' }, 
                    { required: false, message: 'Please enter an address' }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item 
                  label="Unit"
                  name="unit"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item 
                  label="City"
                  name="city"
                  rules={[
                    { message: 'Please enter a city' }, 
                    { required: false, message: 'Please enter a city' }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item 
                  label="County" 
                  autoComplete="shipping county"
                  name="county"
                  rules={[
                    { message: 'Please enter a county' }, 
                    { required: false, message: 'Please enter a country' }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item 
                  label="State / Province" 
                  autoComplete="shipping state" 
                  name="state"
                  rules={[
                    { message: 'Please enter a state / province' }, 
                    { required: false, message: 'Please enter a state / province' }
                  ]}
                >
                  <Select
                    style={{width: '100%'}}
                    showSearch
                    placeholder={"Select State / Province"}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    notFoundContent={null}
                    filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {this.states.map((state, i)=>(
                      <Select.Option key={`state-${i}`} value={state.value}>{state.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  label="Zip / Postal Code"
                  name="zip"
                  rules={[
                    { message: 'Please enter a zip / postal code' }, 
                    { required: false, message: 'Please enter a zip / postal code' }
                  ]}
                >
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
    );
  }
}
export default connect((state)=>
  state
)(AccountForm)