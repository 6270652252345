import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import moment from 'moment'
import {administratorActions} from 'actions'
import {connect} from 'react-redux'
import CreateOrEditGender from '../CreateOrEditGender'
import CreateOrEditBreed from '../CreateOrEditBreed';
import {apiClient} from 'lib'

const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;


class CreateOrEditAdministrator extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createGendersVisible: false,
      genders: []
    };

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDestroyAdministrator = this.handleDestroyAdministrator.bind(this)
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  async handleSubmit (values) {
    const _administrator = {
        firstName: values.firstName,
        middleInitial: values.middleInitial,
        lastName: values.lastName,
        email: values.email,
        mobilePhone: values.mobilePhone,
        homePhone: values.homePhone,
        workPhone: values.workPhone,
        address: values.address,
        unit: values.unit,
        city: values.city,
        county: values.county,
        country: values.country,
        state: values.state,
        zip: values.zip,
        birthday: values.birthday,
        timeZone: values.timeZone
    }
    let administrator
    if(this.administrator.id){
      administrator = await administratorActions.update({
          id: this.administrator.id,
          ..._administrator
      })
    } else {
      administrator = await administratorActions.create(_administrator)
      this.props.onSetCurrentAdministrator(administrator.id)
    }
    this.props.onClose()
  }

  get administrator(){
    const _administrator = this.props.administrator || {}
    
    return {
        id: _administrator.id,
        firstName: _administrator.firstName,
        middleInitial: _administrator.middleInitial,
        lastName: _administrator.lastName,
        email: _administrator.email,
        mobilePhone: _administrator.mobilePhone,
        homePhone: _administrator.homePhone,
        workPhone: _administrator.workPhone,
        address: _administrator.address,
        unit: _administrator.unit,
        city: _administrator.city,
        county: _administrator.county,
        country: _administrator.country,
        state: _administrator.state,
        zip: _administrator.zip,
        birthday: _administrator.birthday,
        notificationPreferences: _administrator.notificationPreferences,
        timeZone: _administrator.timeZone
    }
  }
  componentDidUpdate(previousProps){
    if(previousProps.visible != this.props.visible){
      this.form && this.form.resetFields();
    }else if(!previousProps.visible && this.props.visible){
      
    }
  }
  
  handleNewGender(value){
    if(value === 'new'){
      this.setState({
        createOrEditGenderVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({genderID: ''});
      }, 1)
    }
  }

  onSetCurrentGender(genderID){
    this.form && this.form.setFieldsValue({genderID});
  }

  handleNewSpeciesBreed(value){
    if(value === 'new'){
      this.setState({
        createOrEditSpeciesBreedVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({breedID: ''});
      }, 1)
    }
  }

  onSetCurrentBreed(breedID){
    this.form && this.form.setFieldsValue({breedID});
  }

  handleEditGender(){
    this.setState({
      createOrEditGenderVisible: true
    })
  }

  handleEditSpeciesBreed(){
    this.setState({
      createOrEditSpeciesBreedVisible: true
    })
  }

  handleGenderClose(){
    this.setState({
      createOrEditGenderVisible: false
    })
  }

  handleAdministratorChange(value){
    if(value === 'new'){
      this.setState({
        createOrEditAdministratorVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({administratorID: ''});
      }, 1)
    } 
  }

  handleEditAdministrator(){
    this.setState({
      createOrEditAdministratorVisible: true
    })
  }

  onSetCurrentAdministrator(administratorID){
    this.form && this.form.setFieldsValue({administratorID});
  }

  async handleDestroyAdministrator(e){
    e.preventDefault()
    
    await administratorActions.destroy({
      administratorID: this.administrator.id,
      id: this.administrator.id
    })
    
    this.props.onClose()
  }

  get form() {
    return this.formRef.current
  }

  render() {
    let edit = false;


    const initialValues = {
      title: this.administrator.title,
      firstName: this.administrator.firstName,
      middleInitial: this.administrator.middleInitial,
      lastName: this.administrator.lastName,
      email: this.administrator.email,
      mobilePhone: this.administrator.mobilePhone,
      homePhone: this.administrator.homePhone,
      workPhone: this.administrator.workPhone,
      address: this.administrator.address,
      unit: this.administrator.unit,
      city: this.administrator.city,
      county: this.administrator.county,
      state: this.administrator.state,
      zip: this.administrator.zip,
      birthday: moment(this.administrator.birthday)
    }

    return (
      <Drawer
        title={this.administrator.id ? 'Edit Administrator' : 'Create Administrator'}
        width={720}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <Form ref={this.formRef} layout="vertical" initialValues={initialValues} onFinish={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={3}>
              <Form.Item 
                label="Title"
                name="title"
              >
                <Input placeholder="Please enter a name for Administrator" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item 
                label="First Name"
                name="firstName"
                rules={[{required: true, message: 'Please enter a First Name.'}]}
              >
                <Input placeholder="Please enter a name for patient" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item 
                label="M.I."
                name="middleInitial"
              >
                <Input placeholder="Please enter a name for Administrator" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item 
                label="Last Name"
                name="lastName"
                rules={[{required: true, message: 'Please enter a Last Name.'}]}
              >
                <Input placeholder="Please enter a name for administrator" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              {...Form.ItemLayout}
              label="E-mail"
              name="email"
              rules={[{
                type: 'email', message: 'This not valid E-mail',
              }]}
            >
              <Input placeholder={'drdolittle@example.com'} />
            </Form.Item>
            <Form.Item
              {...Form.ItemLayout}
              label="Mobile Phone"
              name="mobilePhone"
            >
              <Input onChange={this.handleMobilePhoneChange} placeholder={'822-555-0405'} />
            </Form.Item>
            <Form.Item
              {...Form.ItemLayout}
              label="Home Phone"
              name="homePhone"
              rules={[{required: false, message: 'Please enter a Home Phone'}]}
            >
              <Input onChange={this.handleHomePhoneChange} placeholder={'822-555-0405'} />
            </Form.Item>
            <Form.Item
              {...Form.ItemLayout}
              label="Work Phone"
              name="workPhone"
              rules={[{required: false, message: 'Please enter a Work Phone'}]}
            >
              <Input onChange={this.handleWorkPhoneChange} placeholder={'822-555-0405'} />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item 
                label="Address"
                name="address"
                rules={[{ message: 'Please select an address' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                label="Unit"
                name="unit"
                rules={[{ message: 'Please select an address' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item 
                label="City"
                name="city"
                rules={[{ message: 'Please select a city' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item 
                label="County"
                name="county"
                rules={[{ message: 'Please select a county' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="State"
                name="state"
                rules={[{ message: 'Please select a state' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Zip"
                name="zip"
                rules={[{ message: 'Please select a zip' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          
          <Row>
            <Col span={24}>
              <Form.Item 
                label="Birthday"
                name="birthday"
                rules={[{ required: false, message: 'Please select a birthday' }]}
              >
                <DatePicker style={{width: '100%'}}/>
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyPatient} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Patient</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.administrator.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  (CreateOrEditAdministrator)
)