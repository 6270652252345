import React from 'react'
import { Input, Form, InputNumber, DatePicker, Select, Button, Row, Col, Switch, Transfer, Divider} from 'antd'
import { MaskedInput } from 'components/elements'
const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;
const InputGroup = Input.Group;

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import GeneralEditor from 'components/elements/GeneralEditor'
import Scroller from 'components/elements/Scroller'
import FormGroup from 'components/elements/FormGroup'
import {CreateOrEditServiceRegion} from 'components/partials'
import {
  administratorActions,
  administratorTypeActions
} from 'actions'

const dateFormat = 'DD/MM/YYYY';

class Edit extends React.Component {
  constructor(){
    super()
    this.formRef = React.createRef()
    this.state = {
      officeHours:[],
      selectedAppointmentTypes: [],
      createOrEditServiceRegionVisible: false,
      processing: false
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setServiceRegion = this.setServiceRegion.bind(this)
    this.handleServiceRegion = this.handleServiceRegion.bind(this)
    this.handleEditServiceRegion = this.handleEditServiceRegion.bind(this)
    this.handleAppointmentTypeChange = this.handleAppointmentTypeChange.bind(this)
    this.handleCreateOrEditServiceRegionClose = this.handleCreateOrEditServiceRegionClose.bind(this)
  }
  componentDidMount(){
    administratorTypeActions.fetch()
    console.log("HAPPENTING")
    this.setState({
      selectedAppointmentTypes: (this.current.appointmentTypeIDs || [])
    })
  }
  componentDidUpdate(previousProps){
        if(JSON.stringify(this.props.current) != JSON.stringify(previousProps.current)){
          this.setState({
            selectedAppointmentTypes: (this.current.appointmentTypeIDs || [])
          }, ()=>{
            this.form.resetFields()
          })
        }
  }
  async handleSubmit(values){
        this.setState({
            processing: true
        })
        if(this.props.type === 'new'){
            await administratorActions.create(values)
        }
        
        if(this.props.type === 'edit'){
            const payload = {
                ...this.current,
                ...values,
                serviceRegionID: values.serviceRegionID || null,
            }
            if((payload.birthday || {}).format) payload.birthday = payload.birthday.format()

            await administratorActions.update(payload)
        }

        this.setState({
            processing: false
        })

        this.props.history.push(`/admin/administrators/${this.current.id}`)
  }
  get current(){
    return this.props.current || {
      administratorOptions: {}
    }
  }
  get administratorTypes(){
    return Object.values(
      this.props.administratorTypes || {
        administratorTypes: {}
      }
    )
  }
  get appointmentTypes(){
    return Object.values(this.props.appointmentTypes || {})
    .map((appointmentType = {})=>{
      let _appointmentType = {...appointmentType}
      _appointmentType.key = appointmentType.id
      _appointmentType.title = `${appointmentType.name}`
      return _appointmentType
    }) || []
  }

  get currentAppointmentTypeIDs(){
    return this.state.selectedAppointmentTypes || []
  } 

  get form() {
    return this.formRef.current
  }

  get serviceRegions(){
    return Object.values(this.props.serviceRegions).sort((a,b)=>{
      if(a.name < b.name) return -1
      if(a.name > b.name) return 1
      return 0
    })
  }

  get serviceRegion(){
    const id = this.form && this.form.getFieldValue('serviceRegionID')
    return this.props.serviceRegions[id]
  }

  setServiceRegion(value){
    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({serviceRegionID: value});
    }, 1)
  }

  handleAppointmentTypeChange(types){
    this.setState({
      selectedAppointmentTypes: types
    })
  }

  handleCancel(){
    if(this.current.id){
        this.props.history.push(`/admin/administrators/${this.current.id}`)
    } else {
        this.props.history.push(`/admin/administrators`)
    }
  }

  handleEditServiceRegion(){
    this.setState({
      createOrEditServiceRegionVisible: true
    })
  }

  handleServiceRegion(value){
    if(value === 'new'){
      this.setState({
        createOrEditServiceRegionVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({serviceRegionID: ''});
      }, 1)
    }
  }

  handleCreateOrEditServiceRegionClose(){
    this.setState({
      createOrEditServiceRegionVisible: false
    })
  }

// check later

  // initialValue: this.current.title,
  // initialValue: this.current.firstName,
  // initialValue: this.current.middleInitial,
  // initialValue: this.current.lastName,
  // initialValue: this.current.email,
  // initialValue: this.current.mobilePhone,
  // initialValue: this.current.homePhone,
  // initialValue: this.current.workPhone,
  // initialValue: this.current.receiveNotifications,
  // initialValue: this.current.provider,
  // initialValue: moment(this.current.birthday),
  // initialValue: this.current.address,
  // initialValue: this.current.unit,
  // initialValue: this.current.city,
  // initialValue: this.current.county,
  // initialValue: this.current.state,
  // initialValue: this.current.country,
  // initialValue: this.current.zip,
  // initialValue: this.current.description,
  // initialValue: this.current.image,
  // initialValue: (this.current.administratorOptions || {}).maxPatientsPerVisit,
  // initialValue: (this.current.administratorOptions || {}).maxPatientsPerTimeSlot,
  // initialValue: (this.current.administratorOptions || {}).maxVisitsPerTimeSlot,
  // initialValue: this.current.serviceRegionID
 
  render() {

    const { 
      title,
      firstName,
      middleInitial,
      lastName,
      email,
      mobilePhone,
      homePhone,
      workPhone,
      receiveNotifications,
      provider, 
      birthday,
      address,
      unit,
      city,
      county,
      state,
      country,
      zip,
      description,
      image,
      administratorOptions,
      serviceRegionID,
      powerScheduling
    } = this.current
    
    const {
      maxPatientsPerVisit,
      maxPatientsPerTimeSlot,
      maxVisitsPerTimeSlot
    } = administratorOptions || {}

    const initialValues = {
      title,
      firstName,
      middleInitial,
      lastName,
      email,
      mobilePhone,
      homePhone,
      workPhone,
      receiveNotifications: !!receiveNotifications,
      provider: !!provider, 
      birthday: birthday && moment(birthday),
      address,
      unit,
      city,
      county,
      state,
      country,
      zip,
      description,
      image,
      maxPatientsPerVisit,
      maxPatientsPerTimeSlot,
      maxVisitsPerTimeSlot,
      serviceRegionID,
      powerScheduling
    }
    
    let editServiceRegion = false

    if(this.form && this.form.getFieldValue('serviceRegionID')){
      editServiceRegion = true
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div className='edit-switch'>
        <CreateOrEditServiceRegion
          visible={this.state.createOrEditServiceRegionVisible}
          onClose={this.handleCreateOrEditServiceRegionClose}
          setServiceRegion={this.setServiceRegion}
          serviceRegion={this.serviceRegion}
        />
        <Form ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
          <GeneralEditor onCancel={this.handleCancel} onSave={true} spinner={this.state.processing}>
            <Scroller>
                <br />
                <Form.Item
                  {...formItemLayout}
                  label="Name"
                  required={true}
                >
                  <Row gutter={10}>
                    <Col span={3}>
                      <Form.Item
                        name="title"
                        rules={[{
                          type: 'string', message: 'The input is not valid Title!',
                        }, {
                          required: false, message: 'Please input your Title!',
                        }]}
                      >
                        <Input placeholder={'Mr.'} />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item
                        name="firstName"
                        rules={[{
                          type: 'string', message: 'The input is not valid First Name!',
                        }, {
                          required: true, message: 'Please input your First Name!',
                        }]}
                      >
                        <Input placeholder={'John'} />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name="middleInitial"
                        rules={[{
                          type: 'string', message: 'The input is not valid Middle Initial!',
                        }]}
                      >
                        <Input placeholder={'E'} />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item
                        name="lastName"
                        rules={[{
                          type: 'string', message: 'The input is not valid Last Name!',
                        }, {
                          required: false, message: 'Please input your Last Name!',
                        }]}
                      >
                        <Input placeholder={'Dolittle'} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <br />
                <Form.Item
                  {...formItemLayout}
                  label="E-mail"
                  name="email"
                  rules={[{
                    type: 'email', message: 'The input is not valid E-mail!',
                  }, {
                    required: false, message: 'Please input your E-mail!',
                  }]}
                >
                  <Input placeholder={'drdolittle@example.com'} />
                </Form.Item>
                <br />
                <Form.Item
                  {...formItemLayout}
                  label="Mobile Phone"
                  name="mobilePhone"
                  rules={[{
                    type: 'string', message: 'The input is not valid Phone!',
                  }, {
                    required: false, message: 'Please input your Phone!',
                  }]}
                >
                  <MaskedInput placeholder={'822-555-0405'} type='phone' />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Home Phone"
                  name="homePhone"
                  rules={[{
                    type: 'string', message: 'The input is not valid Mobile!',
                  }]}
                >
                  <MaskedInput placeholder={'822-555-0405'} type='phone' />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Work Phone"
                  name="workPhone"
                  rules={[{
                    type: 'string', message: 'The input is not valid Work!',
                  }, {
                    required: false, message: 'Please input 0your Work!',
                  }]}
                >
                  <MaskedInput placeholder={'822-555-0405'} type='phone' />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Notifications"
                  name="receiveNotifications"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Provider"
                  name="provider"
                  valuePropName="checked"
                  >
                  <Switch />
                </Form.Item>
                <Form.Item
                    label="Provider Type"
                    name="administratorTypeID"
                >
                    <Select 
                        showSearch
                        placeholder={'Administrator Type'}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Select.Option value={null} key={`parent-organization--1`}>
                            None
                        </Select.Option>
                        {this.administratorTypes.map((administratorType, i)=>(
                            <Select.Option key={`administrator-type-${i}`} value={administratorType.id}>
                                {administratorType.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Appointment Types"
                  name="appointmentTypeIDs"
                >
                  <Transfer 
                    titles={['Inactive', 'Active']}
                    listStyle={{width:'calc(50% - 25px)'}}
                    dataSource={this.appointmentTypes}
                    targetKeys={this.currentAppointmentTypeIDs}
                    render={item => item.title}
                    onChange={this.handleAppointmentTypeChange}
                  />
                </Form.Item>
                <br />
                <Form.Item
                  {...formItemLayout}
                  label="Date of Birth"
                  name="birthday"
                  rules={[{
                    type: 'object', message: 'The input is not valid Date of Birth!',
                  }, {
                    required: false, message: 'Please input your Date of Birth!',
                  }]}
                >
                  <DatePicker />
                </Form.Item>
                <br />
                <Form.Item
                  {...formItemLayout}
                  label="Address"
                  name="address"
                  rules={[{
                    type: 'string', message: 'The input is not valid Unit!',
                  }, {
                    required: false, message: 'Please input your Unit!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Unit"
                  name="unit"
                  rules={[{
                    type: 'string', message: 'The input is not valid Unit!',
                  }, {
                    required: false, message: 'Please input your Unit!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="City"
                  name="city"
                  rules={[{
                    type: 'string', message: 'The input is not valid City!',
                  }, {
                    required: false, message: 'Please input your City!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="County"
                  name="county"
                  rules={[{
                    type: 'string', message: 'The input is not valid County!',
                  }, {
                    required: false, message: 'Please input your County!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="State"
                  name="state"
                  rules={[{
                    type: 'string', message: 'The input is not valid State!',
                  }, {
                    required: false, message: 'Please input your State!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Country"
                  name="country"
                  rules={[{
                    type: 'string', message: 'The input is not valid Country!',
                  }, {
                    required: false, message: 'Please input your Country!',
                  }]}
                >
                  <Input />
                </Form.Item>
                
                <Form.Item
                  {...formItemLayout}
                  label="Zip"
                  name="zip"
                  rules={[{
                    type: 'string', message: 'The input is not valid Zip!',
                  }, {
                    required: false, message: 'Please input your Zip!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Description"
                  name="description"
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Image"
                  name="image"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Max Patients Per Visit"
                  name="maxPatientsPerVisit"
                  rules={[{
                    type: 'number', message: 'The input is not valid Patients Per Visit!',
                  }, {
                    required: false, message: 'Please input your Patients Per Visit!',
                  }]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Max Patients Per Time Slot"
                  name="maxPatientsPerTimeSlot"
                  rules={[{
                    type: 'number', message: 'The input is not valid Patients Per Visit!',
                  }, {
                    required: false, message: 'Please input your Patients Per Visit!',
                  }]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Max Visits Per Time Slot"
                  name="maxVisitsPerTimeSlot"
                  rules={[{
                    type: 'number', message: 'The input is not valid Patients Per Visit!',
                  }, {
                    required: false, message: 'Please input your Patients Per Visit!',
                  }]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Power Scheduling"
                  name="powerScheduling"
                  rules={[{
                    type: 'number', message: 'The value is not valid for power scheduling!',
                  }, {
                    required: false, message: 'Please input your powerscheduling is not valid!',
                  }]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="Service Region"
                >
                    <InputGroup style={{ width: '100%' }} compact >
                        <Form.Item
                            noStyle
                            name="serviceRegionID"
                        >
                            <Select
                                style={{width: editServiceRegion ? 'calc(100% - 57px)' : '100%'}}
                                onChange={this.handleServiceRegion}
                                // onSearch={this.handleProviderSearch}
                                // showSearch
                                filterOption={false}
                                showArrow={false}
                            >
                                {this.serviceRegions.map((region, i)=>
                                <Option key={`region-${i}`} value={region.id}>
                                    {region.name}
                                </Option>
                                )}
                                <Option key='divider' disabled={true}><Divider className='divider'/></Option>
                                <Option value=''>None</Option>
                                <Option key='new'>New Service Region</Option>
                            </Select>
                        </Form.Item>
                        {editServiceRegion && (
                            <Button onClick={this.handleEditServiceRegion}>Edit</Button>
                        )}
                    </InputGroup>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Waiting Room"
                  name="isWaitingRoom"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
            </Scroller>
          </GeneralEditor>
        </Form>
      </div>
    )
  }
}

export default withRouter(
  (
    connect((state)=>state)(Edit)
  )
)