import React from 'react'
import { Form, Card, Input } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import {AccountForm} from 'components/partials'
import GeneralEditor from 'components/elements/GeneralEditor'
import Scroller from 'components/elements/Scroller'
import {messengerTemplatesActions} from 'actions'

const dateFormat = 'DD/MM/YYYY';
const TextArea = Input.TextArea

class Item extends React.Component {
    constructor(props){
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            messengerTemplate: props.messengerTemplate || {}
        }
        this.handleChange = this.handleChange.bind(this)
    }

    async handleSubmit (e) {
        e.preventDefault();

        const messengerTemplate = {
            ...this.state.messengerTemplate,
            id: !`${this.state.messengerTemplate.id}`.includes('default') ? this.state.messengerTemplate.id : null
        }

        try{
            let _messengerTemplate
            if(messengerTemplate.id){
                _messengerTemplate = await messengerTemplatesActions.update({
                    id: this.messengerTemplate.id,
                    ...messengerTemplate
                })
            } else {
                _messengerTemplate = await messengerTemplatesActions.create(messengerTemplate)
            }
            this.props.history.push(`/admin/messenger-templates/${_messengerTemplate.id}`)
        } catch(error){
            // let {data,errors} = error.response.data

            // let errorData = {}
            // data.forEach((key)=>{
            //   errorData[key] = {errors:[new Error('This value already exists')]}
            // })

            // this.props.form.setFields(errorData);
        }
    }

    get messengerTemplate(){
        return this.props.messengerTemplate || {}
    }
    handleCancel(){
        this.props.history.push(`/admin/accounts/${this.account.id}`)
    }
    componentDidUpdate(previousProps){
        if(JSON.stringify(previousProps.messengerTemplate) !== JSON.stringify(this.props.messengerTemplate)){
            this.setState({
                messengerTemplate: this.props.messengerTemplate
            })
        }
    }

    handleChange(type, e){
        this.setState({
            messengerTemplate: {
                ...this.state.messengerTemplate,
                [`${type}`]: e.target.value
            }
        })
    }
    
    render() {
        return (
            <div className='edit-switch'>
                <Form onFinish={this.handleSubmit}>
                    <GeneralEditor onCancel={this.handleCancel} onSave={this.handleSubmit}>
                        <Scroller>
                            <Card bordered={false}>
                                <form onSubmit={this.handleSubmit}>
                                    <Input value={this.state.messengerTemplate.subject} onChange={this.handleChange.bind(null, 'subject')} />
                                    <br />
                                    <br />
                                    <TextArea value={this.state.messengerTemplate.body} onChange={this.handleChange.bind(null, 'body')} style={{height: 'calc(100% - 116px)', resize: 'none'}} />
                                </form>
                            </Card>
                        </Scroller>
                    </GeneralEditor>
                </Form>
            </div>
        )
    }
}

export default withRouter(
    connect((state)=> state )(
        (Item)
    )
)