import React from 'react';
import { Form, Col, Row, Input, Select, Button, InputNumber } from 'antd';
import {RepeatingForm} from 'components/partials'
import {connect} from 'react-redux'
import CreateAppointmentType from '../CreateAppointmentType';
import Rules from './rules';

const Label = Input.Lab
class RestrictionForm extends React.Component {
  constructor(){
    super();
    this.state = { 
      visible: false
    };
  }

  get form() {
    return this.formRef.current
  }

  get restriction(){
    return this.props.restriction || {}
  }
  get appointmentTypes(){
    return Object.values(this.props.appointmentTypes).filter((appointmentType)=>(appointmentType.active))
  }

  render() {
    let editAppointmentType = false
    let edit = false;
    
    if(this.restriction.id){
      edit = true
    }
    
    return (
        <div>
          <Row gutter={10}>
            <Col span={24}>
              <CreateAppointmentType 
                visible={this.state.createOrEditAppointmentTypeVisible} 
                onClose={this.handleCreateAppointmentTypeClose}
                onSetCurrentAppointmentType={this.handleSetCurrentAppointmentType}
                appointmentType={this.appointmentType}
              />
              <Form.Item 
                label="Name"
                name="name"
                rules={[
                  { message: 'Please enter a name' }, 
                  { required: true, message: 'Please enter a name' }
                ]}
              >
                <Input/>
              </Form.Item>
              <RepeatingForm formRef={this.props.formRef} repeatableItem={this.restriction}/>
              <Form.Item 
                label="Appointment Type"
              >
                <Input.Group style={{ width: '100%' }} compact>
                  <Form.Item
                    name="appointmentTypeID"
                    noStyle
                    rules={[
                      { message: 'Please select an appointment type' }, 
                      { required: true, message: 'Please select an appointment type' }
                    ]}
                  >
                    <Select style={{width: editAppointmentType ? 'calc(100% - 57px)' : '100%'}} onChange={this.handleNewAppointmentType}>
                      {this.appointmentTypes.map((at, i)=>
                        <Select.Option key={i} value={at.id}>{at.name}</Select.Option>
                      )}
                      <Select.Option key='divider' className='divider' disabled={true}>&nbsp;</Select.Option>
                      <Select.Option value=''>None</Select.Option>
                      <Select.Option key='new'>New Appointment Type</Select.Option>
                    </Select>
                  </ Form.Item>
                  {editAppointmentType && (
                    <Button onClick={this.handleEditAppointmentType}>Edit</Button>
                  )}
                </Input.Group>
              </Form.Item>
              <br />
              <Row>
                <Col span={9}>
                  <h3 style={{marginTop: 2}}>There should be no more than</h3>
                </Col>
                <Col span={4}>
                  <Form.Item 
                    style={{width: '100%'}}
                    name="max"
                    rules={[
                      { required: true, message: 'Please enter a Max Patient Count' }
                    ]}
                  >
                    <InputNumber  style={{width: '100'}} />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <h3 style={{marginTop: 2}}>patients</h3>
                </Col>
              </Row>
              <Rules form={this.props.form} rules={this.restriction.rules} visible={this.props.visible} />
            </Col>
          </Row>
        </div>
    );
  }
  static initialValues(form, props = {}){
    const repeatingValues = RepeatingForm.initialValues(form, props)
   
    const rulesValues = (props.rules || []).reduce((acc, rule) => ({
      ...acc,
      [`key--${rule.id}`]: rule.key || '',
      [`operator--${rule.id}`]: rule.operator || '',
      [`value--${rule.id}`]: !_.isNil(rule.value) ? rule.value : rule.restrictionRuleableID || ''
    }), {})
  
    return {
      ...repeatingValues,
      ...rulesValues,
      name: props.name,
      appointmentTypeID: props.restrictionableID,
      max: props.maxPatients
    }
  }
}

export default connect((state)=> state )(RestrictionForm)