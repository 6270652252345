import React, {Component} from 'react'
import {Drawer} from 'antd'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import {CreateOrEditRestriction} from 'components/partials'

const localizer = momentLocalizer(moment)

let i = 0;
const create = ()=>{

}
export default class Restrictions extends Component {
    constructor(){
        super()
        this.state = {
            view: 'week',
            events: []
        }
        this.handleChangeView = this.handleChangeView.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
        this.setRestriction = this.setRestriction.bind(this)
        this.hideCreate = this.hideCreate.bind(this)
    }
    handleChangeView(view){
        this.setState({
            view
        })
    }

    get events(){
        return this.state.events || []
    }
    handleSelect(event){
        this.setState({
            event
        }, ()=>{
            this.setState({
                createVisible: true,
            })
        })
    }
    handleCreate(event){
        this.setState({
            createEditVisible: true,
            event,
            events: [event]
        })
    }
    setRestriction(event){
        i++
        this.setState({
            events: [
                ...this.state.events,
                {
                    ...event,
                    title: `There ${i}`
                }
            ]
        })
    }
    hideCreate(){
        this.setState({
            createEditVisible: false
        })
    }
    render(){
        return (
            <React.Fragment>
                <Calendar
                    localizer={localizer}
                    selectable
                    defaultDate={new Date()}
                    view={this.state.view}
                    onView={this.handleChangeView}
                    events={this.events}
                    onSelectEvent={this.handleSelect}
                    onSelectSlot={this.handleCreate}
                    // onNavigate={this.handleNavigate}
                    views={['week', 'day']}
                    // min={this.minTime}
                    // max={this.maxTime}
                    step={15}
                    timeslots={4}
                />
                <CreateOrEditRestriction visible={this.state.createEditVisible} onClose={this.hideCreate} restriction={this.state.event} />
            </React.Fragment>
        )
    }
}