import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import moment from 'moment'
import {appointmentActions, patientActions, accountActions, appointmentStatusActions} from 'actions'
import {connect} from 'react-redux'
import CreateAppointmentType from '../CreateAppointmentType';
import {CreateOrEditPatient, CreateOrEditAdministrator} from 'components/partials'
import {apiClient} from 'lib'
const TextArea = Input.TextArea;

const {Option} = Select;
const InputGroup = Input.Group;

class CreateOrEditAppointment extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createAppointmentTypeVisible: false,
      providerSearch: ''
    };

    this.patientSearchTimeout = ''

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAppointmentTypeSearch = this.handleAppointmentTypeSearch.bind(this)
    this.handleNewAppointmentType = this.handleNewAppointmentType.bind(this)
    this.handleCreateAppointmentTypeClose = this.handleCreateAppointmentTypeClose.bind(this)
    this.handleSetCurrentAppointmentType = this.handleSetCurrentAppointmentType.bind(this)
    this.handleEditAppointmentType = this.handleEditAppointmentType.bind(this)
    this.handleDestroyAppointment = this.handleDestroyAppointment.bind(this)
    this.handlePatientUser = this.handlePatientUser.bind(this)
    this.handleCreateOrEditPatientClose = this.handleCreateOrEditPatientClose.bind(this)
    this.handleSetCurrentPatient = this.handleSetCurrentPatient.bind(this)
    this.handleEditPatient = this.handleEditPatient.bind(this)
    this.handleProvider = this.handleProvider.bind(this)
    this.handleProviderSearch = this.handleProviderSearch.bind(this)
    this.handleCreateOrEditAdministratorClose = this.handleCreateOrEditAdministratorClose.bind(this)
    this.handleSetCurrentAdministrator = this.handleSetCurrentAdministrator.bind(this)
    this.handleEditAdministrator = this.handleEditAdministrator.bind(this)
    this.handlePatientSearch = this.handlePatientSearch.bind(this)
    this.handleChanges = this.handleChanges.bind(this)
    
    appointmentStatusActions.fetch()
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  async handleSubmit (values) {
    let date = moment(values.date)
    let startsAt = moment(values.startTime).date(date.date()).month(date.month()).year(date.year())
    let endsAt = moment(values.endTime).date(date.date()).month(date.month()).year(date.year())
    if(this.appointment.id){
      appointmentActions.update({
        id: this.appointment.id,
        patientID: values.patientID || null,
        administratorID: values.administratorID || null,
        appointmentTypeID: values.appointmentTypeID || null,
        appointmentStatusID: values.appointmentStatusID || null,
        reason: values.reason,
        startsAt: startsAt.format(),
        endsAt: endsAt.format()
      })
    }else{
      appointmentActions.create({
        appointmentStatusID: values.appointmentStatusID || null,
        appointmentTypeID: values.appointmentTypeID || null,
        patientID: values.patientID || null,
        administratorID: values.administratorID || null,
        reason: values.reason,
        startsAt: startsAt.format(),
        endsAt: endsAt.format()
      })
    }
    this.props.onClose()
  }

    async componentDidUpdate(previousProps){
        if(previousProps.visible != this.props.visible){
            this.form && this.form.resetFields();
            this.setState(this.state) // rerender
        }
        if(!this.patient.id && this.appointment.patientID){
            await patientActions.safeFetchByID({id: this.appointment.patientID})
            this.form && this.form.resetFields();
        }
    }

  handleAppointmentTypeSearch(value){
    this.setState({
      appointmentTypes: appointmentTypes.filter((_)=>(
        _.title.toLocaleLowerCase().includes(value.toLowerCase())
      ))
    });
  }

  async patientSearch(search = ''){
    const {data} = await apiClient.organizations.patients.fetch({limit:5, search})

    this.setState({
      patients: data
    })
  }

  handlePatientSearch(value){
    if(this.patientSearchTimeout){
      clearTimeout(this.patientSearchTimeout)
    }
    this.patientSearchTimeout = setTimeout(()=>{this.patientSearch(value)},600)
  }

  handleNewAppointmentType(value){
    if(value === 'new'){
      this.setState({
        createOrEditAppointmentTypeVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({appointmentTypeID: ''});
      }, 1)
    }
  }

  handleEditAppointmentType(value){
    this.setState({
      createOrEditAppointmentTypeVisible: true
    })
  }
  
  handleEditAppointmentStatus(value){
    this.setState({
      createOrEditAppointmentStatusVisible: true
    })
  }

  handleCreateAppointmentTypeClose(){
    this.setState({
      createOrEditAppointmentTypeVisible: false
    })
  }
  
  handleCreateAppointmentStatusClose(){
    this.setState({
      createOrEditAppointmentStatusVisible: false
    })
  }

  handleSetCurrentAppointmentType(appointmentTypeID){
    this.form && this.form.setFieldsValue({appointmentTypeID});
  }
  
  handleSetCurrentAppointmentStatus(appointmentStatusID){
    this.form && this.form.setFieldsValue({appointmentStatusID});
  }

  async handleDestroyAppointment(e){
    e.preventDefault()

    await appointmentActions.destroy(this.appointment.id)
    this.props.onClose()
  }

  handlePatientUser(value){
    if(value === 'new' || value == 'patientSearchPrompt'){
      if(value === 'new'){
        this.setState({
          createPatientVisible: true
        })
      }

      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({patientID: ''});
      }, 1)
    }
  }
 
  handleProviderSearch(value){
    this.setState({
      providerSearch: value
    })
  }
  handleProvider(value){
    if(value === 'new'){
      this.setState({
        createAdministratorVisible: true
      })

      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({administratorID: ''});
      }, 1)
    }
  }

  handleCreateOrEditPatientClose(){
    this.setState({
      createPatientVisible: false
    })
  }

  handleCreateOrEditAdministratorClose(){
    this.setState({
      createAdministratorVisible: false
    })
  }

  handleSetCurrentPatient(patientID){
    this.form && this.form.setFieldsValue({patientID});
  }

  handleSetCurrentAdministrator(administratorID){
    this.form && this.form.setFieldsValue({administratorID});
  }

  handleEditPatient(){
    this.setState({
      createPatientVisible: true
    })
  }

  handleEditAdministrator(){
    this.setState({
      createAdministratorVisible: true
    })
  }

  get appointment(){
    const _event = this.props.appointment || {}

    return {
      id: _event.id,
      reason: _event.title,
      appointmentTypeID: _event.appointmentTypeID,
      appointmentStatusID: _event.appointmentStatusID,
      administratorID: _event.administratorID,
      patientID: _event.patientID,
      start: moment(_event.start),
      end: moment(_event.end),
      confirmedAt: _event.confirmedAt,
      canceledAt: _event.canceledAt,
      createdAt: _event.createdAt
    }
  }

  get appointmentTypes(){
    return Object.entries(this.props.appointmentTypes).map(([,_])=>_).sort((a, b)=>(
      a.name < b.name ? -1 : 1
    ))
  }
  
  get appointmentStatuses(){
    return Object.entries(this.props.appointmentStatuses).map(([,_])=>_).sort((a, b)=>(
      a.name < b.name ? -1 : 1
    ))
  }

  get appointmentType(){
    const id = this.form && this.form.getFieldValue('appointmentTypeID')
    return this.props.appointmentTypes[id]
  }
  
  get appointmentStatus(){
    const id = this.form && this.form.getFieldValue('appointmentStatusID')
    return this.props.appointmentStatuses[id]
  }

  get administrators(){
    return Object.entries(this.props.administrators || {}).map(([_, a])=>a).filter((administrator)=>{
      let adminName = `${administrator.firstName} ${administrator.lastName}`
      return adminName.toLowerCase().includes(this.state.providerSearch.toLowerCase() || '') ||
      (administrator.email || '').toLowerCase().includes(this.state.providerSearch.toLowerCase() || '')
    })
  }

  get form() {
    return this.formRef.current
  }

  get administrator(){
    return this.props.administrators[this.form && this.form.getFieldValue('administratorID')] || {}
  }

  get patients(){
    return this.state.patients || Object.entries(this.props.patients || {}).map(([_, a])=>a)
  }

  get patient(){
    return this.props.patients[this.form && this.form.getFieldValue('patientID')] || {}
  }

  handleChanges(){
    this.setState(this.state)
  }

  get showAppointmentID(){
    return accountActions.isSuperAdministrator() || accountActions.isPartnerAdministrator()
  }
  
  render() {
    // ref={this.formRef}
    // this.formRef = React.createRef()

    const { 
      appointmentTypeID,
      appointmentStatusID,
      reason,
      date,
      startTime,
      endTime,
      administratorID,
      patientID
    } = this.appointment

    const initialValues = {
      appointmentTypeID,
      appointmentStatusID,
      reason,
      date: this.appointment.start,
      startTime: this.appointment.start,
      endTime: this.appointment.end,
      administratorID,
      patientID,
    }
    
    let edit = false;
    let editAppointmentType = false;
    let editAppointmentStatus = false;
    let editPatient = false;
    let editProvider = false;
    
    if(this.appointment.id){
      edit = true
    }
    if(this.form && this.form.getFieldValue('appointmentTypeID')){
      editAppointmentType = true
    }

    if(this.form && this.form.getFieldValue('appointmentStatusID')){
      editAppointmentStatus = true
    }

    if(this.form && this.form.getFieldValue('patientID')){
      editPatient = true
    }

    if(this.form && this.form.getFieldValue('administratorID')){
      editProvider = true
    }

    const title = (<div
      style={{
        position: 'absolute',
        top: 0,
        zIndex:1,
        width: '100%',
        borderBottom: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }}
      >
      {this.appointment.id ? 'Edit Appointment' : 'Create Appointment'}
  </div>)

    return (
      <Drawer
        title={title}
        width={720}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <CreateAppointmentType 
          visible={this.state.createOrEditAppointmentTypeVisible} 
          onClose={this.handleCreateAppointmentTypeClose}
          onSetCurrentAppointmentType={this.handleSetCurrentAppointmentType}
          appointmentType={this.appointmentType}
        />
        <CreateOrEditPatient
          visible={this.state.createPatientVisible}
          onClose={this.handleCreateOrEditPatientClose}
          onSetCurrentPatient={this.handleSetCurrentPatient}
          patient={this.patient}
        />
        <CreateOrEditAdministrator
          visible={this.state.createAdministratorVisible}
          onClose={this.handleCreateOrEditAdministratorClose}
          onSetCurrentAdministrator={this.handleSetCurrentAdministrator}
          administrator={this.administrator}
        />
        <Form 
          ref={this.formRef}
          layout="vertical"
          initialValues={initialValues}
          onFinish={this.handleSubmit}
          onValuesChange={this.handleChanges}
        >
          <Row gutter={16}>
            <Col span={24}>
              {this.showAppointmentID && (
                <div>
                  {this.appointment.id}
                </div>
              )}
              <Form.Item label="Appointment Status">
              </Form.Item>
              <Form.Item label="Appointment Status">
                <Input.Group 
                  style={{ width: '100%' }} compact 
                >
                  <Form.Item 
                    name="appointmentStatusID"
                    noStyle
                  >
                    <Select style={{width: editAppointmentType ? 'calc(100% - 57px)' : '100%'}}>
                      {this.appointmentStatuses.map((at, i)=>
                        <Option key={i} value={at.id}>{at.name}</Option>
                      )}
                      <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                      <Option value=''>None</Option>
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item label="Appointment Type">
                <Input.Group 
                  style={{ width: '100%' }} compact 
                >
                  <Form.Item 
                    name="appointmentTypeID"
                    noStyle
                  >
                    <Select style={{width: editAppointmentType ? 'calc(100% - 57px)' : '100%'}} onChange={this.handleNewAppointmentType}>
                      {this.appointmentTypes.map((at, i)=>
                        <Option key={i} value={at.id}>{at.name}</Option>
                      )}
                      <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                      <Option value=''>None</Option>
                      <Option key='new'>New Appointment Type</Option>
                    </Select>
                  </Form.Item>
                  {editAppointmentType && (
                    <Button onClick={this.handleEditAppointmentType}>Edit</Button>
                  )}
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Reason"
                name="reason"
              >
                <TextArea style={{height: "110px"}} placeholder="please enter reason for visit" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item 
                label="Date"
                name="date"
                rules={[{ required: true, message: 'Please select a start date' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="Start Time"
                name="startTime"
                rules={[{ required: true, message: 'Please choose the start time' }]}
              >
                <TimePicker use12Hours format="h:mm A" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="End Time"
                name="endTime"
                rules={[{ required: true, message: 'Please choose the end time' }]}
              >
                <TimePicker use12Hours format="h:mm A" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Provider"
              >
                <InputGroup style={{ width: '100%' }} compact >
                  <Form.Item 
                    name="administratorID"
                    noStyle
                  >
                    <Select
                      style={{width: editProvider ? 'calc(100% - 57px)' : '100%'}}
                      onChange={this.handleProvider}
                      onSearch={this.handleProviderSearch}
                      showSearch
                      filterOption={false}
                      showArrow={false}
                    >
                      {this.administrators.map((administrator, i)=>
                        <Option key={`administrator-${i}`} value={administrator.id}>
                          {administrator.firstName} {administrator.lastName}
                        </Option>
                      )}
                      <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                      <Option value=''>None</Option>
                      <Option key='new'>New Provider</Option>
                    </Select>
                  </Form.Item>
                  {editProvider && (
                    <Button onClick={this.handleEditAdministrator}>Edit</Button>
                  )}
                </InputGroup>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Patient"
              >
                <InputGroup style={{ width: '100%' }} compact >
                  <Form.Item
                    name="patientID"
                    noStyle
                  >
                    <Select
                      style={{width: editPatient ? 'calc(100% - 57px)' : '100%'}}
                      onChange={this.handlePatientUser}
                      onSearch={this.handlePatientSearch}
                      showSearch
                      filterOption={false}
                      showArrow={false}
                    >
                      {this.patients.map((patient, i)=>{
                        return (
                          <Option key={`patient-user-${i}`} value={patient.id}>
                            {patient.accountFirstName} {patient.accountLastName} / {patient.firstName} {patient.lastName}
                          </Option>
                        )
                      })}
                      <Option key={`patientSearchPrompt`} value={`patientSearchPrompt`}>
                        Search to see more patients...
                      </Option>
                      <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                      <Option value=''>None</Option>
                      <Option key='new'>New Patient</Option>
                    </Select>
                  </Form.Item>
                  {editPatient && (
                    <Button onClick={this.handleEditPatient}>Edit</Button>
                  )}
                </InputGroup>
              </Form.Item>
              {this.appointment.createdAt && (
                <div>
                  Reserved At: {moment(this.appointment.createdAt).format('h:mma M/DD/YY')}
                </div>
              )}
              {this.appointment.confirmedAt && (
                <div>
                  Confirmed At: {moment(this.appointment.confirmedAt).format('h:mma M/DD/YY')}
                </div>
              )}
              {this.appointment.canceledAt && (
                <div>
                  Canceled At: {moment(this.appointment.canceledAt).format('h:mma M/DD/YY')}
                </div>
              )}
              {this.appointment.id && accountActions.isSuperAdministrator()  && (
                <div>
                  id: {this.appointment.id}
                </div>
              )}
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyAppointment} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Appointment</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.appointment.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  (CreateOrEditAppointment)
)