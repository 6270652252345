import Collection from './collection'

class Companies extends Collection{
    create(options){
        return this.axios.post(`/groups`, {
            ...options
        });
    }
    update(options){
        return this.axios.put(`/groups/${options.id}`, {
            ...options
        });
    }
    addOrganization(options){
        return this.axios.post(`/groups/${options.groupID}/organizations`, {
            ...options
        });
    }
    removeOrganization(options){
        return this.axios.delete(`/groups/${options.id}/organizations`, {
            ...options
        });
    }
    destroy(id){
        return this.axios.delete(`/groups/${id}`);
    }
    fetch(options = {}){
        let params = ''
        
        return this.axios.get(`/groups${params}`);
    }
}

export default Companies;