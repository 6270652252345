import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, List, Row, Col, Input } from 'antd'
import {AdminLayout, Scroller, SectionContent, Spinner} from 'components/elements'
import { waitListActions, administratorActions, appointmentTypeActions } from 'actions'
import moment from 'moment'
import { CreateOrEditWaitList } from 'components/partials'
const _ = require('lodash')

class WaitListMembers extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            resetVisible: false,
            appointmentAdministrators: {},
            saveAppointmentSpinner: {},
            removeAppointmentSpinner: {},
            waitingRoomSpinner: false,
            search: '',
            createOrEditWaitListVisible: false
        }

        this.handleCancel = this.handleCancel.bind(this)
        this.handleClearPrompt = this.handleClearPrompt.bind(this)
        this.handleChangeAdministrator = this.handleChangeAdministrator.bind(this)
        this.handleAddWaitList = this.handleAddWaitList.bind(this)
        this.handleWaitListClose = this.handleWaitListClose.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidMount() {
        waitListActions.fetch()
        administratorActions.fetch()
        appointmentTypeActions.fetch()
    }

    handleCancel(){
        this.setState({resetVisible: false})
    }

    handleClearPrompt(){
        this.setState({
            resetVisible: true
        })
    }

    handleAddWaitList(){
        this.setState({
            createOrEditWaitListVisible: true
        })
    }

    handleWaitListClose(){
        this.setState({
            createOrEditWaitListVisible: false,
            currentWaitList: ''
        })
    }

    handleChangeAdministrator(value){
        if(value){
            let [appointmentID, administratorID] = value.split('--')
            this.setState({
                appointmentAdministrators:{
                    ...this.state.appointmentAdministrators,
                    [`${appointmentID}`]: administratorID
                }
            })
        }
    }
    
    handleSearch(e){
        this.setState({
            search: e.target.value
        })
    }

    handleEditWaitList(waitList){
        this.setState({
            createOrEditWaitListVisible: true,
            currentWaitList: waitList
        })
    }

    handleRemoveWaitList(waitList){
        waitListActions.destroy(waitList.id)
    }

    get waitLists(){
        let _waitLists = _.sortBy(this.props.waitLists || [], [(w)=>((w.account || {}).lastName), (w=>((w.account || {}).firstName))])
        if(this.state.search){
            _waitLists = _waitLists.filter((w)=>(
                w.account &&
                (
                    `${w.account.firstName || ''} ${w.account.lastName || ''} ${w.account.email || ''}`
                ).toLowerCase().includes(this.state.search.toLowerCase())
            ))
        }
        return _waitLists
    }

    get appointmentTypes(){
        return this.props.appointmentTypes || {}
    }

    get administrators(){
        return this.props.administrators || {}
    }

    render() {
        return (
            <AdminLayout>
                <CreateOrEditWaitList 
                    visible={this.state.createOrEditWaitListVisible}
                    onClose={this.handleWaitListClose}
                    waitList={this.state.currentWaitList}
                />
                <SectionContent noPadding={true} noMargin={true}>
                    <Scroller>
                        <Row style={{ margin: '20px'}}>
                            <Col md={12}>
                                <Input.Search
                                    placeholder="Search Accounts" 
                                    onChange={this.handleSearch} 
                                    enterButton 
                                />
                            </Col>
                            <Col md={12}>
                                <div style={{float: 'right'}}>
                                    <Button 
                                        type='primary'
                                        onClick={this.handleAddWaitList}
                                    >
                                        Add To Wait List
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Row style={{margin: '0px 40px 10px'}}>
                                <Col md={5} lg={5}>
                                    Name
                                </Col>
                                <Col md={7} lg={7}>
                                    Appointment Type
                                </Col>
                                <Col md={8} lg={8}>
                                    Administrator
                                </Col>
                                <Col md={4} lg={4} align='center'>
                                </Col>
                            </Row>
                            <List 
                                style={{margin: '0px 20px'}}
                                bordered
                                dataSource={this.waitLists}
                                renderItem={waitList => (
                                    <List.Item>
                                        <Row style={{width: '100%'}}>
                                            <Col md={5} lg={5}>
                                                {waitList.account?
                                                    `${waitList.account.firstName} ${waitList.account.lastName}`
                                                :
                                                    ''
                                                }
                                            </Col>
                                            <Col md={7} lg={7}>
                                                {(this.appointmentTypes[waitList.appointmentTypeID] || {}).name}
                                            </Col>
                                            <Col md={8} lg={8}>
                                                {(this.administrators[waitList.administratorID] || {}).firstName || ''} {(this.administrators[waitList.administratorID] || {}).lastName || ''}
                                            </Col>
                                            <Col md={2} lg={2} align='center'>
                                                <Button 
                                                    type='primary'
                                                    onClick={this.handleEditWaitList.bind(this,waitList)}
                                                >
                                                    Edit
                                                </Button>
                                            </Col>
                                            <Col md={2} lg={2} align='center'>
                                                <Button 
                                                    type='danger'
                                                    onClick={this.handleRemoveWaitList.bind(this,waitList)}
                                                >
                                                    Remove
                                                </Button>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Scroller>
                </SectionContent>
            </AdminLayout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        waitLists: state.waitLists || [],
        appointmentTypes: state.appointmentTypes || {},
        administrators: state.administrators || {}
    }
}

export default withRouter(connect(mapStateToProps)(WaitListMembers))
