import {
    FETCH_SERVICE_TIMES,
    APPEND_SERVICE_TIME,
    DESTROY_SERVICE_TIME,
    SET_ORGANIZATION_CURRENT
  } from '../constants/actionTypes'
  
  export default (state = {}, action) => {
    switch (action.type) {
      case FETCH_SERVICE_TIMES:
        return {
          ...state,
          ...action.payload
        }
      case APPEND_SERVICE_TIME:
        let baseID = action.payload.id
        state = Object.values(state).filter((s)=>s.id != baseID).reduce((acc, item)=>{
          acc[item.repeatingID || item.id] = item
          return acc;
        }, {})
        return {
          ...state,
          [`${action.payload.id}`]: action.payload
        }
      case DESTROY_SERVICE_TIME:
        state = Object.values(state).filter((s)=>s.id != action.payload).reduce((acc, item)=>{
          acc[item.repeatingID || item.id] = item
          return acc;
        }, {})
        
        return state
      case SET_ORGANIZATION_CURRENT:
          return {}
      default:
        return state
    }
  }
  