import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker } from 'antd';
import moment from 'moment'
import {genderActions, breedActions} from 'actions'
import {connect} from 'react-redux'
import CreateOrEditSpecies from '../CreateOrEditSpecies'
const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;

class CreateOrEditBreed extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createOrEditSpeciesVisible: false,
      patientsUsers: []
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDestroyBreed = this.handleDestroyBreed.bind(this)
    this.handleNewSpecies = this.handleNewSpecies.bind(this)
    this.handleSpeciesClose = this.handleSpeciesClose.bind(this)
    this.handleEditSpecies = this.handleEditSpecies.bind(this)
    this.setClassification = this.setClassification.bind(this)
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  async handleSubmit (values) {
    const _breed = {
      classificationID: values.classificationID,
      name: values.name,
      description: values.description
    }

    let breed
    if(this.breed.id){
      breed = await breedActions.update({
          id: this.breed.id,
          ..._breed
      })
    }else{
      breed = await breedActions.create(_breed)
    }
    this.props.onSetCurrentBreed(breed.id)
    this.props.onClose()
  }

  componentDidUpdate(previousProps){
    if(previousProps.visible != this.props.visible){
      this.form && this.form.resetFields();
      this.setState(this.state)
    }
  }

  setClassification(classificationID){
    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({classificationID});
    }, 1)
  }

  async handleDestroyBreed(e){
    e.preventDefault()

    await breedActions.destroy(this.breed.id)
    this.props.onClose()
  }

  get classifications(){
    return Object.entries(this.props.classifications || {}).map(([, b])=> b)
  }

  get form() {
    return this.formRef.current
  }

  get breed(){
    const _breed = this.props.breed || {}
    
    return {
      classificationID: _breed.classificationID,
      id: _breed.id,
      name: _breed.name,
      description: _breed.description,
    }
  }

  get patient(){
    return this.props.patient || {}
  }

  handleNewSpecies(value){
    if(value === 'new'){
      this.setState({
        createOrEditSpeciesVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({classification: ''});
      }, 1)
    }
  }
 
  handleEditSpecies(value){
    this.setState({
      createOrEditSpeciesVisible: true
    })
  }

  handleSpeciesClose(){
    this.setState({
      createOrEditSpeciesVisible: false
    })
  }


  get classification(){
    const id = this.form && this.form.getFieldValue('classificationID')
    return this.props.classifications[id]
  }

  render() {

        // ref={this.formRef}
    // this.formRef = React.createRef()


    const initialValues = {
      classificationID: this.breed.classificationID,
      name: this.breed.name,
      description: this.breed.description
    }


    let edit = false;
    let editSpecies = false;
    if(this.breed.id){
      edit = true
    }
    if(this.form && this.form.getFieldValue('classificationID') && this.form.getFieldValue('classificationID') !== "new"){
      editSpecies = true
    }
    return (
      <Drawer
        title={this.breed.id ? 'Edit Breed' : 'Create Breed'}
        width={520}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <CreateOrEditSpecies 
          visible={this.state.createOrEditSpeciesVisible}
          setClassification={this.setClassification}
          onClose={this.handleSpeciesClose}
          classification={this.classification}
        />
        <Form ref={this.formRef} initialValues={initialValues} layout="vertical" onFinish={this.handleSubmit}>
          <Row>
            <Col span={24}>
              <Form.Item 
                label="Species"
              >
                <Input.Group style={{ width: '100%' }} compact >
                  <Form.Item
                    noStyle
                    name="classificationID"
                    rules={[{ message: 'Please select a classification' }]}
                  >
                    <Select filterOption={this.handleFilterSearch} showSearch style={{width: editSpecies ? 'calc(100% - 57px)' : '100%'}} onChange={this.handleNewSpecies}>
                      {this.classifications.map((classification, i)=>{
                        return (
                          <Option key={`classification-${i}`} value={classification.id}>{classification.name}</Option>
                          )
                        })}
                      <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                      <Option value=''>None</Option>
                      <Option key='new'>New Species</Option>
                    </Select>
                  </Form.Item>
                  {editSpecies && (
                    <Button onClick={this.handleEditSpecies}>Edit</Button>
                  )}
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Name"
                name="name"
                rules={[{ message: 'Please select a name' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Description"
                name="description"
                rules={[{ message: 'Please select a description' }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyBreed} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Breed</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.breed.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(
  CreateOrEditBreed
)