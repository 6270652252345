import {
  FETCH_GENDERS,
  CREATE_GENDER,
  UPDATE_GENDER,
  UPDATE_GENDERS,
  DESTROY_GENDER,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_GENDERS:
      return {
        ...state,
        ...action.payload
      }
    case CREATE_GENDER:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case DESTROY_GENDER:
        state = {
          ...state
        }
        delete state[action.payload]
        return state
    case UPDATE_GENDER:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case UPDATE_GENDERS:
      let payload = action.payload.reduce((acc, item)=>{
          acc[item.repeatingID || item.id] = item
          return acc;
      }, {})

      return {
          ...state,
          ...payload
      }
    case SET_ORGANIZATION_CURRENT:
        return {}
    default:
      return state
  }
}
