import React from 'react'
import { Card, message } from 'antd'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { changeFormFields, login } from 'actions/sessions'
import Form from './Form'
import AppFooter from 'components/elements/AppFooter'
import './index.less'

class Login extends React.Component {
  componentDidUpdate(previousProps) {
    if (previousProps.isLoginLoading && this.props.isLoginSuccess) {
      message.success('You have successfully logged in.')
    } else if (previousProps.isLoginLoading && this.props.loginError) {
      message.error(this.props.loginError)
    }
  }

  render() {
    const { isLoggedIn } = this.props
    if (isLoggedIn) {
      return <Redirect to={{ pathname: '/admin' }} />
    }
    return (
      <div
        className='login-page'
        style={{
          backgroundColor: 'rgb(46, 44, 62)'
        }}
      >
        <Card className='login-form'>
          <div className='header'>
            <h1 className='logo'><span className='n'>N</span>Scheduling Assistant</h1>
            {/* <img src={logoImage} height='48px' alt='React Starter' /> */}
            <h3>Admin Portal</h3>
            <p>Login or Sign Up to get started </p>
          </div>
          <Form
            onFinish={this.props.handleFormOnSubmit}
            onFieldsChange={this.props.handleFormOnFieldsChange}
            formFieldValues={this.props.formFieldValues}
            isLoading={this.props.isLoginLoading}
          />
        </Card>
        <AppFooter />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    formFieldValues,
    isLoginLoading,
    isLoginSuccess,
    loginError,
  } = state.login
  const isLoggedIn = !!state.session.accessToken
  return {
    formFieldValues,
    isLoginLoading,
    isLoggedIn,
    isLoginSuccess,
    loginError,
  }
}

const mapDispatchToProps = dispatch => ({
  handleFormOnSubmit: login,
  handleFormOnFieldsChange: formFieldsChange => dispatch(changeFormFields(formFieldsChange)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
