import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker, AutoComplete } from 'antd';
import moment from 'moment'
import {RestrictionForm} from 'components/partials'
import {restrictionActions, classificationActions, genderActions} from 'actions'
import {connect} from 'react-redux'
import { apiClient } from 'lib';

const TextArea = Input.TextArea;

const {Option, OptGroup} = Select;

class CreateOrEditRestriction extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDestroyRestriction = this.handleDestroyRestriction.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount(){
    classificationActions.fetch()
    genderActions.fetch()
  }

  get restriction(){
    const restriction = this.props.restriction || {}
    return {
      ...restriction,
      repeatingType: this.repeatingType
    }
  }

  setDate(time){
    let date = this.form.getFieldValue('date')
    let day = date.date()
    let month = date.month()
    let year = date.year()

    return time.clone().date(day).month(month).year(year)
  }
  
  async handleSubmit (values) {
    let _restriction = {
      sequence: null,
      dayOfMonth: null,
      dayOfWeek: null,
      skipType: null,
      skipValue: null,
      name: values.name,
      startsAt: this.setDate(values.startsAt),
      endsAt: this.setDate(values.endsAt),
      repeating: true,
      restrictionableID: values.appointmentTypeID,
      restrictionableType: 'AppointmentType',
      maxPatients: values.max
    }
    
    _restriction.rules = Object.keys(values).reduce((acc, keyName)=>{
      if(keyName.includes('key')){
        let id = ''
        if(!keyName.includes('new')){
          [,id] = keyName.split('--')
        }
        let key = values[keyName]
        const restrictionRuleableType = ({
          genderID: 'Gender',
          classificationID: 'Classification'
        })[key]
        
        let value = values[keyName.replace('key', 'value')]
        let restrictionRuleableID;
        if(restrictionRuleableType){
          restrictionRuleableID = value
          value = null
        }
        
        acc =  [
          ...acc,
          {
            id,
            restrictionRuleableType,
            restrictionRuleableID,
            key,
            operator: values[keyName.replace('key', 'operator')],
            value
          }
        ]
      }

      return acc;
    }, [])
    switch(values.repeatingType){
      case 'daily':
        _restriction.dayOfWeek = 'Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday'
        break;
      case 'weekday':
        _restriction.dayOfWeek = 'Monday,Tuesday,Wednesday,Thursday,Friday'
        break;
      case 'weekly':
        _restriction.dayOfWeek = this.dayOfWeek
        break;
      case 'nthOfMonth':
        _restriction.dayOfMonth = moment(this.restriction.start).date()
        break;
      case 'nthDayOfMonth':
        _restriction.dayOfWeek = this.dayOfWeek
        _restriction.sequence = Math.ceil(moment(this.restriction.start).date() / 7)
        break;
      default:
        _restriction.repeating = false
    }

    switch(values.skipType){
      case 'week':
        _restriction.skipValue = 1
        _restriction.skipType = 'week'
        break;
      case 'month':
        _restriction.skipValue = 1
        _restriction.skipType = 'month'
        break;
      case 'xWeeks':
        _restriction.skipValue = values.skipValue
        _restriction.skipType = 'week'
        break;
      case 'xMonths':
        _restriction.skipValue = values.skipValue
        _restriction.skipType = 'month'
        break;
    }

    if(values.repeatsUntil && values.endDate){
      _restriction.repeatsUntil = values.endDate.format()
    } else {
      _restriction.repeatsUntil = null
    }

    try{
      if(this.restriction.id){
        await restrictionActions.update({
          id: this.restriction.id,
          ..._restriction
        })
      } else {
        await restrictionActions.create(_restriction)
      }
      this.props.onClose()
    } catch(error){
      console.log(error)
    }
  }

  componentDidUpdate(previousProps){
    if(!this.props.visible && previousProps.visible){
      const restriction = Object.keys(this.initialValues).reduce((acc, key)=>({
        ...acc,
        [`${key}`]: undefined
      }), {})
      
      this.form.setFieldsValue(restriction)
      this.setState(this.state)
    }

    if(JSON.stringify(previousProps.restriction) != JSON.stringify(this.props.restriction)){
      this.setState(this.state, ()=>{
        this.form && this.form.resetFields();
      })
    }
  }

  async handleDestroyRestriction(e){
    e.preventDefault()
    
    await restrictionActions.destroy({
      id: this.restriction.id
    })
    this.props.onClose()
  }

  get rules() {
    return this.restriction.rules || []
  }

  get form() {
    return this.formRef.current
  }

  get repeatingType(){
    const restriction = this.props.restriction || {}
    if(!restriction.repeating){
      return ''
    }
    if(restriction.sequence){
      return 'nthDayOfMonth'
    }
    if(restriction.dayOfWeek){
      let days = restriction.dayOfWeek.split(',')
      if(days.length == 7){
        return 'daily'
      }
      if(days.length == 5){
        return 'weekday'
      }
      if(days.length == 1){
        return 'weekly'
      }
    }
    if(restriction.dayOfMonth){
      return 'nthOfMonth'
    }
    
    return '' 
  }

  handleChange(){
    this.setState(this.state)
  }

  get initialValues(){
    return RestrictionForm.initialValues(this.form, this.props.restriction)
  }

  render() {
    let edit = false;
    
    if(this.restriction.id){
      edit = true
    }
    
    return (
      <Drawer
        title={this.restriction.id ? 'Edit Restriction' : 'Create Restriction'}
        width={720}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <Form 
          ref={this.formRef}
          initialValues={this.initialValues}
          onFinish={this.handleSubmit}
          onError={((a)=> console.log(a))}
          onValuesChange={this.handleChange}
        >
          {
            <RestrictionForm formRef={this.formRef} restriction={this.restriction} visible={this.props.visible} />
          }
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
          >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyRestriction} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Restriction</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.restriction.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default CreateOrEditRestriction