import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducer from './reducers'
import rewire from './rewire'

const win = window

const configureStore = () => {
  let initialState = {
    session: {},
    organizations: [],
    accounts: {}
  }
  try{
    if(localStorage.getItem('store')){
      const {
        session,
        organizations,
        currentOrganizationID
      } = JSON.parse(
        localStorage.getItem('store')
      )

      let _organizations = {}
      try{
        if(localStorage.getItem('organizations')){
            _organizations = JSON.parse(localStorage.getItem('organizations'))
        }
      }catch(error){
        console.log(error)
      }

      initialState = {
        ...initialState,
        session,
        organizations: {
            ...organizations,
            ..._organizations
        },
        currentOrganizationID
      }
    }
  }catch(error){
    console.log(error)
  }
  
  const logger = store => next => action => {
    // console.log("HERE", console.trace());
    let result = next(action);
    return result
  }

  const middlewares = [logger, thunkMiddleware]
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(require('redux-immutable-state-invariant').default()) // eslint-disable-line
    middlewares.push(require('redux-logger').default) // eslint-disable-line
  }

  const storeEnhancers = compose(
    applyMiddleware(...middlewares),
    (win && win.devToolsExtension) ? win.devToolsExtension() : f => f,
  )

  const store = createStore(reducer, initialState, storeEnhancers)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default // eslint-disable-line
      store.replaceReducer(nextReducer)
    })
  }

  store.subscribe(()=>{
    try{
      const state = store.getState()
      const organizations = Object.values(Object(state.organizations))
      const _organizations = organizations.filter((organization)=>(
        organization.active && !organization.archived
      )).reduce((acc, organization)=>({
        ...acc,
        [organization.id]: {
            id: organization.id,
            name: organization.name
        }
      }), {})

      const currentOrganization = Object(Object(state.organizations)[state.currentOrganizationID])
      const _state = {
        ...state,
        organizations: {
          [currentOrganization.id]: currentOrganization
        },
        accounts: {},
        patients: {},
        session: {
          ...Object(state.session),
          organizations: {}
        }
      }

      
      localStorage.setItem('store', JSON.stringify(_state))
      localStorage.setItem('organizations', JSON.stringify(_organizations))
    }catch(error){
      console.log('unable to save state', error)
    }
  })
  return {
    ...store,
    getState: ()=>rewire(store.getState())
  }
}
window.otterStore = configureStore()
export default window.otterStore
