import {
  FETCH_BREEDS,
  CREATE_BREED,
  UPDATE_BREED,
  UPDATE_BREEDS,
  DESTROY_BREED,
  SET_ORGANIZATION_CURRENT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_BREEDS:
      return {
        ...state,
        ...action.payload,
      }
    case CREATE_BREED:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case DESTROY_BREED:
      state = {
        ...state
      }
      delete state[action.payload]
      return state
    case UPDATE_BREED:
      return {
        ...state,
        [`${action.payload.id}`]: action.payload
      }
    case UPDATE_BREEDS:
      let payload = action.payload.reduce((acc, item)=>{
        acc[item.repeatingID || item.id] = item
        return acc;
      }, {})

      return {
        ...state,
        ...payload
      }
    case SET_ORGANIZATION_CURRENT:
        return {}
    default:
      return state
  }
}
