import {
  ADMIN_OPENSUBMENU_CHANGE,
} from '../constants/actionTypes'

export default (state = {openKeys:['general']}, action) => {
  switch (action.type) {
    case ADMIN_OPENSUBMENU_CHANGE:
      const openKeys = state.openKeys || []
      return {
        ...state,
        openKeys: action.openKeys.filter((key)=>
          openKeys[0] != key
        ),
      }
    default:
      return state
  }
}
