import Collection from '../collection'
import store from 'store'

class Workers extends Collection{
    fetch(options = {}){
        const id = store.getState().currentOrganization.id
        return this.axios.get( `/operations/workers/${id}`, {...options}, {
            ...options
        });
    }
    update(options = {}){
        const id = store.getState().currentOrganization.id
        return this.axios.put(`/operations/workers/${id}`, {...options})
    }
}

export default Workers;