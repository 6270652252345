import Collection from '../collection'

class Genders extends Collection{
    create(options){
        return this.axios.post(`/organizations/${this.props.currentOrganization.id}/genders`, {
            ...options
        });
    }
    update(options){
        return this.axios.put(`/organizations/${this.props.currentOrganization.id}/genders/${options.id}`, {
            ...options
        });
    }
    activate(options){
        return this.axios.put(`/organizations/${this.props.currentOrganization.id}/genders/activate`, {
            ...options
        });
    }
    destroy(id){
        return this.axios.delete(`/organizations/${this.props.currentOrganization.id}/genders/${id}`);
    }
    fetch(options = {}){
        return this.axios.get(`/organizations/${this.props.currentOrganization.id}/genders`, {
            ...options
        });
    }
}

export default Genders;