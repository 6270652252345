import {
    FETCH_WAITING_ROOM,
    DESTROY_WAITING_ROOM,
    SET_ORGANIZATION_CURRENT
  } from '../constants/actionTypes'
  
  export default (state = '', action) => {
    switch (action.type) {
      case FETCH_WAITING_ROOM:
        return [
          ...action.payload
        ]
      case DESTROY_WAITING_ROOM:
      case SET_ORGANIZATION_CURRENT:
          return ''
      default:
        return state
    }
  }
  