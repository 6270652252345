import React from 'react';
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select } from 'antd';
import {partitionActions} from 'actions'
import {connect} from 'react-redux'

const {Option, OptGroup} = Select;

class CreateOrEditPartition extends React.Component {
    constructor(){
        super();
        this.formRef = React.createRef()
        this.state = { 
            visible: false
        };
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleDestroyPartition = this.handleDestroyPartition.bind(this)
    }

    showDrawer () {
        this.setState({
            visible: true,
        });
    };

    get account(){
        return this.props.account || {}
    }

    async handleSubmit (values) {
        const _partition = {
            name: values.name
        }
        let partition
        if(this.partition.id){
            partition = await partitionActions.update({
                id: this.partition.id,
                ..._partition
            })
        }else{
            partition = await partitionActions.create(_partition)
        }
        this.props.onSetCurrentPartition(partition.id)
        this.props.onClose()
    }

    get form() {
        return this.formRef.current
    }

    get partition(){
        const _partition = this.props.partition || {}
        
        return {
            id: _partition.id,
            name: _partition.name
        }
    }

    componentDidUpdate(nextProps){
        if(this.props.visible && !nextProps.visible){
            this.form && this.form.resetFields();
        }
    }

    async handleDestroyPartition(e){
        e.preventDefault()

        await partitionActions.destroy(this.partition.id)
        this.props.onClose()
    }

    render() {

        const { 
            name
        } = this.partition

        const initialValues = {
            name
        }

        let edit = false;
        
        if(this.partition.id){
            edit = true
        }
        return (
            <Drawer
                title={this.partition.id ? 'Edit Partition' : 'Create Partition'}
                width={520}
                placement="right"
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
                <Form initialValues={initialValues} ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item 
                                label="Name"
                                name="name"
                                rules={[{ message: 'Please enter a name.' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        {edit && 
                            <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyPartition} cancelText="No">
                                <Button type='danger' className='pull-left'>Delete Partition</Button>
                            </Popconfirm>
                        }
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={this.props.onClose}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">{this.partition.id ? 'Update' : 'Create' }</Button>
                    </div>
                </Form>
            </Drawer>
        );
    }
}
export default connect((state)=> state)(
    (CreateOrEditPartition)
)