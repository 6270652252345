import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Input, message, Tabs, Form, Card, Transfer, Select, Button, Switch} from 'antd'
import { Fa } from 'components/elements'
import {   
  appointmentTypeActions, 
  administratorActions, 
  availabilityActions, 
  customFieldActions,
} from 'actions'
import Sexes from './Sexes'
import AdminLayout from 'components/elements/AdminLayout'
import Restrictions from './Restrictions'
import SectionContent from 'components/elements/SectionContent'
import _ from 'lodash'
import { 
  WidgetCustomFields, 
  WidgetSuperAdmin, 
  WidgetAdministrators, 
  WidgetCodeSnippet, 
  WidgetAppointmentTypes, 
  WidgetSpeciesAndBreed, 
  WidgetGenderChange, 
  WidgetAppointments, 
  WidgetAdvancedFields
} from 'components/partials'
import Scroller from 'components/elements/Scroller'

const TabPane = Tabs.TabPane

const { Search } = Input
const pageTitle = 'Users'
const storeKey = 'accounts'
const createPath = '/admin/patients/create'
const editPath = '/admin/patients/edit'

class Users extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      resetSpinner: '',
      resetSuccess: ''
    }

    this.handleTableOnChange = this.handleTableOnChange.bind(this)
    this.handleOnSelect = this.handleOnSelect.bind(this)
    
  }

  componentDidMount() {
    administratorActions.fetch()
    appointmentTypeActions.fetch();
    customFieldActions.fetch();

    this.setState({
      widgetAvailabilityToggled: !!this.props.currentOrganization.widgetAvailability,
      condenseAdminsToggled: !!(this.props.currentOrganization.widgetSettings || {}).condenseAdministrators,
    })
  }

  componentDidUpdate(previousProps) {
    if (previousProps.isFetchItemsLoading && this.props.fetchItemsError) {
      message.error(this.props.fetchItemsError)
    }
  }

  async handleTableOnChange(pagination, filters, sorter) {
    const { columnKey, field, order } = sorter
    await this.props.changeTable({
      pagination,
      filters,
      sorter: {
        columnKey,
        field,
        order,
      },
    })
    this.fetch()
  }

  handleOnSelect(account) {
    if(account){
      this.props.history.push(`/admin/accounts/${account.id}`)
    }
  }

  async handleSearchOnClick() {
    await this.props.searchTable({ isSearching: true })
    await this.props.changeTable({})
    this.fetch()
  }

  async handleSearchOnCancel(e) {
    e.preventDefault()
    await this.props.editSearch({ search: null })
    await this.props.searchTable({ isSearching: false })
    await this.props.changeTable({})
    this.fetch()
  }

  render() {
    return (
      <div>
        <AdminLayout>
          <SectionContent noPadding={true} noMargin={true}>
            <Tabs defaultActiveKey="basic" onChange={this.handleTabChange}>
              {this.props.session.account.isSuperAccount &&
                <TabPane tab={<span><Fa icon='fire-extinguisher' /> Super Admin</span>} key="super">
                  <Card bordered={false}>
                    <WidgetSuperAdmin />
                  </Card>
                </TabPane>
              }
              <TabPane tab={<span><Fa icon='cog' /> Basic</span>} key="basic">
                <Card bordered={false}>
                  <WidgetAppointments />
                </Card>
              </TabPane>
              <TabPane tab={<span><Fa icon='venus-mars' /> Sexes</span>} key="sexes">
                <Card bordered={false}>
                    <WidgetGenderChange />
                </Card>
              </TabPane>
              <TabPane tab={<span><Fa icon='paw'  /> Species and Breeds</span>} key="speciesBreeds">
                <Card bordered={false}>
                    <WidgetSpeciesAndBreed />
                </Card>
              </TabPane>
              <TabPane tab={<span><Fa icon='paw'  /> Custom Fields</span>} key="customFields">
                <Scroller>
                  <Card bordered={false} style={{paddingBottom: '200px'}}>
                      <WidgetCustomFields />
                  </Card>
                </Scroller>
              </TabPane>
              <TabPane tab={<span><Fa icon='clipboard-list'  /> Appointment Types</span>} key="appointmentTypes">
                <Card bordered={false}>
                  <WidgetAppointmentTypes />
                </Card>
              </TabPane>
              <TabPane tab={<span><Fa icon='user-md'  /> Administrators</span>} key="administrator">
                <Card bordered={false}>
                  <WidgetAdministrators />
                </Card>
              </TabPane>
              <TabPane tab={<span><Fa icon='paw'  /> Advanced</span>} key="advancedFields">
                <Scroller>
                  <Card bordered={false} style={{paddingBottom: '200px'}}>
                      <WidgetAdvancedFields />
                  </Card>
                </Scroller>
              </TabPane>
              <TabPane tab={<span><Fa icon='code' /> Code Snippets and Preview</span>} key="snippets">
                <Card bordered={false}>
                  <WidgetCodeSnippet />
                </Card>
              </TabPane>
            </Tabs>
          </SectionContent>
        </AdminLayout>
      </div>
    )
  }
}

export default withRouter(connect((state)=>state)(Users))
