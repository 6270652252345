import React from 'react';
import { Drawer, Form, Button, Popconfirm } from 'antd';
import {connect} from 'react-redux'
import {CompanyForm} from 'components/partials'
import {companyActions, accountActions} from 'actions'
import _ from 'lodash'

class CreateOrEditCompany extends React.Component {
  constructor(){
    super();
    this.formRef = React.createRef()
    this.state = { 
      visible: false,
      createGendersVisible: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onClose = this.onClose.bind(this)
    this.triggerSubmit = this.triggerSubmit.bind(this)
  }

  get form() {
    return this.formRef.current
  }

  get company(){
    return this.props.company || {}
  }

  triggerSubmit() {
    return this.form.submit()
  }

  async handleSubmit (values) {
    const _company = {
        name: values.name,
        description: values.description
    }
    let company
    if(this.company.id){
      company = await companyActions.update({
          id: this.company.id,
          ..._company
      })
    } else {
      company = await companyActions.create(_company)
      this.props.onSetCurrentCompany && this.props.onSetCurrentCompany(company.id)
    }
    this.form && this.form.resetFields();
    this.props.onClose && this.props.onClose(company)
  }

  onClose(){
    this.form && this.form.resetFields();
    
    this.props.onClose()
  }

  componentDidUpdate(previousProps){
      if(previousProps.visible != this.props.visible){
          this.form && this.form.resetFields();
          this.setState(this.state) // rerender
      }
  }

  render() {    

    const initialValues = {
        id: this.company.id,
        name: this.company.name,
        description: this.company.description,
    }

    let edit = false;
    
    if(this.company.id){
      edit = true
    }

    return (
      <Drawer
        title={this.company.id ? 'Edit Company' : 'Create Company'}
        width={720}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <Form ref={this.formRef} initialValues={initialValues} layout="vertical" onFinish={this.handleSubmit}>
            <CompanyForm formRef={this.formRef} company={this.company} account={this.account}/>
        </Form>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}
          >
          {edit && 
              <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyCompany} cancelText="No">
                  <Button type='danger' className='pull-left'>Delete Company</Button>
              </Popconfirm>
          }
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={this.onClose}
            >
            Cancel
          </Button>
          <Button onClick={this.triggerSubmit} type="primary" htmlType="submit">{this.company.id ? 'Update' : 'Create' }</Button>
        </div>
      </Drawer>
    );
  }
}
export default connect((state)=> state)(CreateOrEditCompany)