import React, {Component, Fragment} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'

import { 
    Input,
    Button,
    Form,
    Tabs,
    DatePicker,
    Card,
    List,
    Row,
    Col
} from 'antd';
import JSONTree from 'react-json-tree'
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
import moment from 'moment'
import Scroller from 'components/elements/Scroller'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import {
    syncActions,
    syncStatusActions,
    administratorActions
} from 'actions'
import { apiClient } from 'lib';
import Display from './Display'
import './style.less'
import Item from 'antd/lib/list/Item';

const TextArea = Input.TextArea;

class Notes extends Component {
    constructor(props){
        super(props)
        this.state = {
            notes: []
        };

        this.fetchData = this.fetchData.bind(this)
        this.createNote = this.createNote.bind(this)
        this.formRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this)
        this.destroyNote = this.destroyNote.bind(this)
        this.markAsReadNote = this.markAsReadNote.bind(this)
        this.resolvedNote = this.resolvedNote.bind(this)
    }
    
    async fetchData(){    
        const response = await apiClient.organizations.operations.notes.fetch()
        if(response){
            this.setState({
                notes: response.data
            })
        }
    }
    componentDidMount(){
        this.fetchData()
        administratorActions.fetch()
    }
    async createNote(values){
        values = JSON.parse(JSON.stringify(values))
        this.formRef.current.setFieldsValue({
            content: ''
        })
        const response = await apiClient.organizations.operations.notes.create(values)
        if(response){
            this.fetchData()

        }
    }
    
    async destroyNote(id){
        // console.log(id)
        const response = await apiClient.organizations.operations.notes.destroy({id})

        if(response){
            this.fetchData()

        }
    }
    
    async markAsReadNote(id){
        // console.log(id)
        const response = await apiClient.organizations.operations.notes.markAsRead({id})

        if(response){
            this.fetchData()

        }
    }
    
    async resolvedNote(id){
        // console.log(id)
        const response = await apiClient.organizations.operations.notes.resolved({id})

        if(response){
            this.fetchData()

        }
    }

    get notes(){
        return this.state.notes || []
    }
    
    handleSubmit(e){
        if(e.key =='Enter'){
            this.formRef.current.submit()
        }
        //this.formRef.current.
    }
    
    itemResolvedBy(item){
        return _.get(this, `props.administrators[${item.markedResolvedByID}]`, {})
    }

    render(){
        return (
            <div>
                <Card bordered={false}>
                    <Scroller>
                        <Card bordered={false}>
                        <List
                                className="demo-loadmore-list"
                                loading={false}
                                itemLayout="horizontal"
                                //loadMore={loadMore}
                                dataSource={this.notes}
                                renderItem={(item)=>{
                                    return (
                                        <List.Item
                                            actions={([
                                                <a key="list-loadmore-edit" onClick={this.destroyNote.bind(null, item.id)}>destroy</a>,
                                                <a key="list-loadmore-mark-as-read" onClick={this.markAsReadNote.bind(null, item.id)}>
                                                    {Object(item.readByAccountIDs)[_.get(this, 'props.session.account.id')] ? "Mark as UnRead" : "Mark as Read"}
                                                </a>,
                                                item.markedResolvedByID ? (
                                                    `${this.itemResolvedBy(item).firstName} ${this.itemResolvedBy(item).lastName}`
                                                ):(
                                                    <a key="list-loadmore-resolved" onClick={this.resolvedNote.bind(null, item.id)}>
                                                        resolved
                                                    </a>
                                                )
                                            ])}
                                        >
                                            {/* <Skeleton avatar title={false} active> */}
                                            <List.Item.Meta
                                                title={
                                                    <Row>
                                                        <Col sm={12}>
                                                                {_.get(item, 'administrator.account').firstName}
                                                                {_.get(item, 'administrator.account').lastName}
                                                        </Col>
                                                        <Col sm={12}>
                                                            {moment(item.createdAt).format('M/DD/YY h:mm a')}
                                                        </Col>
                                                    </Row>
                                                }

                                                description={
                                                    <div>
                                                        {item.content}
                                                        {item.issueID &&
                                                            <div>
                                                                Issue: <a href={`https://github.com/next-in-line/ocean/issues/${item.issueID}`} target="_blank">
                                                                    {item.issueID}
                                                                </a>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    )
                                }}
                            />
                            <Form ref={this.formRef} onFinish={this.createNote}>
                                <Form.Item
                                    name='content'
                                >
                                    <Input.TextArea onKeyUp={this.handleSubmit} />
                                </Form.Item>
                                <Form.Item
                                    name='issueID'
                                >
                                    <Input />
                                </Form.Item>
                                <Button htmlType='submit' />
                            </Form>
                        </Card>
                    </Scroller>
                </Card>
            </div>
        )
    }
}

export default connect(({session, administrators})=>({session, administrators}))(Notes)