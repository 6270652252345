import * as ActionTypes from '../constants/actionTypes'
import {apiClient} from '../lib'
import store from '../store'

import * as openTimeActions from './openTimes'
import * as genderActions from './genders'
import * as breedActions from './breeds'
import * as classificationActions from './classifications'
import * as appointmentTypeActions from './appointmentTypes'
import * as accountActions from './accounts'
import * as administratorActions from './administrators'
import * as restrictionActions from './restrictions'
import * as integrationConfigActions from './integrationConfigs'
import * as insightsActions from './insights'
import * as appointmentActions from './appointments'
import moment from 'moment'
import { organizationActions } from 'actions'

export const createOrganization = async (values = {}) => {
  store.dispatch({ type: ActionTypes.CREATE_ORGANIZATION })
  
  try{
    const response = await apiClient.organizations.create(values)

    let organizations = []
    try{
      organizations = JSON.parse(localStorage.getItem('organizations')) || []
    }catch(e){
      console.log("Organizations Not Set Properly")
    }

    if(response.data){
      organizations.push(response.data)
      localStorage.setItem('organizations', JSON.stringify(organizations))
    }
    store.dispatch({ type: ActionTypes.CREATE_ORGANIZATION, value: response.data })
  }catch(error){
    store.dispatch({ type: ActionTypes.CREATE_ORGANIZATION_ERROR, patientError: 'This is a patient create error.' })
  }
  return true
}

export const superCreateOrganization = async (values = {}) => {
  const response = await apiClient.organizations.create(values)

  return response.data
}

export const update = async (params = {}) => {
  const response = await apiClient.organizations.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_ORGANIZATION,
    payload: response.data
  })

  store.dispatch({
    type: ActionTypes.SET_ORGANIZATION_CURRENT,
    payload: response.data.id
  })

  return response.data
}

export const changeCurrent = (organizationID)=>{
    if(!store.getState().currentOrganization || organizationID != store.getState().currentOrganization.id){
        store.dispatch({ 
            type: ActionTypes.SET_ORGANIZATION_CURRENT,
            payload: organizationID
        })
        
        openTimeActions.fetch({skipRepeating: true})
        genderActions.fetch();
        breedActions.fetch();
        classificationActions.fetch();
        appointmentTypeActions.fetch();
        accountActions.fetch()
        administratorActions.fetch()
        restrictionActions.fetch()
        integrationConfigActions.fetch()
        insightsActions.fetch()
        appointmentActions.fetch({startingAt: moment().startOf('week').format(), unit: 'week'})
        fetchTypes()
    }
}

export const fetchTypes = async ()=>{
  const response = await apiClient.organizations.fetchTypes()
  store.dispatch({ 
    type: ActionTypes.FETCH_ORGANIZATION_TYPES,
    payload: response.data
  })
}

export const fetch = async ()=>{
  const response = await apiClient.organizations.fetch({integrationConfigs: true})

  store.dispatch({ 
    type: ActionTypes.SET_ALL_ORGANIZATIONS,
    payload: response.data
  })
}

export const toggleReminders = async ()=>{
  const response = await apiClient.organizations.toggleReminders()
  const payload = Object.values(store.getState().organizations).map((organization)=>{
    organization = JSON.parse(JSON.stringify(organization))
    if(organization.id == store.getState().currentOrganization.id){
      organization.settings.reminders = response.data.value == "true"
    }
    return organization
  })
  store.dispatch({ 
    type: ActionTypes.SET_ALL_ORGANIZATIONS,
    payload
  })
}

export const addPartner = async (options)=>{
  const response = await apiClient.organizations.addPartner(options)
  const payload = Object.values(store.getState().organizations).map((organization)=>{
    organization = JSON.parse(JSON.stringify(organization))
    if(organization.id == store.getState().currentOrganization.id){
      organization.partners = [...(organization.partners || []), response.data.partner]
    }
    return organization
  })
  store.dispatch({ 
    type: ActionTypes.SET_ALL_ORGANIZATIONS,
    payload
  })
}

export const removePartner = async (options)=>{
  const response = await apiClient.organizations.removePartner(options)
  const payload = Object.values(store.getState().organizations).map((organization)=>{
    organization = JSON.parse(JSON.stringify(organization))
    if(organization.id == store.getState().currentOrganization.id){
      organization.partners = (organization.partners || []).filter(({id})=>
        id !== options.partnerID
      )
    }
    return organization
  })
  store.dispatch({ 
    type: ActionTypes.SET_ALL_ORGANIZATIONS,
    payload
  })
}