import Collection from 'lib/client/collections/collection'
import Account from './accounts';
import Patients from './patients';
import Appointments from './appointments';
import AdministratorTypes from './administratorTypes';
import AppointmentTypes from './appointmentTypes';
import AppointmentStatuses from './appointmentStatuses';
import Genders from './genders';
import Breeds from './breeds';
import Classifications from './classifications';
import Administrators from './administrators';
import Shifts from './shifts';
import OpenTimes from './openTimes';
import ServiceTimes from './serviceTimes';
import IntegrationConfigs from './integrationConfigs';
import Availabilities from './availabilities'
import Restrictions from './restrictions'
import Workers from './workers'
import Messages from './messages'
import Notifications from './notifications'
import ServiceRegions from './serviceRegions'
import ServiceAreas from './serviceAreas'
import MessengerTemplates from './messengerTemplates'
import Operations from './operations'
import WaitingRoom from './waitingRoom'
import WaitLists from './waitLists'
import WaitListMessages from './waitListMessages'
import CustomFields from './customFields'
import Insights from './Insights'
import Partitions from './partitions'
import ManualWorkerExecutions from './ManualWorkerExecutions'
import Companies from '../companies';
import Groups from '../groups';

class Organization extends Collection{
    myOrganizations(options){
        return this.axios.get('/my-organizations', {
            ...options
        });
    }
    create(options){
        return this.axios.post('/organizations', {
            ...options
        });
    }
    fetch(options){
        return this.axios.get('/organizations', {
            ...options
        });
    }
    update(options){
        return this.axios.put(`/organizations/${options.organizationID || this.props.currentOrganization.id}`, {
            ...options
        });
    }
    toggleReminders(options){
        return this.axios.put(`/organizations/${this.props.currentOrganization.id}/toggle-reminders`);
    }
    addPartner(options){
        return this.axios.put(`/partners/${options.partnerID}/organizations/${options.organizationID}`);
    }
    removePartner(options){
        return this.axios.delete(`/partners/${options.partnerID}/organizations/${options.organizationID}`);
    }
    fetchTypes(){
        return this.axios.get(`/organizations/${this.props.currentOrganization.id}/types`);
    }

    get accounts(){
        return new Account(this.options)
    }
    get patients(){
        return new Patients(this.options)
    }
    get appointments(){
        return new Appointments(this.options)
    }
    get administratorTypes(){
        return new AdministratorTypes(this.options)
    }
    get appointmentTypes(){
        return new AppointmentTypes(this.options)
    }
    get appointmentStatuses(){
        return new AppointmentStatuses(this.options)
    }
    get genders(){
        return new Genders(this.options)
    }
    get classifications(){
        return new Classifications(this.options)
    }
    get breeds(){
        return new Breeds(this.options)
    }
    get administrators(){
        return new Administrators(this.options)
    }
    get shifts(){
        return new Shifts(this.options)
    }
    get openTimes(){
        return new OpenTimes(this.options)
    }
    get serviceTimes(){
        return new ServiceTimes(this.options)
    }
    get integrationConfigs(){
        return new IntegrationConfigs(this.options)
    }
    get availabilities(){
        return new Availabilities(this.options)
    }
    get restrictions(){
        return new Restrictions(this.options)
    }
    get workers(){
        return new Workers(this.options)
    }
    get operations(){
        return new Operations(this.options)
    }
    get messages(){
        return new Messages(this.options)
    }
    get notifications(){
        return new Notifications(this.options)
    }
    get serviceRegions(){
        return new ServiceRegions(this.options)
    }
    get serviceAreas(){
        return new ServiceAreas(this.options)
    }
    get messengerTemplates(){
        return new MessengerTemplates(this.options)
    }
    get waitingRoom(){
        return new WaitingRoom(this.options)
    }
    get waitLists(){
        return new WaitLists(this.options)
    }
    get waitListMessages(){
        return new WaitListMessages(this.options)
    }
    get customFields(){
        return new CustomFields(this.options)
    }
    get insights(){
        return new Insights(this.options)
    }
    get partitions(){
        return new Partitions(this.options)
    }
    get manualWorkerExecutions(){
        return new ManualWorkerExecutions(this.options)
    }
}

window.Organization = Organization;
export default Organization;