import {
  SET_ALL_PARTNERS
} from '../constants/actionTypes'

export default (state = [], action) => {
    switch (action.type) {
        
        case SET_ALL_PARTNERS:
            if(action.payload){
                let _partners = (action.payload || []).reduce((acc, partner)=>{
                    acc[partner.id] = partner
                    return acc
                },{})
                return _partners
            }else{
                return state
            }
        default:
            return state
    }
}
