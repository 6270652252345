import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';
import store from '../store'

export const fetch = async(params = {})=>{
    const response = await apiClient.organizations.waitListMessages.fetch(params)

    if(response.data){
        store.dispatch({
            type: ActionTypes.FETCH_WAIT_LIST_MESSAGES,
            payload: response.data
        })
    }

    return response.data
}

export const create = async(params = {})=>{
    const response = await apiClient.organizations.waitListMessages.create(params)

    if(response.data){
        store.dispatch({
            type: ActionTypes.APPEND_WAIT_LIST_MESSAGE,
            payload: response.data
        })
    }

    return response.data
}

export const update = async(params = {})=>{
    const response = await apiClient.organizations.waitListMessages.update(params)

    if(response.data){
        store.dispatch({
            type: ActionTypes.DESTROY_WAIT_LIST_MESSAGE,
            payload: params.id
        })
        store.dispatch({
            type: ActionTypes.APPEND_WAIT_LIST_MESSAGE,
            payload: response.data
        })
    }

    return response.data
}

export const destroy = async (id = '') => {
    const response = await apiClient.organizations.waitListMessages.destroy(id)

    if(!response.data){
        store.dispatch({
            type: ActionTypes.DESTROY_WAIT_LIST_MESSAGE,
            payload: id
        })
    }
}