import React from 'react'
import { Form, DatePicker, Input, Col, Row } from 'antd'

import GeneralEditor from 'components/elements/GeneralEditor'
import MaskedInput from 'components/elements/MaskedInput'
import Scroller from 'components/elements/Scroller'
import {sessionActions} from 'actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

class LoginForm extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.formRef = React.createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleSubmit(values) {
    sessionActions.updateAccount(values)
  }
  handleCancel(values) {
    sessionStorage.clear()
    localStorage.clear()
    try{
      sessionActions.logout()
      this.props.history.push('/login')
    }catch(error){
      console.log('error')
    }
  }

  get form() {
    return this.formRef.current
  }

  render() {
      
    const initialValues = {
      lastName: this.props.session.account.lastName,
      firstName: this.props.session.account.firstName,
      title: this.props.session.account.title,
      email: this.props.session.account.email,
      mobilePhone: this.props.session.account.mobilePhone,
      workPhone: this.props.session.account.workPhone,
      homePhone: this.props.session.account.homePhone,
        // initialValue: moment(this.props.session.account.birthday || moment())
      address: this.props.session.account.address,
      unit: this.props.session.account.unit,
      city: this.props.session.account.city,
      county: this.props.session.account.county,
      state: this.props.session.account.state,
      country: this.props.session.account.country,
      zip: this.props.session.account.zip
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
    };

    return (
      <Form ref={this.formRef} initialValues={initialValues} onFinish={this.handleSubmit}>
        <GeneralEditor onNext={this.handleSubmit} onCancel={this.handleCancel}>
          <Scroller>
              <Form.Item
                  {...formItemLayout}
                  label="Name"
                  required={true}
                >
                  <Row gutter={10}>
                    <Col span={3}>
                      <Form.Item
                        name="title"
                        rules={[{
                          type: 'string', message: 'The input is not valid Title!',
                        }, {
                          required: false, message: 'Please input your Title!',
                        }]}
                      >
                        <Input placeholder={'Mr.'} />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item
                        name="firstName"
                        rules={[{
                        type: 'string', message: 'The input is not valid First Name!',
                      }, {
                        required: true, message: 'Please input your First Name!',
                      }]}
                        >
                        <Input placeholder={'John'} />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name="middleInitial"
                      >
                        <Input placeholder={'E'} />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item
                        name="lastName"
                        rules={[{
                          type: 'string', message: 'The input is not valid Last Name!',
                        }, {
                          required: true, message: 'Please input your Last Name!',
                        }]}
                      >
                        <Input placeholder={'Dolittle'} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <br />
                <Form.Item
                  {...formItemLayout}
                  label="Email"
                  name="email"
                  rules={[{
                    type: 'string', message: 'The input is not valid Email!',
                  }, {
                    required: false, message: 'Please input your Email!',
                  }]}
                >
                  <Input placeholder={'abc@example.com'} type='email' />
                </Form.Item>
                <br />
                <Form.Item
                  {...formItemLayout}
                  label="Mobile Phone"
                  name="mobilePhone"
                  rules={[{
                    type: 'string', message: 'The input is not valid Phone!',
                  }, {
                    required: false, message: 'Please input your mobile phone!',
                  }]}
                >
                  <MaskedInput placeholder={'822-555-0405'} type='phone' />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Work Phone"
                  name="workPhone"
                  rules={[{
                    type: 'string', message: 'The input is not valid work phone!',
                  }]}
                >
                  <MaskedInput placeholder={'822-555-0405'} type='phone' />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Home Phone"
                  name="homePhone"
                  rules={[{
                    type: 'string', message: 'The input is not valid!',
                  }]}
                >
                  <MaskedInput placeholder={'822-555-0405'} type='phone' />
                </Form.Item>
                <br />
                <Form.Item
                  {...formItemLayout}
                  label="Date of Birth"
                  name="birthday"
                  rules={[{
                    type: 'object', message: 'The input is not a valid Date of Birth!',
                  }]}
                >
                  <DatePicker />
                </Form.Item>
                <br />
                <Form.Item
                  {...formItemLayout}
                  label="Address"
                  name="address"
                  rules={[{
                    type: 'string', message: 'The input is not valid Address!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Unit"
                  name="unit"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="City"
                  name="city"
                  rules={[{
                    type: 'string', message: 'The input is not valid City!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="County"
                  name="county"
                  rules={[{
                    type: 'string', message: 'The input is not valid County!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="State"
                  name="state"
                  rules={[{
                    type: 'string', message: 'The input is not valid State!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Country"
                  name="country"
                  rules={[{
                    type: 'string', message: 'The input is not valid Country!',
                  }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Zip"
                  name="zip"
                  rules={[{
                    type: 'string', message: 'The input is not valid Zip!',
                  }]}
                >
                  <Input />
                </Form.Item>
            </Scroller>
          </GeneralEditor>
      </Form>
    )
  }
}

const CustomizedForm = LoginForm


export default withRouter(
  connect((state)=>state)(CustomizedForm)
)
