import React, {Component, Fragment} from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import InternalCalendar from './InternalCalendar'
import Restrictions from './Restrictions'

export default class Appointments extends Component {
    componentDidMount(){
        
    }
    render(){
        return (
            <Switch>
                <Route exact path='/admin/appointments/restrictions' component={Restrictions} />
                <Route exact path='/admin/appointments/:calendar' component={InternalCalendar} />
            </Switch>
        )
    }
}