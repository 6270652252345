import React from 'react'
import { Modal } from 'antd'
import { TableRowEditButton } from 'components/elements/AppButton'
import {SnapList} from 'components/elements/ListEditor'
import {withRouter} from 'react-router-dom'

class AdministratorCards extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleDeleteButtonOnClick = this.handleDeleteButtonOnClick.bind(this)
  }

  handleDeleteButtonOnClick() {
    const handleTableOnDelete = () => { this.props.onDelete() }
    Modal.confirm({
      title: 'Delete Item',
      content: 'Are you sure to delete this item?',
      onOk() {
        handleTableOnDelete()
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  

  render() {
    return (
      <SnapList
        placeholder="admin placeholder"
        data={this.props.dataSource || []}
        listType={'Administrator'}
        sortBy={'lastName'}
        search={this.props.search || ''}
        createPrompt={'Create or Invite Existing Account'}
        initial={this.props.match.params.id}
        onSelect={this.props.onSelect}
        onCreate={this.props.onCreate}
        onSearch={this.props.onSearch}
        row={(props)=>(
          <div>
            {props.firstName} {props.lastName}
            <br />
            {props.email || props.phone}
          </div>
        )}
      />
    )
  }
}

export default withRouter(AdministratorCards)

// <Table
//   rowKey={record => `item-row-${record.id}`}
//   columns={columns}
//   {...this.props}
// />