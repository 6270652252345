import Collection from 'lib/client/collections/collection'

class OpenTimes extends Collection{
    fetch(options){
        let params = ''
        if(options.fullAppointments) params = `?fullAppointments=${options.fullAppointments}`
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/waitingRoom${params}`);
    }

    reorder(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/waitingRoom/reorder/${options.appointmentID}`, options);
    }

    destroy(id = ''){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/waitingRoom/${id}`);
    }
}

export default OpenTimes;