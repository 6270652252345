import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';

import store from 'store'

export const fetch = async(params = {})=>{
  let response = await apiClient.organizations.messengerTemplates.fetch({
    organizationID: store.getState().currentOrganization.id
  })

  let data = {};
  if(response.data.id){
    data[response.data.id] = response.data
  }else{
    data = response.data.reduce((acc, item)=>{
      acc[item.id] = item
      return acc;
    }, {})
  }

  store.dispatch({ 
    type: ActionTypes.FETCH_MESSENGER_TEMPLATES,
    payload: data
  })
}

const fetching = {}
export const safeFetchByID = async(params = {})=>{
  if(!fetching[params.id] && !store.getState().messengerTemplates[params.id]){
    fetching[params.id] = true
    await fetch({id: params.id})
    fetching[params.id] = false
  }
}

export const update = async(params = {}) => {
  const response = await apiClient.organizations.messengerTemplates.update(params)

  store.dispatch({
    type: ActionTypes.APPEND_MESSENGER_TEMPLATES,
    payload: response.data
  })

  return response.data
}

export const create = async(params = {}) => {
  const response = await apiClient.organizations.messengerTemplates.create(params)

  store.dispatch({
    type: ActionTypes.APPEND_MESSENGER_TEMPLATES,
    payload: response.data
  })

  return response.data
}