import Collection from 'lib/client/collections/collection'
import _ from 'lodash'

class ManualWorkerExecutions extends Collection {
    // fetch(params){
    fetch(){
        var params = _.pickBy(params, _.identity);
        let query = Object.entries(params).map(([key, value])=>
            `${key}=${value}`
        )
        if(query.length !== 0){
            query = `?${query.join('&')}`
        }else{
            query = ''
        }

        return this.axios.get(`/organizations/${this.props.currentOrganization.id}/manual-worker-executions`)
    }
    fetchOne(id){
        var params = _.pickBy(params, _.identity);
        let query = Object.entries(params).map(([key, value])=>
            `${key}=${value}`
        )
        if(query.length !== 0){
            query = `?${query.join('&')}`
        }else{
            query = ''
        }

        return this.axios.get(`/organizations/${this.props.currentOrganization.id}/manual-worker-executions/${id}`)
    }
    
    create(params){
        return this.axios.post(`/organizations/${this.props.currentOrganization.id}/manual-worker-executions`, params)
    }
    retry(id){
        return this.axios.put(`/organizations/${this.props.currentOrganization.id}/manual-worker-executions/${id}`)
    }
    cancel(id){
        return this.axios.delete(`/organizations/${this.props.currentOrganization.id}/manual-worker-executions/${id}`)
    }
}

export default ManualWorkerExecutions