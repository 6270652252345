import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGenderless, faCode, faPaw, faMap, faBan, faCheckCircle, faClipboardList, faVenusMars, faUserMd, faCog, faFireExtinguisher, faSpinner, faCheck, faTimes, faListUl} from '@fortawesome/free-solid-svg-icons'
import {faCircle} from '@fortawesome/free-regular-svg-icons'
library.add(faGenderless, faCode, faPaw, faMap, faBan, faCheckCircle, faCircle, faClipboardList, faVenusMars, faUserMd, faCog, faFireExtinguisher,faSpinner, faCheck, faTimes, faListUl)


export default (props)=>{
    //['genderless', 'code', 'paw', 'map', 'ban', 'check-circle', 'circle'].includes(props.icon) || console.error('icon unavailable', props.icon)

    return <FontAwesomeIcon icon={props.icon} {...props} />
}