import React from 'react'
import { Modal, Input, Card } from 'antd'

import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import Scroller from 'components/elements/Scroller'

class Show extends React.Component {
  get patient(){
    return this.props.patient || {}
  }

  get account(){
    return this.props.account || {}
  }

  get species(){
    return this.props.classifications[this.breed.classificationID] || {}
  }

  get breed(){
    return this.props.breeds[this.patient.breedID] || {}
  }

  get gender(){
    return this.props.genders[this.patient.genderID] || {}
  }

  get appointmentTypes(){
    return this.props.appointmentTypes || {}
  }

  get upcomingAppointments(){
    return (Object.values(this.props.appointments || {}) || []).filter((appointment)=>{
      return moment().isBefore(appointment.startsAt)
    })
  }

  get historyAppointments(){
    return (Object.values(this.props.appointments || {}) || []).filter((appointment)=>{
      return moment().isAfter(appointment.startsAt)
    })
  }

  render() {
    return (
      <Scroller>
        <Card bordered={false}>
          <div className='edit-switch'>
            <Link to={`/admin/patients/${this.patient.id}/edit`}>Edit</Link>
            <h2 className='text-center'>
                {this.patient.firstName} {this.patient.lastName}
            </h2>
            
            <label>Account:</label> 
            <br />
            <Link to={`/admin/accounts/${this.account.id}`}>
              {this.account.firstName} {this.account.lastName}
            </Link>
            <br />
            {this.account.email &&
              <div>
                <a href={`mailto:${(this.account.email || '').toLowerCase()}`}>
                  {(this.account.email || '').toLowerCase()}
                </a>
                <br />
              </div>
            }
            {
              this.account.mobilePhone &&
              <div>
                <a href={`tel:${this.account.mobilePhone}`}>
                  {this.account.mobilePhone}
                </a>
                <br />
              </div>
            }
            <br />
            
            <label>Patient Details:</label>
            <br />
            <label>PIMS ID:</label> {Object.values(Object(this.patient.integrationIDs))[0]} 
            <br />
            <label>Age:</label> {moment().diff(this.patient.birthday, 'years')} 
            <br />
            <label>Date of Birth:</label> {moment(this.patient.birthday).format('M/DD/YY')}
            <br />
            <label>Gender:</label> {this.gender.name}
            <br />
            <label>Species:</label> {this.species.name}
            <br />
            <label>Breed:</label> {this.breed.name}
            {this.patient.weight && 
              <React.Fragment>
                <br />
                <label>Weight:</label> {this.patient.weight}
              </React.Fragment>
            }
            {/* <br />
            <label>Color:</label> {this.patient.details.color} */}
            <br />
            <br />
            {/* <label>Conditions:</label> {(this.patient.conditions || []).join(', ')}
            <br />
            <label>Allergies:</label> {(this.patient.allergies || []).join(', ')}        <br />
            <br />
            <label>Prescriptions:</label> 
            <br />{(this.patient.prescriptions || []).map((prescription, i)=>
              <span key={`prescription-${i}`}>
                {prescription.name} {prescription.amount} &nbsp;
                ({moment(prescription.startingOn).format('M/DD/YYYY')} - {moment(prescription.startingOn).add(prescription.days, 'days').format('M/DD/YYYY')})
              </span>
            )} */}
            {/* <br />
            <br /> */}
            {this.upcomingAppointments.length > 0 &&
              <div>
                <label>Upcoming Visits:</label> {this.upcomingAppointments.map((visit, i)=>(
                  <React.Fragment key={`visits-${i}`}>
                    <div>{(this.appointmentTypes[visit.appointmentTypeID] || {}).name}</div>
                    <div>{moment(visit.startsAt).format('M/DD/YY')}  (In {moment(visit.startsAt).diff(moment(), 'days')} days)</div>
                    <div>{visit.reason}</div>
                    <br />
                  </React.Fragment>
                ))}
                <br />
              </div>
            }
            {this.historyAppointments.length > 0 &&
              <div>
                <label>Visit History:</label> {this.historyAppointments.map((visit, i)=>(
                  <React.Fragment key={`visits-${i}`}>
                    <div>{(this.appointmentTypes[visit.appointmentTypeID] || {}).name}</div>
                    <div>{moment(visit.startsAt).format('M/DD/YY')}</div>
                    <div>{visit.reason}</div>
                    <br />
                  </React.Fragment>
                ))}
              </div>
            }
          </div>
        </Card>
      </Scroller>
    )
  }
}

export default connect((state)=> state)(Show)