import React from 'react';
import { withRouter } from 'react-router-dom'
import { Drawer, Form, Button, Popconfirm, Col, Row, Input, Select, DatePicker, TimePicker, Switch } from 'antd';
import moment from 'moment'
import {openTimeActions} from 'actions'
import {connect} from 'react-redux'
import _ from 'lodash'

const {Option} = Select;

class CreateOrEditOpenTime extends React.Component {
  constructor(){
      super();
      this.formRef = React.createRef()
      this.state = { 
          visible: false
      };
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleDestroyOpenTime = this.handleDestroyOpenTime.bind(this)
      this.handleChanges = this.handleChanges.bind(this)
  }

  componentDidUpdate(previousProps){
    if(!this.props.visible && previousProps.visible){
      const openTime = Object.keys(this.openTime).reduce((acc, key)=>({
        ...acc,
        [`${key}`]: undefined
      }), {})
      this.form.setFieldsValue(openTime)
    }

    if(JSON.stringify(previousProps.openTime) != JSON.stringify(this.props.openTime)){
      this.setState(this.state, ()=>{
        this.form && this.form.resetFields();
      })
    }
  }

  get openTime(){
      const _event = this.props.openTime || {}
      
      let startsAt = this.form && this.form.getFieldValue('startsAt')
      if(_.isNil(startsAt)) startsAt = _event.startsAt
      
      let endsAt = this.form && this.form.getFieldValue('endsAt')
      if(_.isNil(endsAt)) endsAt = _event.endsAt

      let startsAtDate = this.form && this.form.getFieldValue('startsAtDate')
      if(_.isNil(startsAtDate)) startsAtDate = _event.startsAt
      
      let endsAtDate = this.form && this.form.getFieldValue('endsAtDate')
      if(_.isNil(endsAtDate) && _event.startsAt) endsAtDate = _event.endsAt
      
      let repeatingType = this.form && this.form.getFieldValue('repeatingType');
      if(_.isNil(repeatingType) && _event.startsAt) repeatingType = this.repeatingType 

      let skipType = this.form && this.form.getFieldValue('skipType');
      if(_.isNil(skipType) && _event.startsAt) skipType = this.skipType

      let skipValue = this.form && this.form.getFieldValue('skipValue');
      if(_.isNil(skipValue) && _event.startsAt) skipValue = _event.skipValue

      let dayOfMonth = this.form && this.form.getFieldValue('dayOfMonth');
      if(_.isNil(dayOfMonth) && _event.startsAt) dayOfMonth = _event.dayOfMonth 

      let dayOfWeek = this.form && this.form.getFieldValue('dayOfWeek');
      if(_.isNil(dayOfWeek) && _event.startsAt) dayOfWeek = _event.dayOfWeek

      let sequence = this.form && this.form.getFieldValue('sequence')
      if(_.isNil(sequence) && _event.startsAt) sequence = _event.sequence

      let repeatsUntil = this.form && this.form.getFieldValue('repeatsUntil')
      if(_.isNil(repeatsUntil) && _event.startsAt && _event.repeatsUntil) repeatsUntil = moment(_event.repeatsUntil)
     
      let repeatsUntilType = this.form && this.form.getFieldValue('repeatsUntilType')
      if(_.isNil(repeatsUntilType) && _event.startsAt) repeatsUntilType = _event.repeatsUntil ? 'end' : ''
      
      return {
        id: _event.id,
        sequence,
        dayOfMonth,
        dayOfWeek,
        repeatingType,
        startsAt,
        endsAt,
        startsAtDate,
        endsAtDate,
        skipType,
        skipValue,
        repeatsUntil,
        repeatsUntilType
      }
  }

  showDrawer () {
      this.setState({
          visible: true,
      });
  };

  async handleSubmit (values) {

    let endDate = {
      year: values.endsAtDate.year(),
      month: values.endsAtDate.month(),
      day: values.endsAtDate.day()
    }
    let _openTime = {
      sequence: null,
      dayOfMonth: null,
      dayOfWeek: null,
      skipType: null,
      skipValue: null,
      startsAt: values.startsAt,
      endsAt: values.endsAt.set(endDate),
      administratorID: this.props.match.params.id,
      repeating: true
    }

    switch(values.repeatingType){
      case 'daily':
        _openTime.dayOfWeek = 'Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday'
        break;
      case 'weekday':
        _openTime.dayOfWeek = 'Monday,Tuesday,Wednesday,Thursday,Friday'
        break;
      case 'weekly':
        _openTime.dayOfWeek = this.dayOfWeek
        break;
      case 'nthOfMonth':
        _openTime.dayOfMonth = moment(this.openTime.startsAt).date()
        break;
      case 'nthDayOfMonth':
        _openTime.dayOfWeek = this.dayOfWeek
        _openTime.sequence = Math.ceil(moment(this.openTime.startsAt).date() / 7)
        break;
      default:
        _openTime.repeating = false
    }

    switch(values.skipType){
      case 'week':
        _openTime.skipValue = 1
        _openTime.skipType = 'week'
        break;
      case 'month':
        _openTime.skipValue = 1
        _openTime.skipType = 'month'
        break;
      case 'xWeeks':
        _openTime.skipValue = values.skipValue
        _openTime.skipType = 'week'
        break;
      case 'xMonths':
        _openTime.skipValue = values.skipValue
        _openTime.skipType = 'month'
        break;
    }

    if(!!values.repeatsUntilType){
      _openTime.repeatsUntil = values.repeatsUntil.format()
    } else {
      _openTime.repeatsUntil = null
    }

    if(this.openTime.id){
      await openTimeActions.update({
          id: this.openTime.id,
          ..._openTime
      })
    }else{
      await openTimeActions.create(_openTime)
    }
    this.props.onClose()
  }


  async handleDestroyOpenTime(e){
    e.preventDefault()

    await openTimeActions.destroy(this.openTime.id)
    this.props.onClose()
  }

  get dayOfWeek(){
    return moment(this.openTime.startsAtDate).format('dddd')
  }

  get dateOfMonth(){
    return moment(this.openTime.startsAtDate).format('Do')
  }

  get nthDay(){
    let nth = Math.ceil(moment(this.openTime.startsAt).date() / 7)
    switch(nth){
      case 1:
        return `${nth}st`
      case 2:
        return `${nth}nd`
      case 3:
        return `${nth}rd`
      default:
        return `${nth}th`
    }
  }

  get repeatingType(){
    const openTime = this.props.openTime || {}
    if(!openTime.repeating){
      return '' 
    }

    if(!openTime.repeating){
      return '' 
    }
    if(openTime.sequence){
      return 'nthDayOfMonth'
    }
    if(openTime.dayOfWeek){
      let days = openTime.dayOfWeek.split(',')
      if(days.length == 7){
        return 'daily'
      }
      if(days.length == 5){
        return 'weekday'
      }
      if(days.length == 1){
        return 'weekly'
      }
    }
    if(openTime.dayOfMonth){
      return 'nthOfMonth'
    }
    
    return '' 
  }

  get skipOptions(){
    const q = {};
    switch(this.openTime.repeatingType){
      case 'weekly':
      case 'daily':
      case 'weekday':
        return [
          {
            value:'week',
            text:"Skip Every Other Week"
          },
          {
            value:'xWeeks',
            text:"Skip Every X Week"
          }
        ]
      case 'nthDayOfMonth':
      case 'nthOfMonth':
        return [
          {
            value:'month',
            text:'Skip Every Other Month'
          },
          {
            value:'xMonths',
            text:'Skip Every X Month'
          }
        ]
      default:
        return []
    }
  }

  get skipType(){
    const openTime = this.props.openTime || {}

    const skipType = this.form && this.form.getFieldValue('skipType')

    if(`${skipType}`.includes('x') || (openTime.skipValue && openTime.skipValue > 1)){
      const skipType = _.capitalize(openTime.skipType)
      return `x${skipType}s`
    }else{
      return `${openTime.skipType || ''}`
    }
  }

  get form() {
    return this.formRef.current
  }

  get specificDate(){
    if(this.form && this.form.getFieldValue('repeatsUntil') == 'end'){
      return true
    }
    return false

  }

  handleChanges(){
    let skipType = this.form ? this.form.getFieldValue('skipType') : ''
  
    if(!skipType.includes('x')){
      this.form.setFieldsValue({'skipValue': 1})
    }
    
    let skip = '';
    if(skipType.toLowerCase().includes('month')) skip = 'month'
    if(skipType.toLowerCase().includes('week')) skip = 'week'

    let repeatingType = this.form ? this.form.getFieldValue('repeatingType') : ''
    let repeating = '';
    if(repeatingType.toLowerCase().includes('daily')) repeating = 'week'
    if(repeatingType.toLowerCase().includes('week')) repeating = 'week'
    if(repeatingType.toLowerCase().includes('month')) repeating = 'month'

    if(skip != repeating){
      this.form.setFieldsValue({'skipType': ''})
    }

    this.setState(this.state, ()=>{
      this.form && this.form.resetFields();
    })
  }
  render() {
    const initialValues = {
      startsAtDate: this.openTime.startsAtDate,
      startsAt: this.openTime.startsAt,
      endsAtDate: this.openTime.endsAtDate,
      endsAt: this.openTime.endsAt,
      repeatingType: this.openTime.repeatingType,
      skipType: this.openTime.skipType,
      skipValue: this.openTime.skipValue,
      repeatsUntilType: this.openTime.repeatsUntilType,
      repeatsUntil: this.openTime.repeatsUntil
    }

    let edit = false;
    
    if(this.openTime.id){
      edit = true
    }
    window.com = this

    return (
      <Drawer
        title={edit ? 'Edit Open Time' : 'Create Open Time'}
        width={420}
        placement="right"
        onClose={this.props.onClose}
        visible={this.props.visible}
        style={{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: 53,
        }}
      >
        <Form
          ref={this.formRef}
          initialValues={initialValues}
          layout="vertical"
          onValuesChange={this.handleChanges}
          onFinish={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="Start Date"
                name="startsAtDate"
                rules={[{
                  required: true,
                  message: 'Please select a start date'
                }]}
              >
                <DatePicker style={{display: 'block'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Start Time"
                name="startsAt"
                rules={[{
                  required: true,
                  message: 'Please choose the start time'
                }]}
              >
                <TimePicker use12Hours format="h:mm A"  style={{display: 'block'}} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={18}>
            <Col span={12}>
              <Form.Item 
                label="End Date"
                name="endsAtDate"
                rules={[{
                  required: true,
                  message: 'Please select a end date'
                }]}
              >
                <DatePicker  style={{display: 'block'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="End Time"
                name="endsAt"
                rules={[{
                  required: true,
                  message: 'Please choose the end time'
                }]}
              >
                <TimePicker use12Hours format="h:mm A"  style={{display: 'block'}} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Repeating"
                name="repeatingType"
                rules={[{
                  message: 'Please select a classification'
                }]}
              >
                <Select>
                  <Option value=''>Does Not Repeat</Option>
                  <Option value='daily'>Repeat Daily</Option>
                  <Option value='weekday'>Repeat Every Weekday (Monday - Friday)</Option>
                  <Option value='weekly'>Repeat Every {this.dayOfWeek}</Option>
                  <Option value='nthOfMonth'>Repeat on the {this.dateOfMonth} of every month</Option>
                  <Option value='nthDayOfMonth'>Repeat Monthly on the {this.nthDay} {this.dayOfWeek}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {!!this.openTime.repeatingType &&
            <Row gutter={16}>
              <Col span={!!this.skipType.includes('x') ? 20 : 24}>
                <Form.Item 
                  label="Skip"
                  name="skipType"
                >
                  <Select>
                    <Option value=''>Does Not Skip</Option>
                    {this.skipOptions.map((skip,i)=>{
                      return (
                        <Option key={`skip-${i}`}value={skip.value}>{skip.text}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {!!this.skipType.includes('x') &&
                <Col span={4}>
                  <Form.Item 
                    label={<span>&nbsp;</span>}
                    name="skipValue"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              }
            </Row>
          }
          {!!this.openTime.repeatingType && (
            <Row gutter={16}>
              <Col span={!!this.openTime.repeatsUntilType ? 16 : 24}>
                <Form.Item 
                  label="Repeats Until"
                  name="repeatsUntilType"
                >
                  <Select>
                    <Option value=''>Does Not End</Option>
                    <Option value='end'>Repeat Until a Specific Date</Option>
                  </Select>
                </Form.Item>
              </Col>
              {!!this.openTime.repeatsUntilType && (
                <Col span={8}>
                  <Form.Item 
                    label="End Date"
                    name="repeatsUntil"
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
            >
            {edit && 
                <Popconfirm placement="topRight" title='Are you sure' okText="Yes" onConfirm={this.handleDestroyOpenTime} cancelText="No">
                    <Button type='danger' className='pull-left'>Delete Open Time</Button>
                </Popconfirm>
            }
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.props.onClose}
              >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">{this.openTime.id ? 'Update' : 'Create' }</Button>
          </div>
        </Form>
      </Drawer>
    );
  }
}
export default withRouter(connect((state)=> state)(
  CreateOrEditOpenTime
))