import React from 'react';
import { Popconfirm, Form, Col, Row, Button, Input, DatePicker, Select } from 'antd';
import moment from 'moment'
import {connect} from 'react-redux'
import _ from 'lodash'
import CustomFieldForm from 'components/groups/CustomFieldForm'
import { withRouter } from 'react-router-dom'


class WidgetCustomFields extends React.Component {
  constructor(){
    super();
    this.state = {
      addCustomField: false
    }
    this.handleAddCustomField = this.handleAddCustomField.bind(this)
  }

  handleAddCustomField(){
    this.setState({
      addCustomField: !this.state.addCustomField 
    })
  }

  get customFields(){
    return _.sortBy(Object.values(this.props.customFields || {}), (customField) => (
      customField.createdAt
    ))
  }

  render() {
    return (
      <Row>
        <Col span={16}>                      
          {this.state.addCustomField ? (
            <CustomFieldForm onCancel={this.handleAddCustomField} onFinish={this.handleAddCustomField}/>
          ):(
            <div style={{height: '222px', width: '782.5', textAlign: 'center', position: 'relative'}}>
              <div style={{position: 'absolute', textAlign: 'center', width: '100%', bottom: '85px'}}>
                Click here to create a custom field
              </div>
              <Button style={{backgroundColor: 'transparent', height: '100%', fontSize: 'xx-large', width: '100%', textAlign: 'center', paddingBottom: '30px'}} id="customField" onClick={this.handleAddCustomField}>Create Custom Field</Button>
            </div>
          )}
          {this.customFields.map((customField) => (
            <div key={customField.id}>
              <div style={{width: "100%", borderTop: '1px solid rgb(232, 232, 232)', marginTop: '20px'}}></div>
              <CustomFieldForm customField={{...customField}} />
            </div>
          ))}
        </Col>
      </Row>   
    );
  }
}
export default withRouter(connect((state)=>state)(WidgetCustomFields))