import React from 'react'
import { Modal, Input, Button, Row, Col } from 'antd'

import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import Edit from './Edit'
import Invite from './Invite'
import Show from './Show'

class Item extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleNew = this.handleNew.bind(this)
    this.handleInvite = this.handleInvite.bind(this)
  }

  get administrators(){
    return this.props.administrators || {}
  }
  get current(){
    const accountCurrent = this.administrators[this.props.match.params.id] || {};
    
    return {
      ...accountCurrent,
      phone: accountCurrent.phone,
    }
  }

  handleNew(){
    this.props.history.push(`/admin/administrators/new`)
  }

  handleInvite(){
    this.props.history.push(`/admin/administrators/invite`)
  }

  render() {
    return (
      <div className='edit-switch'>
        <Switch>
          <Route exact path='/admin/administrators/newType' render={()=>
            <div style={{textAlign: "center", margin: "100px 20px"}}>
              <Row gutter={10}>
                <Col xs={{span:24}} sm={{span:11}}>
                  <h2>Does this administrator already have an account?</h2>
                </Col>
                <Col xs={{span:24}} sm={{span:2}}></Col>
                <Col xs={{span:24}} sm={{span:11}}>
                  <h2>Does this administrator need an account?</h2>
                </Col>
              </Row>
              <Row gutter={10} style={{marginTop: "50px"}}>
                <Col xs={{span:24}} sm={{span:11}}>
                  <Button onClick={this.handleInvite}>Invite Existing Account</Button>
                </Col>
                <Col xs={{span:24}} sm={{span:2}}>
                  <h4>OR</h4>
                </Col>
                <Col xs={{span:24}} sm={{span:11}}>
                  <Button onClick={this.handleNew}>Create New Administrator</Button>
                </Col>
              </Row>
            </div>
          }/>
          <Route exact path='/admin/administrators/new' render={()=>
            <Edit current={this.current} type='new' />
          }/>
          <Route exact path='/admin/administrators/invite' render={()=>
            <Invite current={this.current} type='new' />
          }/>
          <Route exact path='/admin/administrators/:id' render={()=>
            <Show current={this.current} />
          }/>
          <Route exact path='/admin/administrators/:id/edit' render={()=>
            <Edit current={this.current} type='edit' />
          }/>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    administrators
  } = state

  return {
    administrators
  }
}

export default withRouter(connect(mapStateToProps)(Item))