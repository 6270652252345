import React from 'react'
import { Modal, Input } from 'antd'

import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import Edit from './Edit'
import Show from './Show'

class Item extends React.Component {
  constructor(props, context) {
    super(props, context)
  }

  get accounts(){
    return this.props.accounts || {}
  }
  get account(){
    const currentAccount = this.accounts[this.props.match.params.id] || {};

    return {
      ...currentAccount,
      phone: currentAccount.phone,
      patients: currentAccount.patients || []
    }
  }

  render() {
    return (
      <div className='edit-switch'>
        <Switch>
          <Route exact path='/admin/accounts/new' render={()=>
            <Edit account={this.account} type='new' />
          }/>
          <Route exact path='/admin/accounts/:id' render={()=>
            <Show account={this.account} />
          }/>
          <Route exact path='/admin/accounts/:id/edit' render={()=>
            <Edit account={this.account} type='edit' />
          }/>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {patientCurrent, accounts} = state

  return {
    accounts,
    patientCurrent
  }
}

export default withRouter(connect(mapStateToProps)(Item))

// <Table
//   rowKey={record => `item-row-${record.id}`}
//   columns={columns}
//   {...this.props}
// />