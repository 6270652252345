import Collection from '../collection'

class CustomFields extends Collection{
    fetch(options = {}){
        return this.axios.get(`/organizations/${this.props.currentOrganization.id}/custom-fields`, {
            ...options
        });
    }
    create(options){
        return this.axios.post(`/organizations/${this.props.currentOrganization.id}/custom-fields`, {
            ...options
        });
    }
    update(options){
        return this.axios.put(`/organizations/${this.props.currentOrganization.id}/custom-fields/${options.id}`, {
            ...options
        });
    }
    destroy(id){
        return this.axios.delete(`/organizations/${this.props.currentOrganization.id}/custom-fields/${id}`);
    }
}

export default CustomFields;