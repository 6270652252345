import Accounts from './collections/account';
import Partners from './collections/partners';
import Groups from './collections/groups';
import Companies from './collections/companies';
import Organizations from './collections/organizations';
import store from 'store'


class Client {
    constructor(options = {}){
        this._options = options
    }
    
    get options(){
        const _options = {
            ...this._options
        }

        if(store.getState().session.accessToken || store.getState().session.tempAccessToken){
            _options.headers = {
                'x-token': store.getState().session.accessToken || store.getState().session.tempAccessToken
            }
        }

        return _options
    }
    set options(value){
        this._options = value
    }
    get currentAccount(){
        return new Accounts(this.options);
    }
    
    get organizations(){
        return new Organizations(this.options);
    }
    get companies(){
        return new Companies(this.options)
    }
    get groups(){
        return new Groups(this.options)
    }
    get partners(){
        return new Partners(this.options);
    }
    get accounts(){
        return new Accounts(this.options);
    }
}

window.OtterApiClient = Client;
export default Client;