import React from 'react';
import { Form, Col, Row, Input, DatePicker, Select, TimePicker } from 'antd';
import moment from 'moment'
import {connect} from 'react-redux'


class RepeatingForm extends React.Component {
  constructor(){
    super();
    
    this.state = { 
      visible: false
    };
  }

  get dayOfWeek(){
    return moment(this.repeatableItem.date).format('dddd')
  }

  get dateOfMonth(){
    return moment(this.repeatableItem.date).format('Do')
  }

  get nthDay(){
    let nth = Math.ceil(moment(this.repeatableItem.date).date() / 7)
    switch(nth){
      case 1:
        return `${nth}st`
      case 2:
        return `${nth}nd`
      case 3:
        return `${nth}rd`
      default:
        return `${nth}th`
    }
  }

  get skipOptions(){
    switch(this.repeatableItem.repeatingType){
      case 'weekly':
      case 'daily':
      case 'weekday':
        return [
          {value:'week',text:"Skip Every Other Week"},
          {value:'xWeeks',text:"Skip Every X Week"}
        ]
      case 'nthDayOfMonth':
      case 'nthOfMonth':
        return [
          {value:'month',text:'Skip Every Other Month'},
          {value:'xMonths',text:'Skip Every X Month'}
        ]
      default:
        return []
    }
  }


  get form() {
    return this.props.formRef.current
  }

  get repeatableItem(){
    return this.constructor.initialValues(this.form, this.props.repeatableItem)
  }

  render() {
    
    return (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item 
                label="Date"
                name="date"
                rules={[{ required: true, message: 'Please select a startsAt date' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="Start Time"
                name="startsAt"
                rules={[{ required: true, message: 'Please choose the startsAt time' }]}
              >
                <TimePicker use12Hours format="h:mm A" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="End Time"
                name="endsAt"
                rules={[{ required: true, message: 'Please choose the end time' }]}
              >
                <TimePicker use12Hours format="h:mm A" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item 
                label="Repeating"
                name="repeatingType"
                rules={[{ message: 'Please select a classification' }]}
              >
                <Select>
                  <Select.Option value=''>Does Not Repeat</Select.Option>
                  <Select.Option value='daily'>Repeat Daily</Select.Option>
                  <Select.Option value='weekday'>Repeat Every Weekday (Monday - Friday)</Select.Option>
                  <Select.Option value='weekly'>Repeat Every {this.dayOfWeek}</Select.Option>
                  <Select.Option value='nthOfMonth'>Repeat on the {this.dateOfMonth} of every month</Select.Option>
                  <Select.Option value='nthDayOfMonth'>Repeat Monthly on the {this.nthDay} {this.dayOfWeek}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {this.repeatableItem.repeatingType &&
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item 
                  label="Skip"
                  name="skipType"
                >
                  <Select>
                    <Select.Option value=''>Does Not Skip</Select.Option>
                    {this.skipOptions.map((skip,i)=>{
                      return (
                        <Select.Option key={`skip-${i}`}value={skip.value}>{skip.text}</Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {`${this.repeatableItem.skipType}`.includes('x') &&
                <Col span={4}>
                  <Form.Item 
                    label={<span>&nbsp;</span>}
                    name="skipValue"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              }
            </Row>
          }
          {this.repeatableItem.repeatingType &&
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item 
                  label="Repeats Until"
                  name="repeatsUntilType"
                >
                  <Select>
                    <Select.Option value=''>Does Not End</Select.Option>
                    <Select.Option value='end'>Repeat Until a Specific Date</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {!!this.repeatableItem.repeatsUntilType &&
                <Col span={8}>
                  <Form.Item 
                    label="End Date"
                    name="repeatsUntil"
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
              }
            </Row>
          }
        </div>
    );
  }


  static initialValues(form, repeatableItem){
      const _event = repeatableItem || {}

      let _skipType = ''
      if(!_event.skipValue){
        _skipType = ''
      }else if(_event.skipValue > 1 && _event.skipType == 'week'){
        _skipType = 'xWeeks'
      }else if(_event.skipValue > 1 && _event.skipType == 'month'){
        _skipType = 'xMonths'
      }else if(_event.skipType == 'week'){
        _skipType = 'week'
      }else if(_event.skipType == 'month'){
        _skipType = 'month'
      }
      
      let _repeatingType = '';
      if(!_event.repeating){
        _repeatingType = '' 
      }else if(_event.sequence){
        _repeatingType = 'nthDayOfMonth'
      }else if(_event.dayOfWeek){
        let days = _event.dayOfWeek.split(',')
        if(days.length == 7){
          _repeatingType = 'daily'
        }else if(days.length == 5){
          _repeatingType = 'weekday'
        }else if(days.length == 1){
          _repeatingType = 'weekly'
        }
      }else if(_event.dayOfMonth){
        _repeatingType = 'nthOfMonth'
      }
      
      let sequence = form && form.getFieldValue('sequence')
      if(_.isNil(sequence) && _event.startsAt) sequence = _event.sequence
      
      let dayOfMonth = form && form.getFieldValue('dayOfMonth')
      if(_.isNil(dayOfMonth) && _event.startsAt) dayOfMonth = _event.dayOfMonth
      
      let dayOfWeek = form && form.getFieldValue('dayOfWeek')
      if(_.isNil(dayOfWeek) && _event.startsAt) dayOfWeek = _event.dayOfWeek
      
      let repeatingType = form && form.getFieldValue('repeatingType')
      if(_.isNil(repeatingType) && _event.startsAt) repeatingType = _repeatingType
      
      let startsAt = form && form.getFieldValue('startsAt')
      if(_.isNil(startsAt) && _event.startsAt) startsAt = _event.startsAt
      
      let endsAt = form && form.getFieldValue('endsAt')
      if(_.isNil(endsAt) && _event.startsAt) endsAt = _event.endsAt
      
      let date = form && form.getFieldValue('date')
      if(_.isNil(date) && _event.startsAt) date = _event.startsAt
      
      let repeatsUntil = form && form.getFieldValue('repeatsUntil')
      if(_.isNil(repeatsUntil) && _event.startsAt && _event.repeatsUntil) repeatsUntil = moment(_event.repeatsUntil)
      
      let repeatsUntilType = form && form.getFieldValue('repeatsUntilType')
      if(_.isNil(repeatsUntilType) && _event.startsAt) repeatsUntilType = _event.repeatsUntil  ? 'end' : '';
      
      let skipType = form && form.getFieldValue('skipType')
      if(_.isNil(skipType) && _event.startsAt) skipType = _skipType
      
      let skipValue = form && form.getFieldValue('skipValue')
      if(_.isNil(skipValue) && _event.startsAt) skipValue = _event.skipValue
      
      return {
        id: _event.id,
        sequence,
        dayOfMonth,
        dayOfWeek,
        repeatingType,
        date,
        startsAt,
        endsAt,
        repeatsUntil,
        repeatsUntilType,
        skipType,
        skipValue
      }
  }
}


export default connect((state)=> state )(RepeatingForm)