import Collection from '../collection'

class Messages extends Collection{
    create(options){
        return this.axios.post(`/organizations/${this.props.currentOrganization.id}/messages`, {
            ...options
        });
    }
    fetch(options = {}){
        let params = ''
        let id = ''
        if(options.accountID) params = `?accountID=${options.accountID}`
        if(options.id) id = `/${options.id}`
        delete options.id
        delete options.accountID
        return this.axios.get(`/organizations/${this.props.currentOrganization.id}/messages${id}${params}`, {params:
            {...options}
        });
    }
    fetchActive(options = {}){
        options.active = true
        return this.axios.get(`/organizations/${this.props.currentOrganization.id}/messages`, {params:
            {...options}
        });
    }
}

export default Messages;