import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'

const minnow = document.createElement('script');

minnow.src = (window.location.host.includes('localhost') ? 'http://localhost:4002' : window.location.origin.replace('otter', 'minnow').replace('scheduling', 'minnow')) + `/bundle.js?cacheID=${(new Date()).getTime()}`;
minnow.type = 'application/javascript';
let ready = false; 

minnow.onload = ()=>{
  ready = true
}

document.body.appendChild(minnow);

class Booking extends Component {
  constructor(props, context) {
    super(props, context)
  }

  componentDidMount() {
    this.container.style.width = '100%'
    this.container.style.height = '100%'
    if(!ready){
      minnow.onload = ()=>{
        ready = true
        this.loadWidget(this.props.currentOrganization.id)
      }
    }else{
      this.loadWidget(this.props.currentOrganization.id)
    }
  }

  componentDidUpdate(previousProps){
    if(previousProps.currentOrganization.id != this.props.currentOrganization.id){
      this.loadWidget(this.props.currentOrganization.id)
    }
  }

  loadWidget(organizationID){
    this.container.innerHTML = ''
    const config = {};
    
    if(otterApiClient._options.version) config.version = otterApiClient._options.version
    const widget = NextInLine.init({
      organizationID,
      ...config
    })
   
    widget.events.on('willLogout', ()=>{
      this.props.onLogout()
    })

    widget.actions.SessionActions.set(this.props.account)

    widget.render(this.container);
    window.widget = widget
  }

  render() {
    return (
        <div ref={(e)=>this.container = e}></div>
    )
  }
}

export default withRouter(connect((state)=>(state))(Booking))
