import Collection from 'lib/client/collections/collection'

class Partitions extends Collection{
    fetch(){
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/partitions`);
    }

    create(params){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/partitions`,params);
    }

    update(params){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/partitions/${params.id}`,params);
    }

    destroy(id = ''){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/partitions/${id}`);
    }
}

export default Partitions;