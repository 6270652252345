import React from 'react';
import { Form, Button, Col, Row, Input, Select, DatePicker } from 'antd';
import moment from 'moment'
import {patientActions, genderActions, breedActions, classificationActions, accountActions} from 'actions'
import {connect} from 'react-redux'
import CreateOrEditGender from './CreateOrEditGender'
import CreateOrEditBreed from './CreateOrEditBreed';
import CreateOrEditAccount from './CreateOrEditAccount';
import {apiClient} from 'lib'
import _ from 'lodash';

const TextArea = Input.TextArea;

const {Option} = Select;


class PatientForm extends React.Component {
  constructor(props){
    super(props);
    this.state = { 
      visible: false,
      createGendersVisible: false,
      genders: []
    };

    this.accountSearchTimeout = ''

    this.handleDestroyPatient = this.handleDestroyPatient.bind(this)
    this.handleNewGender = this.handleNewGender.bind(this)
    this.handleGenderClose = this.handleGenderClose.bind(this)
    this.handleEditGender = this.handleEditGender.bind(this)
    this.onSetCurrentGender = this.onSetCurrentGender.bind(this)

    this.handleAccountChange = this.handleAccountChange.bind(this)
    this.handleAccountSearch= this.handleAccountSearch.bind(this)

    this.handleNewSpeciesBreed = this.handleNewSpeciesBreed.bind(this)
    this.handleSpeciesBreedClose = this.handleSpeciesBreedClose.bind(this)
    this.onSetCurrentBreed = this.onSetCurrentBreed.bind(this)
    this.handleEditSpeciesBreed = this.handleEditSpeciesBreed.bind(this)

    this.handleCreateOrEditAccountClose = this.handleCreateOrEditAccountClose.bind(this)
    this.onSetCurrentAccount = this.onSetCurrentAccount.bind(this)
    this.handleEditAccount = this.handleEditAccount.bind(this)

    this.handleFilterSearch = this.handleFilterSearch.bind(this)
    genderActions.fetch();
    breedActions.fetch();
    classificationActions.fetch();
  }

  showDrawer () {
    this.setState({
      visible: true,
    });
  };

  componentDidUpdate(previousProps){
    if(JSON.stringify(previousProps.patient) != JSON.stringify(this.props.patient)){
      this.form && this.form.resetFields();
    }
    if(_.get(previousProps, 'patient.primaryAccountID') != _.get(this, 'props.patient.primaryAccountID')){
      accountActions.safeFetchByID({id: _.get(this, 'props.patient.primaryAccountID')})
    }
  }

  async search(search = ''){
    const {data} = await apiClient.organizations.accounts.fetch({limit:5, search})

    this.setState({
      accounts: data
    })
  }
  
  handleNewGender(value){
    if(value === 'new'){
      this.setState({
        createOrEditGenderVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({genderID: ''});
      }, 1)
    }
  }

  onSetCurrentGender(genderID){
    this.form && this.form.setFieldsValue({genderID});
  }

  handleNewSpeciesBreed(value){
    if(value === 'new'){
      this.setState({
        createOrEditSpeciesBreedVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({breedID: ''});
      }, 1)
    }
  }

  onSetCurrentBreed(breedID){
    this.form && this.form.setFieldsValue({breedID});
  }

  handleEditGender(){
    this.setState({
      createOrEditGenderVisible: true
    })
  }

  handleEditSpeciesBreed(){
    this.setState({
      createOrEditSpeciesBreedVisible: true
    })
  }

  handleGenderClose(){
    this.setState({
      createOrEditGenderVisible: false
    })
  }

  handleAccountChange(value){
    if(value === 'new'){
      this.setState({
        createOrEditAccountVisible: true
      })
      setTimeout(()=>{ 
        // Huge Hack
        // Huge Hack
        // Huge Hack
        // Huge Hack
        this.form && this.form.setFieldsValue({accountID: ''});
      }, 1)
    } 
  }

  handleEditAccount(){
    this.setState({
      createOrEditAccountVisible: true
    })
  }

  onSetCurrentAccount(accountID){
    this.form && this.form.setFieldsValue({accountID});
  }

  handleAccountSearch(value){
    if(this.accountSearchTimeout){
      clearTimeout(this.accountSearchTimeout)
    }
    this.accountSearchTimeout = setTimeout(()=>{this.search(value)},1000)
  }

  handleCreateOrEditAccountClose(){
    this.setState({
      createOrEditAccountVisible: false
    })
  }

  get patient(){
    return this.props.patient || {}
  }

  get gender(){
    const id = this.form && this.form.getFieldValue('genderID')
    return this.props.genders[id]
  }

  get breed(){
    const id = this.form && this.form.getFieldValue('breedID')
    return this.props.breeds[id]
  }

  get handleEditBreed(){
    const id = this.form && this.form.getFieldValue('breedID')
    return this.props.breeds[id]
  }

  async handleDestroyPatient(e){
    e.preventDefault()
    
    await patientActions.destroy({
      accountID: this.account.id,
      id: this.patient.id
    })
    
    this.props.onClose()
  }

  get genders(){
    return Object.entries(this.props.genders || {}).map(([_, a])=> a)
  }

  get breeds(){
    return Object.entries(this.props.breeds || {}).map(([_, a])=> a)
  }

  get classificationsBreeds(){
    return Object.entries(this.props.classificationsBreeds || {}).map(([_, a])=> a).filter((a)=>(_.isNil((a.classification || {}).siblingID)))
  }

  get classifications(){
    return Object.entries(this.props.classifications || {}).map(([_, a])=> a)
  }

  get accounts(){
    return this.state.accounts || Object.entries(this.props.accounts || {}).map(([_, a])=> a)
  }

  get account(){
    return (
      this.props.account || this.props.accounts[this.form && this.form.getFieldValue('accountID') || ''] || {}
    )
  }

  get organization(){
    return this.props.organizations[this.props.currentOrganizationID] || {}
  }

  get form() {
    return this.props.formRef.current
  }

  get weightUnit(){
    let weightUnit = this.patient.weightUnit
    if(!weightUnit){
      switch(this.organization.country){
        case "US":
          return 'lbs'
        case "CA":
          return 'kg'
        default:
          return 'lbs'
      }
    }
    return weightUnit 
  }

  handleFilterSearch(input, option){
    return `${option.props.children}`.toLowerCase().indexOf(`${input}`.toLowerCase()) >= 0
  }

  handleSpeciesBreedClose(value){
    this.setState({
      createOrEditSpeciesBreedVisible: false
    })
  }

  render() {
    let edit = false;
    let editGender = false;
    let editSpeciesBreed = false
    let editAccount = false;
    
    if(this.patient.id){
      edit = true
    }

    if(this.form && this.form.getFieldValue('genderID') !== ''){
      editGender = true
    }

    if(this.form && this.form.getFieldValue('breedID') !== ''){
      editSpeciesBreed = true
    }
    
    if(this.form && this.form.getFieldValue('accountID') && this.form.getFieldValue('accountID') !== 'new'){
      editAccount = true
    }

    const initialValues = {
      accountID: this.account.id || this.patient.primaryAccountID,
      firstName: this.patient.firstName,
      lastName: this.patient.lastName || this.account.lastName,
      breedID: this.patient.breedID,
      genderID: this.patient.genderID,
      birthday: this.patient.birthday ? moment(this.patient.birthday) : moment(),
      weight: this.patient.weight,
      color: this.patient.color
    }

    return (
      <div>
        <CreateOrEditGender
          visible={this.state.createOrEditGenderVisible}
          onClose={this.handleGenderClose}
          onSetCurrentGender={this.onSetCurrentGender}
          gender={this.gender}
        />
        <CreateOrEditBreed
          visible={this.state.createOrEditSpeciesBreedVisible}
          onClose={this.handleSpeciesBreedClose}
          onSetCurrentBreed={this.onSetCurrentBreed}
          breed={this.breed}
        />
        <CreateOrEditAccount
          visible={this.state.createOrEditAccountVisible}
          onClose={this.handleCreateOrEditAccountClose}
          onSetCurrentAccount={this.onSetCurrentAccount}
          account={this.account}
        />
        <Form 
          ref={this.props.formRef}
          initialValues={initialValues}
          onFinish={this.props.onFinish}
          onFinishFailed={this.props.onFinishFailed}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Account" 
              >
                {this.props.hideClientSelect != true ? (
                  <Input.Group style={{ width: '100%' }} compact>
                    <Form.Item
                      noStyle
                      name="accountID"
                      rules={[{required: true, message: 'Please select an Account'}]}
                    >
                        <Select
                          style={{width: editAccount ? 'calc(100% - 57px)' : '100%'}}
                          showSearch
                          placeholder={"Select Account"}
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          notFoundContent={null}
                          onChange={this.handleAccountChange}
                          onSearch={this.handleAccountSearch}
                          >
                          {this.accounts.map((account, i)=>(
                            <Option key={`accounts-${i}`} value={account.id}>{account.firstName} {account.lastName}</Option>
                            ))}
                          <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                          <Option value=''>None</Option>
                          <Option key='new'>New Account</Option>
                        </Select>
                    </Form.Item>
                    {editAccount && 
                      <Button onClick={this.handleEditAccount}>Edit</Button>
                    }
                  </Input.Group>
                ):(
                  <Form.Item
                    noStyle
                    name="accountID"
                  >
                    <Input type="hidden" />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="First Name"
                name="firstName"
                rules={[{required: true, message: 'Please enter a First Name.'}]}
              >
                <Input placeholder="Please enter a name for patient" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Last Name"
                name="lastName"
              >
                <Input placeholder="Please enter a name for patient" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item 
                label="Species / Breed"
              >
                <Input.Group style={{ width: '100%' }} compact>
                  <Form.Item 
                    noStyle
                    name="breedID"
                    rules={[{required: true, message: 'Please select a breed'}]}
                    >
                    <Select filterOption={this.handleFilterSearch} showSearch style={{width: editSpeciesBreed ? 'calc(100% - 57px)' : '100%'}} onChange={this.handleNewSpeciesBreed}>
                      {this.classificationsBreeds.map((cb, i)=>(
                        <Option key={`breed-${i}`} value={cb.breed?cb.breed.id:''}>{cb.classification?cb.classification.name:''} / {cb.breed?cb.breed.name:''}</Option>
                        ))}
                      <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                      <Option value=''>None</Option>
                      <Option key='new'>New Species / Breed</Option>
                    </Select>
                  </Form.Item>
                  {editSpeciesBreed && (
                    <Button onClick={this.handleEditSpeciesBreed}>Edit</Button>
                    )}
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Gender"
              >
                <Input.Group style={{ width: '100%' }} compact>
                  <Form.Item
                    noStyle
                    name="genderID"
                    rules={[{required: true, message: 'Please select a gender'}]}
                    >
                    <Select style={{width: editGender ? 'calc(100% - 57px)' : '100%'}} onChange={this.handleNewGender}>
                      {this.genders.map((gender, i)=>{
                        return <Option key={i} value={gender.id}>{gender.name}</Option>
                      })}
                      <Option key='divider' className='divider' disabled={true}>&nbsp;</Option>
                      <Option value=''>None</Option>
                      <Option key='new'>New Gender</Option>
                    </Select>
                  </Form.Item>
                  {editGender && (
                    <Button onClick={this.handleEditGender}>Edit</Button>
                    )}
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="Birthday"
                name="birthday"
                rules={[{ required: false, message: 'Please select a birthday' }]}
              >
                <DatePicker style={{width: '100%'}}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label={`Weight (${this.weightUnit})`}
                name="weight"
              >
                <Input placeholder="Please input patient weight"/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label={`Color`}
                name="color"
              >
                <Input placeholder="Please input patient color"/>
              </Form.Item>
            </Col>
        </Row>
      </Form>
    </div>
    );
  }
}
export default connect((state)=> state)(PatientForm)