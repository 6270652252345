import Collection from 'lib/client/collections/collection'

class OpenTimes extends Collection{
    fetch(options){
        return this.axios.get(`organizations/${this.props.currentOrganization.id}/openTimes`, options);
    }

    create(options){
        return this.axios.post(`organizations/${this.props.currentOrganization.id}/openTimes`, options);
    }

    update(options){
        return this.axios.put(`organizations/${this.props.currentOrganization.id}/openTimes/${options.id}`, options);
    }
    
    destroy(id){
        return this.axios.delete(`organizations/${this.props.currentOrganization.id}/openTimes/${id}`);
    }
}

export default OpenTimes;