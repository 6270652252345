import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';

import store from '../store'

export const fetch = async(params = {})=>{
    // const account = await apiClient.currentAccount.fetch()
    
    // localStorage.setItem('account', JSON.stringify(account.data || {}))

    // store.dispatch({ 
    //   type: ActionTypes.FETCH_CURRENT_ACCOUNT,
    //   payload: account.data
    // })
}