import * as ActionTypes from '../constants/actionTypes'
import { apiClient } from '../lib';

import store from '../store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.messages.fetch(params)

  let data = {};
  if(response.data.id){
    data[response.data.id] = response.data
  }else{
    data = response.data.reduce((acc, item)=>{
      acc[item.id] = item
      return acc;
    }, {})
  }

  store.dispatch({ 
    type: ActionTypes.FETCH_MESSAGES,
    payload: data
  })
}

const fetching = {}
export const safeFetchByID = async(params = {})=>{
  if(!fetching[params.id] && !store.getState().messages[params.id]){
    fetching[params.id] = true
    await fetch({id: params.id})
    fetching[params.id] = false
  }
}

export const create = async(params = {}) => {
  const response = await apiClient.organizations.messages.create(params);

  (response.data || []).map((data)=>{
    store.dispatch({
      type: ActionTypes.CREATE_MESSAGE,
      payload: data
    })
  })

  return response.data
}