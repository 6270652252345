import * as ActionTypes from 'constants/actionTypes'
import { apiClient } from 'lib';

import store from 'store'

export const fetch = async(params = {})=>{
  const response = await apiClient.organizations.breeds.fetch(params)

  const data = response.data.reduce((acc, item)=>{
    acc[item.id] = item
    return acc;
  }, {})

  store.dispatch({ 
    type: ActionTypes.FETCH_BREEDS,
    payload: data
  })
}

export const update = async(params = {}) => {
   const response = await apiClient.organizations.breeds.update(params)

  store.dispatch({
    type: ActionTypes.UPDATE_BREED,
    payload: response.data
  })

  return response.data
}

export const activate = async(ids = []) => {
  const response = await apiClient.organizations.breeds.activate({ids})

  store.dispatch({
    type: ActionTypes.UPDATE_BREEDS,
    payload: response.data
  })

  return response.data
}

export const destroy = async(id) => {
  await apiClient.organizations.breeds.destroy(id)

  store.dispatch({
    type: ActionTypes.DESTROY_BREED,
    payload: id
  })
}

export const create = async(params = {}) => {
  const response = await apiClient.organizations.breeds.create(params)

  store.dispatch({
    type: ActionTypes.CREATE_BREED,
    payload: response.data
  })

  return response.data
}

