import React from 'react';
import { Popconfirm, Form, Col, Row, Button, Input, Switch, DatePicker, Select } from 'antd';
import moment from 'moment'
import {connect} from 'react-redux'
import _ from 'lodash'
import {
  organizationActions
} from 'actions'
import { withRouter } from 'react-router-dom'


class WidgetAdvancedFields extends React.Component {
  constructor(){
    super();
    this.state = {
      addAdvancedField: false,
      widgetAdvancedToggled: false,
    }
    this.handleAddAdvancedField = this.handleAddAdvancedField.bind(this)
    this.handleCondenseToggle = this.handleCondenseToggle.bind(this)
    this.handleWidgetType = this.handleWidgetType.bind(this)
    this.handleWidgetValue = this.handleWidgetValue.bind(this)
    this.handleLimitToggle = this.handleLimitToggle.bind(this)
    this.handleBasicSubmit = this.handleBasicSubmit.bind(this)
  }

  componentDidMount() {    
    let widgetAdvanced = this.widgetAdvanced
    this.setState({
      widgetAdvancedToggled: !!this.props.currentOrganization.widgetAdvanced,
      condenseAdminsToggled: !!(this.props.currentOrganization.widgetSettings || {}).condenseAdministrators,
      widgetPeriod: widgetAdvanced.period,
      widgetValue: widgetAdvanced.value,
      widgetType: widgetAdvanced.type,
    })
  }



  handleLimitToggle(value){
    this.setState({
      widgetAdvancedToggled: value
    })
  }

  handleWidgetType(value){
    this.setState({
      widgetType: value
    })
  }

  handleCondenseToggle(value){
    this.setState({
      condenseAdminsToggled: value
    })
  }

  handleWidgetValue(e){
    this.setState({
      widgetValue: e.target.value
    })
  }

  handleAddAdvancedField(){
    this.setState({
      addAdvancedField: !this.state.addAdvancedField 
    })
  }

  get advancedFields(){
    return _.sortBy(Object.values(this.props.advancedFields || {}), (advancedField) => (
      advancedField.createdAt
    ))
  }

  get widgetAdvanced(){
    let values = (this.props.currentOrganization.widgetAdvanced || '').split('_')
    let data = {
      period: this.state.widgetPeriod || values[0],
      value: this.state.widgetValue || values[1],
      type: this.state.widgetType || values[2]
    }
    return data
  }

  async handleBasicSubmit(values){
    organizationActions.update(values)
  }

  handleAdvancedChange(e){
    let value = e.target.value
    setTimeout(()=>{ 
      // Huge Hack
      // Huge Hack
      // Huge Hack
      // Huge Hack
      this.form && this.form.setFieldsValue({advanced: value});
    }, 1)
  }

  render() {
    return (
      <Form layout="vertical" onFinish={this.handleBasicSubmit} initialValues={{waitingRoomClosedMessage: _.get(this, "props.currentOrganization.settings.waitingRoomClosedMessage")}}>
            <div>
              {(this.state.widgetAdvancedToggled || _.get(this, "props.currentOrganization.settings.waitingRoomClosedMessage")) &&
                <div>
                  <br />
                  <Form.Item
                    {...Form.ItemLayout}
                    label="Waiting Room custom message"
                    name="waitingRoomClosedMessage"
                    rules={[{required: false, message: 'Please enter message to display.'}]}
                  >
                    <Input onChange={this.handleAdvancedChange} placeholder={"We're sorry, the waiting room is closed right now."} />
                  </Form.Item>
                </div>
              }
              <label style={{paddingRight: "20px"}}>Toggle Advanced:</label>
              <Switch
                checked={!!(this.state.widgetAdvancedToggled || _.get(this, "props.currentOrganization.settings.waitingRoomClosedMessage"))} 
                onChange={this.handleLimitToggle}
              />
              <br />
              <br />
              <br />
              <Button type="primary" htmlType="submit">Update</Button>
            </div>
      </Form>
    );
  }
}
export default withRouter(connect((state)=>state)(WidgetAdvancedFields))