import {
    FETCH_INSIGHTS
  } from '../constants/actionTypes'
  
  export default (state = {}, action) => {
    switch (action.type) {
      case FETCH_INSIGHTS:
        return {
          ...state,
          ...action.payload
        }
      default:
        return state
    }
  }
  