import React from 'react'
import { Form, Card } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import {AccountForm} from 'components/partials'
import GeneralEditor from 'components/elements/GeneralEditor'
import Scroller from 'components/elements/Scroller'
import {accountActions} from 'actions'

const dateFormat = 'DD/MM/YYYY';

class Edit extends React.Component {
  constructor(){
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit (values) {
    const _account = {
      firstName: values.firstName,
      middleInitial: values.middleInitial,
      lastName: values.lastName,
      email: values.email,
      mobilePhone: values.mobilePhone,
      workPhone: values.workPhone,
      homePhone: values.homePhone,
      address: values.address,
      unit: values.unit,
      city: values.city,
      state: values.state,
      zip: values.zip,
      county: values.county,
      birthday: values.birthday
    }

    try{
      let account
      if(this.account.id){
        account = await accountActions.update({
            id: this.account.id,
            ..._account
        })
      } else {
        account = await accountActions.create(_account)
      }
      this.props.history.push(`/admin/accounts/${account.id}`)
    } catch(error){
      // let {data,errors} = error.response.data

      // let errorData = {}
      // data.forEach((key)=>{
      //   errorData[key] = {errors:[new Error('This value already exists')]}
      // })

      // this.props.form.setFields(errorData);
    }
  }

  get account(){
    return this.props.account || {}
  }
  handleCancel(){
    this.props.history.push(`/admin/accounts/${this.account.id}`)
  }
  
  render() {
    return (
      <div className='edit-switch'>
        <Form onFinish={this.handleSubmit}>
          <GeneralEditor onCancel={this.handleCancel} onSave={this.handleSubmit}>
            <Scroller>
              <Card bordered={false}>
                <AccountForm form={this.props.form} account={this.account} />
              </Card>
            </Scroller>
          </GeneralEditor>
        </Form>
      </div>
    )
  }
}

export default withRouter(
  connect((state)=> state )(
    (Edit)
  )
)